<template>
  <section
    class="app-section"
    :class="{
      '_full-width': isFullWidth,
      '_is-container': isContainer,
      '_no-y-padding': isNoYPadding
    }"
  >
    <div v-if="isTitle" class="app-section__header">
      <div
        class="app-section__title"
        :class="{_centered: isCentered}"
      >
        <content-placeholders rounded>
          <content-placeholders-text
            :lines="1"
            style="width: 400px; height: 28px;"
          />
        </content-placeholders>
      </div>
    </div>
    <div class="app-section__content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppSectionSkeleton',
  props: {
    isTitle: { type: Boolean, default: true },
    isCentered: { type: Boolean, default: false },
    isFullWidth: { type: Boolean, default: false },
    isContainer: { type: Boolean, default: true },
    isNoYPadding: { type: Boolean, default: false },
  },
};
</script>

<style lang="less">
.app-section {
  &._mobile._is-container {
    .container-m;
  }

  &._mobile {
    padding-top: 0;
    padding-bottom: @size-x5;
  }

  &._mobile &__title {
    padding-top: @size-x3;
    overflow: hidden;
  }

  &._mobile &__header {
    margin-bottom: @size-x2;
    padding-right: @size-x4;
    padding-left: @size-x4;
  }

  &._no-y-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
