import isEmpty from 'lodash.isempty';

export const formattedCoupon = (coupon) => {
  let formattedCouponObj = null;

  if (coupon && !isEmpty(coupon)) {
    formattedCouponObj = {
      value: coupon.coupon.toLowerCase(),
      description: coupon.description,
      apply: coupon.apply,
    };
  }

  return formattedCouponObj;
};
