import { useStore, computed } from '@nuxtjs/composition-api';

const useAuthorizations = () => {
  const { state } = useStore();

  const isAuthorized = computed({
    get: () => state.isAuthorized,
    set: () => {},
  });

  return { isAuthorized };
};

export default useAuthorizations;
