import { defaultErrorsList } from '@/utils/formatters/formattedError';

export const ERRORS_GET_DETAIL_SERIES = {
  ...defaultErrorsList,
  seriesNotFound: {
    code: 'b9256968-9df5-448b-93ac-d23017f10d91',
    message: 'Серия не найдена',
  },
  isSeriesNotFound(code) {
    return code === this.seriesNotFound.code;
  },
};
