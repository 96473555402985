/**
 * Форматирование даты
 * @param {Date|string} date
 * @param {Object} [options]
 * @param {string} [options.weekday] - Представление дней недели. Возможными значениями являются "narrow", "short" и "long".
 * @param {string} [options.era] - Представление эр. Возможными значениями являются "narrow", "short" и "long".
 * @param {string} [options.year] - Представление лет. Возможными значениями являются "numeric" и "2-digit".
 * @param {string} [options.month] - Представление месяцев. Возможными значениями являются "numeric", "2-digit", "narrow", "short" и "long".
 * @param {string} [options.day] - Представление часов. Возможными значениями являются "numeric" и "2-digit".
 * @param {string} [options.minute] - Представление минут. Возможными значениями являются "numeric" и "2-digit".
 * @param {string} [options.second] - Представление секунд. Возможными значениями являются "numeric" и "2-digit".
 * @param {string} [options.timeZoneName] - Представление названий часовых поясов. Возможными значениями являются "short" и "long".
 * @return {string}
 */
export default (date, options = {}) => new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
