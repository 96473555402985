import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';

import { ERRORS_MNOGO_RU } from '@/services/v1/MnogoRuService/errorEnum';
import { ERROR_SERVER_ERROR, MNOGORU_COUPON_LENGTH } from '@/utils/constants/globalConst';

const checkCard = (card, reject) => {
  if (typeof card !== 'string') {
    return reject(new ServiceError(ERRORS_MNOGO_RU.cardNotString));
  }
  if (card.length === 0) {
    return reject(new ServiceError(ERRORS_MNOGO_RU.cardEmpty));
  }
  if (card.length !== MNOGORU_COUPON_LENGTH) {
    return reject(new ServiceError(ERRORS_MNOGO_RU.cardFixLength));
  }
};

export default class MnogoRuService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  fetchAppliedMnogoRuCard() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/sale/order/coupon-mnogoru/')
        .then(({ data: { data: dataRes } }) => {
          resolve(dataRes?.coupon ?? '');
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_MNOGO_RU);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  applyMnogoRuCard(card) {
    return new Promise((resolve, reject) => {
      checkCard(card, reject);

      this.mainHttp.post('/api/v1/sale/order/coupon-mnogoru/', {
        COUPON: card,
      })
        .then(({ data: { data: dataRes } }) => {
          resolve(dataRes?.coupon ?? '');
        })
        .catch((error) => {
          if (error.response) {
            const errors = buildErrorCollection(error.response.data, ERRORS_MNOGO_RU);
            const [firstError] = errors;
            if (firstError.message) firstError.message = firstError.message.replace('много.ру', 'Много.ру');
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  removeAppliedMnogoRuCard(card) {
    return new Promise((resolve, reject) => {
      checkCard(card, reject);

      this.mainHttp.delete(`/api/v1/sale/order/coupon-mnogoru/${card}/`)
        .then(() => resolve())
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_MNOGO_RU);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }
}
