<template>
  <div
    :class="{
      _mobile: isMobile,
      _form: isForm,
    }"
    class="app-logo-group"
  >
    <div
      v-for="(logo, index) of logos"
      :key="index"
      class="app-logo-group__logo"
    >
      <img
        :src="logo.image"
        :alt="logo.alt"
        class="app-logo-group__logo-img"
      />
      <div class="app-logo-group__divider">
        ×
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppLogoGroup',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const logos = [
      {
        image: '/_ssr/img/authorization/logo-book24.svg',
        alt: 'Логотип Book24.ru',
      },
      {
        image: '/_ssr/img/authorization/logo-chg.svg',
        alt: 'Логотип «Читай-город»',
      },
      {
        image: '/_ssr/img/authorization/logo-bookvoed.svg',
        alt: 'Логотип «Буквоед»',
      },
    ];

    return { logos };
  },
});
</script>

<style lang="less">
.app-logo-group {
  display: inline-flex;
  align-items: center;
  background: @white;
  padding: @size-x3 @size-x5;
  border-radius: @size-x1;

  &._form {
    display: flex;
    padding: @size-x2 0 @size-x1;
    background: none;
    border-radius: unset;
  }

  &__logo {
    display: flex;
    align-items: center;

    &:last-of-type > div {
      display: none;
    }
  }

  &__logo-img {
    max-width: 100%;
    width: 100%;
    height: @size-x6;
  }

  &__divider {
    padding: 0 @size-x3;
    color: @gray;
  }

  &._form & {
    &__divider {
      padding: 0 @size-x2;
    }

    &__logo-img {
      height: auto;
    }
  }

  &._mobile {
    display: flex;
    justify-content: center;
  }

  &._mobile & {
    &__logo-img {
      height: @size-x4;
    }
  }
}
</style>
