<template>
  <div
    :class="{ _mobile: isMobile }"
    class="authorization-union-loyalty"
  >
    <h4
      v-if="isAutocomplete"
      class="authorization-union-loyalty__title"
    >
      Единая программа лояльности
    </h4>
    <p class="authorization-union-loyalty__desc">
      <template v-if="isAutocomplete">
        Нашли вашу бонусную карту в программе
        лояльности и подставили в анкету данные,
        которые вы уже указывали.
      </template>
      <template v-else>
        Если у вас есть аккаунты на наших сайтах-партнерах,
        мы обновим в них анкетные данные, чтобы бонусная
        карта продолжила работать.
      </template>
    </p>
    <app-link
      v-if="isAutocomplete"
      href="/cashback/"
      blank
      class="authorization-union-loyalty__link"
    >
      Узнать больше о программе
    </app-link>
    <app-logo-group
      :is-form="true"
      class="authorization-union-loyalty__logos"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import AppLogoGroup from '@/components/AppLogos/index.vue';

export default defineComponent({
  name: 'AuthorizationUnionLoyalty',
  components: { AppLogoGroup },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    isAutocomplete: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="less">
.authorization-union-loyalty {
  padding: @size-x3 @size-x4;
  background: @blue-back-2;
  border-radius: @size-x1;

  &__title {
    .h4-desktop;

    margin-top: 0;
    margin-bottom: @size-x2;
    font-weight: 600;
  }

  &__desc {
    .small;

    margin-top: 0;
    margin-bottom: @size-x2;
  }

  &__link {
    .small;

    display: inline-block;
    font-weight: 600;
    margin-bottom: @size-x2;
  }

  &__logos {
    display: flex;
    align-items: center;
    padding: @size-x2 0 @size-x1;
  }

  &__logo {
    display: flex;
    align-items: center;

    &:last-of-type > div {
      display: none;
    }
  }

  &__logo-img {
    max-width: 100%;
    width: 100%;
  }

  &__divider {
    padding: 0 @size-x2;
    color: @gray;
  }

  &._mobile {
    padding: @size-x2 0;
  }
}
</style>
