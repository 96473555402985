import md5 from 'md5';
import { getArrayBanners } from '@/utils/analytics/getArrayBanners';
import { getArrayItems } from '@/utils/analytics/getArrayItems';
import { pushEventToDataLayer } from '@/utils/analytics/pushEventToDataLayer';
import { ROUBLE_CODE } from '@/utils/constants/globalConst';
import { getItemListName } from '@/utils/analytics/getItemListName';
import { getTargetProperties } from '@/utils/analytics/getTargetProperties';
import { setProductAnalyticProperties } from '@/utils/analytics/setProductAnalyticProperties';
import { getBasketTotalPrice } from '@/utils/analytics/getBasketTotalPrice';

// Просмотр списка товаров
export const analyticViewItemList = (params) => {
  pushEventToDataLayer({
    event: 'view_item_list',
    ecommerce: {
      items: getArrayItems(params),
    },
  });
};

// Клик по мини-карточке товара
export const analyticSelectItem = (params) => {
  pushEventToDataLayer({
    event: 'select_item',
    ecommerce: {
      items: getArrayItems(params),
    },
  });
};

// Просмотр детальной страницы товара
export const analyticViewItem = (params) => {
  pushEventToDataLayer({
    event: 'view_item',
    ecommerce: {
      items: getArrayItems(params),
    },
  });
};

// Просмотр корзины
export const analyticViewCart = (params) => {
  pushEventToDataLayer({
    event: 'view_cart',
    ecommerce: {
      value: params.value,
      currency: ROUBLE_CODE,
      items: getArrayItems(params.items),
    },
  });
};

// Просмотр категории
export const analyticViewCategory = (route, tbkCode) => {
  const isContentPage = route.meta?.ddm?.type !== 'listing';
  const isAuthorPage = route.name === 'author';
  if (isContentPage || isAuthorPage) return;

  const data = {
    event: 'view_category',
    page_type: getItemListName(route.name),
  };

  const categoryId = route.query?.section_id || route.params?.sectionId;
  if (categoryId) data.id_category = Number(categoryId);

  const categoryTbk = tbkCode || null;
  if (categoryTbk) data.id_category_tbk = Number(categoryTbk);

  pushEventToDataLayer(data);
};

// Добавление / удаление из корзины
export const analyticToggleCart = (params) => {
  pushEventToDataLayer({
    event: params.isAdd ? 'add_to_cart' : 'remove_from_cart',
    ecommerce: {
      items: getArrayItems(params.items),
    },
  });
};

// Добавление / удаление из избранного
export const analyticToggleWishlist = (params) => {
  pushEventToDataLayer({
    event: params.isAdd ? 'add_to_wishlist' : 'remove_from_wishlist',
    ecommerce: {
      items: getArrayItems(params.items),
    },
  });
};

// Обновление корзины
export const analyticUpdateCart = (params) => {
  pushEventToDataLayer({
    event: 'update_cart',
    ecommerce: {
      value: params.value,
      currency: ROUBLE_CODE,
      items: getArrayItems(params.items),
    },
  });
};

// Нажали на кнопку "Перейти к оформлению" в корзине
export const analyticBeginCheckout = (params) => {
  pushEventToDataLayer({
    event: 'begin_checkout',
    ecommerce: {
      items: getArrayItems(params.items, params.route_name),
    },
  });
};

// Пользователь указывает свои платежные данные во время оформления покупки
export const analyticAddPaymentInfo = (params) => {
  const ecommerce = {
    currency: ROUBLE_CODE,
    payment_type: params.type,
    items: getArrayItems(params.items),
  };

  if (params.coupon) ecommerce.coupon = params.coupon;

  pushEventToDataLayer({
    event: 'add_payment_info',
    ecommerce,
  });
};

// Пользователь выбирает данные о доставке
export const analyticAddShippingInfo = (params) => {
  const ecommerce = {
    currency: ROUBLE_CODE,
    shipping_tier: params.type,
    items: getArrayItems(params.items),
  };

  if (params.coupon) ecommerce.coupon = params.coupon;

  pushEventToDataLayer({
    event: 'add_shipping_info',
    ecommerce,
  });
};

// Завершение покупки
export const analyticPurchase = (params) => {
  const ecommerce = {
    transaction_id: params.orderId,
    value: params.basketTotalPrice,
    currency: ROUBLE_CODE,
    payment_type: params.payment,
    shipping_type: params.shipping.type,
    shipping: params.shipping.price,
    items: getArrayItems(params.products),
  };

  if (params.coupon) ecommerce.coupon = params.coupon;

  pushEventToDataLayer({
    event: 'purchase',
    ecommerce,
  });
};

// Просмотр баннера
export const analyticViewPromotion = (params) => {
  pushEventToDataLayer({
    event: 'view_promotion',
    ecommerce: {
      items: getArrayBanners(params),
    },
  });
};

// Клик по баннеру
export const analyticSelectPromotion = (params) => {
  pushEventToDataLayer({
    event: 'select_promotion',
    ecommerce: {
      items: getArrayBanners(params),
    },
  });
};

// Просмотр сниппета
export const analyticViewSnippet = (params) => {
  pushEventToDataLayer({
    event: 'view_snippet',
    ecommerce: {
      items: params.map(item => ({
        header_block: item.title,
        name_snippet: item.names,
        snippet_link: item.links,
        index_block: item.index,
      })),
    },
  });
};

// Клик по сниппету
export const analyticSelectSnippet = (params) => {
  pushEventToDataLayer({
    event: 'select_snippet',
    ecommerce: {
      items: params.map(item => ({
        header_block: item.title,
        name_snippet: item.names,
        snippet_link: item.links,
        index_block: item.index,
      })),
    },
  });
};

// Авторизация пользователя
export const analyticStart = (user) => {
  pushEventToDataLayer({
    event: 'start',
    user_id: Number(user.id),
    email: user.email,
    phone_number: user.phone,
    email_md5: md5(user.email),
    phone_number_md5: md5(user.phone),
    first_name: user.firstName,
    last_name: user.lastName,
  });
};

// Открывается форма авторизации
export const analyticAuthFormOpen = (route, entryPoint) => {
  pushEventToDataLayer({
    event: 'authRegFormOpened',
  });
  pushEventToDataLayer({
    event: 'view_popup_form',
    params: {
      item_list_name: getItemListName(route.name),
      point_entry: entryPoint,
    },
  });
};

// Клики по кнопкам в форме авторизации
export const analyticAuthFormButtonClick = (buttonName) => {
  pushEventToDataLayer({
    event: 'form_button',
    params: {
      button_name: buttonName,
    },
  });
};

// Клиент запрашивает код
export const analyticAuthRequestCode = () => {
  pushEventToDataLayer({
    event: 'authRegRequestConfirmationCode',
  });
};

// Клиент ввел неверный код подтверждения
export const analyticAuthIncorrectCode = () => {
  pushEventToDataLayer({
    event: 'authRegConfirmationCodeIncorrect',
  });
};

// Клиент ввел проверочный код, он оказался верным
export const analyticAuthCorrectCode = () => {
  pushEventToDataLayer({
    event: 'authRegConfirmationSuccess',
  });
};

// Клиент ввел проверочный код, он оказался верным и мы авторизовываем клиента
export const analyticAuthSuccess = () => {
  pushEventToDataLayer({
    event: 'authSuccess',
  });
};

// Клиент ввел проверочный код, он оказался верным и мы авторизовываем клиента
export const analyticAuthRegFormOpen = () => {
  pushEventToDataLayer({
    event: 'regFormOpened',
  });
};

// Клиент заполнил форму регистрации и мы ее отправили на бэкенд и там успешно все сохранилось
export const analyticAuthRegSuccess = () => {
  pushEventToDataLayer({
    event: 'regSuccess',
  });
};

// Клиент заполнил форму регистрации и мы ее отправили на бэкенд и там вернулась ошибка
export const analyticAuthRegError = (error) => {
  pushEventToDataLayer({
    event: 'regError',
    value: error?.message || error,
  });
};

// Клиент отправил успешный запрос на авторизацию или регистрацию
export const analyticAuthFormSubmitSuccess = (action, byTinkoff) => {
  if (!action) return;
  pushEventToDataLayer({
    event: 'form_submit',
    params: {
      successful: action,
      login_tinkoff: !!byTinkoff,
    },
  });
};

// Клиент отправил неуспешный запрос на авторизацию или регистрацию
export const analyticAuthFormSubmitError = (action, error) => {
  pushEventToDataLayer({
    event: 'form_submit',
    params: {
      successful: action,
      errors: error?.message || error,
    },
  });
};

// Клиент нажал "Выйти" из аккаунта
export const analyticAuthLogout = () => {
  pushEventToDataLayer({
    event: 'logged_out',
  });
};

export const analyticAuthOpenCaptcha = (requestName) => {
  pushEventToDataLayer({
    event: 'open_google_captcha',
    requestName,
  });
};

// Просмотр остальных страниц
export const analyticViewOther = (route, orderStatus, isCheckout) => {
  const pageType = getItemListName(route.name, orderStatus);

  const isAuthorPage = route.name === 'author';
  const isCatalogPage = route.meta?.ddm?.type === 'listing';
  const excludedPages = ['dashboard', 'product'];

  if (!isCheckout) excludedPages.push('checkout-page');
  if (!orderStatus) excludedPages.push('personal-order-page');

  if ((isCatalogPage && !isAuthorPage) || excludedPages.includes(pageType)) return;

  const prevEvent = window.dataLayer.slice(-1)[0];

  if (prevEvent.event === 'view_other' && prevEvent.page_type === pageType) return;

  pushEventToDataLayer({
    event: 'view_other',
    page_type: pageType,
  });
};

// Просмотр страницы спасибо
export const analyticViewThankYouPage = (order, isPreOrder) => {
  const items = order.basket?.basketItem || order.cart.cartItem;
  pushEventToDataLayer({
    event: 'thank_you_page',
    page_type: 'thanks',
    order_id: Number(order.id),
    value: getBasketTotalPrice(order.amount, true),
    items: items?.map(item => ({
      ...item,
      targetProperties: getTargetProperties(item?.basketItemProperty),
    })).map((item, index) => setProductAnalyticProperties(item, index, isPreOrder ? 'product' : 'cart')),
  });
};

// Просмотр страницы результата поиска по ключевому запросу пользователя
export const analyticViewSearchResults = ({ query, count, products }) => {
  const productIds = products.map(product => product.id);

  function splitArrayIntoChunks(arr, chunkSize) {
    return arr.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!result[`item_id_${chunkIndex + 1}`]) {
        result[`item_id_${chunkIndex + 1}`] = [];
      }

      result[`item_id_${chunkIndex + 1}`].push(String(item));

      return result;
    }, {});
  }

  pushEventToDataLayer({
    event: 'view_search_results',
    search_term: query,
    item_results: count,
    content_type: 'search_result',
    ...splitArrayIntoChunks(productIds, 6),
  });
};
