import LoadScript from '@/utils/loadScript';

const url = `https://www.google.com/recaptcha/api.js?render=${process.env.APP_GOOGLE_RECAPTCHA_PUBLIC_KEY_V3}`;

export default () => new Promise((resolve, reject) => {
  if (window.grecaptcha) {
    resolve(window.grecaptcha);
  } else {
    LoadScript(url)
      .then(() => window.grecaptcha.ready(() => resolve(window.grecaptcha)))
      .catch(reject);
  }
});
