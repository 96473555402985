import {
  computed,
  onMounted,
  reactive,
  toRefs,
  useContext,
  watch,
  watchEffect,
  useStore,
} from '@nuxtjs/composition-api';

import {
  ERROR_USER_ALREADY_HAVE,
  LOGIN,
  ERROR_EMAIL_BLOCKED,
  AUTH_ISSUE_ID_KEY,
  REGISTRATION,
  SUCCESS,
} from '@/utils/constants/authorizationConstants';
import {
  analyticAuthFormSubmitError,
  analyticAuthFormSubmitSuccess,
  analyticAuthRegError,
  analyticAuthRegSuccess,
  analyticAuthFormButtonClick,
} from '@/utils/analytics/analyticEvents';

import { useVuelidateForm } from '@/compositions/useVuelidateForm';
import useCommonModal from '@/compositions/useCommonModal';
import useAuthorization from '@/compositions/authorization/useAuthorization';
import useHttpClient from '@/compositions/useHttpClient';

const useRegistration = () => {
  const store = useStore();
  const { $cookies } = useContext();
  const { auth, product } = useHttpClient();
  const { modal } = useCommonModal();
  const authorization = useAuthorization();

  const initialData = reactive({
    email: '',
    firstName: '',
    lastName: '',
  });

  const localState = reactive({
    email: '',
    firstName: '',
    lastName: '',
    subscribed: true,
    emailAlreadyTaken: false,
    isAgreeWithRules: true,
    isLoading: false,
    isAutocomplete: false,
    isAutocompleteError: false,
    isAlert: false,
  });

  const validation = useVuelidateForm(localState, REGISTRATION);

  const errorTextPolicy = computed(() => (localState.isAgreeWithRules ? '' : 'Ознакомьтесь и согласитесь с условиями'));

  const buttonDisabled = computed(() => localState.isLoading
    || validation.v$.value.$invalid
    || localState.emailAlreadyTaken
    || !localState.isAgreeWithRules);

  const buttonTextAnotherPhone = 'Указать другой номер телефона';

  const registrationByAnotherPhone = () => {
    analyticAuthFormButtonClick(buttonTextAnotherPhone);
    localStorage.removeItem(AUTH_ISSUE_ID_KEY);
    $cookies.remove(AUTH_ISSUE_ID_KEY);
    authorization.changeModeHandler(LOGIN);
  };

  const autocompleteRegistration = async () => {
    const authIssueId = authorization.authIssueId.value;
    if (!authIssueId) return;

    try {
      const { firstName, lastName, email } = await auth.autocompleteRegistration({ authIssueId });

      if (firstName || lastName || email) {
        localState.isAutocomplete = true;
        localState.firstName = firstName;
        localState.lastName = lastName;
        localState.email = email;

        initialData.firstName = firstName;
        initialData.lastName = lastName;
        initialData.email = email;
      }
    } catch {
      localState.isAutocompleteError = true;
    }
  };

  watch(() => localState.email,
    () => localState.emailAlreadyTaken = false);

  watchEffect(() => {
    localState.isAlert = localState.email !== initialData.email
      || localState.firstName !== initialData.firstName
      || localState.lastName !== initialData.lastName;
  });

  const registration = async () => {
    const authIssueId = authorization.authIssueId.value;
    const customerId = authorization.customerId.value;

    const payload = {
      authIssueId,
      customer: {
        name: localState.firstName,
        lastName: localState.lastName,
        email: localState.email,
        subscribed: localState.subscribed,
      },
    };

    if (customerId) {
      payload.customerId = customerId;
    }

    await auth.registration(payload)
      .then(() => {
        analyticAuthRegSuccess();
        analyticAuthFormSubmitSuccess(REGISTRATION);

        const payloadLogin = { authIssueId };

        if (customerId) {
          payloadLogin.customerId = customerId;
        }

        localStorage.removeItem(AUTH_ISSUE_ID_KEY);
        $cookies.remove(AUTH_ISSUE_ID_KEY);

        auth.login(payloadLogin)
          .then(async () => {
            analyticAuthFormSubmitSuccess(LOGIN);
            const promoCode = await product.getCouponForUser();
            if (promoCode) store.commit('setPromoCode', promoCode);
            authorization.changeModeHandler(SUCCESS);
            await authorization.authorizationHandler();
          });
      })
      .catch((error) => {
        localState.isLoading = false;
        analyticAuthRegError(error);
        analyticAuthFormSubmitError(REGISTRATION, error);
        if (error.code === ERROR_USER_ALREADY_HAVE || error.code === ERROR_EMAIL_BLOCKED) {
          localState.emailAlreadyTaken = true;
        }
      });
  };

  const buttonText = 'Зарегистрироваться';

  const submitHandler = async () => {
    analyticAuthFormButtonClick(buttonText);
    localState.isLoading = true;
    localState.emailAlreadyTaken = false;
    await registration();
  };

  const showInfoHandler = () => {
    modal.show({
      component: () => import('@/components/AppModalDrawer/index.vue'),
      slots: {
        default: {
          component: () => import('@/components/Authorization/CashbackTooltip/index.vue'),
          bind: {
            isMobile: true,
          },
        },
      },
    });
  };

  onMounted(async () => {
    await autocompleteRegistration();
  });

  return {
    ...toRefs(localState),
    ...validation,
    buttonText,
    buttonDisabled,
    errorTextPolicy,
    buttonTextAnotherPhone,
    showInfoHandler,
    submitHandler,
    registrationByAnotherPhone,
  };
};

export default useRegistration;
