<template>
  <div class="error-page-500" :class="{'_mobile': isMobile}">
    <div class="error-page-500__icon-box">
      <svg-icon
        name="52/Error_52"
        class="error-page-500__icon"
        :width="52"
        :height="52"
      />
    </div>
    <p class="error-page-500__title">
      Произошла ошибка сервера
    </p>
    <div class="error-page-500__desc">
      <p>Попробуйте перезагрузить страницу и начните сначала.</p>
      <p>
        Если это не поможет, обратитесь в службу поддержки
        <a class="page__error-email" href="mailto:imarket@book24.ru">
          imarket@book24.ru
        </a>
      </p>
    </div>
    <div v-show="false" class="error-page-500__desc">
      <pre>{{ error.message }}</pre>
    </div>
    <div class="error-page-500__button">
      <button-control type="secondary" @click="handlerReloadPage">
        Перезагрузить страницу
      </button-control>
    </div>
  </div>
</template>

<script>
import { ButtonControl } from '@book24/ui-components';

export default {
  name: 'Error500',
  components: {
    ButtonControl,
  },
  props: {
    isMobile: { type: Boolean, default: false },
    error: { type: Object, default: () => ({}) },
  },
  head: {
    title: 'Извините, произошла ошибка сервера',
  },
  methods: {
    handlerReloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="less">
.error-page-500 {
  .container-d;

  padding-top: @size-x10;
  padding-bottom: @size-x10;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    fill: @pink-error;
  }

  &__title {
    .h3-mobile;

    font-weight: bold;
    margin: @size-x6 0 0;
  }

  &__desc {
    .p;

    margin: @size-x2 0 0;
  }

  &__button {
    margin: @size-x6 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &._mobile {
    .container-m();
  }
}
</style>
