/**
 * Возвращает объект для свойства meta роута
 * @param {Object} [options={}]
 * @param {string} [options.ddmCategory]
 * @param {string} [options.ddmType]
 * @return {{}}
 */
export default (options = {}) => {
  const resultObj = { ...options };

  if (options.ddmCategory) {
    if (!resultObj.ddm) resultObj.ddm = {};
    resultObj.ddm.category = options.ddmCategory;
    delete resultObj.ddmCategory;
  }

  if (options.ddmType) {
    if (!resultObj.ddm) resultObj.ddm = {};
    resultObj.ddm.type = options.ddmType;
    delete resultObj.ddmType;
  }

  return resultObj;
};
