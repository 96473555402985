/**
 * Вычисляем сколько товаров добавили или удалили из корзины
 * @param {*} oldValue
 * @param {*} newValue
 * @returns
 */
export const getHowMuchQuantityChanged = (oldValue, newValue) => {
  if (oldValue > newValue) {
    const quantity = oldValue - newValue;
    const isAdded = false;
    return { quantity, isAdded };
  }

  const quantity = newValue - oldValue;
  const isAdded = true;

  return { quantity, isAdded };
};
