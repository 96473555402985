// @ts-check
/// <reference path="./typedefs.js" />

import { priceFormatted } from '@/utils/formatters/formattedPrice';
import formatDate from '@/utils/formatDate';
import { BOXBERRY_PVZ, DHL_COURIER, PICKPOINT_PVZ } from '@/utils/constants/delivery/deliveryTypes';

const DEFAULT_METRO_COLOR = '#f46363';

/**
 * Нормализует данные пункта выдачи заказа
 * @param {PvzItem} data
 * @param {boolean} showDate
 * @returns {Object}
 */
const formattedPvz = (data, showDate = true) => ({
  address: data.address,
  code: data.pointId,
  addressDescription: data.addressDescription,
  distance: data.distance,
  distanceFormatted: data.distanceFormat,
  coordinates: [data.coordinates.latitude, data.coordinates.longitude],
  schedule: data.schedule,
  deliveryCode: data.deliveryTariffCode,
  onlinePayment: data.isOnlinePaymentAvailable,
  cashPayment: data.isCashPaymentAvailable,
  cardPayment: data.isCardPaymentAvailable,
  isPrepayment: [BOXBERRY_PVZ, PICKPOINT_PVZ, DHL_COURIER].includes(data.deliveryTariffCode),
  deliveryDays: data.deliveryDays,
  price: Number(data.deliveryPrice),
  priceFormatted: priceFormatted(data.deliveryPrice),
  metroId: data.checkoutMetro ? Number(data.checkoutMetro?.id) : null,
  metroName: data.checkoutMetro ? data.checkoutMetro?.name : null,
  metroColorBranch: data.checkoutMetro ? data.checkoutMetro?.color : DEFAULT_METRO_COLOR,
  metro: { colorBranch: data.checkoutMetro?.color, color: data.checkoutMetro?.color, name: data.checkoutMetro?.name },
  paymentMessage: data.paymentMessage,
  deliveryDate: showDate ? (data.deliveryDate || null) : null,
  deliveryDateFormat: showDate ? (formatDate(data.deliveryDate, { day: 'numeric', month: 'long' }) || null) : null,
});

export { formattedPvz };
