// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import {
  buildCollection,
  buildElement,
  buildErrorCollection,
} from '@/utils/formatters/processingOpenApi';
import formattedPageBreadcrumbs from '@/utils/formatters/formattedPageBreadcrumbs';
import { ERRORS_GET_SALE_DETAIL } from '@/utils/constants/salesConstants';

export default class SaleService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получаем список акций
   * @param {Object} [payload={}]
   * @param {number} [payload.pageSize=15]
   * @param {number} [payload.pageNumber=1]
   * @param {number} [payload.excludedId]
   * @param {boolean} [payload.isGetDiscountData]
   * @param {boolean} [payload.isGetSelection]
   * @param {boolean} [payload.isActiveDate]
   * @param {string} [payload.tag]
   * @return {Promise<unknown>}
   */
  getSalesList(payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        pageSize = 15,
        pageNumber = 1,
        excludedId,
        isGetDiscountData = false,
        isGetSelection = false,
        isActiveDate = true,
        tag = null,
      } = payload;

      const dataRequest = { 'per-page': pageSize, page: pageNumber };

      const include = [];
      if (isGetDiscountData) include.push('discountData');
      if (isGetSelection) include.push('selection');

      if (include.length) dataRequest.include = include.join(',');
      if (excludedId) dataRequest.excludedId = excludedId;
      if (isActiveDate) dataRequest.activeDate = isActiveDate;
      if (tag) dataRequest.tag = tag;

      this.mainHttp.get('/api/v1/page-sale/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          return resolve({
            list: list.map(item => ({
              ...item,
              image: {
                type: 'book24',
                x1: item?.previewPicture?.src || '/_ssr/img/not-image/Not_image_sale_item_slider.jpg',
                x2: null,
                webpX1: null,
                webpX2: null,
              },
              url: `/sales/${item.id}/`,
            })),
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получаем информацию о детальной акции
   * @param {number} saleId
   * @param {Object} [options]
   * @param {boolean} [options.isGetBreadcrumbs=false]
   * @param {boolean} [options.isGetSeoMeta=false]
   * @param {boolean} [options.isGetSelection=false]
   * @param {boolean} [options.isGetDiscountData=false]
   * @return {Promise<unknown>}
   */
  getSaleDetail(saleId, options = {}) {
    return new Promise((resolve, reject) => {
      if (!saleId) reject(new Error('[SaleService:getSaleDetail] saleId не заполнен'));
      if (typeof saleId !== 'number') reject(new Error('[SaleService:getSaleDetail] saleId должен быть числом'));

      const {
        isGetBreadcrumbs = false,
        isGetSeoMeta = false,
        isGetSelection = false,
        isGetDiscountData = false,
      } = options;

      const include = [];
      if (isGetBreadcrumbs) include.push('breadcrumbs');
      if (isGetSeoMeta) include.push('seoMeta');
      if (isGetSelection) include.push('selection');
      if (isGetDiscountData) include.push('discountData');

      const dataRequest = {};
      if (include.length) dataRequest.include = include.join(',');

      return this.mainHttp.get(`/api/v1/page-sale/${saleId}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);
          return resolve({
            isHighlight: resFormatted.properties.isHighlight,
            name: resFormatted.name,
            seoMeta: resFormatted.relationships?.seoMeta,
            crumbs: formattedPageBreadcrumbs(resFormatted.relationships?.breadcrumbs || []),
            saleInfo: resFormatted,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, ERRORS_GET_SALE_DETAIL);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
