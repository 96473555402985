/// <reference path="../../typedefs.js" />

import { defaultErrorsList } from '@/utils/formatters/formattedError';

export const comparisonEnum = Object.freeze({
  list: [
    { label: 'in', value: '@', oldLabel: null },
    { label: 'neq', value: '!=', oldLabel: null },
    { label: 'gte', value: '>=', oldLabel: 'from' },
    { label: 'lte', value: '<=', oldLabel: 'to' },
    { label: 'eq', value: '=', oldLabel: null },
    { label: 'gt', value: '>', oldLabel: null },
    { label: 'lt', value: '<', oldLabel: null },
  ],
  /**
   * Включает ли значение спец символ или обозначение спец символа
   * @param {string} [value=''] - Проверяемое значение
   * @return {boolean}
   */
  includesSymbolsOrName(value = '') {
    let isInclude = false;
    for (let i = 0; i < this.list.length - 1; i += 1) {
      if (value.includes(this.list[i].value)
        || value.endsWith(`_${this.list[i].label}`)
        || value.endsWith(`_${this.list[i].oldLabel}`)) {
        isInclude = true;
        break;
      }
    }
    return isInclude;
  },
  /**
   * Получаем измененное имя по спец символу
   * @param {string} name - имя свойства
   * @param {string} value - спец символ
   * @return {string}
   */
  getNameByValue(name, value) {
    if (!name) throw new Error('[App symbolsEnum.getNameByValue]: no declared argument - name');
    if (typeof name !== 'string') throw new Error('[App symbolsEnum.getNameByValue]: argument name expected string');
    if (!value) throw new Error('[App symbolsEnum.getNameByValue]: no declared argument - value');
    if (typeof value !== 'string') throw new Error('[App symbolsEnum.getNameByValue]: argument value expected string');
    const item = this.list.find(i => i.value === value);
    if (!item) return name;
    const label = item.oldLabel ? item.oldLabel : item.label;
    return `${name}_${label}`;
  },

  getNameAndValueWithoutLabel(name) {
    let isInclude = {};
    for (let i = 0; i < this.list.length - 1; i += 1) {
      const endsWithLabel = `_${this.list[i].label}`;
      const endsWithOldLabel = `_${this.list[i].oldLabel}`;
      if (name.endsWith(endsWithLabel)) {
        const indexFirstSymbol = name.indexOf(endsWithLabel);
        isInclude = {
          name: name.slice(0, indexFirstSymbol),
          value: this.list[i].value,
        };
        break;
      }
      if (name.endsWith(endsWithOldLabel)) {
        const indexFirstSymbol = name.indexOf(endsWithOldLabel);
        isInclude = {
          name: name.slice(0, indexFirstSymbol),
          value: this.list[i].value,
        };
        break;
      }
    }
    return isInclude;
  },
});

export const sortOptionsEnum = Object.freeze({
  list: [
    {
      value: '-popular',
      label: 'Сначала популярные',
      sort: 'sort',
      by: 'desc',
    },
    {
      value: 'price_discount',
      label: 'Сначала дешевые',
      sort: 'price_discount',
      by: 'asc',
    },
    {
      value: '-price_discount',
      label: 'Сначала дорогие',
      sort: 'price_discount',
      by: 'desc',
    },
    {
      value: '-discount_percent',
      label: 'Сначала с максимальной скидкой',
      sort: 'discount_percent',
      by: 'desc',
    },
    {
      value: '-novelty',
      label: 'Сначала новые',
      sort: 'date',
      by: 'desc',
    },
  ],
  getPresetName({ sort, by }) {
    const preset = this.list.find(item => item.by === by && item.sort === sort);
    if (!preset) return null;
    return preset.value;
  },
});

export const cdnType = Object.freeze({
  eksmo: {
    value: 'eksmo',
    baseUrl: 'https://cdn.book24.ru/',
  },
  book24: {
    value: 'book24',
    baseUrl: ['https://ndc.book24.ru/', 'http://demo.cdn.xmo/', 'https://demo.cdn.xmo/', 'http://demo2.cdn-01.st.spb.xmo/', 'https://demo2.cdn-01.st.spb.xmo/'],
  },
});

export const productImage = Object.freeze({
  type: 'fallback',
  x1: '/_ssr/img/not-image/Not_image_book.png',
  x2: '/_ssr/img/not-image/Not_image_book@2x.png',
  webpX1: '/_ssr/img/not-image/Not_image_book.webp',
  webpX2: '/_ssr/img/not-image/Not_image_book@2x.webp',
});

export const filtersType = Object.freeze({
  boolean: 'boolean',
  match: 'match',
  range: 'range',
  isBoolean(type) {
    return type === this.boolean;
  },
  isMatch(type) {
    return type === this.match;
  },
  isRange(type) {
    return type === this.range;
  },
});

export const facetsHardCodeType = Object.freeze({
  price: 'price',
  section: 'section_id',
  isPrice(type) {
    return type === this.price;
  },
  isSection(type) {
    return type === this.section;
  },
});

export const CatalogProductPropertyNameEnum = {
  ID: 'id',
  NEW_PRODUCT: 'newproduct',
  VERIFIED_REVIEWS: 'verified_reviews',
  HIGH_RATING: 'high_rating',
  SALE_LEADER: 'saleleader',
  PRE_ORDER: 'pre_order',
  PRICE: 'price',
  PRICE_DISCOUNT: 'price_discount',
  YEAR_OF_PUB: 'year_of_pub',
  COVER_LIST: 'cover_list',
  BOOK_COVER: 'book_cover',
  AUTHOR: 'author_id',
  DEMAND_PRINT: 'demand_print',
  PUBLISHING_HOUSE: 'publi_id',
  PREDZAKAZ_MONTH: 'predzakaz_month',
  SERIES: 'serie_id',
  MATERIAL_TYPE: 'material_type',
  EXAM: 'exam',
  SUBJECT: 'subject',
  GRADE: 'grade',
  AVAILABLE: 'available',
  SECTION: 'section_id',
  BEST_PRICE: 'bestprice',
  DISCOUNT_ID: 'discount_id',
  NOM_FOLDER: 'nom_folder',
  AUTOGRAPH: 'autograph',
  ONLY_SHOP: 'retail_only_available',
  PRODUCT_TYPE: 'product_type',
  GUID_NOM: 'guid_nom',
  GIFT: 'gift',
  GIFT_PRESENT: 'gift_present',
  GIFT_INTEREST: 'gift_interest',
  GIFT_EVENT: 'gift_event',
  GIFT_COLLECTION: 'gift_collection',
  SALE_CLOSE: 'sale_close',
  PUBLI_BRAND_LINK: 'publi_brand_link',
  LITERATURE_AGE: 'literature_age',
  QUANTITY: 'quantity',
  PROMO_EXCEPTION: 'promo_exception',
  AMOUNT_OF_DISCOUNT: 'amount_of_discount',
  PARENT_SECTION_ID: 'parent_section_id',
  ISBN: 'isbn',
  LICENSE: 'license',
  EDITION_LINK: 'edition_link',
  FRAGMENT: 'fragment',
  PAPER_ID: 'paper_id',
  SHOW_IN_NEW_PRODUCTS: 'show_in_new_products',
  NOMCODE: 'nomcode',
  SPECIAL_OFFER: 'specialoffer',
  STATIONERY: 'stationery',
  SCHOOL_BOOKS: 'school_books',
  TYPE_NEW: 'type_new',
  PURPOSE: 'purpose',
  CATALOG_WEIGHT: 'catalog_weight',
  CATALOG_HEIGHT: 'catalog_height',
  CATALOG_WIDTH: 'catalog_width',
  PROIZVEDEDIE: 'proizvedenie',
  DEPARTMENT: 'department',
  HIGHLIGHTS_IDS: 'highlight_ids',
  CART_RULE_CODES: 'cart_rule_codes',
  IS_RRC: 'is_rrc',
  HOME_READING: 'home_reading',
  SCHOOL_PREPARE: 'school_prepare',
  EDUCATION_SYSTEM: 'education_system',
  EXAM_YEAR: 'exam_year',
  UMK: 'umk',
  // локальная модификация фильтра price
  PRICE_TO: 'price_to',
  PRICE_FROM: 'price_from',
  YEAR_OF_PUB_TO: 'year_of_pub_to',
  YEAR_OF_PUB_FROM: 'year_of_pub_from',
  isMatch(val) {
    const findItem = Object.keys(this).find(item => this[item] === val);
    if (!findItem) return null;
    return this[findItem];
  },
};

export const rootCategoryName = 'Каталог';

export const rootCategoryNewName = 'Все категории';

export const errorsGetCatalogCategoryInfo = {
  ...defaultErrorsList,
  categoryNotFound: {
    code: '45f4cc8b-cfab-4043-baee-8d84c7ddeb31',
    message: 'Категория не найдена',
  },
  isCategoryNotFound(code) {
    return code === this.categoryNotFound.code;
  },
};

export const errorsGetProducts = {
  ...defaultErrorsList,
  sortingErrorValue: {
    code: 'ba785a8c-82cb-4283-967c-3cf342181b40',
    message: 'Извините, ничего не найдено',
  },
  isErrorValue(code) {
    return code === this.sortingErrorValue.code;
  },
  filterParameterErrorValue: {
    code: '8e179f1b-97aa-4560-a02f-2a8b42e49df7',
    message: 'Выбранное Вами значение недопустимо.',
  },
  isErrorFilterParameter(code) {
    return code === this.sortingErrorValue.code;
  },
};

export const sectionCodeGiftCertificates = 'podarochnye-sertifikaty';

/**
 * @const
 * @readonly
 * @enum
 * @type {Object} errorNotFoundTag
 * @property {ErrorObject} tagPage
 * @property {ErrorObject} byTags
 */
export const errorNotFoundTag = {
  tagPage: {
    code: 'b3f9e70d-6fc8-45c2-bf17-345c0ec6d22a',
    message: 'Тег не найден',
  },
  byTags: {
    code: '49083239-9d2f-488d-bf6a-cd5544e59f9f',
    message: 'Тег не найден',
  },
};

export const errorSubscription = {
  ...defaultErrorsList,
  reSubscribe: {
    code: '1040',
    message: 'Данный email уже подписан на оповещения о поступлении товара в продажу',
  },
};
