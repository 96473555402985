import { defaultErrorsList } from '@/utils/formatters/formattedError';

export const typeChars = Object.freeze({
  author: {
    position: 1,
  },
  translator: {
    position: 2,
  },
  series: {
    position: 3,
  },
  category: {
    position: 4,
  },
  brand: {
    position: 5,
  },
  isbn: {
    position: 6,
  },
  ageLimit: {
    position: 7,
  },
  yearPublishing: {
    position: 8,
  },
  numberOfPages: {
    position: 9,
  },
  coverType: {
    position: 10,
  },
  paperType: {
    position: 11,
  },
  format: {
    position: 12,
  },
  circulation: {
    position: 13,
  },
  weight: {
    position: 14,
  },
});

export const charUnitType = Object.freeze({
  size: {
    type: 'size',
    label: 'мм',
  },
  weight: {
    type: 'weight',
    label: 'кг',
  },
});

export const productTypeEnum = Object.freeze({
  product: {
    type: 'product',
  },
  litresCertificate: {
    type: 'litresCertificate',
  },
  electronicCertificate: {
    type: 'electronicCertificate',
  },
  demandPrint: {
    type: 'demandPrint',
  },
  preOrder: {
    type: 'preOrder',
  },
  book: {
    type: 'book',
  },
});

export const ERRORS_GET_PRODUCT_DETAIL = {
  ...defaultErrorsList,
};

/**
 * Максимальная длинна отзыва
 * @type {number}
 */
export const MAX_LENGTH_REVIEW = 8000;

/**
 * Минимальная длинна отзыва
 * @type {number}
 */
export const MIN_LENGTH_REVIEW = 100;

export const LENGTH_CHARS = 5;

export const PRODUCT_DETAIL_FORM_SUBSCRIBE = Object.freeze({
  type: 'productDetailSubscribe',
});

export const PRODUCT_DETAIL_FORM_REVIEW = Object.freeze({
  type: 'productDetailReview',
});
