export const getVisitorId = async () => {
  const FingerprintJS = require('@fingerprintjs/fingerprintjs');

  const fpPromise = FingerprintJS.load();

  if (window) {
    const fp = await fpPromise;
    const result = await fp.get();

    return result.visitorId;
  }
};
