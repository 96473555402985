// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildElement, buildCollection, buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import formattedPageBreadcrumbs from '@/utils/formatters/formattedPageBreadcrumbs';
import { ERRORS_GET_DETAIL_SERIES } from '@/utils/constants/seriesConstants';
import { formattedSeriesItem } from '@/utils/formatters/formattedProduct';

export default class SeriesService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получаем список серий
   * @param {Object} options
   * @param {number} [options.pageNumber=1]
   * @param {number} [options.pageSize=50]
   * @param {string} [options.match='']
   * @param {boolean} [options.isGetSeriesBookImage=true]
   * @param {Array} [options.idList=[]]
   * @return {Promise<unknown>}
   */
  getSeriesList(options = {}) {
    return new Promise((resolve, reject) => {
      const {
        pageNumber = 1,
        pageSize = 50,
        match = '',
        isGetSeriesBookImage = true,
        idList = [],
      } = options;

      const include = [];
      if (isGetSeriesBookImage) include.push('seriesBookImage');

      let dataRequest = {
        page: pageNumber,
        'per-page': pageSize,
      };
      if (match) dataRequest = { ...dataRequest, match };
      if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };
      if (idList.length) dataRequest = { ...dataRequest, id: idList };

      return this.mainHttp.get('/api/v1/series/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildCollection(dataRes, true);

          return resolve({
            list: resFormatted.list.map(item => formattedSeriesItem(item)),
            pagination: resFormatted.pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получаем детальную информацию о серии
   * @param idSeries
   * @param {Object} options
   * @param {boolean} [options.isGetBreadcrumbs=false]
   * @param {boolean} [options.isGetSeoMeta=false]
   * @param {boolean} [options.isGetSeriesText=false]
   * @param {boolean} [options.isGetSeriesImage=false]
   * @param {boolean} [options.isGetSeriesBookImage=false]
   * @return {Promise<unknown>}
   */
  getSeriesDetail(idSeries, options = {}) {
    return new Promise((resolve, reject) => {
      if (!idSeries) reject(new Error('[getSeriesDetail]: ID серии не указан'));

      const {
        isGetBreadcrumbs = false,
        isGetSeoMeta = false,
        isGetSeriesText = false,
        isGetSeriesImage = false,
        isGetSeriesBookImage = false,
      } = options;

      const include = [];
      if (isGetBreadcrumbs) include.push('breadcrumbs');
      if (isGetSeoMeta) include.push('seoMeta');
      if (isGetSeriesText) include.push('seriesText');
      if (isGetSeriesImage) include.push('seriesImage');
      if (isGetSeriesBookImage) include.push('seriesBookImage');

      const dataRequest = {};
      if (include.length) {
        dataRequest.include = include.join(',');
      }

      return this.mainHttp.get(`/api/v1/series/${idSeries}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);

          return resolve({
            id: resFormatted.id,
            name: resFormatted.name,
            count: resFormatted.numBooks,
            code: resFormatted.code,
            link: `/serie/${resFormatted.code}-${resFormatted.id}/`,
            detailDescription: resFormatted.relationships?.seriesText?.detailDescription || null,
            previewDescription: resFormatted.relationships?.seriesText?.previewDescription || null,
            seoMeta: resFormatted.relationships?.seoMeta
              ? resFormatted.relationships.seoMeta
              : null,
            crumbs: resFormatted.relationships?.breadcrumbs
              ? formattedPageBreadcrumbs(resFormatted.relationships.breadcrumbs || [])
              : null,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, ERRORS_GET_DETAIL_SERIES);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
