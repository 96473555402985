export const ERRORS_BASKET = Object.freeze({
  notExistBitrixBasket: {
    code: '5f7bce16-8d6d-4c56-a232-d1975b736346',
    message: 'Битрикс-корзина не существует',
  },
  notExistBasket: {
    code: 'a210490f-645c-45a6-9ab3-8c0eb9e882ab',
    message: 'Корзина не существует',
  },
  errorAddToBasket: {
    code: 'b0e6e260-8c4d-4816-a0cf-20b42fdee204',
    message: 'Ошибка добавления товара в корзину',
  },
  productNotFound: {
    code: '4d2112cd-a866-4ff8-9113-cb3ebd1efa57',
    message: 'Товар не найден',
  },
  notExistCustomer: {
    code: '7f7877b3-4bc7-449c-b577-8d6e796993f7',
    message: 'Покупатель не существует',
  },
  notExistBitrixCustomer: {
    code: 'bab8be9a-db65-4f44-8574-119d09122cd6',
    message: 'Битрикс-покупатель не существует',
  },
  errorSaveBasket: {
    code: '7d301c92-7434-49e7-ac66-7e5c63f0b559',
    message: 'Ошибка сохранения корзины',
  },
  errorLegacyCustomer: {
    code: 'e679b8ce-b855-4ac9-ba10-a6d524aac2b6',
    message: 'Необходимо зафиксить legacy-покупателя',
  },
  basketIdNotString: {
    code: '5ca7c02a-5aa4-432a-a340-d53230bc9c02',
    message: 'Идентификатор корзины должен быть строкой',
  },
  basketIdNotExist: {
    code: '19e0a4ef-92d7-4ff3-a0a0-6021d64bd705',
    message: 'Идентификатор корзины не должен быть пустым',
  },
  promoCodeNotString: {
    code: 'ce5b17ea-a8c2-422c-b83d-52f3fc24690e',
    message: 'Промокод должен быть строкой',
  },
  promoCodeNotExist: {
    code: '28496346-a64c-4088-833a-bf14349649d8',
    message: 'Промокод не должен быть пустым',
  },
  isNotExistCustomer(code) {
    return code === this.notExistCustomer.code || code === this.notExistBitrixCustomer.code;
  },
  isNotExistBasket(code) {
    return code === this.notExistBasket.code || code === this.notExistBitrixBasket.code
      || code === this.basketIdNotExist.code || code === this.basketIdNotString.code;
  },
  isErrorLegacy(code) {
    return code === this.errorLegacyCustomer.code;
  },
});
