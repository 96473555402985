import axios from 'axios';
import qs from 'qs';
import { context, propagation, trace } from '@opentelemetry/api';
import checkBeforeTransform from '@/utils/checkBeforeTransform';

import { createHttpAgent, createHttpsAgent } from '@/utils/createHttpAgents';

/**
 * @param {Date} startDate
 * @param {string} url
 */
const logDiffTime = (startDate, url) => {
  const end = new Date().getTime();
  // eslint-disable-next-line no-console
  console.log(`axios: [GET] ${url} for ${end - startDate.getTime()} ms`);
};

export default class HttpClient {
  constructor(config) {
    const {
      apiUrl,
      apiUrlInternal,
      xOauthClientId,
      cookie,
    } = config;
    this.apiUrl = process.server ? apiUrlInternal : apiUrl;
    this.xOauthClientId = xOauthClientId;
    this.cookie = cookie;
  }

  /**
   * Возвращает инстанс аксиоса
   * @return {AxiosInstance}
   */
  mainHttp() {
    const axiosInstance = axios.create({
      baseURL: this.apiUrl,
      withCredentials: true,
      headers: {
        'X-OAUTH-CLIENT-ID': this.xOauthClientId,
      },
      httpAgent: createHttpAgent(),
      httpsAgent: createHttpsAgent(),
    });

    if (process.server) {
      const currentSpan = trace.getSpan(context.active());

      if (currentSpan) {
        const traceId = currentSpan.spanContext().traceId;

        const output = {};

        propagation.inject(context.active(), output);

        const { traceparent } = output;

        if (traceparent) axiosInstance.defaults.headers.traceparent = traceparent;

        if (traceId) axiosInstance.defaults.headers['x-request-id'] = traceId;
      }
    }

    if (this.cookie) axiosInstance.defaults.headers.Cookie = this.cookie;
    return axiosInstance;
  }

  /**
   * GET запрос
   * @template T
   * @param {string} path - путь запроса
   * @param {Object} payload - данные запроса
   * @return {string|Promise<unknown>|Promise<T>|any}
   */
  get(path, payload = {}) {
    const start = new Date();
    let paramsQuery = null;
    return this.mainHttp().request({
      method: 'GET',
      url: path,
      params: { ...payload },
      transformResponse(data) {
        return checkBeforeTransform(data);
      },
      paramsSerializer(params) {
        paramsQuery = qs.stringify(params, { skipNulls: true });
        return paramsQuery;
      },
    })
      .finally(() => {
        if (process.server) {
          let url = path;
          if (paramsQuery) url += `?${paramsQuery}`;
          logDiffTime(start, url);
        }
      });
  }

  /**
   * POST запрос
   * @template T
   * @param {string} path - путь запроса
   * @param {Object} payload - данные запроса
   * @param {Object} options - дополнительные параметры
   * @param {boolean} [options.isFormData=false] - нужно ли данные запроса трансформировать в FormData
   * @param {Object} [options.payloadParams={}] - дополнительные параметры запроса
   * @return {Promise<unknown>|Promise<T>|any}
   */
  post(path, payload = {}, options = {}) {
    const start = new Date();
    const {
      isFormData = false,
      payloadParams = {},
    } = options;

    const request = {
      method: 'POST',
      url: path,
      data: payload,
      params: { ...payloadParams },
      transformResponse(data) {
        return checkBeforeTransform(data);
      },
    };

    if (isFormData) {
      request.transformRequest = data => qs.stringify(data, { skipNulls: true });
    }

    return this.mainHttp().request(request)
      .finally(() => {
        if (process.server) {
          logDiffTime(start, path);
        }
      });
  }

  /**
   * PUT запрос
   * @param {string} path - путь запроса
   * @param {Object} payload - данные запроса
   * @param {Object} options - дополнительные параметры
   * @param {boolean} [options.isFormData=false] - нужно ли данные запроса трансформировать в FormData
   * @param {Object} [options.payloadParams={}] - дополнительные параметры запроса
   * @return {any|Promise<AxiosResponse<any>>}
   */
  put(path, payload = {}, options = {}) {
    const {
      isFormData = false,
      payloadParams = {},
    } = options;

    const request = {
      method: 'PUT',
      url: path,
      data: payload,
      params: { ...payloadParams },
      transformResponse(data) {
        return checkBeforeTransform(data);
      },
    };

    if (isFormData) {
      request.transformRequest = data => qs.stringify(data);
    }

    return this.mainHttp().request(request);
  }

  /**
   * DELETE запрос
   * @param {string} path - путь запроса
   * @param {Object} payload - данные запроса
   * @return {any|Promise<AxiosResponse<any>>}
   */
  delete(path, payload = {}) {
    return this.mainHttp().request({
      method: 'DELETE',
      url: path,
      data: payload,
      transformResponse(data) {
        return checkBeforeTransform(data);
      },
    });
  }

  /**
   * Запрос скачивания файла в формате blob
   * @param {string} path - путь запроса
   * @param {Object} payload - данные запроса
   * @return {Promise<AxiosResponse<any>>}
   */

  download(path, payload = {}) {
    return this.mainHttp().request({
      method: 'GET',
      url: path,
      params: { ...payload },
      responseType: 'blob',
    })
      .then(data => Promise.resolve(data.data))
      .catch(async (error) => {
        if (error.response.data instanceof Blob) {
          const errorText = await (new Response(error.response.data)).text();
          const formattedErrorText = JSON.parse(errorText);
          error.response.data = formattedErrorText;
        }

        return Promise.reject(error);
      });
  }

  /**
   * Запрос отправки файла в формате FormData
   * @param {string} path - путь запроса
   * @param {File} file - файл выбранный пользователем
   * @return {Promise<AxiosResponse<any>>}
   */
  upload(path, file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.mainHttp().request({
      method: 'POST',
      url: path,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  }
}
