// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import {
  buildErrorCollection,
  buildElement,
  buildCollection,
} from '@/utils/formatters/processingOpenApi';
import ServiceError from '@/utils/errors/ServiceError';
import { ERROR_USER_NOT_AUTH } from '@/utils/constants/globalConst';
import { getDiffInMilliseconds, parseDateISO, addToDate } from '@/utils/dateTime';

export default class LotteryService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  registrationPlayer() {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/lottery/players/')
        .then(({ data: { data: dataRes } }) => {
          resolve(dataRes.data);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) {
              return reject(new ServiceError(ERROR_USER_NOT_AUTH));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getDrawing() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/lottery/drawings/', {
        lotteryId: 'de7fd36c-ccd5-4fba-a7da-e26f469c8f04',
        holding: true,
      })
        .then(({ data: { data: { data: dataRes } } }) => resolve(dataRes.map(item => ({
          id: item.id,
          isCompleted: item.attributes.isCompleted,
        }))))
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) {
              return reject(new ServiceError(ERROR_USER_NOT_AUTH));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  checkDrawingUser(drawingId) {
    return new Promise((resolve, reject) => {
      if (!drawingId) reject(new ServiceError('[LotteryService:checkDrawingUser] drawingId обязателен для заполнения'));
      this.mainHttp.post(`/api/v1/lottery/drawings/${drawingId}/game/`, {
        dryRun: 'true',
      })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  game(drawingId) {
    return new Promise((resolve, reject) => {
      if (!drawingId) reject(new ServiceError('[LotteryService:game] drawingId обязателен для заполнения'));
      this.mainHttp.post(`/api/v1/lottery/drawings/${drawingId}/game/`)
        .then(({ data: { data: dataRes } }) => resolve(buildElement(dataRes)))
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) {
              return reject(new ServiceError(ERROR_USER_NOT_AUTH));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getDatePlayerWinner() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/lottery/player-winners/', {
        lotteryId: 'de7fd36c-ccd5-4fba-a7da-e26f469c8f04',
      })
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes);
          const [lastItem] = list;
          if (lastItem) {
            const createdAt = lastItem?.createdAt;

            const createdAtDate = parseDateISO(createdAt);

            const nowDate = new Date();

            const createdAtLeft30Days = addToDate(createdAtDate, { days: 30 });

            resolve(getDiffInMilliseconds(createdAtLeft30Days, nowDate));
          } else {
            reject(new ServiceError('Нет последнего выигрыша'));
          }
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) {
              return reject(new ServiceError(ERROR_USER_NOT_AUTH));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
