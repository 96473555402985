const middleware = {}

middleware['auth'] = require('../../_desktop/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['redirects'] = require('../../_desktop/middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['trailingSlash'] = require('../../_desktop/middleware/trailingSlash.js')
middleware['trailingSlash'] = middleware['trailingSlash'].default || middleware['trailingSlash']

export default middleware
