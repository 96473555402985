<template>
  <button
    v-if="isSupported"
    type="button"
    class="app-copy-button"
    :aria-label="ariaLabel"
    @click.prevent="copyHandler"
  >
    <slot :data="{ isSupported, copied }">
      {{ copiedText }}
    </slot>
    <transition name="slide">
      <span v-if="showTooltip" class="app-copy-button__tooltip">
        {{ tooltipText }}
      </span>
    </transition>
  </button>
  <span v-else class="app-copy-button">
    <slot :data="{ isSupported, copied }">
      {{ copiedText }}
    </slot>
  </span>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs, watch,
} from '@nuxtjs/composition-api';
import { useClipboard } from '@vueuse/core';

import useToast from '@/compositions/useToast';
import { checkMobile } from '@/utils/deviceCheck';

export default defineComponent({
  name: 'AppCopyButton',
  props: {
    copiedText: { type: String, default: 'Копировать' },
    ariaLabel: { type: String, default: 'Копировать' },
    tooltipText: { type: String, default: 'Скопировано' },
    copiedDuring: { type: Number, default: 2000 },
  },
  setup(props, { emit }) {
    const { toast } = useToast();
    const { copy, isSupported, copied } = useClipboard({
      read: false,
      copiedDuring: props.copiedDuring,
    });

    const localState = reactive({
      isSupported: true,
    });

    const copyHandler = () => {
      copy(props.copiedText);
      emit('copied', props.copiedText);
    };

    watch(
      () => copied.value,
      () => {
        toast.bottom(props.tooltipText, {
          duration: 2000,
          wordWrap: true,
        });
      },
      { immediate: false },
    );

    const showTooltip = computed(() => !checkMobile() && copied.value);

    onMounted(() => {
      localState.isSupported = isSupported;
    });

    return {
      ...toRefs(localState),
      copyHandler,
      copied,
      showTooltip,
    };
  },
});
</script>

<style lang="less">
.app-copy-button {
  .button-reset;
  .small;

  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: left;

  &__tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -@size-x2);
    color: @white;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: @main-size;
    display: flex;
    padding: 0 @size-x2;
    white-space: nowrap;
    font-weight: normal;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      transform: translate(-50%, 0);
      left: 50%;
      border: 4px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.8);
    }

    &.slide-enter-active,
    &.slide-leave-active {
      transition: @A opacity, @A bottom;
      bottom: 100%;
    }

    &.slide-enter,
    &.slide-leave-to {
      opacity: 0;
      bottom: 60%;
    }
  }

  &._right {
    .app-copy-button__tooltip {
      left: 150%;
      top: 35%;
      bottom: auto;
    }

    .app-copy-button__tooltip::before {
      top: 50%;
      right: auto;
      left: 0;
      transform: translate(-98%, -45%);
      border: @main-size solid transparent;
      border-right-color: rgba(0, 0, 0, 0.8);
    }
  }
}
</style>
