import { nanoid } from 'nanoid';
import { CALL_CENTER_PHONE, MAIN_REGIONS } from '@/utils/constants/globalConst';

export default {
  namespaced: true,
  state() {
    return {
      currentPhone: CALL_CENTER_PHONE[1],
      imarketEmail: 'imarket@book24.ru',
      catalogMenu: [],
      nuxtRenderKey: null,
    };
  },
  actions: {
    setPhone({ commit }, currentLocation) {
      commit('setPhone', currentLocation);
    },
    setImarketEmail({ commit }, imarketEmail) {
      commit('setImarketEmail', imarketEmail);
    },
    async getCatalogMenu({ commit }) {
      await this.$httpClient.catalog.getMenu()
        .then((res) => {
          commit('setCatalogMenu', res);
        })
        .catch(() => {
          commit('setCatalogMenu');
        });
    },
    forceRenderNuxt({ commit }) {
      commit('setNuxtRenderKey', nanoid());
    },
  },
  mutations: {
    setPhone(state, currentLocation) {
      if (currentLocation === MAIN_REGIONS.msk.code) {
        state.currentPhone = CALL_CENTER_PHONE[0];
      } else {
        state.currentPhone = CALL_CENTER_PHONE[1];
      }
    },
    setImarketEmail(state, imarketEmail) {
      state.imarketEmail = imarketEmail;
    },
    setCatalogMenu(state, menu = []) {
      state.catalogMenu = menu;
    },
    setNuxtRenderKey(state, id) {
      state.nuxtRenderKey = id;
    },
  },
};
