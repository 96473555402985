import { checkMobile } from '@/utils/deviceCheck';

import AppSectionMobile from '@/components/AppSection/mobile.vue';
import AppSectionDesktop from '@/components/AppSection/desktop.vue';

export default {
  functional: true,
  render(createElement, context) {
    return createElement(
      checkMobile() ? AppSectionMobile : AppSectionDesktop,
      context.data,
      context.children,
    );
  },
};
