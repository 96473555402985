import { MNOGORU_COUPON_LENGTH } from '@/utils/constants/globalConst';

export const ERRORS_MNOGO_RU = Object.freeze({
  cardNotString: {
    code: '9dc914ab-cdb0-4132-934d-2dde9c535a8a',
    message: 'Номер карты должен быть строкой',
  },
  cardEmpty: {
    code: '48551739-c163-437e-8554-7d630a7815c8',
    message: 'Номер карты Много.ру не должен быть пустой',
  },
  cardFixLength: {
    code: '5915eb7c-f43c-4d1e-84d9-8cc89c592f93',
    message: `Номер карты Много.ру состоит из ${MNOGORU_COUPON_LENGTH} цифр`,
  },
  isNotExistCard: {
    code: 'c667226b-4a60-4043-a445-be90345fc995',
    message: 'Номер карты Много.ру не найден',
  },
});
