// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildElement, buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import ServiceError from '@/utils/errors/ServiceError';
import { convertISOFromDateFormat } from '@/utils/dateTime';
import { ERROR_NOT_FOUND } from '@/utils/constants/globalConst';
import { removeScripts, getScriptsSrc } from '@/utils/iFrameContentScripts.js';

export default class JournalService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * @typedef {Object} OptionsGetJournalDetail
   * @property {boolean} [isGetSeoMeta=false] - нужно ли получать SEO данные
   * @property {boolean} [isGetPageConstructor=false] - нужно ли получать данные о конструкторе
   * @property {boolean} [isGetPageConstructorBlock=false] - нужно ли получать данные о блоках конструктора
   */

  /**
   * Возвращает список контента (блоги, новости, подборки и т.д.)
   * @param payload
   * @return {Promise<{pagination, items}>}
   */
  getJournalContent(payload = {}) {
    const {
      type,
      sort,
      showOnMainOnly,
      pageNumber = 1,
      pageSize = 10,
    } = payload;

    const dataRequest = {
      type,
      sort,
      showOnMainOnly,
      page: pageNumber,
      'per-page': pageSize,
    };

    return this.mainHttp.get('/api/v1/page-content/', dataRequest)
      .then(({ data: { data: dataRes } }) => ({
        items: dataRes?.data || [],
        pagination: dataRes?.meta || {},
      }))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Возвращает контент (пост, новость, подборку)
   * @param {number|string} contentId
   * @param {string} contentType
   * @param {OptionsGetJournalDetail} options
   * @return {Promise<unknown>}
   */
  getJournalDetail(contentId, contentType, options = {}) {
    return new Promise((resolve, reject) => {
      if (!contentId) reject(new ServiceError('[JournalService:getJournalDetail] contentId обязателен для заполнения'));

      const {
        isGetSeoMeta = false,
        isGetPageConstructor = false,
        isGetPageConstructorBlock = false,
      } = options;

      const include = [];
      if (isGetSeoMeta) include.push('seoMeta');
      if (isGetPageConstructor) include.push('pageConstructor');
      if (isGetPageConstructorBlock) include.push('pageConstructor.pageConstructorBlock');

      const dataRequest = {};
      if (include.length) dataRequest.include = include.join(',');

      this.mainHttp.get(`/api/v1/page-${contentType}/${contentId}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const formattedElement = buildElement(dataRes);
          return resolve({
            detailPage: {
              title: formattedElement?.relationships?.seoMeta.h1 || formattedElement.name,
              previewPicture: formattedElement.previewPicture,
              detailPicture: formattedElement.properties.topPicture || formattedElement.detailPicture,
              name: formattedElement.name,
              id: formattedElement.id,
              code: formattedElement.code,
              previewText: formattedElement.previewText,
              detailText: removeScripts(formattedElement.detailText),
              cdnScripts: getScriptsSrc(formattedElement.detailText),
              dateActiveFrom: formattedElement.dateActiveFrom
                ? convertISOFromDateFormat(formattedElement.dateActiveFrom, 'dd MMMM yyyy')
                : null,
              dateActiveTo: formattedElement.dateActiveTo
                ? convertISOFromDateFormat(formattedElement.dateActiveTo, 'dd MMMM yyyy')
                : null,
              ...formattedElement.properties,
              pageConstructorBlock: formattedElement.properties.isPageConstructor
                ? formattedElement.relationships.pageConstructor.relationships.pageConstructorBlock
                : null,
            },
            attrsPage: {
              title: formattedElement?.relationships?.seoMeta.title || null,
              description: formattedElement?.relationships?.seoMeta.description || null,
            },
          });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Возвращает список страниц Люди и книги
   * @param payload
   * @return {Promise<{pagination, items}>}
   */
  getPeopleAndBooks(payload = {}) {
    const {
      sort,
      pageNumber = 1,
      pageSize = 10,
    } = payload;

    const dataRequest = {
      sort,
      page: pageNumber,
      'per-page': pageSize,
    };

    return this.mainHttp.get('/api/v1/page-people-and-books/', dataRequest)
      .then(({ data: { data: dataRes } }) => ({
        items: dataRes?.data || [],
        pagination: dataRes?.meta || {},
      }))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }
}
