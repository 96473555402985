<template>
  <button
    class="accordion-panel"
    :class="{'_active': !collapsed}"
    @click="clickHandler"
  >
    <span class="accordion-panel__wrapper">
      <slot></slot>
      <svg-icon
        name="24/Arrow_down_24"
        class="accordion-panel__icon"
        :class="{'_active': !collapsed}"
        width="24"
        height="24"
      />
    </span>
  </button>
</template>

<script>
import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AccordionPanel',
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, { emit }) {
    const clickHandler = () => {
      emit('toggle');
    };

    return {
      clickHandler,
    };
  },
});
</script>

<style lang="less">
.accordion-panel {
  .button-reset;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: @A background-color;

  &._active {
    background-color: @blue-back;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    margin: 0 @size-x4 0 @size-x2;
    fill: @gray;
    transition: @A transform;
    flex-shrink: 0;

    &._active {
      transform: rotate(180deg);
    }
  }
}
</style>
