import {
  inject,
  provide,
  toRefs,
  reactive,
} from '@nuxtjs/composition-api';

const AUTHORIZATION_CONTEXT = Symbol('b24-authorization');

export const useAuthorizationProvider = () => {
  const state = reactive({
    mode: '',
    captchaOptions: {},
  });

  provide(AUTHORIZATION_CONTEXT, {
    ...toRefs(state),
  });
};

export const useAuthorizationContext = () => {
  const context = inject(AUTHORIZATION_CONTEXT);

  if (!context) {
    throw new Error('useAuthorizationContext необходимо использовать с useAuthorizationProvider');
  }

  return context;
};
