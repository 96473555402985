<template>
  <button
    :class="['slider-arrow', classes]"
    :disabled="isDisabled"
    @click="handlerClick"
  >
    <svg-icon :name="icon" width="16" height="16" />
  </button>
</template>

<script>
export default {
  name: 'EmblaArrowButtons',
  props: {
    isNext: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
  },
  computed: {
    icon() {
      return this.isNext ? '16/Arrow_right_16' : '16/Arrow_back_16';
    },
    classes() {
      return this.isNext ? '_next' : '_prev';
    },
  },
  methods: {
    handlerClick() {
      this.$emit('click', this.isNext ? 'next' : 'prev');
    },
  },
};
</script>

<style lang="less">
.slider-arrow {
  position: absolute;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-color: hsla(0, 0%, 100%, 0.9);
  z-index: 2;
  transform: translateY(-50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transition: @A all;
  color: @black;
  top: 50%;
  border: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &::before {
    display: none;
  }

  &:hover {
    color: @black;
    background-color: @white;
  }

  &:active {
    color: @black;
    background-color: @gray-back-2;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  }

  &:disabled {
    opacity: 0;
  }

  &._prev {
    left: 0;
  }

  &._next {
    right: 0;
  }
}
</style>
