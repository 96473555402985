import { defaultErrorsList } from '@/utils/formatters/formattedError';

export const ERRORS_GET_WISH_LIST = {
  ...defaultErrorsList,
  userNotAuth: {
    code: 'd4a0eed6-26bb-4c71-bed7-86b7b154443f',
    message: 'Пользователь не авторизован',
  },
  isUserNotAuthByCode(code) {
    return code === this.userNotAuth.code;
  },
};

export const ERRORS_ADD_TO_WISH_LIST = {
  ...defaultErrorsList,
  productNotFound: {
    code: 'fae04aaa-c4b1-4584-b7a7-65e0e77d3efd',
    message: 'Товар не найден',
  },
  userNotAuth: {
    code: 'd4a0eed6-26bb-4c71-bed7-86b7b154443f',
    message: 'Пользователь не авторизован',
  },
  isUserNotAuthByCode(code) {
    return code === this.userNotAuth.code;
  },
};

export const ERRORS_REMOVE_IN_WISH_LIST = {
  ...defaultErrorsList,
  productNotFound: {
    code: 'fae04aaa-c4b1-4584-b7a7-65e0e77d3efd',
    message: 'Товар не найден',
  },
  userNotAuth: {
    code: 'd4a0eed6-26bb-4c71-bed7-86b7b154443f',
    message: 'Пользователь не авторизован',
  },
  isUserNotAuthByCode(code) {
    return code === this.userNotAuth.code;
  },
};
