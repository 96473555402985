<template>
  <app-section-skeleton is-title :is-container="isContainer">
    <div class="shelf-products">
      <div
        v-for="item in 6"
        :key="`${getId()}-${item}`"
        class="shelf-products__item"
      >
        <product-card-skeleton :show-buttons="showButtons" />
      </div>
    </div>
  </app-section-skeleton>
</template>

<script>
import { nanoid } from 'nanoid';
import AppSectionSkeleton from '@/components/AppSection/skeleton';
import ProductCardSkeleton from '@/components/ProductCard/skeleton';

export default {
  name: 'ShelfProductsSkeleton',
  components: {
    AppSectionSkeleton,
    ProductCardSkeleton,
  },
  props: {
    showButtons: { type: Boolean, default: true },
    isContainer: { type: Boolean, default: true },
  },
  methods: {
    getId() {
      return nanoid();
    },
  },
};
</script>

<style lang="less" scoped>
.shelf-products {
  display: flex;
  margin-left: -@size-x6;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 37px;
    background: linear-gradient(270deg, @white 50%, rgba(255, 255, 255, 0) 100%);
  }

  &__item {
    flex: 0 0 auto;
    width: calc(244px + @size-x6);
    padding-left: @size-x6;
    position: relative;
    counter-increment: embla;
  }
}
</style>
