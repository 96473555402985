export default (value, plurals) => {
  // Single option or string passed
  if (typeof plurals === 'string' || plurals.length === 1) {
    return plurals;
  }
  const n = Math.abs(value);
  // See http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html?id=l10n/pluralforms
  let i;

  if (n % 10 === 1 && n % 100 !== 11) {
    i = 0;
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    i = 1;
  } else {
    i = 2;
  }
  return plurals[i];
};
