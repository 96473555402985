// @ts-check
/// <reference path="./typedefs.js" />

import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection, buildCollection } from '@/utils/formatters/processingOpenApi';
import ServiceError from '@/utils/errors/ServiceError';
import { ERROR_SERVER_ERROR, ERROR_USER_NOT_AUTH } from '@/utils/constants/globalConst';

export default class LoyaltyService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Отдача списка операций по счёту в программе лояльности
   * @return {Promise<TransactionsList>}
   */
  getLoyaltyTransactions() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/pl/transaction/')
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes, true);
          return resolve(list);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) {
              return reject(new ServiceError(ERROR_USER_NOT_AUTH));
            }
            if (e.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Отдача структуры уровней участника программы лояльности
   * @return {Promise<unknown>}
   */
  getMemberLevelStructure() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/pl/member-level-structure/')
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes, false);
          return resolve(list.map(item => ({
            cashBackPercent: Number(item.cashBackPercent),
            id: item.id,
            isCurrent: item.isCurrent,
            leftToLevel: Number(item.leftToLevel),
            level: item.level,
            name: item.name,
          })));
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) {
              return reject(new ServiceError(ERROR_USER_NOT_AUTH));
            }
            if (e.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
