import LoadScript from '@/utils/loadScript';

export default function getTinkoffID() {
  return new Promise((resolve, reject) => {
    const url = 'https://sso-forms-prod.t-static.ru/tid/widget.js';

    if (window.TidSDK) {
      resolve(window.TidSDK);
    } else {
      LoadScript(url)
        .then(() => resolve(window.TidSDK))
        .catch(reject);
    }
  });
}
