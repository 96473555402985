// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';

export default class ContentService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получает CSRF-токен
   * @param {Object} payload
   * @param {string} [payload.visitorId]
   * @param {string} [payload.goal]
   * @param {{kv1: string, kv2: string, kv3: string}} [payload.kv1]
   * @return {Promise<string>}
   */
  getCsrfCode(payload) {
    return new Promise((resolve, reject) => this.mainHttp.post('/api/v1/csrf/', payload)
      .then(({ data: { data: { data: dataRes } } }) => resolve(dataRes.attributes?.token?.code))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }
}
