/// <reference path="../../services/v1/CatalogService/typedefs.js" />

import {
  useContext,
  useRouter,
  reactive,
  toRefs,
  computed,
} from '@nuxtjs/composition-api';

import useHttpClient from '@/compositions/useHttpClient';

const useProducts = () => {
  const router = useRouter();
  const httpClient = useHttpClient();
  const context = useContext();

  const params = reactive({
    isLoadingProducts: false,
    products: [],
    pageSize: 30,
    pagination: {
      total: 0,
      count: 0,
      perPage: 30,
      totalPages: 1,
      currentPage: 1,
    },
    isEmptyProducts: false,
  });

  /**
   * Переход по постраничной навигации
   * @param {Object} options
   * @param {number} [options.pageNumber=1] - Номер страницы для перехода
   * @param {string} options.pathTemplate - Шаблон пути для перехода
   */
  const goToPage = (options) => {
    const {
      pageNumber = 1,
      pathTemplate,
    } = options;
    if (!pathTemplate) throw new Error('[App goToPage]: no declared argument - pathTemplate');
    if (typeof pathTemplate !== 'string') throw new Error('[App goToPage]: argument pathTemplate expected string');
    if (typeof pageNumber !== 'number') throw new Error('[App goToPage]: argument pageNumber expected number');
    router.push({ path: pathTemplate.replace('$pageNumber', String(pageNumber)), query: context.query.value });
  };

  /**
   * Получаем список товаров
   * @param {Object} [payload={}]
   * @param {Object} [options={}]
   * @param {boolean} [options.isOverwriteProducts=false] - нужно ли перезаписывать товары при их получении
   * @param {number} [options.reComputePagination=0] - нужно ли переинициализировать пагинацию
   * @return {Promise<ProductList>}
   */
  const getProductsList = (payload = {}, options = {}) => new Promise((resolve, reject) => {
    params.isLoadingProducts = true;

    const { isOverwriteProducts = false, reComputePagination = 0 } = options;
    const dataRequest = { ...payload };

    if (!dataRequest?.pageSize) {
      dataRequest.pageSize = params.pageSize;
    }

    return httpClient.catalog.getProducts(dataRequest)
      .then(({ items, pagination }) => {
        params.products = isOverwriteProducts ? items : [...params.products, ...items];
        params.isEmptyProducts = !items.length;

        if (reComputePagination) {
          params.pagination = {
            count: pagination.count,
            currentPage: (pagination.perPage * pagination.currentPage) / reComputePagination,
            perPage: reComputePagination,
            total: pagination.total,
            totalPages: Math.ceil(pagination.total / reComputePagination),
          };
        } else {
          params.pagination = pagination;
        }
        return resolve({ items, pagination });
      })
      .catch(error => reject(error))
      .finally(() => {
        params.isLoadingProducts = false;
      });
  });

  /**
   * Получаем список товаров
   * @param {Object} [payload={}]
   * @param {Object} [options={}]
   * @param {boolean} [options.isOverwriteProducts=false] - нужно ли перезаписывать товары при их получении
   * @param {number} [options.reComputePagination=0] - нужно ли переинициализировать пагинацию
   * @return {Promise<ProductList>}
   */
  const getProductsListForBookPage = (payload = {}, options = {}) => new Promise((resolve, reject) => {
    params.isLoadingProducts = true;

    const { isOverwriteProducts = false, reComputePagination = 0 } = options;
    const dataRequest = { ...payload };

    if (!dataRequest?.pageSize) {
      dataRequest.pageSize = params.pageSize;
    }

    return httpClient.product.getProducts(dataRequest)
      .then(({ items, pagination }) => {
        params.products = isOverwriteProducts ? items : [...params.products, ...items];
        params.isEmptyProducts = !items.length;

        if (reComputePagination) {
          params.pagination = {
            count: pagination.count,
            currentPage: (pagination.perPage * pagination.currentPage) / reComputePagination,
            perPage: reComputePagination,
            total: pagination.total,
            totalPages: Math.ceil(pagination.total / reComputePagination),
          };
        } else {
          params.pagination = pagination;
        }
        return resolve({ items, pagination });
      })
      .catch(error => reject(error))
      .finally(() => {
        params.isLoadingProducts = false;
      });
  });

  const isMayLoadMore = computed({
    get: () => {
      const totalPages = params.pagination.totalPages;
      const currentPage = params.pagination.currentPage;
      return currentPage < totalPages;
    },
    set: () => {},
  });

  const loadMoreCount = computed({
    get: () => {
      const currentPage = params.pagination.currentPage;
      const totalProducts = params.pagination.total;
      const countPerPage = params.pagination.count;

      const passedProductsCount = currentPage * countPerPage;
      const countIsMayLoaded = totalProducts - passedProductsCount;

      if (countIsMayLoaded >= countPerPage) {
        return countPerPage;
      }

      return countIsMayLoaded;
    },
    set: () => {},
  });

  return {
    ...toRefs(params),
    goToPage,
    getProductsList,
    getProductsListForBookPage,
    loadMoreCount,
    isMayLoadMore,
  };
};

export default useProducts;
