import CatalogService from '@/services/v1/CatalogService';
import BasketService from '@/services/v1/BasketService';
import ContentService from '@/services/v1/ContentService';
import UserService from '@/services/v1/UserService';
import AuthorService from '@/services/v1/AuthorService';
import LocationService from '@/services/v1/LocationService';
import WishListService from '@/services/v1/WishListService';
import SearchService from '@/services/v1/SearchService';
import SaleService from '@/services/v1/SaleService';
import HighlightService from '@/services/v1/HighlightService';
import SeriesService from '@/services/v1/SeriesService';
import RecommendationService from '@/services/v1/RecommendationService';
import BrandService from '@/services/v1/BrandService';
import ProductService from '@/services/v1/ProductService';
import OrderService from '@/services/v1/OrderService';
import FilesService from '@/services/v1/FilesService';
import SubscriptionService from '@/services/v1/SubscriptionService';
import SiteService from '@/services/v1/SiteService';
import CheckoutService from '@/services/v1/CheckoutService';
import PartnerService from '@/services/v1/PartnerService';
import SelectionService from '@/services/v1/SelectionService';
import PeopleAndBooksService from '@/services/v1/PeopleAndBooksService';
import LoyaltyService from '@/services/v1/LoyaltyService';
import LotteryService from '@/services/v1/LotteryService';
import TagService from '@/services/v1/TagService';
import B2BService from '@/services/v1/B2BService';
import RatingService from '@/services/v1/RatingService';
import PolicyService from '@/services/v1/PolicyService';
import SurveyService from '@/services/v1/SurveyService';
import PaymentService from '@/services/v1/PaymentService';
import JournalService from '@/services/v1/JournalService';
import CustomersService from '@/services/v1/CustomersService';
import MnogoRuService from '@/services/v1/MnogoRuService';
import CsrfService from '@/services/v1/CsrfService';
import AuthService from '@/services/v1/AuthService';

export default (context, inject) => {
  const { $config, req } = context;
  const clonedConfig = { ...$config, cookie: req?.headers?.cookie };
  clonedConfig.apiUrl = process.server ? $config.apiUrlInternal : $config.apiUrl;

  if (process.env.APP_MODE === 'demo') {
    clonedConfig.apiUrl = $config.appUrl;
  }

  const content = new ContentService(clonedConfig);
  const catalog = new CatalogService(clonedConfig);
  const basket = new BasketService(clonedConfig);
  const author = new AuthorService(clonedConfig);
  const user = new UserService(clonedConfig);
  const location = new LocationService(clonedConfig);
  const wishList = new WishListService(clonedConfig);
  const search = new SearchService(clonedConfig);
  const sales = new SaleService(clonedConfig);
  const highlight = new HighlightService(clonedConfig);
  const series = new SeriesService(clonedConfig);
  const recommendation = new RecommendationService(clonedConfig);
  const brand = new BrandService(clonedConfig);
  const product = new ProductService(clonedConfig);
  const order = new OrderService(clonedConfig);
  const files = new FilesService(clonedConfig);
  const subscription = new SubscriptionService(clonedConfig);
  const site = new SiteService(clonedConfig);
  const checkout = new CheckoutService(clonedConfig);
  const partner = new PartnerService(clonedConfig);
  const selection = new SelectionService(clonedConfig);
  const peopleAndBooks = new PeopleAndBooksService(clonedConfig);
  const loyalty = new LoyaltyService(clonedConfig);
  const lottery = new LotteryService(clonedConfig);
  const tag = new TagService(clonedConfig);
  const b2b = new B2BService(clonedConfig);
  const rating = new RatingService(clonedConfig);
  const policy = new PolicyService(clonedConfig);
  const survey = new SurveyService(clonedConfig);
  const payment = new PaymentService(clonedConfig);
  const journal = new JournalService(clonedConfig);
  const customers = new CustomersService(clonedConfig);
  const mnogoRu = new MnogoRuService(clonedConfig);
  const csrf = new CsrfService(clonedConfig);
  const auth = new AuthService(clonedConfig);

  inject('httpClient', {
    catalog,
    basket,
    content,
    author,
    user,
    location,
    wishList,
    search,
    sales,
    highlight,
    series,
    recommendation,
    brand,
    product,
    order,
    files,
    subscription,
    site,
    checkout,
    partner,
    selection,
    peopleAndBooks,
    loyalty,
    lottery,
    tag,
    b2b,
    rating,
    policy,
    survey,
    payment,
    journal,
    customers,
    mnogoRu,
    csrf,
    auth,
  });
};
