<template>
  <button-control
    v-if="!isAddedToCart"
    :type="type"
    :size="size"
    :is-block="true"
    :disabled="isLoadingBtn"
    :loading="isLoadingBtn"
    @click="handlerClickButton"
  >
    В корзину
  </button-control>
  <app-link
    v-else
    href="/personal/cart/"
    class="b24-btn _block"
    :class="rootSize"
  >
    <span class="b24-btn__content">
      Оформить
    </span>
  </app-link>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

import { ButtonControl } from '@book24/ui-components';

import { TYPE_ELEMENT_SIZES, TYPES_BUTTON } from '@/utils/constants/globalConst';

import useAddToBasket from '@/compositions/catalog/useAddToBasket';

export default defineComponent({
  name: 'AddToCartButton',
  components: {
    ButtonControl,
  },
  props: {
    productId: { type: Number, required: true },
    quantity: { type: Number, required: true },
    isLoading: { type: Boolean, default: false },
    isGift: { type: Boolean, default: false },
    isAddedToCart: { type: Boolean, default: false },
    isSendDdl: { type: Boolean, default: true },
    size: { type: String, default: TYPE_ELEMENT_SIZES.small.value },
    // more || secondary || 'link' || 'link-dashed'
    type: { type: String, default: TYPES_BUTTON.default.value },
    isFetchBasketAfterAddToBasket: { type: Boolean, default: true },
    isLoadingFetchBasket: { type: Boolean, default: false },
  },
  setup(props) {
    const addToBasket = useAddToBasket(props.productId);

    const isLoadingBtn = computed({
      get: () => props.isLoading || addToBasket.isLoadingData.value || props.isLoadingFetchBasket,
      set: () => {},
    });

    const rootSize = computed(() => ({
      [TYPE_ELEMENT_SIZES.small.className]: props.size === TYPE_ELEMENT_SIZES.small.value,
      [TYPE_ELEMENT_SIZES.extraSmall.className]: props.size === TYPE_ELEMENT_SIZES.extraSmall.value,
      [TYPE_ELEMENT_SIZES.large.className]: props.size === TYPE_ELEMENT_SIZES.large.value,
    }));

    const handlerClickButton = ({ target }) => addToBasket.add2Basket({
      isGift: props.isGift,
      quantity: props.quantity,
      isFetchBasket: props.isFetchBasketAfterAddToBasket,
      target,
    });

    return {
      isLoadingBtn,
      rootSize,
      handlerClickButton,
    };
  },
});
</script>
