import { NOT_REAL_MONEY_LIST } from '@/utils/constants/paymentTypes';

export const useOrderPropertyValue = () => {
  const getPropertyValue = (values, code) => values.find(item => item.code === code)?.value ?? null;

  const getContactInfo = (orderPropertyValue) => {
    const contactInfo = {};

    const email = getPropertyValue(orderPropertyValue, 'EMAIL');
    const phone = getPropertyValue(orderPropertyValue, 'PERSONAL_PHONE');
    const firstName = getPropertyValue(orderPropertyValue, 'NAME');
    const lastName = getPropertyValue(orderPropertyValue, 'LAST_NAME');
    const secondName = getPropertyValue(orderPropertyValue, 'SECOND_NAME');
    const zip = getPropertyValue(orderPropertyValue, 'ZIP');
    const address = getPropertyValue(orderPropertyValue, 'ADDRESS');
    const street = getPropertyValue(orderPropertyValue, 'STREET');
    const house = getPropertyValue(orderPropertyValue, 'HOME');
    const block = getPropertyValue(orderPropertyValue, 'CORPUS');
    const apartment = getPropertyValue(orderPropertyValue, 'APARTMENT');
    const location = getPropertyValue(orderPropertyValue, 'LOCATION');

    if (phone) {
      contactInfo.phone = phone;
    }
    if (email) {
      contactInfo.email = email;
    }
    if (firstName) {
      contactInfo.firstName = firstName;
    }
    if (lastName) {
      contactInfo.lastName = lastName;
    }
    if (secondName) {
      contactInfo.secondName = secondName;
    }
    if (zip) {
      contactInfo.zip = zip;
    }
    if (address) {
      contactInfo.address = address;
    }
    if (street) {
      contactInfo.street = street;
    }
    if (house) {
      contactInfo.house = house;
    }
    if (block) {
      contactInfo.block = block;
    }
    if (apartment) {
      contactInfo.apartment = apartment;
    }
    if (location) {
      contactInfo.location = location;
    }

    return contactInfo;
  };

  const getRealPayment = (order) => {
    const orderPayment = order
      ?.orderPayment
      ?.filter(item => item.type === 'orderPayment');

    const [item] = (orderPayment ?? []).filter(el => !NOT_REAL_MONEY_LIST.includes(el.code));

    return item;
  };

  const getRealPaymentName = order => getRealPayment(order)?.name ?? '';

  const getCoupons = order => order
    ?.orderAppliedCoupon
    ?.map(item => item.code) ?? [];

  return {
    getContactInfo,
    getRealPayment,
    getRealPaymentName,
    getCoupons,
    getPropertyValue,
  };
};
