import {
  computed,
  getCurrentInstance,
  onMounted,
  ref,
  toRefs,
  useRouter,
} from '@nuxtjs/composition-api';

import { checkMobile } from '@/utils/deviceCheck';
import { getRecommendationsSave } from '@/utils/recommendationProductsUser';
import { getProductProperty, PROP_TYPES } from '@/utils/analytics/getProductProperty';

import useAdult from '@/compositions/adult/useAdult';
import { useAnalyticProducts } from '@/compositions/analytics/useAnalyticProducts';
import useAddToBasket from '@/compositions/catalog/useAddToBasket';
import useCommonModal from '@/compositions/useCommonModal';

const useProductCard = (props) => {
  const router = useRouter();
  const { emit } = getCurrentInstance();
  const { isAddedToCart } = useAddToBasket(props.product.id);
  const { modal, getModalComponent } = useCommonModal();
  const { checkIsAdult } = useAdult();
  const isMobile = checkMobile();
  const analyticProducts = useAnalyticProducts();
  const productProperty = PROP_TYPES;

  const { product } = toRefs(props);
  const labels = ref([]);

  const showDiscountNearPrice = computed({
    get: () => !product.value?.badge?.percent,
    set: () => {
    },
  });

  const showBadge = computed({
    get: () => Boolean((product.value?.badge?.percent > 0 || product.value?.badge?.icon) && product.value.isAvailable),
    set: () => {},
  });

  const categoryId = computed({
    get: () => props.product?.category?.id || '0',
    set: () => {
    },
  });

  const bookId = computed({
    get: () => props.product?.bookId || '',
    set: () => {},
  });

  const tbkCode = computed({
    get: () => props.product?.tbkCode || '',
    set: () => {},
  });

  const link = computed({
    get: () => {
      let res = props.product.link;
      if (props.paramsLink && Object.keys(props.paramsLink).length) {
        res += '?';
        Object.keys(props.paramsLink).forEach((el) => {
          res += `${el}=${props.paramsLink[el]}`;
        });
      }
      return res;
    },
    set: () => {},
  });

  const isShowBestPriceBadge = computed({
    get: () => props.product.isBestPrice && props.product.isAvailable,
    set: () => {},
  });

  const isShowLabels = computed({
    get: () => props.withLabel && labels.value?.length,
    set: () => {},
  });

  const formattedLabels = () => {
    const savedRecoList = getRecommendationsSave();
    const timeout = savedRecoList?.length ? 0 : 1000;

    setTimeout(() => {
      const isRecommendation = savedRecoList.includes(String(props.product.id));

      if (isRecommendation) {
        labels.value.unshift({
          title: 'Рекомендуем',
          isRecommendation: true,
        });
      }

      const sliceLabels = product.value.labels?.slice(0, isMobile ? 1 : 2);
      labels.value = [...labels.value, ...sliceLabels];
    }, timeout);
  };

  const handlerClosedModalFormAvailable = () => {
    emit('showFormSubscribeOnAvailableProduct', false);
  };

  const handlerClickNotAvailable = () => {
    emit('showFormSubscribeOnAvailableProduct', true);
    modal.show({
      component: getModalComponent(),
      slots: {
        default: {
          component: () => import('@/components/FormSubscribeOnAvailableProduct/index.vue'),
          bind: {
            productId: product.value.id,
            isContainer: false,
          },
          on: {
            closedFormSubscribeOnAvailableProduct: handlerClosedModalFormAvailable,
          },
        },
      },
    });
  };

  const handlerClickProduct = async (event) => {
    analyticProducts.sendAnalyticSelectProduct(event);
    await router.push({ path: `${link.value}` });
  };

  onMounted(() => {
    formattedLabels();
  });

  return {
    isAddedToCart,
    showDiscountNearPrice,
    showBadge,
    categoryId,
    bookId,
    tbkCode,
    link,
    isShowBestPriceBadge,
    isShowLabels,
    labels,
    checkIsAdult,
    productProperty,
    getProductProperty,
    handlerClickNotAvailable,
    handlerClosedModalFormAvailable,
    handlerClickProduct,
  };
};

export default useProductCard;
