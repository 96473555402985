<template>
  <section class="app-section-mobile" :class="rootClass">
    <h2 class="app-section-mobile__title">
      <app-link v-if="linkMore" class="app-section-mobile__more-link" :href="linkMore">
        {{ title }}
        <arrow-right16
          class="app-section-mobile__more-icon"
          :width="16"
          :height="16"
        />
      </app-link>
      <template v-else>
        {{ title }}
      </template>
    </h2>
    <h3 v-if="$slots.subTitle" class="app-section-mobile__sub-title">
      <slot name="subTitle"></slot>
    </h3>
    <span
      v-if="badge"
      class="app-section-mobile__badge"
    >
      {{ badge }}
    </span>
    <div class="app-section-mobile__content-holder">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';

import ArrowRight16 from '@/assets/sprite/svg/16/Arrow_right_16.svg';

export default defineComponent({
  name: 'AppSectionMobile',
  components: { ArrowRight16 },
  props: {
    title: { type: String, default: '' },
    linkMore: { type: String, default: '' },
    isContainer: { type: Boolean, default: true },
    isTitleContainer: { type: Boolean, default: false },
    badge: { type: String, default: '' },
  },
  setup(props) {
    const rootClass = computed({
      get: () => ({
        _container: props.isContainer,
        '_title-container': props.isTitleContainer,
      }),
      set: () => {},
    });

    return {
      rootClass,
    };
  },
});
</script>

<style lang="less">
.app-section-mobile {
  &._container {
    .container-m();
  }

  &__title {
    .h-reset();
    .h2-mobile();
  }

  &._title-container &__title {
    .container-m();
  }

  &__badge {
    .small;

    display: inline-block;
    border-radius: @main-size;
    background-color: @pink;
    color: @white;
    font-weight: bold;
    margin-top: @size-x2;
    padding: 2px @size-x2;
  }

  &__more-link {
    .a-reset();

    display: flex;
    align-items: center;
    margin-left: auto;
    transition: @A color;

    &._tap {
      color: @link-hover;
    }
  }

  &__content-holder {
    margin-top: @size-x4;
  }

  &__more-icon {
    flex: 0 0 16px;
    fill: @link;
    transition: @A fill, @A transform;
    margin-left: auto;
  }

  &__more-link._tap &__more-icon {
    fill: @link-hover;
    transform: translate(-@main-size);
  }

  &__sub-title {
    .h-reset();
    .h3-mobile();

    font-weight: normal;
    margin: @main-size 0 0;
  }
}
</style>
