import Vue from 'vue';
import Router from 'vue-router';

import { SEARCH_QUERY } from '../../utils/constants/searchConst';
import { disabledPagesToScroll } from '../../utils/constants/disabledPagesScrolling';
import getMetaRoute from '../../utils/getMetaRoute';

import checkoutModule from '../../modules/checkout/desktopRouter';
import payModule from '../../modules/pay/router';
import successOrderModule from '../../modules/successOrder/desktopRouter';
import basketModule from '../../modules/basket/router';
import bookqrModule from '../../modules/bookqr/router';

const MainPage = () => import(/* webpackChunkName: "MainPage" */ '../pages/index.vue').then(m => m.default || m);

const CatalogPage = () => import(/* webpackChunkName: "CatalogPage" */ '../pages/catalog/index.vue').then(m => m.default || m);
const NoveltyPage = () => import(/* webpackChunkName: "NoveltyPage" */ '../pages/novelty/index.vue').then(m => m.default || m);
const CatalogCategoryPage = () => import(/* webpackChunkName: "CatalogCategoryPage" */ '../pages/catalog/category.vue').then(m => m.default || m);
const BestsellersPage = () => import(/* webpackChunkName: "BestsellersPage" */ '../pages/bestsellers/index.vue').then(m => m.default || m);
const BestPricePage = () => import(/* webpackChunkName: "BestPricePage" */ '../pages/best-price/index.vue').then(m => m.default || m);
const CatalogSalePage = () => import(/* webpackChunkName: "CatalogSalePage" */ '../pages/catalog-sale/index.vue').then(m => m.default || m);
const PodPage = () => import(/* webpackChunkName: "PodPage" */ '../pages/pod/index.vue').then(m => m.default || m);
const SearchPage = () => import(/* webpackChunkName: "SearchPage" */ '../pages/search/index.vue').then(m => m.default || m);
const AuthorDetailPage = () => import(/* webpackChunkName: "AuthorDetailPage" */ '../pages/authorDetail/index.vue').then(m => m.default || m);
const InterestsPage = () => import(/* webpackChunkName: "InterestsPage" */ '../pages/personal/interests/index.vue').then(m => m.default || m);
const AuthPage = () => import(/* webpackChunkName: "AuthPage" */ '../pages/auth/index.vue').then(m => m.default || m);
const RegisterPage = () => import(/* webpackChunkName: "RegisterPage" */ '../pages/registration').then(m => m.default || m);
const SalesPage = () => import(/* webpackChunkName: "SalesPage" */ '../pages/sales').then(m => m.default || m);
const SalesDetailPage = () => import(/* webpackChunkName: "SalesDetailPage" */ '../pages/salesDetail').then(m => m.default || m);
const SeriesPage = () => import(/* webpackChunkName: "SeriesPage" */ '../pages/series').then(m => m.default || m);
const SeriesDetailPage = () => import(/* webpackChunkName: "SeriesDetailPage" */ '../pages/seriesDetail').then(m => m.default || m);
const ReturnOfGoodsSpbPage = () => import(/* webpackChunkName: "ReturnOfGoodsSpbPage" */ '../pages/return-of-goods-spb').then(m => m.default || m);
const HelpPage = () => import(/* webpackChunkName: "HelpPage" */ '../pages/help').then(m => m.default || m);
const BrandPage = () => import(/* webpackChunkName: "BrandPage" */ '../pages/brands/item').then(m => m.default || m);
const AuthorsPage = () => import(/* webpackChunkName: "AuthorsPage" */ '../pages/authors/index').then(m => m.default || m);
const SubscribedPage = () => import(/* webpackChunkName: "SubscribedPage" */ '../pages/subscribed/index').then(m => m.default || m);
const SubscribePage = () => import(/* webpackChunkName: "SubscribePage" */ '../pages/subscribe/index').then(m => m.default || m);
const SubscriptionBonusPage = () => import(/* webpackChunkName: "SubscriptionBonusPage" */ '../pages/subscriptionBonus/index').then(m => m.default || m);
const SubscriptionPage = () => import(/* webpackChunkName: "SubscriptionPage" */ '../pages/subscription/index').then(m => m.default || m);
const UnsubscribedPartnerPage = () => import(/* webpackChunkName: "UnsubscribedPartnerPage" */ '../pages/unsubscribe/partner/index').then(m => m.default || m);
const ProductDetailPage = () => import(/* webpackChunkName: "ProductDetailPage" */ '../pages/productDetail/index').then(m => m.default || m);
const AboutPage = () => import(/* webpackChunkName: "AboutPage" */ '../pages/about/index').then(m => m.default || m);
const PredzakazPage = () => import(/* webpackChunkName: "PredzakazPage" */ '../pages/predzakaz/index').then(m => m.default || m);
const ForeignOrdersPage = () => import(/* webpackChunkName: "ForeignOrdersPage" */ '../pages/foreign-orders/index').then(m => m.default || m);
const PaymentPage = () => import(/* webpackChunkName: "PaymentPage" */ '../pages/payment/index').then(m => m.default || m);
const LotteryPage = () => import(/* webpackChunkName: "LotteryPage" */ '../pages/lottery/index').then(m => m.default || m);
const PersonalRecommendationsPage = () => import(/* webpackChunkName: "PersonalRecommendationsPage" */ '../pages/personal/recommendation').then(m => m.default || m);
const SiteMapPage = () => import(/* webpackChunkName: "SiteMapPage" */ '../pages/siteMap/index').then(m => m.default || m);
const ContactsPage = () => import(/* webpackChunkName: "ContactsPage" */ '../pages/contacts/index').then(m => m.default || m);
const PersonalOrdersPage = () => import(/* webpackChunkName: "PersonalOrdersPage" */ '../pages/personal/orders').then(m => m.default || m);
const SelectionsPage = () => import(/* webpackChunkName: "SelectionsPage" */ '../pages/selections/index.vue').then(m => m.default || m);
const PersonalProfilePage = () => import(/* webpackChunkName: "PersonalProfilePage" */ '../pages/personal/my-profile/index.vue').then(m => m.default || m);
const SelectionDetailPage = () => import(/* webpackChunkName: "SelectionDetailPage" */ '../pages/selectionDetail/index.vue').then(m => m.default || m);
const PresentsPage = () => import(/* webpackChunkName: "presentsPage" */ '../pages/presents/index.vue').then(m => m.default || m);
const PresentsPageCategory = () => import(/* webpackChunkName: "PresentsPageCategory" */ '../pages/presents/category/index.vue').then(m => m.default || m);
const PersonalCashbackPage = () => import(/* webpackChunkName: "PersonalCashbackPage" */ '../pages/personal/cashback/index.vue').then(m => m.default || m);
const PersonalMyLibraryPage = () => import(/* webpackChunkName: "PersonalMyLibraryPage" */ '../pages/personal/my-library/index.vue').then(m => m.default || m);
const PersonalMySubscriptionPage = () => import(/* webpackChunkName: "PersonalMySubscriptions" */ '../pages/personal/my-subscriptions/index.vue').then(m => m.default || m);
const PersonalOrderPage = () => import(/* webpackChunkName: "PersonalOrderPage" */ '../pages/personal/order/index.vue').then(m => m.default || m);
const TagPage = () => import(/* webpackChunkName: "TagPage" */ '../pages/tagPage/index.vue').then(m => m.default || m);
const B2BPage = () => import(/* webpackChunkName: "B2BPage" */ '../pages/b2b/index.vue').then(m => m.default || m);
const CashbackPage = () => import(/* webpackChunkName: "CashbackPage" */ '../pages/cashback/index.vue').then(m => m.default || m);
const RatingPage = () => import(/* webpackChunkName: "RatingPage" */ '../pages/ratings/index.vue').then(m => m.default || m);
const RatingPageCategory = () => import(/* webpackChunkName: "RatingPageCategory" */ '../pages/ratings/category/index.vue').then(m => m.default || m);
const TermsPage = () => import(/* webpackChunkName: "TermsPage" */ '../pages/terms/index.vue').then(m => m.default || m);
const TagsPage = () => import(/* webpackChunkName: "TagsPage" */ '../pages/tags/index.vue').then(m => m.default || m);
const SurveyOrderPage = () => import(/* webpackChunkName: "SurveyOrderPage" */ '../pages/survey/order/index.vue').then(m => m.default || m);
const PaymentThirdPartyPage = () => import(/* webpackChunkName: "PaymentThirdPartyPage" */ '../pages/personal/payment-third-party-types/index.vue').then(m => m.default || m);
const MnogoruPage = () => import(/* webpackChunkName: "MnogoruPage" */ '../pages/mnogoru/index.vue').then(m => m.default || m);
const JournalPage = () => import(/* webpackChunkName: "JournalPage" */ '../pages/journal/index.vue').then(m => m.default || m);
const BlogPage = () => import(/* webpackChunkName: "BlogPage" */ '../pages/blog/index.vue').then(m => m.default || m);
const BlogDetailPage = () => import(/* webpackChunkName: "BlogDetailPage" */ '../pages/blogDetail/index.vue').then(m => m.default || m);
const NewsPage = () => import(/* webpackChunkName: "NewsPage" */ '../pages/news/index.vue').then(m => m.default || m);
const NewsDetailPage = () => import(/* webpackChunkName: "NewsDetailPage" */ '../pages/newsDetail/index.vue').then(m => m.default || m);
const PeopleAndBooksPage = () => import(/* webpackChunkName: "PeopleAndBooksPage" */ '../pages/people-and-books/index.vue').then(m => m.default || m);
const PeopleAndBooksDetailPage = () => import(/* webpackChunkName: "PeopleAndBooksDetailPage" */ '../pages/people-and-books-detail/index.vue').then(m => m.default || m);
const DeliveryPage = () => import(/* webpackChunkName: "DeliveryPage" */ '../pages/delivery/index.vue').then(m => m.default || m);
const FavoritePage = () => import(/* webpackChunkName: "FavoritePage" */ '../pages/personal/favorite/index.vue').then(m => m.default || m);

Vue.use(Router);

let routes = [
  {
    path: '/',
    component: MainPage,
    name: 'main',
    meta: getMetaRoute({
      ddmCategory: 'Home',
      ddmType: 'home',
    }),
  },
  {
    path: '/catalog/',
    component: CatalogPage,
    props: () => ({ pageNumber: 1 }),
    name: 'catalog',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/catalog/page-:pageNumber(\\d+)/',
    component: CatalogPage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'catalog-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/catalog/:sectionCode-:sectionId(\\d+)/',
    component: CatalogCategoryPage,
    props: route => ({
      pageNumber: 1,
      sectionCode: route.params.sectionCode,
      sectionId: +route.params.sectionId,
    }),
    name: 'catalog-category',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/catalog/:sectionCode-:sectionId(\\d+)/page-:pageNumber(\\d+)/',
    component: CatalogCategoryPage,
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      sectionCode: route.params.sectionCode,
      sectionId: Number(route.params.sectionId),
    }),
    name: 'catalog-category-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/novie-knigi/',
    component: NoveltyPage,
    props: () => ({ pageNumber: 1 }),
    name: 'novelty',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/novie-knigi/page-:pageNumber(\\d+)/',
    component: NoveltyPage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'novelty-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/knigi-bestsellery/',
    component: BestsellersPage,
    props: () => ({ pageNumber: 1 }),
    name: 'bestsellers',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/knigi-bestsellery/page-:pageNumber(\\d+)/',
    component: BestsellersPage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'bestsellers-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/best-price/',
    component: BestPricePage,
    props: () => ({ pageNumber: 1 }),
    name: 'best-price',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/best-price/page-:pageNumber(\\d+)/',
    component: BestPricePage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'best-price-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/catalog-sale/',
    component: CatalogSalePage,
    props: () => ({ pageNumber: 1 }),
    name: 'catalog-sale',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/catalog-sale/page-:pageNumber(\\d+)/',
    component: CatalogSalePage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'catalog-sale-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/pod/',
    redirect: '/',
    component: PodPage,
    props: () => ({ pageNumber: 1 }),
    name: 'pod',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/pod/page-:pageNumber(\\d+)/',
    redirect: '/',
    component: PodPage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'pod-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/search/',
    component: SearchPage,
    name: SEARCH_QUERY,
    props: route => ({ pageNumber: 1, query: route.query.q }),
    meta: getMetaRoute({
      ddmCategory: 'Search Listing',
      ddmType: 'search',
    }),
  },
  {
    path: '/search/page-:pageNumber(\\d+)/',
    component: SearchPage,
    name: `${SEARCH_QUERY}-pages`,
    props: route => ({ pageNumber: Number(route.params.pageNumber), query: route.query.q }),
    meta: getMetaRoute({
      ddmCategory: 'Search Listing',
      ddmType: 'search',
    }),
  },
  {
    path: '/author/',
    component: AuthorsPage,
    name: 'authors',
    meta: getMetaRoute({
      ddmCategory: 'Author Listing',
      ddmType: 'content',
    }),
  },
  {
    path: '/author/page-:pageNumber(\\d+)/',
    redirect: '/author/',
  },
  {
    path: '/author/:slug-:id(\\d+)/',
    component: AuthorDetailPage,
    name: 'author',
    props: route => ({ slug: route.params.slug, id: Number(route.params.id) }),
    meta: getMetaRoute({
      ddmCategory: 'Author Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/author/:slug-:id(\\d+)/page-:pageNumber(\\d+)/',
    component: AuthorDetailPage,
    name: 'author-pagination',
    props: route => ({
      slug: route.params.slug,
      id: Number(route.params.id),
      pageNumber: Number(route.params.pageNumber),
    }),
    meta: getMetaRoute({
      ddmCategory: 'Author Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/personal/interests/',
    component: InterestsPage,
    name: 'interests-page',
    meta: getMetaRoute({
      requiredAuth: true,
    }),
  },
  {
    path: '/personal/interests/page-:pageNumber(\\d+)/',
    redirect: '/personal/interests/',
  },
  {
    path: '/personal/favorite/',
    component: FavoritePage,
    name: 'favorite-page',
    meta: getMetaRoute({
      requiredAuth: true,
    }),
  },
  {
    path: '/auth/',
    component: AuthPage,
    props: route => ({
      redirect: {
        params: route.params,
        query: route.query,
      },
    }),
    name: 'auth-page',
  },
  {
    path: '/register/',
    component: RegisterPage,
    props: route => ({
      redirect: {
        params: route.params,
        query: route.query,
      },
    }),
    name: 'register-page',
  },
  {
    path: '/sales/',
    component: SalesPage,
    props: route => ({
      pageNumber: 1,
      salesQuery: route.query?.salesQuery || '',
    }),
    name: 'sales',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/sales/:saleId(\\d+)/',
    component: SalesDetailPage,
    props: route => ({
      pageNumber: 1,
      saleId: Number(route.params.saleId),
    }),
    name: 'sales-detail',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/sales/:saleId(\\d+)/page-:pageNumber(\\d+)/',
    component: SalesDetailPage,
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      saleId: Number(route.params.saleId),
    }),
    name: 'sales-detail-pages',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/serie/',
    component: SeriesPage,
    props: route => ({
      pageNumber: 1,
      seriesQuery: route.query?.seriesQuery || '',
    }),
    name: 'series',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/serie/page-:pageNumber(\\d+)/',
    component: SeriesPage,
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      seriesQuery: route.query?.seriesQuery || '',
    }),
    name: 'series-pages',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/serie/:sectionCode-:sectionId(\\d+)/',
    component: SeriesDetailPage,
    props: route => ({
      pageNumber: 1,
      seriesCode: route.params.sectionCode,
      seriesId: +route.params.sectionId,
    }),
    name: 'series-detail',
    meta: getMetaRoute({
      ddmCategory: 'Series Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/serie/:sectionCode-:sectionId(\\d+)/page-:pageNumber(\\d+)/',
    component: SeriesDetailPage,
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      seriesCode: route.params.sectionCode,
      seriesId: +route.params.sectionId,
    }),
    name: 'series-detail-pages',
    meta: getMetaRoute({
      ddmCategory: 'Series Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/pravila/',
    name: 'pravila',
    redirect: '/terms/pravila/',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/politika-obrabotki-pd/',
    name: 'politika-obrabotki-pd',
    redirect: '/terms/politika-obrabotki-pd/',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/return_of_goods/',
    name: 'return-of-goods',
    redirect: '/terms/return-of-goods/',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/return_of_goods_spb/',
    component: ReturnOfGoodsSpbPage,
    name: 'return-of-goods-spb',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/help/',
    component: HelpPage,
    name: 'help',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/brand/:name',
    component: BrandPage,
    props: route => ({ pageNumber: 1, name: route.params.name }),
    name: 'brands-page',
    meta: getMetaRoute({
      ddmCategory: 'Publisher Navigation',
      ddmType: 'content',
    }),
  },
  {
    path: '/brand/:name/page-:pageNumber(\\d+)/',
    component: BrandPage,
    props: route => ({ pageNumber: Number(route.params.pageNumber), name: route.params.name }),
    name: 'brands-pages',
    meta: getMetaRoute({
      ddmCategory: 'Publisher Navigation',
      ddmType: 'content',
    }),
  },
  {
    path: '/subscribed/',
    component: SubscribedPage,
    name: 'podpiska',
  },
  {
    path: '/subscribe/',
    component: SubscribePage,
    name: 'subscribe',
  },
  {
    path: '/subscriptionbonus/',
    component: SubscriptionBonusPage,
    name: 'subscriptionbonus',
  },
  {
    path: '/subscription/',
    component: SubscriptionPage,
    name: 'subscription',
  },
  {
    path: '/unsubscribe/partner/',
    component: UnsubscribedPartnerPage,
    name: 'unsubscribe-partner',
    props: route => ({
      hash: route.query.hash,
    }),
  },
  {
    path: '/product/:productSlug-:productId(\\d+)/',
    component: ProductDetailPage,
    name: 'product-page',
    props: route => ({
      productSlug: route.params.productSlug,
      productId: +route.params.productId,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Product Detail',
      ddmType: 'product',
    }),
  },
  {
    path: '/o-nas/',
    component: AboutPage,
    name: 'o-nas',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/predzakaz/',
    component: PredzakazPage,
    props: () => ({ pageNumber: 1 }),
    name: 'predzakaz',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/predzakaz/page-:pageNumber(\\d+)/',
    component: PredzakazPage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'predzakaz-pages',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/lottery/',
    component: LotteryPage,
    name: 'lottery',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/foreign-orders/',
    component: ForeignOrdersPage,
    name: 'foreign-orders',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/payment',
    component: PaymentPage,
    name: 'payment-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/certificates/',
    name: 'certificates',
    redirect: '/terms/certificates/',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/personal/recommendation/',
    component: PersonalRecommendationsPage,
    name: 'personal-recommendation-page',
    props: () => ({ pageNumber: 1 }),
    meta: getMetaRoute({
      requiredAuth: true,
    }),
  },
  {
    path: '/map/',
    component: SiteMapPage,
    name: 'site-map-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/contacts/',
    component: ContactsPage,
    name: 'contacts-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/personal/',
    component: PersonalOrdersPage,
    name: 'personal-page',
    meta: getMetaRoute({
      requiredAuth: true,
      title: 'Личный кабинет',
      description: 'Личный кабинет',
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/personal/order/',
    component: PersonalOrdersPage,
    name: 'personal-orders-page',
    meta: getMetaRoute({
      requiredAuth: true,
      title: 'Мои заказы',
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/podborki/',
    component: SelectionsPage,
    props: () => ({ pageNumber: 1 }),
    name: 'selections-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/podborki/page-:pageNumber(\\d+)/',
    component: SelectionsPage,
    props: route => ({ pageNumber: Number(route.params.pageNumber) }),
    name: 'selections-pages',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/personal/my-profile/',
    component: PersonalProfilePage,
    name: 'personal-my-profile',
    meta: getMetaRoute({
      requiredAuth: true,
      title: 'Личные данные',
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/podborki/:selectionSlug-:selectionId(\\d+)/',
    component: SelectionDetailPage,
    props: route => ({
      selectionSlug: route.params.selectionSlug,
      selectionId: Number(route.params.selectionId),
    }),
    name: 'selection-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/podarki/',
    component: PresentsPage,
    name: 'presents-page',
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/podarki/:category/',
    component: PresentsPageCategory,
    name: 'presents-page-category',
    props: route => ({
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/podarki/:category/page-:pageNumber(\\d+)/',
    component: PresentsPageCategory,
    name: 'presents-pages-category',
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/podarki/:slug/:category/',
    component: PresentsPageCategory,
    name: 'presents-page-last-category',
    props: route => ({
      category: route.params.category,
      gender: route.params.slug,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/podarki/:slug/:category/page-:pageNumber(\\d+)/',
    component: PresentsPageCategory,
    name: 'presents-pages-last-category',
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      category: route.params.category,
      gender: route.params.slug,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/personal/cashback/',
    component: PersonalCashbackPage,
    name: 'personal-cashback-page',
    meta: getMetaRoute({
      requiredAuth: true,
      title: 'Бонусный счёт',
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/personal/my-library/',
    component: PersonalMyLibraryPage,
    name: 'personal-my-library',
    meta: getMetaRoute({
      requiredAuth: true,
      title: 'Отложенные товары',
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/personal/my-subscriptions/',
    component: PersonalMySubscriptionPage,
    name: 'personal-subscriptions-page',
    meta: getMetaRoute({
      requiredAuth: true,
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/personal/order/detail/:orderId(\\d+)/',
    component: PersonalOrderPage,
    name: 'personal-order-page',
    meta: getMetaRoute({
      requiredAuth: true,
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
    props: route => ({
      orderId: Number(route.params.orderId),
    }),
  },
  {
    path: '/tag-page/:category/',
    component: TagPage,
    name: 'tag-page',
    props: route => ({
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/tag-page/:category/page-:pageNumber(\\d+)/',
    component: TagPage,
    name: 'tag-pages',
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/b2b/',
    component: B2BPage,
    name: 'b2b-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/cashback/',
    component: CashbackPage,
    name: 'cashback-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/terms/',
    name: 'terms-page',
    redirect: '/terms/pravila/',
  },
  {
    path: '/terms/:code/',
    component: TermsPage,
    name: 'terms-child',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/rating/',
    component: RatingPage,
    name: 'rating-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/rating/top*/',
    redirect: '/rating/',
  },
  {
    path: '/rating/top-(\\w+)+/',
    redirect: '/rating/',
  },
  {
    path: '/rating/:category/',
    component: RatingPageCategory,
    name: 'rating-page-category',
    props: route => ({
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/rating/:category/page-:pageNumber(\\d+)/',
    component: RatingPageCategory,
    name: 'rating-page-categories',
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/tags/:category/',
    component: TagsPage,
    name: 'by-tags-page',
    props: route => ({
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/tags/:category/page-:pageNumber(\\d+)/',
    component: TagsPage,
    name: 'by-tags-pages',
    props: route => ({
      pageNumber: Number(route.params.pageNumber),
      category: route.params.category,
    }),
    meta: getMetaRoute({
      ddmCategory: 'Category Listing',
      ddmType: 'listing',
    }),
  },
  {
    path: '/survey/order/',
    component: SurveyOrderPage,
    name: 'survey-order-page',
    meta: getMetaRoute({
      requiredAuth: true,
    }),
  },
  {
    path: '/personal/payment/',
    component: PaymentThirdPartyPage,
    name: 'payment-third-party-types',
    props: route => ({ orderId: (Number(route.query.ORDER_ID) || Number(route.query.order_id)) ?? null }),
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
      requiredAuth: true,
    }),
  },
  {
    path: '/mnogoru/',
    component: MnogoruPage,
    name: 'mnogoru',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/journal/',
    component: JournalPage,
    name: 'journal-page',
    meta: getMetaRoute({
      title: 'Что читать',
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/blog/',
    component: BlogPage,
    name: 'blog-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/blog/:blogId(\\d+)/',
    component: BlogDetailPage,
    props: route => ({
      blogId: Number(route.params.blogId),
    }),
    name: 'blog-detail-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/news/',
    component: NewsPage,
    name: 'news-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/news/:newsId(\\d+)/',
    component: NewsDetailPage,
    props: route => ({
      newsId: Number(route.params.newsId),
    }),
    name: 'news-detail-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/people-and-books/',
    component: PeopleAndBooksPage,
    name: 'people-and-books-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/people-and-books/:peopleSlug-:peopleId(\\d+)/',
    component: PeopleAndBooksDetailPage,
    props: route => ({
      peopleSlug: route.params.peopleSlug,
      peopleId: Number(route.params.peopleId),
    }),
    name: 'people-and-books-detail-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  {
    path: '/delivery/',
    component: DeliveryPage,
    props: () => ({ }),
    name: 'delivery-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
    }),
  },
  ...checkoutModule,
  ...payModule,
  ...successOrderModule,
  ...basketModule,
  ...bookqrModule,
];

export function createRouter() {
  const getRoutes = () => {
    if (process.env.APP_MODE !== 'prod') {
      const devRoutes = require('./dev.routes');
      routes = [...devRoutes.default, ...routes];
    }

    return routes;
  };

  const router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savePosition) {
      const disabledPages = disabledPagesToScroll.find(el => el === to.name);
      if (!savePosition && !to.hash && to !== from && !disabledPages) {
        return {
          x: 0,
          y: 0,
        };
      }
      return new Promise((resolve) => {
        let position = {
          x: 0,
          y: 0,
        };
        if (to.hash) {
          position = {
            selector: to.hash,
          };
        }

        this.app.$root.$once('triggerScrollCustom', () => {
          if (savePosition) {
            position = savePosition;
          }
          setTimeout(() => {
            resolve(position);
          }, 100);
        });
      });
    },
    routes: getRoutes(),
  });

  router.afterEach(() => {
    if (process.client && window?.$nuxt?.$httpClient) {
      window.$nuxt.$httpClient.partner.clickRecorder();
    }
  });

  return router;
}

export const plainRoutes = routes.map(item => ({ name: item.name, path: item.path }));
