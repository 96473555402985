import getRecaptcha from '@/utils/getThirdPartyScript/getRecaptcha';
import pushToDataLayer from '@/utils/dataLayer/pushToDataLayer';

const pageType = 'defaultAction';

const renderCaptcha = async ({ callback, container }) => {
  const recaptcha = await getRecaptcha();
  return recaptcha.render(container, {
    sitekey: process.env.APP_GOOGLE_RECAPTCHA_PUBLIC_KEY_V2,
    callback,
  });
};

const resetCaptcha = async (recaptchaId) => {
  const recaptcha = await getRecaptcha();
  recaptcha.reset(recaptchaId);
};

const getResponseCaptcha = async (recaptchaId) => {
  const recaptcha = await getRecaptcha();
  recaptcha.getResponse(recaptchaId);
};

const getToken = async (action = pageType) => {
  const recaptcha = await getRecaptcha();
  return new Promise((resolve, reject) => {
    recaptcha.execute(process.env.APP_GOOGLE_RECAPTCHA_PUBLIC_KEY_V3, { action })
      .then(
        (token) => {
          resolve(token);
        },
        (reason) => {
          pushToDataLayer({ event: 'CaptchaNotExecuteToken' });
          reject(reason);
        },
      );
  });
};

export default {
  renderCaptcha,
  resetCaptcha,
  getResponseCaptcha,
  getToken,
};
