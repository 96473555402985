import { getProductProperty, PROP_TYPES } from '@/utils/analytics/getProductProperty';

/**
 * Получаем параметры товара и места взаимодействия с ним
 * и приводим их к нужному для аналитики формату
 * @param item
 * @param index
 * @param routeName
 */
export const setProductAnalyticProperties = (item, index, routeName) => ({
  productQuantity: item.quantity,
  productId: getProductProperty(item.product, PROP_TYPES.id),
  productBaseId: getProductProperty(item.product, PROP_TYPES.baseId),
  productPrice: getProductProperty(item, PROP_TYPES.price),
  productName: getProductProperty(item.product, PROP_TYPES.name),
  productBrand: getProductProperty(item.product, PROP_TYPES.brand),
  productDiscount: getProductProperty(item.product, PROP_TYPES.discount),
  productCategory: getProductProperty(item.product, PROP_TYPES.category),
  productAvailable: getProductProperty(item.product, PROP_TYPES.available),
  productPreOrder: getProductProperty(item.product, PROP_TYPES.preorder),
  productIndex: getProductProperty(item.targetProperties, PROP_TYPES.index, index) + 1,
  itemListName: getProductProperty(item.targetProperties, PROP_TYPES.itemListName, routeName),
  shelfName: getProductProperty(item.targetProperties, PROP_TYPES.shelfName),
  shelfIndex: getProductProperty(item.targetProperties, PROP_TYPES.shelfIndex),
});
