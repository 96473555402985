<template>
  <div
    class="promoCode"
    :class="{ _mobile: isMobile }"
  >
    <img src="/_ssr/img/authorization/authorization-success.svg" />
    <div class="promoCode__content">
      <promo-code-info />
      <button-control
        class="promoCode__button"
        size="large"
        tabindex="5"
        @click="closeAuthorizationModalHandler"
      >
        Перейти к покупкам
      </button-control>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api';

import { ButtonControl } from '@book24/ui-components';

import { checkMobile } from '@/utils/deviceCheck';

import useAuthorization from '@/compositions/authorization/useAuthorization';

import PromoCodeInfo from '@/components/PromoCode/PromoCodeInfo/PromoCodeInfo.vue';

export default defineComponent({
  name: 'PromoCode',
  components: {
    PromoCodeInfo,
    ButtonControl,
  },
  setup() {
    const router = useRouter();
    const isMobile = checkMobile();
    const isShow = ref(true);
    const { closeAuthorizationModal } = useAuthorization();

    const closeAuthorizationModalHandler = () => {
      closeAuthorizationModal();
      router.push('/');
    };

    return {
      isShow,
      isMobile,
      closeAuthorizationModalHandler,
    };
  },
});
</script>

<style lang="less">
.promoCode {
  width: 100%;
  box-sizing: content-box;
  padding: @size-x5 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__picture {
    width: 100%;
    flex: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: @size-x10;
    width: 100%;
  }

  &__button {
    width: 100%;
  }

  &._mobile {
    max-width: 100%;
  }

  &._mobile &__picture {
    width: auto;
  }

  &._mobile &__content {
    gap: @size-x8;
    margin-top: @size-x8;

    @media @375 {
      width: 100%;
    }
  }
}
</style>
