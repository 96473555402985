// @ts-check
/// <reference path="./typedefs.js" />
/// <reference path="../../../typedefs.js" />

import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';

import { buildErrorCollection, buildElement, buildCollection } from '@/utils/formatters/processingOpenApi';
import { ERROR_NOT_FOUND, ERROR_SERVER_ERROR } from '@/utils/constants/globalConst';
import { ERRORS_CREATE_INVOICE } from '@/modules/pay/enums';

export default class PaymentService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получение формы оплаты сторонних сервисов
   * @param {string} orderId - ид заказа
   * @return {Promise<ThirdPartyPaymentForm|ErrorObject>} - форма оплаты
   */
  getPaymentThirdTypeForm(orderId) {
    return new Promise((resolve, reject) => {
      if (!orderId) return reject(new ServiceError('Обязательный параметр orderId отсутствует'));
      if (typeof orderId !== 'number') return reject(new ServiceError('Параметр orderId должен быть числом'));

      this.mainHttp.get(`/api/v1/order/${orderId}/payment-form/`)
        .then(({ data: { data: dataRes } }) => {
          const formattedDataRes = buildElement(dataRes);
          resolve(formattedDataRes);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            reject(new ServiceError(firstError));
          }
          reject(new ServiceError(e));
        });
    });
  }

  fetchCardsList() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/sale/order/payment/token/')
        .then(({ data: { data: dataRes } }) => resolve(buildCollection(dataRes || {})))
        .catch((error) => {
          if (error.response) {
            const errors = buildErrorCollection(error.response.data);
            const [firstError] = errors;
            reject(new ServiceError(firstError));
          }
          reject(new ServiceError(error));
        });
    });
  }

  removeCardById(idCard) {
    return new Promise((resolve, reject) => {
      this.mainHttp.delete(`/api/v1/sale/order/payment/token/${idCard}/`)
        .then(() => resolve())
        .catch((error) => {
          if (error.response) {
            const errors = buildErrorCollection(error.response.data);
            const [firstError] = errors;
            reject(new ServiceError(firstError));
          }
          reject(new ServiceError(error));
        });
    });
  }

  payCard(payload = {}) {
    return new Promise((resolve, reject) => {
      const requestUrl = payload.orderInvoiceId
        ? `/api/v1/invoice/${payload.orderInvoiceId}/pay/`
        : `/api/v1/sale/order/${payload.orderId}/pay/`;

      this.mainHttp.post(requestUrl, {
        type: payload.type,
        strategy: payload.strategy,
        cryptogram: payload.cryptogram,
        cardHolder: payload.cardHolder,
        remember: payload.remember,
      })
        .then(({ data: { data: dataRes } }) => resolve(dataRes))
        .catch((error) => {
          if (error.response) {
            const errors = buildErrorCollection(error.response.data);
            let [firstError] = errors;
            firstError = {
              ...firstError,
              failUrl: typeof error.response.data?.data?.failUrl === 'string' ? error.response.data.data.failUrl : null,
            };

            reject(firstError);
          }
          reject(new ServiceError(error));
        });
    });
  }

  startSessionApplePay(payload = {}) {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/sale/applepay/startsession/', payload)
        .then(({ data: { data } }) => resolve(data))
        .catch((error) => {
          if (error.response) reject(error.response);
          reject(error);
        });
    });
  }

  /**
   * Получаем информацию о заказе по invoiceId
   * @param {string} invoiceId
   * @param {Object} [params]
   * @param {boolean} [params.isGetOrder=true]
   * @param {boolean} [params.isGetOrderLocation=false]
   * @param {boolean} [params.isGetOrderPropertyValue=false]
   * @return {Promise<unknown>}
   */
  fetchInvoiceData(invoiceId, params = {}) {
    return new Promise((resolve, reject) => {
      const { isGetOrder = true, isGetOrderLocation = false, isGetOrderPropertyValue = false } = params;
      let payload = {};

      const include = [];
      if (isGetOrder) include.push('order');
      if (isGetOrderLocation) include.push('order.orderLocation');
      if (isGetOrderPropertyValue) include.push('order.orderPropertyValue');

      if (include.length) payload = { ...payload, include: include.join(',') };

      this.mainHttp.get(`/api/v1/invoices/${invoiceId}/`, payload)
        .then(({ data: { data: dataRes } }) => {
          const info = buildElement(dataRes);

          resolve({
            invoiceStatus: info.status,
            orderId: info.relationships.order.id,
            status: info.relationships.order.status,
            createdAt: info.relationships.order.createdAt,
            amount: info.relationships.order.amount,
            orderPropertyValue: info.relationships?.order?.relationships?.orderPropertyValue ?? [],
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              reject(new ServiceError(ERROR_NOT_FOUND));
            }
            if (error.response.status === 500) {
              reject(new ServiceError(ERROR_SERVER_ERROR));
            }
            const errors = buildErrorCollection(error.response.data);
            const [firstError] = errors;
            reject(new ServiceError(firstError));
          }
          reject(new ServiceError(error));
        });
    });
  }

  createInvoice(fromInvoiceId) {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/invoices/', { fromInvoiceId })
        .then(({ data: { data: { data: dataRes } } }) => resolve(dataRes.id))
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              reject(new ServiceError(ERROR_NOT_FOUND));
            }
            if (error.response.status === 500) {
              reject(new ServiceError(ERROR_SERVER_ERROR));
            }
            const errors = buildErrorCollection(error.response.data, ERRORS_CREATE_INVOICE);
            const [firstError] = errors;
            reject(new ServiceError(firstError));
          }
          reject(new ServiceError(error));
        });
    });
  }

  /**
   * Получение файла на оплату в формате pdf
   * @param {string} orderId - ид заказа
   * @return {Promise<unknown|ErrorObject>} - файл на оплату
   */
  getPaymentFile(orderId) {
    return new Promise((resolve, reject) => {
      if (!orderId) return reject(new ServiceError('Обязательный параметр orderId отсутствует'));
      if (typeof orderId !== 'number') return reject(new ServiceError('Параметр orderId должен быть числом'));

      this.mainHttp.download(`/api/v1/order/${orderId}/payment-file/`)
        .then(data => resolve(data))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
