<template>
  <div class="card-adult">
    <div
      v-if="showBadge"
      class="card-adult__circle"
    >
      18+
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CardAdult',
  props: {
    showBadge: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="less">
.card-adult {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(6px);
  z-index: 1;

  &__circle {
    .h3-desktop;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background: @gray-dark;
    border-radius: 100%;
    color: @white;

    @media @mobile {
      .h4-desktop;

      width: 48px;
      height: 48px;
    }
  }
}
</style>
