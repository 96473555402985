import { ROUBLE_CODE } from '@/utils/constants/globalConst';
import { getItemListName } from './getItemListName';

/**
 * Приводим список с продуктами к формату аналитики
 * @param items
 */
export const getArrayItems = (items) => {
  if (!items) return [];

  const resultItems = [];

  items.forEach((item) => {
    const params = {
      index: item.productIndex || 1,
      item_id: item.productId,
      item_name: item.productName,
      item_brand: item.productBrand,
      discount: parseFloat(item.productDiscount),
      item_category: item.productCategory,
      not_available: item.productAvailable ? 'no' : 'yes',
      price: parseFloat(item.productPrice),
      quantity: Number(item.productQuantity) || 1,
      preorder: item.productPreOrder ? 'yes' : 'no',
      item_list_name: getItemListName(item.itemListName),
      currency: ROUBLE_CODE,
    };

    if (item.shelfIndex) params.index_shelf = Number(item.shelfIndex);
    if (item.shelfName) params.product_shelf = item.shelfName;

    resultItems.push(params);
  });

  return resultItems;
};
