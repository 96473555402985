<template>
  <ul
    :class="{ _mobile: isMobile }"
    class="certificates-list"
  >
    <li
      v-for="(certificate, index) of certificates"
      :key="index"
      class="certificates-list__item"
    >
      <app-picture
        :item="images"
        :alt="`Сертификат №${index + 1}`"
        class-picture="certificates-list__picture"
        class-img="certificates-list__image"
      />
      <h4 class="certificates-list__title">
        Подарочный сертификат №{{ index + 1 }}
      </h4>
      <download-button
        :links="[certificate]"
        :number="index + 1"
        type="secondary"
        size="small"
        is-block
      >
        Скачать сертификат
      </download-button>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import AppPicture from '@/components/AppPicture';

export default defineComponent({
  name: 'CertificatesList',
  components: {
    AppPicture,
    DownloadButton: () => import('@/components/DownloadButton'),
  },
  props: {
    images: {
      type: Object,
      default: () => {},
    },
    certificates: {
      type: Array,
      default: () => [],
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="less">
.certificates-list {
  .list-reset;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: @size-x8;
  padding: @size-x4;

  &__title {
    .h4-desktop;

    margin-top: @size-x2;
  }

  &__image {
    width: 100%;
    max-height: 154px;
    object-fit: contain;
  }

  &._mobile {
    grid-template-columns: 1fr;
  }

  &._mobile &__image {
    max-height: 254px;
  }

  &._mobile &__item:last-of-type {
    padding-bottom: @size-x8;
  }

  &._mobile &__title {
    text-align: center;
  }
}
</style>
