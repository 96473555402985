<template>
  <div
    v-if="promoCode"
    class="promoCodeInfo"
    :class="{
      _forProduct: isForProduct,
      _mobile: isMobile
    }"
  >
    <p class="promoCodeInfo__percentage">
      Промокод на <b>скидку {{ percentage }}%</b> только для вас:
    </p>
    <app-copy-button
      :copied-text="promoCode.coupon"
      tooltip-text="Промокод скопирован"
      class="promoCodeInfo__code"
      @copied="handlerCopied"
    >
      {{ promoCode.coupon }}
      <copy-icon16 class="promoCodeInfo__code-icon" />
    </app-copy-button>
    <p v-if="!isForProduct">
      Срок действия промокода <b>до {{ date }}</b>
    </p>
    <p v-if="isForProduct">
      Промокод действует <b>по {{ date }}</b>
    </p>
  </div>
</template>

<script>
import { ref, computed, defineComponent, useStore } from '@nuxtjs/composition-api';

import { captureException } from '@sentry/browser';
import { checkMobile } from '@/utils/deviceCheck';
import { getFormatDateTime } from '@/utils/dateTime';

import { useBasketContext } from '@/compositions/basket/basketStorage';

import AppCopyButton from '@/components/AppCopyButton/index.vue';

// icons
import CopyIcon16 from '@/assets/sprite/svg/16/Copy_16.svg';

export default defineComponent({
  name: 'PromoCodeInfo',
  components: { AppCopyButton, CopyIcon16 },
  props: {
    isForProduct: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const isMobile = checkMobile();
    const { applyPromoCode } = useBasketContext();

    const promoCode = computed(() => {
      if (store.state.promoCode.coupon) return store.state.promoCode;
      return null;
    });
    const date = computed(() => (promoCode.value ? getFormatDateTime(promoCode.value?.activeTo, 'dd.M') : null));
    const percentage = '30';

    const isApplied = ref(false);
    const handlerCopied = async (code) => {
      if (isApplied.value) return false;
      await applyPromoCode(code)
        .then(() => isApplied.value = true)
        .catch((error) => {
          captureException(error);
        });
    };

    return {
      date,
      isMobile,
      promoCode,
      percentage,
      handlerCopied,
    };
  },
});
</script>

<style lang="less">
.promoCodeInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: @size-x4;
  background: @yellow-back;
  border-radius: @size-x1;
  align-items: center;

  p {
    .small;

    margin: 0;
  }

  &__code {
    gap: @size-x3;
    color: @link;
    font-weight: 700;
    font-size: 18px;
  }

  &__code-icon {
    width: @size-x6;
    height: @size-x6;
    fill: @link;
  }

  &._forProduct {
    background: @yellow-light;
    align-items: flex-start;
  }

  &._mobile {
    align-items: center;
    text-align: center;
  }
}
</style>
