// @ts-check
/// <reference path="./typedefs.js" />

import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import HttpClient from '@/utils/clients/clientBookMain';

import { ERROR_CALL_ORDER } from '@/utils/constants/constErrorsCode';
import { ERROR_CAPTCHA, ERROR_USER_AUTHORIZED } from '@/utils/constants/userConstants';

export default class AuthService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Создание заявки на аутентификацию
   * @param {PayloadCreateAuthIssue} payload
   * @return {Promise<ResponseAuthIssue|ErrorObject|Error>}
   */
  createAuthIssue(payload) {
    const params = {
      strategy: {
        type: payload.strategy,
        payload: {
          goal: 'auth',
          visitorId: payload.visitorId,
          phone: payload.phone,
        },
      },
      brsign: { ...payload.brsign },
    };
    return new Promise((resolve, reject) => {
      if (!payload.visitorId) return reject(ERROR_USER_AUTHORIZED.notVisitorId);
      if (!payload.phone) return reject(ERROR_CALL_ORDER.notStringPhone);
      if (typeof payload.phone !== 'string') return reject(ERROR_CALL_ORDER.emptyPhone);
      if (!payload.brsign.payload.token) return reject(ERROR_CAPTCHA.emptyCaptchaToken);
      if (typeof payload.brsign.payload.token !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaToken);
      if (!payload.brsign.payload.action) return reject(ERROR_CAPTCHA.emptyCaptchaAction);
      if (typeof payload.brsign.payload.action !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaAction);
      if (!payload.brsign.payload.version) return reject(ERROR_CAPTCHA.emptyCaptchaVersion);
      if (typeof payload.brsign.payload.version !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaVersion);

      this.mainHttp.post('/api/v1/auth-issues/', params)
        .then(({ data: { data: { data: dataRes } } }) => {
          const response = {
            id: dataRes.id,
            transitions: dataRes.attributes.transitions,
          };
          return resolve(response);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Подтверждение номера телефона
   * @param {PayloadApprovePhone} payload
   * @return {Promise<ResponseApprovePhone|ErrorObject|Error>}
   */
  approvePhone({ id, code }) {
    return new Promise((resolve, reject) => {
      if (!id) return reject(ERROR_USER_AUTHORIZED.notAuthIssueId);
      if (!code) return reject(ERROR_USER_AUTHORIZED.notVerificationCode);

      this.mainHttp.post(`/api/v1/auth-issues/${id}/verify/`, { code })
        .then(({ data: { data: { data: dataRes } } }) => resolve(dataRes.attributes))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Проверяем заявку на аутентификацию
   * @param {string} id
   * @return {Promise<ResponseAuthIssue|ErrorObject|Error>}
   */
  checkAuth(id) {
    return new Promise((resolve, reject) => {
      if (!id) return reject(ERROR_USER_AUTHORIZED.notAuthIssueId);

      this.mainHttp.get(`/api/v1/auth-issues/${id}/`)
        .then(({ data: { data: { data: dataRes } } }) => {
          const response = {
            id: dataRes.id,
            transitions: dataRes.attributes.transitions,
          };
          return resolve(response);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Авторизуем пользователя по подтверждённой заявке
   * @param {PayloadLogin} payload
   * @return {Promise<void|ErrorObject|Error>}
   */
  login(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.authIssueId) return reject(ERROR_USER_AUTHORIZED.notAuthIssueId);

      this.mainHttp.post('/api/v1/user-session-by-auth-issue/', payload)
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Регистрируем пользователя по подтверждённой заявке
   * @param {PayloadRegistration} payload
   * @return {Promise<void|ErrorObject|Error>}
   */
  registration(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.authIssueId) return reject(ERROR_USER_AUTHORIZED.notAuthIssueId);
      if (!payload.customer.email) return reject(ERROR_USER_AUTHORIZED.notEmail);
      if (!payload.customer.name) return reject(ERROR_USER_AUTHORIZED.notName);
      if (!payload.customer.lastName) return reject(ERROR_USER_AUTHORIZED.notLastName);
      if (payload.customer.subscribed === undefined) return reject(ERROR_USER_AUTHORIZED.notSubscribed);

      this.mainHttp.post('/api/v1/users/', payload)
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получает предзаполнение данных клиента при регистрации
   * @param {PayloadAutocompleteRegistration} payload
   * @return {Promise<ResponseAutocompleteRegistration|ErrorObject|Error>}
   */
  autocompleteRegistration(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.authIssueId) return reject(ERROR_USER_AUTHORIZED.notAuthIssueId);

      this.mainHttp.post('/api/v1/user/suggest-sign-up-data/', payload)
        .then(({ data: { data: { data: dataRes } } }) => resolve(dataRes?.attributes))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Проверяем зарегистрирована ли юзер с указанным телефоном на сайте
   * @param {PayloadCheckPhoneAvailability} payload
   * @return {Promise<ErrorObject|Error>}
   */
  checkPhoneAvailability(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.phone) return reject(ERROR_CALL_ORDER.notStringPhone);
      if (typeof payload.phone !== 'string') return reject(ERROR_CALL_ORDER.emptyPhone);
      if (!payload.brsign.payload.token) return reject(ERROR_CAPTCHA.emptyCaptchaToken);
      if (typeof payload.brsign.payload.token !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaToken);
      if (!payload.brsign.payload.action) return reject(ERROR_CAPTCHA.emptyCaptchaAction);
      if (typeof payload.brsign.payload.action !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaAction);
      if (!payload.brsign.payload.version) return reject(ERROR_CAPTCHA.emptyCaptchaVersion);
      if (typeof payload.brsign.payload.version !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaVersion);

      this.mainHttp.post('/api/v1/user/check-phone-availability/', payload)
        .then(({ data: { data: { data: dataRes } } }) => {
          if (dataRes?.attributes) resolve(dataRes?.attributes);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
