<template>
  <div class="shelf-products" :class="{ _container: isContainer }">
    <content-placeholders rounded class="shelf-products__title">
      <content-placeholders-text
        :lines="1"
        style="width: 280px; height: 28px;"
      />
    </content-placeholders>
    <div class="shelf-products__scroller">
      <div v-for="item in 3" :key="item" class="shelf-products__scroller-item">
        <component
          :is="isSmall ? 'ProductCardSmallSkeleton' : 'SkeletonMobileVertical'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import ProductCardSmallSkeleton from '@/components/ProductCardSmall/skeleton';
import SkeletonMobileVertical from '@/components/ProductCard/ProductCardMobile/skeleton.vue';

export default defineComponent({
  name: 'ShelfProductsMobileSkeleton',
  components: {
    ProductCardSmallSkeleton,
    SkeletonMobileVertical,
  },
  props: {
    isTitle: { type: Boolean, default: true },
    showButtons: { type: Boolean, default: true },
    isContainer: { type: Boolean, default: true },
    isSmall: { type: Boolean, default: true },
  },
});
</script>

<style lang="less">
.shelf-products {
  &._brand,
  &._bundle {
    .shelf-products__scroller {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 10px;
      max-width: 100%;
      overflow: hidden;
    }
  }
}
</style>
