export const ERROR_TEXT_WHEN_FETCH_PVZ = Object.freeze({
  detectCoords: 'Ошибка определения координат',
  cityChangedWhenDetectCoords: 'Город определения координат не соответствует выбранному городу',
  emptyNearPvz: 'Ближайшие пункты выдачи не найдены',
  emptyCheapestPvz: 'Дешевые пункты выдачи не найдены',
  emptyPvz: 'Пункты выдачи не найдены',
});

export const ERROR_CERTIFICATE = Object.freeze({
  emptyNumber: {
    code: '0000',
    message: 'Введите номер сертификата',
  },
  unCorrectNumber: {
    code: '0001',
    message: 'Поле должно состоять из {maxLengthValue} символов',
  },
  emptyNumberCert: {
    code: '0c70d86b-dd01-544d-8a90-ca793e7d09c9',
    message: 'Введите номер сертификата',
  },
  notFoundCert: {
    code: '2f41df3e-6dd7-5861-90eb-6bff2b8ff95b',
    message: 'Сертификат с таким номером не найден',
  },
  notActiveCert: {
    code: 'bfa37727-4efc-59e5-bffb-31e7e8981ed7',
    message: 'Сертификат с таким номером не активен',
  },
  wasActiveCert: {
    code: '6c16db5e-02af-5d19-b024-3de0a0d546d2',
    message: 'Сертификат с таким номером уже был применен',
  },
});

export const ERRORS_DELIVERY = Object.freeze({
  emptyDelivery: {
    code: '1020',
    message: 'Сожалеем, но в данный населённый пункт доставка не осуществляется.',
  },
  otherError: {
    code: '000',
    message: 'Сожалеем, но данный способ доставки недоступен. Попробуйте выбрать другой.',
  },
});

export const ERROR_TEXT_NOT_DELIVERIES = 'Сожалеем, но данный способ доставки недоступен. Попробуйте выбрать другой.';

export const ERRORS_DELIVERY_SUMMARY = Object.freeze({
  incorrectStrategy: {
    message: 'Передана не подходящая стратегия',
    code: 'bee5aac9-2217-4153-a4a3-976bb293ce41',
  },
  incorrectProductId: {
    message: 'Передано недопустимое значение productId',
    code: 'abd9f587-84d2-46e5-bc2f-d03aa7a63a9f',
  },
  incorrectQuantity: {
    message: 'Передано недопустимое значение quantity',
    code: '92538995-519d-449d-a378-ffb41e68e4f1',
  },
  incorrectCoupon: {
    message: 'Передано недопустимое значение coupon',
    code: '2445e6e4-f41a-431a-8202-16316226f249',
  },
  incorrectBasketId: {
    message: 'Передано недопустимое значение basketId',
    code: 'd3708950-c5e5-480f-9311-de347de70fa6',
  },
  emptyDelivery: {
    message: 'Для заказа не найдено ни одной службы доставки',
    code: 'eceb4034-7500-4712-a64b-5ca5f5d6769e',
  },
  notExistBitrixBasket: {
    code: '5f7bce16-8d6d-4c56-a232-d1975b736346',
    message: 'Битрикс-корзина не существует',
  },
  notExistBasket: {
    code: 'a210490f-645c-45a6-9ab3-8c0eb9e882ab',
    message: 'Корзина не существует',
  },
  isNotExistBasket(code) {
    return code === this.notExistBasket.code || code === this.notExistBitrixBasket.code;
  },
  isEmptyDelivery(code) {
    return code === this.emptyDelivery.code;
  },
});

export const ERRORS_PAYMENT_SYSTEMS = Object.freeze({
  deliveryUnavailable: {
    message: 'Сожалеем, но данный способ доставки недоступен. Попробуйте выбрать другой.',
    code: '110c4855-0379-46ae-908f-46a158d094dc',
  },
});

export const ERRORS_SUMMARY = Object.freeze({
  incorrectStrategy: {
    message: 'Передана не подходящая стратегия',
    code: 'bee5aac9-2217-4153-a4a3-976bb293ce41',
  },
  incorrectProductId: {
    message: 'Передано недопустимое значение productId',
    code: 'abd9f587-84d2-46e5-bc2f-d03aa7a63a9f',
  },
  incorrectQuantity: {
    message: 'Передано недопустимое значение quantity',
    code: '92538995-519d-449d-a378-ffb41e68e4f1',
  },
  incorrectCoupon: {
    message: 'Передано недопустимое значение coupon',
    code: '2445e6e4-f41a-431a-8202-16316226f249',
  },
  incorrectBasketId: {
    message: 'Передано недопустимое значение basketId',
    code: 'd3708950-c5e5-480f-9311-de347de70fa6',
  },
  emptyDelivery: {
    message: 'Для заказа не найдено ни одной службы доставки',
    code: 'eceb4034-7500-4712-a64b-5ca5f5d6769e',
  },
  notAvailableProduct: {
    message: 'Товар недоступен для заказа',
    code: '8564f4e0-8326-4262-9182-4f5267e82c42',
  },
  notExistBitrixBasket: {
    code: '5f7bce16-8d6d-4c56-a232-d1975b736346',
    message: 'Битрикс-корзина не существует',
  },
  notExistBasket: {
    code: 'a210490f-645c-45a6-9ab3-8c0eb9e882ab',
    message: 'Корзина не существует',
  },
  deliveryUnavailable: {
    message: 'Сожалеем, но данный способ доставки недоступен. Попробуйте выбрать другой.',
    code: '5fdf973a-8bb5-4b99-951c-b9e8d6c5918a',
  },
  isNotExistBasket(code) {
    return code === this.notExistBasket.code || code === this.notExistBitrixBasket.code;
  },
  isEmptyDelivery(code) {
    return code === this.emptyDelivery.code;
  },
  isNotAvailableProduct(code) {
    return code === this.notAvailableProduct.code;
  },
});

export const ERRORS_ORDER = Object.freeze({
  bonusesFail: {
    message: 'Применение бонусов временно недоступно',
    code: 'e6979dfb-fc61-4027-8a02-4d2213cd49bd',
  },
  outOfStock: {
    message: 'Товары закончились',
    code: '7880c5e0-1678-481d-981c-2338a452e712',
  },
});
