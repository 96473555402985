<template>
  <!--Если товар из категории "Нежелательная литература"-->
  <app-link
    v-if="checkIsAdult && isAdult"
    :href="link"
    class="b24-btn _block"
    :class="rootClass"
    :blank="blank"
  >
    <span class="b24-btn__content">
      Смотреть
    </span>
  </app-link>

  <!--Если электронный сертификат-->
  <app-link
    v-else-if="isCertificate"
    :href="link"
    class="b24-btn _block"
    :class="rootClass"
    :blank="blank"
  >
    <span class="b24-btn__content">
      Купить
    </span>
  </app-link>

  <!--Если предзаказ-->
  <app-link
    v-else-if="isPreOrder"
    :href="link"
    class="b24-btn _block"
    :class="rootClass"
  >
    <span class="b24-btn__content">
      Предзаказ
    </span>
  </app-link>

  <!--Если нет в наличии-->
  <!-- TODO: реализовать обработчик открытия модалки -->
  <button-control
    v-else-if="isNotAvailable"
    :class="{'_multi-lines': !isAvailableButtonShort}"
    :size="size"
    :type="type"
    :is-block="true"
    @click.prevent="handlerClickNotAvailable"
  >
    {{ isAvailableButtonShort ? 'Нет в наличии' : 'Узнать о поступлении' }}
  </button-control>

  <!--Кнопка по дефолту-->
  <add-to-cart-button
    v-else
    :product-id="productId"
    :quantity="quantity"
    :is-added-to-cart="isAddedToCart"
    :size="size"
    :type="type"
    :is-fetch-basket-after-add-to-basket="isFetchBasketAfterAddToBasket"
    :is-loading-fetch-basket="isLoadingFetchBasket"
    @addToBasket="handlerClickAddToBasket"
  />
</template>

<script>
import { ButtonControl } from '@book24/ui-components';

import { TYPE_ELEMENT_SIZES, TYPES_BUTTON } from '@/utils/constants/globalConst';

import useAdult from '@/compositions/adult/useAdult';

import AddToCartButton from '@/components/AddToCartButton';

export default {
  name: 'CardMainButton',
  components: {
    ButtonControl,
    AddToCartButton,
  },
  props: {
    productId: { type: [Number, String], required: true },
    quantity: { type: Number, required: true },
    link: { type: String, required: true },
    blank: { type: Boolean, default: false },
    isAddedToCart: { type: Boolean, default: false },
    isPreOrder: { type: Boolean, default: false },
    isCertificate: { type: Boolean, default: false },
    isNotAvailable: { type: Boolean, default: false },
    // использовать ли короткое название кнопки "Нет в наличии"
    isAvailableButtonShort: { type: Boolean, default: false },
    size: { type: String, default: TYPE_ELEMENT_SIZES.small.value },
    // more || secondary || 'link' || 'link-dashed'
    type: { type: String, default: TYPES_BUTTON.secondary.value },
    isFetchBasketAfterAddToBasket: { type: Boolean, default: true },
    isLoadingFetchBasket: { type: Boolean, default: false },
    isAdult: { type: Boolean, default: false },
  },
  setup() {
    const { checkIsAdult } = useAdult();
    return { checkIsAdult };
  },
  computed: {
    rootClass() {
      return {
        [TYPES_BUTTON.secondary.className]: this.type === TYPES_BUTTON.secondary.value,
        [TYPES_BUTTON.more.className]: this.type === TYPES_BUTTON.more.value,
        [TYPES_BUTTON.link.className]: this.type === TYPES_BUTTON.link.value,
        [TYPES_BUTTON.linkDashed.className]: this.type === TYPES_BUTTON.linkDashed.value,
        [TYPE_ELEMENT_SIZES.small.className]: this.size === TYPE_ELEMENT_SIZES
          .small.value,
        [TYPE_ELEMENT_SIZES.extraSmall.className]: this.size === TYPE_ELEMENT_SIZES
          .extraSmall.value,
        [TYPE_ELEMENT_SIZES.large.className]: this.size === TYPE_ELEMENT_SIZES
          .large.value,
      };
    },
  },
  methods: {
    handlerClickNotAvailable() {
      this.$emit('clickNotAvailable');
    },
    handlerClickAddToBasket(payload) {
      this.$emit('addToBasket', payload);
    },
  },
};
</script>
