import { REDIRECTS_LIST, PERSONAL_PAYMENT, BOOK_PAGE } from '../../utils/constants/redirects';

export default function redirects({ route, redirect }) {
  const isQueries = Object.values(route.query).length;

  if (route.path === PERSONAL_PAYMENT.from && !isQueries) {
    REDIRECTS_LIST.push(PERSONAL_PAYMENT);
  }

  const findItem = REDIRECTS_LIST.find(item => route.path === item.from);
  if (findItem) {
    const statusCode = findItem.status || 301;
    redirect(statusCode, {
      path: findItem.to,
      query: findItem.query,
    });
  }

  if (route.path === BOOK_PAGE.path && route.query.PT_ID === BOOK_PAGE.fromId) {
    redirect({
      query: { ...route.query, PT_ID: BOOK_PAGE.toId },
    });
  }
}
