import { defaultErrorsList } from '@/utils/formatters/formattedError';

export const viewTypes = Object.freeze([
  {
    id: '-popular',
    text: 'По популярности',
  },
  {
    id: 'author-release-index-desc',
    text: 'По дате издания',
  },
  {
    id: 'author-series-index-asc',
    text: 'По сериям',
  },
]);

export const errorsGetAuthorInfo = {
  ...defaultErrorsList,
  authorNotFound: {
    code: 'dda448a2-bf96-4196-916e-c4bb15d734ab',
    message: 'Автор не найден',
  },
  isAuthorNotFound(code) {
    return code === this.authorNotFound.code;
  },
  pageNotExist: {
    code: 'a427e33f-93ad-4933-8ac1-bb47b63c92ef',
    message: 'Страницы не существует',
  },
  isPageNotExist(code) {
    return code === this.pageNotExist.code;
  },
};

export const letters = {
  all: 'Все авторы',
  ru: ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ы', 'Э', 'Ю', 'Я'],
  en: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
  isRuLetter(letter) {
    let res = false;
    if (typeof letter === 'string') {
      res = !!this.ru.find(el => el.toLowerCase() === letter.toLowerCase());
    }
    return res;
  },
};
