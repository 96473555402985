import {
  add,
  differenceInDays,
  differenceInMilliseconds,
  differenceInMonths,
  differenceInYears,
  differenceInCalendarDays,
  formatISO,
  fromUnixTime,
  getISODay,
  getUnixTime,
  parse,
  parseISO,
} from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

const timeZone = 'Europe/Moscow';

/**
 * Получить дату нужного формата из Date
 * @param {Date|number} date
 * @param {string} [expectedFormat='dd.MM.yyyy']
 * @returns {string}
 */
export const getFormatDateTime = (date, expectedFormat = 'dd.MM.yyyy') => {
  const utcDate = zonedTimeToUtc(date);
  const zonedDate = utcToZonedTime(utcDate, timeZone);
  return format(
    zonedDate,
    expectedFormat,
    { locale: ru, timeZone },
  );
};

/**
 * Получить разницу между двумя датами
 * @param {Date|number} dateFrom
 * @param {Date|number} dateTo
 * @param {string} [type]
 * @returns {Date|number}
 */
export const getDifferenceBetweenDateTypes = (dateFrom, dateTo, type = 'years') => {
  if (type === 'years') return differenceInYears(dateFrom, dateTo);
  if (type === 'months') return differenceInMonths(dateFrom, dateTo);
  if (type === 'days') return differenceInDays(dateFrom, dateTo);
  if (type === 'calendarDays') return differenceInCalendarDays(dateFrom, dateTo);
  if (type === 'milliseconds') return differenceInMilliseconds(dateFrom, dateTo);
};

/**
 * Вернуть отформатированную строку даты в формате ISO
 * @param {Date} data
 * @return {string}
 */
export const getFormatISO = (data) => {
  const utcDate = zonedTimeToUtc(data);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return formatISO(zonedDate);
};

/**
 * Получить отметку времени в секундах из заданной даты в формате ISO
 * @param {string} data
 * @return {number}
 */
export const getUnixTimestampFromISO = (data) => {
  const utcDate = zonedTimeToUtc(data);
  const zonedDate = utcToZonedTime(utcDate, timeZone);

  return getUnixTime(zonedDate);
};

/**
 * Получить Date из строки
 * @param {string} date
 * @param {string} [originalFormat='dd.MM.yyyy']
 * @returns {Date|*}
 */
export const parseDateOfString = (date, originalFormat = 'dd.MM.yyyy') => parse(date, originalFormat, new Date());

/**
 * Получить Date из unix timestamp
 * @param {number} date
 * @returns {Date|*}
 */
export const parseDateOfUnixTime = date => fromUnixTime(date);

/**
 * Получить дату в нужном фромате из исходной строки
 * @param {string} date
 * @param {string} [originalFormat='dd.MM.yyyy']
 * @param {string} [expectedFormat='d MMMM yyyy']
 * @returns {string|*}
 */
export const getDateTimeOfString = (date, expectedFormat = 'dd.MM.yyyy', originalFormat = 'dd.MM.yyyy') => getFormatDateTime(parseDateOfString(date, originalFormat), expectedFormat);

/**
 * Получить дату в нужном формате из unix timestamp
 * @param {number} date
 * @param {string} [expectedFormat='d MMMM yyyy']
 * @returns {string}
 */
export const getDateTimeOfUnixTime = (date, expectedFormat = 'dd.MM.yyyy') => getFormatDateTime(parseDateOfUnixTime(date), expectedFormat);

/**
 * Получить ISO номер дня недели
 * @param {string|number} date
 * @param {string} [originalFormat='dd.MM.yyyy']
 * @returns {number}
 */
export const getDayNumber = (date, originalFormat = 'dd.MM.yyyy') => {
  if (typeof date === 'number') {
    return getISODay(parseDateOfUnixTime(date));
  }
  return getISODay(parseDateOfString(date, originalFormat));
};

/**
 * Получаем объект даты из даты в формате ISO
 * @param {string} date
 * @return {Date}
 */
export const parseDateISO = date => parseISO(date);

/**
 * Получаем дату в отформатированном виде из даты в формате ISO
 * @param {string} date
 * @param {string} [expectedFormat='dd.MM.yyyy']
 * @return {string}
 */
export const convertISOFromDateFormat = (date, expectedFormat = 'dd.MM.yyyy') => getFormatDateTime(parseDateISO(date), expectedFormat);

/**
 * @typedef {Object} durationDate
 * @param {number} [years]
 * @param {number} [months]
 * @param {number} [weeks]
 * @param {number} [days]
 * @param {number} [hours]
 * @param {number} [minutes]
 * @param {number} [seconds]
 */

/**
 * Добавьте к указанной дате указанные годы, месяцы, недели, дни, часы, минуты и секунды.
 * @param {Date} date - дата
 * @param {durationDate} duration
 * @return {Date}
 */
export const addToDate = (date, duration) => add(date, duration);

/**
 * Получите количество миллисекунд между указанными датами.
 * @param {Date} dateLeft - более поздняя дата
 * @param {Date} dateRight - более ранняя дата
 * @return {number}
 */
export const getDiffInMilliseconds = (dateLeft, dateRight) => differenceInMilliseconds(dateLeft, dateRight);

/**
 * Получите название месяца в предложном падеже
 * @param date
 * @returns {string}
 */
export const getMonthNamePrepositional = (date) => {
  const months = ['январе', 'феврале', 'марте', 'апреле', 'мае', 'июне', 'июле', 'августе', 'сентябре', 'октябре', 'ноябре', 'декабре'];
  const monthIndex = parseDateISO(date).getMonth();
  return months[monthIndex];
};
