// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';

export default class SurveyService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Отправляем оценку заказа
   * @param {Object} [payload={}]
   * @param {number} [payload.orderId]
   * @param {number} [payload.rate]
   * @returns {Promise<unknown>}
   */
  sendRate(payload) {
    return new Promise((resolve, reject) => this.mainHttp.post('/api/v1/order-survey/', null,
      { payloadParams: payload })
      .then(() => resolve())
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          // знаем точно что ожидаем одну ошибку
          const [firstError] = errors;
          return reject(new ServiceError(firstError));
        }
        return reject(new ServiceError(e));
      }));
  }

  /**
   * Отправляем причину низкой оценки заказа
   * @param {Object} [payload={}]
   * @param {number} [payload.orderId]
   * @param {string} [payload.reason]
   * @param {string} [payload.comment]
   * @returns {Promise<unknown>}
   */
  sendReason(payload) {
    return new Promise((resolve, reject) => this.mainHttp.put('/api/v1/order-survey/reason/', null,
      { payloadParams: payload })
      .then(() => resolve())
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          // знаем точно что ожидаем одну ошибку
          const [firstError] = errors;
          return reject(new ServiceError(firstError));
        }
        return reject(new ServiceError(e));
      }));
  }
}
