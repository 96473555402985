import Url from 'url-parse';
import { cdnType, productImage } from '@/utils/constants/catalogConstants';

/**
 * Возвращает отформаттированную картинку по нужным размерам из ndc.book24.ru
 * @param {string} srcPicture - путь картинки
 * @param {Object} [options={}]
 * @param {number} [options.sizePicWidth=220] Ширина картинки
 * @param {number} [options.sizePicHeight=284] Высота картинки
 * @param {Object} [options.fallbackImages=productImage] Фолбек если нет картинки
 * @return {Readonly<{webpX2: (null|string), x1: (null|string), x2: (null|string), webpX1: (null|string), type: (null|string)}>}
 */
export default (srcPicture, options = {}) => {
  const {
    sizePicWidth = 220,
    sizePicHeight = 284,
    fallbackImages = productImage,
  } = options;

  if (!srcPicture) return fallbackImages;

  const result = {
    type: cdnType.book24.value,
    x1: null,
    x2: null,
    webpX1: null,
    webpX2: null,
  };

  if (srcPicture) {
    const isBaseUrl = cdnType.book24.baseUrl.find(item => srcPicture.includes(item));
    const [url] = srcPicture.split(isBaseUrl).filter(item => item);
    const parsedUrl = new Url(isBaseUrl);
    const finalUrl = `//${parsedUrl.host}/`;
    result.x1 = `${finalUrl}resize/${sizePicWidth}x${sizePicHeight}/${url}`;
    result.x2 = `${finalUrl}resize/${sizePicWidth * 2}x${sizePicHeight * 2}/${url}`;
  }

  return Object.freeze(result);
};
