import { computed } from '@nuxtjs/composition-api';

import useRecaptcha from '@/compositions/useRecaptcha';
import { useAuthorizationContext } from '@/compositions/authorization/authorizationStorage';

const useCaptcha = () => {
  const recaptcha = useRecaptcha();
  const authorizationContext = useAuthorizationContext();

  const captchaOptions = computed(() => authorizationContext.captchaOptions.value);

  const generateCaptcha = async state => recaptcha.getReRecaptchaToken(state)
    .then((captchaToken) => {
      const options = {
        captchaToken,
        captchaAction: state,
        captchaVersion: 'rv3',
      };

      authorizationContext.captchaOptions.value = { ...options };
    });

  return {
    ...recaptcha,
    captchaOptions,
    generateCaptcha,
  };
};

export default useCaptcha;
