<template>
  <div class="logo">
    <logo-icon v-if="$route.path === '/'" width="138" height="42" />
    <app-link v-else class="logo__link" href="/">
      <logo-icon width="138" height="42" />
    </app-link>
  </div>
</template>

<script>
import logoIcon from '@/assets/sprite/svg/main_logo.svg';

export default {
  name: 'AppLogo',
  components: {
    logoIcon,
  },
};
</script>

<style lang="less">
.logo {
  display: flex;
  align-items: center;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
