// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import { buildCollection, buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import formattedProduct from '@/utils/formatters/formattedProduct';

const formattedDataRequest = (payload) => {
  const {
    pageSize = 15,
    isGetAuthors = true,
    isGetBadge = true,
    isGetSeries = false,
    isGetProductText = false,
    isGetPublishingHouse = true,
    isGetCategory = true,
    isGetParentCategory = false,
    isGetOrsProduct = true,
  } = payload;

  const dataRequest = {
    recoType: 'reco_kk',
    'per-page': pageSize,
  };

  const include = [];
  if (isGetAuthors) include.push('author');
  if (isGetSeries) include.push('series');
  if (isGetBadge) include.push('badge');
  if (isGetProductText) include.push('productText');
  if (isGetPublishingHouse) include.push('publishingHouse');
  if (isGetCategory) include.push('category');
  if (isGetParentCategory) include.push('category.parentCategory');
  if (isGetOrsProduct) include.push('orsProduct');

  if (include.length) dataRequest.include = include.join(',');

  if (payload.sectionId) {
    dataRequest.sectionId = payload.sectionId;
  }

  if (payload.filter) {
    dataRequest.filter = payload.filter;
  }

  return dataRequest;
};

export default class RecommendationService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получаем список рекомендуемых товаров
   * @param {Object} payload
   * @param {number} [payload.pageSize=15] - Количество товаров на странице
   * @param {boolean} [payload.isGetAuthors=true] - Нужно ли получить автора товара
   * @param {boolean} [payload.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [payload.isGetSeries=false] - Нужно ли получить серии товара
   * @param {boolean} [payload.isGetProductText=false] - Нужно ли получить описание товара
   * @param {string} [payload.getQuotes=''] - Получить цитаты товаров, строка вида quote:limit(2|0)
   * @param {boolean} [payload.isGetPublishingHouse=true] - Нужно ли получить издателя товара
   * @param {boolean} [payload.isGetCategory=true] - Нужно ли получить категорию товара
   * @param {boolean} [payload.isGetParentCategory=false] - Нужно ли получить родительские категории товара
   * @param {boolean} [payload.isGetOrsProduct=true] - Нужно ли получить bookId и codeTbk
   * @return {Promise<unknown>}
   */
  getRecommendationProduct(payload = {}) {
    return new Promise((resolve, reject) => {
      const dataRequest = formattedDataRequest(payload);

      this.mainHttp.get('/api/v1/reco-product/recommendation/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          const items = list.map(item => formattedProduct(item)) || [];
          return resolve({
            items,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  getProductsSimilarByProductId(productId, payload = {}) {
    return new Promise((resolve, reject) => {
      if (!productId) return reject(new ServiceError('[RecommendationService:getProductsRecommendationByProduct] productId обязателен для заполнения'));

      const dataRequest = formattedDataRequest(payload);

      this.mainHttp.get(`/api/v1/reco-product/similar/${productId}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          const items = list.map(item => formattedProduct(item)) || [];
          return resolve({
            items,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получаем список рекомендуемых товаров по поиску
   * @param {Object} payload
   * @param {string} [payload.query=''] - Запрос
   * @param {number} [payload.pageSize=20] - Количество товаров на странице
   * @param {boolean} [payload.isGetAuthors=true] - Нужно ли получить автора товара
   * @param {boolean} [payload.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [payload.isGetSeries=false] - Нужно ли получить серии товара
   * @param {boolean} [payload.isGetProductText=false] - Нужно ли получить описание товара
   * @param {string} [payload.isGetQuote=''] - Получить цитаты товаров, строка вида quote:limit(2|0)
   * @param {boolean} [payload.isGetPublishingHouse=true] - Нужно ли получить издателя товара
   * @param {boolean} [payload.isGetCategory=false] - Нужно ли получить категорию товара
   * @param {boolean} [payload.isGetParentCategory=false] - Нужно ли получить родительские категории товара
   * @param {boolean} [payload.isGetOrsProduct=true] - Нужно ли получить bookId и codeTbk
   * @return {Promise<unknown>}
   */
  getRecommendationProductsForSearch(payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        query = '',
        pageSize = 20,
        isGetAuthors = true,
        isGetSeries = true,
        isGetProductText = false,
        isGetBadge = false,
        isGetQuote = false,
        isGetPublishingHouse = true,
        isGetCategory = true,
        isGetParentCategory = false,
        isGetOrsProduct = true,
      } = payload;

      const dataRequest = {
        'per-page': pageSize,
        query,
      };

      const include = [];

      if (isGetAuthors) include.push('author');
      if (isGetSeries) include.push('series');
      if (isGetBadge) include.push('badge');
      if (isGetProductText) include.push('productText');
      if (isGetPublishingHouse) include.push('publishingHouse');
      if (isGetCategory) include.push('category');
      if (isGetQuote) include.push('quote:limit(2|0)');
      if (isGetParentCategory) include.push('category.parentCategory');
      if (isGetOrsProduct) include.push('orsProduct');

      if (include.length) dataRequest.include = include.join(',');

      this.mainHttp.get('/api/v1/reco-product/search/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          const items = list.map(item => formattedProduct(item)) || [];
          return resolve({
            items,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Список товаров пользовательского интереса к системе рекомендаций
   * @param {Object} data
   * @param {number} [data.pageSize='30'] - Количество товаров на странице
   * @param {number} [data.pageNumber=1] - Номер страницы в пагинации
   * @param {string} [data.filter] - фильтр товаров
   */
  getInterestsProducts(data = {}) {
    return new Promise((resolve, reject) => {
      const {
        pageSize = 20,
        pageNumber = 1,
        filter,
      } = data;

      let dataRequest = { 'per-page': pageSize, page: pageNumber };

      if (filter) dataRequest = { ...dataRequest, filter };

      return this.mainHttp.get('/api/v1/internal/customer-attraction/offered-product/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildCollection(dataRes, true);
          return resolve(resFormatted);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Отправка в рекомендательную систему пользовательского интереса к товару
   * @param {Object} options
   * @param {Number} [options.productId]
   * @param {Number} [options.rate] - оценка товара
   * @param {'rating'|'hide'|'read'|'uninteresting'|'cancel'} [options.type] - тип пользовательского интереса
   */
  sendProductAttraction(options = {}) {
    const {
      productId = null,
      rate = null,
      type = '',
    } = options;

    let dataRequest = { productId, type };
    if (rate) dataRequest = { ...dataRequest, rate };

    return this.mainHttp.post('/api/v1/customer-product-attraction/', dataRequest, { isFormData: true })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Отправляет в рекомендательную систему пользовательский интерес к жанру или автору
   * @param {Object} options
   * @param {String[]|Number[]} [options.itemIds] - массив ids жанров или авторов
   * @param {String} [options.type] - тип интереса (жанр или автор)
   */
  saveFavoriteGenresOrAuthors(options = {}) {
    const {
      itemIds = [],
      type = '',
    } = options;

    const dataRequest = { itemIds, type };

    return this.mainHttp.post('/api/v1/customer-reference-attraction/', dataRequest)
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Удаляет из рекомендательной системы пользовательский интерес к жанру или автору
   * @param {Object} options
   * @param {String[]|Number[]} [options.ids] - массив ids жанров или авторов
   */
  removeFavoriteGenresOrAuthors(options = {}) {
    const {
      ids = [],
    } = options;

    const dataRequest = { ids };

    return this.mainHttp.delete('/api/v1/customer-reference-attraction/', dataRequest)
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Удаляет из рекомендательной системы пользовательский интерес ко всем
   * жанрам или авторам сразу
   * @param {String} type
   */
  removeAllFavoriteGenresOrAuthors(type) {
    return this.mainHttp.delete(`/api/v1/customer-reference-attraction/items/?type=${type}`)
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Поиск авторов
   * @param {Object} options
   * @param {String} [options.match] - поисковой запрос
   */
  searchFavoriteAuthors(options = {}) {
    return new Promise((resolve, reject) => {
      const {
        match = '',
      } = options;

      const dataRequest = { match };

      return this.mainHttp.get('api/v1/internal/suggestion/author/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes, true);
          return resolve({
            list: list.map(item => ({
              id: item.id,
              name: item.relationships.author.fullName,
              letter: item.relationships.author.letter,
            })),
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
