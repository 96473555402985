<template>
  <div class="embla-slide-product">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmblaSlideProduct',
};
</script>

<style lang="less">
.embla-slide-product {
  flex: 0 0 auto;
  width: 244px;
  position: relative;
  counter-increment: embla;
}
</style>
