<template>
  <accordion
    class="typography-accordion"
    :max-height="maxHeight"
    :is-full-stretch-height="isFullStretchHeight"
  >
    <template #accordion>
      <div class="typography-accordion__panel">
        <slot name="panel">
        </slot>
      </div>
    </template>
    <div class="typography-accordion__wrapper">
      <slot></slot>
    </div>
  </accordion>
</template>

<script>
import Accordion from '@/components/Accordion/index';

export default {
  name: 'TypographyAccordion',
  components: {
    Accordion,
  },
  props: {
    maxHeight: { type: Number, default: 1000 },
    isFullStretchHeight: { type: Boolean, default: true },
  },
};
</script>

<style lang="less">
.typography-accordion {
  border-bottom: 1px solid @gray-super-light;

  &__panel {
    padding: @size-x3 @size-x4;
    text-align: left;
  }

  &__wrapper {
    padding: @size-x3 @size-x4;
  }
}
</style>
