// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import formattedPartnerLinks from '@/utils/formatters/formattedPartnerLinks';

export default class PartnerService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  clickRecorder() {
    return this.mainHttp.post('/api/v1.2/click-recorder/', {
      requestUri: (window.document.location.pathname || '')
        + (window.document.location.search || '')
        + (window.document.location.hash || ''),
      referrer: window.document.referrer || '',
    });
  }

  checkCurrentUserPartner() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/partner/user/')
        .then(data => resolve(data))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  getPartnerLinks(params) {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/partner/link/', params)
        .then((res) => {
          const links = formattedPartnerLinks(res);
          resolve(links);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Отписывает партнёра по hash из ссылки
   * @param {string} [hash]
   * @return {Promise<void|ErrorObject|Error>}
   */
  unsubscribePartnerByHash(hash) {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/partner-unsubscribe-by-hash/', { hash })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
