const REDIRECT_CATALOG_LIST = [
  '/product/semeynyy-kalendar-s-konni-5917707/',
  '/product/detskiy-kalendar-v-risunkakh-v-suteeva-5796129/',
  '/product/god-bychka-5804695/',
  '/product/kalendar-samostoyatelnogo-rebenka-5804638/',
  '/product/coffeemania-kalendar-vazhnykh-sobytiy-na-derevyannoy-doshchechke-50-serdechek-i-50-kruzhochkov-dlya--5781423/',
  '/product/kalendar-zhivoy-prirody-5835808/',
  '/product/12-mesyatsev-s-chevostikom-kalendar-na-2018-god-1765272/',
  '/product/udivitelnye-goroda-nastennyy-kalendar-2017-670881/',
  '/product/bibliya-shedevry-mirovogo-iskusstva-perekidnoy-seriya-podarochnye-izdaniya-kalendari-na-52-nedeli-157291/',
  '/product/impressionisty-shedevry-zhivopisi-seriya-kniga-kalendar-s-aforizmami-173753/',
  '/product/vremena-goda-nastolnyy-kalendar-v-futlyare-5857164/',
  '/product/russkoe-iskusstvo-kalendar-nastolnyy-2778474/',
  '/product/monokhrom-zhivotnye-kalendar-nastennyy-na-2020-god-300kh300-mm-5351336/',
  '/product/van-gog-kalendar-nastennyy-trekhblochnyy-na-2020-god-380kh765-mm-5338140/',
  '/product/pravoslavnye-ikony-5981266/',
  '/product/peyzazh-v-mirovom-iskusstve-kalendar-nastolnyy-2776101/',
  '/product/iskusstvo-renessansa-nastolnyy-kalendar-v-futlyare-5426578/',
  '/product/vokrug-sveta-5981034/',
  '/product/milashki--5982263/',
  '/product/milashki-5982434/',
  '/product/milashki--5981831/',
  '/product/milashki--5982266/',
  '/product/dozhdevik-detskiy-razmer-102-110-dlya-detey-3-5-let-material-pvkh-upakovka-paket-s-kryuchkom-kartonn-1731703/',
  '/product/dozhdevik-detskiy-razmer-102-110-dlya-detey-3-5-let-material-pvkh-upakovka-paket-s-kryuchkom-kartonn-1731729/',
  '/product/dozhdevik-detskiy-razmer-102-110-dlya-detey-3-5-let-material-pvkh-upakovka-paket-s-kryuchkom-kartonn-1731730/',
  '/product/dozhdevik-detskiy-razmer-102-110-dlya-detey-3-5-let-material-pvkh-upakovka-paket-s-kryuchkom-kartonn-1731713/',
  '/product/meshok-dlya-obuvi-trolli-razmer-standartnyy-33-42-sm-poliester-1563336/',
  '/product/meshok-dlya-obuvi-trolli-razmer-standartnyy-33-42-sm-poliester-1563249/',
  '/product/meshok-dlya-obuvi-trolli-razmer-standartnyy-33-42-sm-poliester-1563229/',
  '/product/roboty-5981810/',
  '/product/roboty--5981818/',
  '/product/roboty-5982232/',
  '/product/gosudarstvennyy-simvol-21-5980825/',
  '/product/gosudarstvennyy-simvol-21-5981117/',
  '/product/gosudarstvennyy-simvol-21-5981581/',
  '/product/nast-igr-tg-igra-dlya-devochek-top-model-art-00035-1720952/',
  '/product/nast-igr-tg-igra-dlya-devochek-top-model-art-00035-1720957/',
  '/product/tg-nast-igra-dlya-devochek-top-model-art-00035-1720952/',
  '/product/dk-magnitnaya-azbuka-angl-850-3-5sm-1720448/',
  '/product/nast-igr-dk-magnitnaya-azbuka-angl-850-3-5sm-1720448/',
  '/product/v-gorakh-5981357/',
  '/product/v-gorakh-5981191/',
  '/product/rst-konstruktor-met-1-173det-1721952/',
  '/product/rst-konstruktor-met-1-173det-1721951/',
  '/product/rst-konstruktor-met-2-223det-1721954/',
  '/product/rst-konstruktor-met-2-223det-1721953/',
  '/product/fizika-sbornik-voprosov-i-zadach-7-klass-5404212/',
  '/product/fizika-sbornik-voprosov-i-zadach-7-klass-5445046/',
  '/product/-120--2099222/',
  '/product/1toy-spider-man-mobilnyy-telefon-smartfon-svet-zvuk-13-5kh22sm-blist-1722922/',
  '/product/1toy-spider-man-mobilnyy-telefon-smartfon-svet-zvuk-13-5kh22sm-blist-1722923/',
  '/product/400-nakleek-modnye-naryady-1613337/',
  '/product/400-nakleek-modnye-naryady-1613337/?amp%3Butm_medium=cpc&amp%3Butm_campaign=400-nakleek-modnye-naryady-1613337_drugoe9103_msk_1613337&amp%3Butm_content=1613337',
  '/product/almaznye-uzory-vremena-goda-30-30sm-art-06080-5911839/',
  '/product/almaznye-uzory-vremena-goda-30-40sm-art-06081-5911834/',
  '/product/a-nast-ig-fg-tk-karavan-skazok-art-6436-1720347/',
  '/product/a-nast-ig-khit-kolonizatory-adon-kuptsy-i-varvary-1720366/',
  '/product/a-nast-ig-khit-kolonizatory-adon-kuptsy-i-varvary-1720367/',
];

/**
 * @typedef {Object} redirectRoute
 * @const
 * @readonly
 * @property {string} from - текущий путь
 * @property {string} to - путь редиректа
 * @property {number} status
 * @property {Object} query
 */

/**
 * @type {Array.<redirectRoute>}
 */
const REDIRECTS_LIST = [
  {
    from: '/sales/6032250/',
    to: '/podborki/darim-3-eksklyuzivnye-lektsii-ot-topovykh-ekspertov-iskusstva--6030779/',
    status: 301,
    query: {},
  },
  {
    from: '/sales/5709406/',
    to: '/lottery/',
    status: 301,
    query: {},
  },
  {
    from: '/sales/5913904/',
    to: '/sales/6040384/',
    status: 301,
    query: {},
  },
  {
    from: '/sales/6050791/',
    to: '/podborki/darite-volshebstvo-knigi-k-prazdniku-ot-ast-6049325/',
    status: 301,
    query: {},
  },
  {
    from: '/tags/',
    to: '/',
    status: 301,
    query: {},
  },
  {
    from: '/sales/6131280/',
    to: '/podborki/fei-vikingi-i-dinozavry-vybiraem-podarki-ot-ast-k-prazdnikam-6124889/',
    status: 301,
    query: {},
  },
];

REDIRECT_CATALOG_LIST.forEach((url) => {
  REDIRECTS_LIST.push(
    {
      from: url,
      to: '/catalog/',
      status: 301,
      query: {},
    },
  );
});

/**
 * @type {redirectRoute} PERSONAL_PAYMENT
 */
export const PERSONAL_PAYMENT = Object.freeze({
  from: '/personal/payment/',
  to: '/',
  status: 301,
  query: {},
});

export const BOOK_PAGE = Object.freeze({
  path: '/book/',
  fromId: '0059',
  toId: '0090',
});

export { REDIRECTS_LIST };
