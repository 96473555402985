// @ts-check
/// <reference path="./typedefs.js" />

import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection, buildElement } from '@/utils/formatters/processingOpenApi';

import {
  ERROR_CAPTCHA,
  ERROR_CALL_ORDER,
  ERROR_USER_UPDATE,
} from '@/utils/constants/userConstants';

import ServiceError from '@/utils/errors/ServiceError';
import { formattedCoupon } from '@/utils/formatters/formattedCoupon';

export default class UserService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Возвращает сессионные данные пользователя
   * @param {Object} dataParams
   * @param {boolean} [dataParams.isGetUser=false]
   * @param {boolean} [dataParams.isGetUserStats=false]
   * @param {boolean} [dataParams.isGetUserLoyalty=false]
   * @param {boolean} [dataParams.isGetUserSubscription=false]
   * @param {boolean} [dataParams.isGetUserChildren=false]
   * @param {boolean} [dataParams.isGetUserSubscriptionRubric=false]
   * @param {boolean} [dataParams.isGetUserSubscriber=false]
   * @param {boolean} [dataParams.isServer=false] - происходит ли запрос на сервере
   * @param {boolean} [dataParams.isGetUserSubscriber=false]
   * @return {Promise<unknown>}
   */
  getUserSessions({
    isGetUser = false,
    isGetUserStats = false,
    isGetUserSubscription = false,
    isGetUserSubscriptionRubric = false,
    isGetUserChildren = false,
    isGetUserLoyalty = false,
    isServer = false,
    isGetUserSubscriber = false,
  }) {
    return new Promise((resolve, reject) => {
      const include = [];
      if (isGetUser) include.push('user');
      if (isGetUserStats) include.push('user.userStats');
      if (isGetUserSubscription) include.push('user.subscription');
      if (isGetUserSubscriptionRubric) include.push('user.subscription.rubric');
      if (isGetUserChildren) include.push('user.customerChildren');
      if (isGetUserLoyalty) include.push('user.userLoyalty');
      if (isGetUserSubscriber) include.push('user.subscriber');

      const dataRequest = {};
      if (include.length) dataRequest.include = include.join(',');

      return this.mainHttp.get('/api/v1/internal/user-session/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);
          let res = { sessionId: resFormatted.id };
          if (resFormatted.relationships?.user) {
            res = {
              ...res,
              ...resFormatted.relationships.user,
            };
          }
          return resolve(res);
        })
        .catch((e) => {
          if (isServer) {
            return reject(e);
          }
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Разлогинивает пользователя
   * @return {Promise<unknown>}
   */
  deleteUserSession() {
    return new Promise((resolve, reject) => this.mainHttp.delete('/api/v1/user-session/')
      .then(() => resolve())
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Начисление баллов за установку приложения на главный экран
   * @return {Promise<unknown>}
   */
  userInstallApp() {
    return new Promise((resolve, reject) => this.mainHttp.post('/api/v1/user/web-app-installed/')
      .then(() => resolve())
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          // знаем точно что ожидаем одну ошибку
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Проверка доступности email для регистрации
   * @param {string} email
   * @return {Promise<unknown>}
   */
  checkEmailAvailability(email) {
    return new Promise((resolve, reject) => this.mainHttp.post('/api/v1/user/check-email-availability/', {
      EMAIL: email,
    })
      .then(({ data: { data: dataRes } }) => resolve(dataRes))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Проверка email на корректность ввода по белому списку
   * @param {string} email
   * @return {Promise<unknown>}
   */
  checkEmailDomain(email) {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/user/check-email-domain/', {
      EMAIL: email,
    })
      .then((res) => {
        const { data: dataRes } = res;
        if (dataRes.status === 'OK') {
          return resolve(true);
        }
        if (dataRes.status === 'ERROR') {
          return resolve(false);
        }
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Обновляет пользователя
   * @param {string} id
   * @param {PayloadUserUpdate} payload
   * @param {object[]} children
   * @return {Promise<unknown>}
   */
  userUpdate(id, payload, children) {
    return new Promise((resolve, reject) => {
      if (!id) return reject(ERROR_USER_UPDATE.notUserId);
      if (!payload.email) return reject(ERROR_USER_UPDATE.notEmail);
      if (!payload.name) return reject(ERROR_USER_UPDATE.notName);
      if (!payload.lastName) return reject(ERROR_USER_UPDATE.notLastName);

      const data = {
        customer: { ...payload },
      };

      if (children) {
        data.children = [...children];
      }

      if (!payload.gender.length) {
        delete data.customer.gender;
      }

      this.mainHttp.put(`/api/v1/users/${id}/`, data)
        .then((res) => {
          const { data: { data: dataRes } } = res;
          return resolve(dataRes?.user);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получает подсказки по данным клиента из MindBox
   * @return {Promise<ResponseAutocompleteRegistration|ErrorObject|Error>}
   */
  userSuggest() {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/user/suggest-profile/')
        .then(({ data: { data: { data: dataRes } } }) => {
          const res = {
            ...dataRes?.attributes,
          };

          if (dataRes?.attributes?.middleName) {
            res.secondName = dataRes.attributes.middleName;
          }

          if (dataRes?.attributes?.birthAt) {
            res.birthday = new Date(dataRes?.attributes?.birthAt);
          }

          resolve(res);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Удаляет ребенка пользователя
   * @param {Object} payload
   * @param {string} payload.customerId
   * @param {string} payload.childrenId
   * @return {Promise<unknown>}
   */
  removeUserChild(payload) {
    return new Promise((resolve, reject) => this.mainHttp.delete(
      `api/v1/customers/${payload.customerId}/children/${payload.childrenId}/`,
    )
      .then(() => resolve())
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  removeMnogoru(coupon) {
    return new Promise((resolve, reject) => {
      this.mainHttp.delete(`/api/v1/sale/order/coupon-mnogoru/${coupon}/`)
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            return reject(errors);
          }
          return reject(new ServiceError(e));
        });
    });
  }

  applyMnogoru(coupon) {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/sale/order/coupon-mnogoru/', {
        COUPON: coupon,
      })
        .then(({ data: { data: { coupon: couponNumber } } }) => {
          resolve(
            formattedCoupon({ coupon: couponNumber }),
          );
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Оформление обратного звонка
   * @param {PayloadCallOrder} payload
   * @return {Promise<void>}
   */
  callOrder(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.phone) {
        return reject(ERROR_CALL_ORDER.notStringPhone);
      }
      if (typeof payload.phone !== 'string') {
        return reject(ERROR_CALL_ORDER.emptyPhone);
      }
      if (!payload.brsign.payload.token) {
        return reject(ERROR_CAPTCHA.emptyCaptchaToken);
      }
      if (typeof payload.brsign.payload.token !== 'string') {
        return reject(ERROR_CAPTCHA.notStringCaptchaToken);
      }
      if (!payload.brsign.payload.action) {
        return reject(ERROR_CAPTCHA.emptyCaptchaAction);
      }
      if (typeof payload.brsign.payload.action !== 'string') {
        return reject(ERROR_CAPTCHA.notStringCaptchaAction);
      }
      if (!payload.brsign.payload.version) {
        return reject(ERROR_CAPTCHA.emptyCaptchaVersion);
      }
      if (typeof payload.brsign.payload.version !== 'string') {
        return reject(ERROR_CAPTCHA.notStringCaptchaVersion);
      }

      return this.mainHttp.post('/api/v1/internal/form/request-call/', payload, { isFormData: true })
        .then(({ data: { data: dataRes } }) => resolve(dataRes))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  sendFeedBack(payload = {}) {
    return new Promise((resolve, reject) => {
      if (!payload.name && payload.name !== 'string') reject(new ServiceError('[UserService:sendFeedBack] name обязателен для заполнения и должен быть строкой'));
      if (!payload.email && payload.email !== 'string') reject(new ServiceError('[UserService:sendFeedBack] email обязателен для заполнения и должен быть строкой'));
      if (!payload.message && payload.message !== 'string') reject(new ServiceError('[UserService:sendFeedBack] message обязателен для заполнения  и должен быть строкой'));

      if (!payload.captchaToken) {
        return reject(ERROR_CAPTCHA.emptyCaptchaToken);
      }
      if (typeof payload.captchaToken !== 'string') {
        return reject(ERROR_CAPTCHA.notStringCaptchaToken);
      }
      if (!payload.captchaAction) {
        return reject(ERROR_CAPTCHA.emptyCaptchaAction);
      }
      if (typeof payload.captchaAction !== 'string') {
        return reject(ERROR_CAPTCHA.notStringCaptchaAction);
      }
      if (!payload.captchaVersion) {
        return reject(ERROR_CAPTCHA.emptyCaptchaVersion);
      }
      if (typeof payload.captchaVersion !== 'string') {
        return reject(ERROR_CAPTCHA.notStringCaptchaVersion);
      }

      const dataRequest = {
        name: payload.name,
        email: payload.email,
        message: payload.message,
        captchaToken: payload.captchaToken,
        captchaAction: payload.captchaAction,
        captchaVersion: payload.captchaVersion,
      };

      if (payload.files.length) dataRequest.files = payload.files;
      return this.mainHttp.post('/api/v1/internal/form/feedback/', dataRequest, { isFormData: true })
        .then(({ data: { success } }) => {
          if (success) {
            return resolve();
          }
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получение прогнозируемого вознаграждения за выполнение заказа
   * @param orderId - номер заказа
   * @return {Promise<WrapRewardData>}
   */
  orderReward(orderId) {
    return new Promise((resolve, reject) => this.mainHttp.get(`/api/v1/pl/user/reward/order/${orderId}/`)
      .then(({ data }) => resolve(data))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(new ServiceError(firstError));
        }
        return reject(new ServiceError(e));
      }));
  }

  /**
   * Подтверждение пользователем своего совершеннолетия
   */
  confirmAdult() {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/user/confirm-adult/')
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            return reject(errors);
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Подтверждение пользователем согласия на куки
   */
  confirmCookie() {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/user/cookie-policy-agree/')
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            return reject(errors);
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
