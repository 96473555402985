export default function auth({ route, redirect, store }) {
  const { isAuthorized } = store.state;
  if (route.meta.some(el => el.requiredAuth) && !isAuthorized) {
    route.meta = { redirect: route.path };
    const query = { ...route.query, redirect: route.path };
    redirect(
      {
        path: '/auth/',
        query,
        params: route.params,
      },
    );
  }

  if (route.path === '/auth/' && !route.query.redirect && isAuthorized) {
    redirect(
      {
        path: '/',
      },
    );
  }
}
