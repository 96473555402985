// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection, buildElement } from '@/utils/formatters/processingOpenApi';

export default class SubscriptionService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Подписывает пользователя на получение рассылки
   * @param {Object} payload
   * @param {('sales'|'news'|'author_releases')} payload.rubricType
   * @param {('ddlUser')} [payload.include]
   * @param {string} [payload.email]
   * @return {Promise<unknown>}
   */
  subscribe(payload) {
    return new Promise((resolve, reject) => this.mainHttp.post('/api/v1/subscription/', null, {
      payloadParams: payload,
    })
      .then(({ data: { data: dataRes } }) => {
        const resFormatted = buildElement(dataRes);
        resolve(resFormatted);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Получение данных по подпискам клиента на тематики
   * @return {Promise<unknown>}
   */
  getSubscribeTopics() {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/subscription/topic/')
      .then(({ data: { data: dataRes } }) => {
        resolve(dataRes.data);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Обновляет статус подписки на тематику
   * @param {Object} payload
   * @param {string} payload.topic
   * @param {number} [payload.isSubscribed]
   * @return {Promise<unknown>}
   */
  updateSubscribeTopics(payload) {
    return new Promise((resolve, reject) => this.mainHttp.put('/api/v1/subscription/topic/', payload)
      .then(({ data: { data: dataRes } }) => {
        resolve(dataRes);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Подписывает пользователя на всех топики
   * @return {Promise<unknown>}
   */
  subscribeAllTopics() {
    return new Promise((resolve, reject) => this.mainHttp.put('/api/v1/subscription-all-topic/')
      .then(() => {
        resolve();
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Отписывает пользователя от всех топиков
   * @return {Promise<unknown>}
   */
  unsubscribeAllTopics() {
    return new Promise((resolve, reject) => this.mainHttp.put('/api/v1/unsubscription-all-topic/')
      .then(() => {
        resolve();
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }
}
