import { useRoute } from '@nuxtjs/composition-api';
import {
  analyticSelectItem,
  analyticToggleCart,
  analyticViewCart,
  analyticToggleWishlist,
  analyticViewItem,
  analyticAddPaymentInfo,
  analyticAddShippingInfo,
  analyticUpdateCart,
  analyticBeginCheckout,
  analyticPurchase,
} from '@/utils/analytics/analyticEvents';
import { getBasketTotalPrice } from '@/utils/analytics/getBasketTotalPrice';
import { getOrderCoupon } from '@/utils/analytics/getOrderCoupon';
import { getPropertiesFromAttributes } from '@/utils/analytics/getPropertiesFromAttributes';
import { setDeliveryType } from '@/utils/analytics/setDeliveryType';
import { setPaymentType } from '@/utils/analytics/setPaymentType';
import { setProductAnalyticProperties } from '@/utils/analytics/setProductAnalyticProperties';
import { getOrderPayment } from '@/utils/analytics/getOrderPayment';
import { getOrderShipping } from '@/utils/analytics/getOrderShipping';
import { getTargetProperties } from '@/utils/analytics/getTargetProperties';

export const useAnalyticProducts = () => {
  const route = useRoute();

  const sendAnalyticSelectProduct = ({ target }) => {
    analyticSelectItem([
      getPropertiesFromAttributes(target),
    ]);
  };

  const sendAnalyticViewItem = () => {
    const target = document.querySelector('[data-b24-analytic-view-item]');
    analyticViewItem([
      getPropertiesFromAttributes(target),
    ]);
  };

  const sendAnalyticViewCart = ({ value, items }) => {
    analyticViewCart({
      value: getBasketTotalPrice(value),
      items: items.map((item, index) => setProductAnalyticProperties(item, index, route.value.name)),
    });
  };

  const sendAnalyticUpdateCart = ({ value, items }) => {
    analyticUpdateCart({
      value: getBasketTotalPrice(value),
      items: items.map((item, index) => setProductAnalyticProperties(item, index, route.value.name)),
    });
  };

  const sendAnalyticBeginCheckoutPreOrder = (quantity, preOrderProduct) => {
    const product = Object.assign(preOrderProduct,
      { productQuantity: quantity });
    analyticBeginCheckout({ items: [product] });
  };

  const sendAnalyticBeginCheckout = ({ items }) => {
    analyticBeginCheckout({
      items: items.map((item, index) => setProductAnalyticProperties(item, index, route.value.name)),
    });
  };

  const sendAnalyticPurchase = (orderInfo, isPreOrder) => {
    // TODO: Тут удалить fallback
    const products = orderInfo?.basket?.basketItem || orderInfo?.cart?.cartItem;
    analyticPurchase({
      orderId: Number(orderInfo.id),
      basketTotalPrice: getBasketTotalPrice(orderInfo.amount),
      coupon: getOrderCoupon(orderInfo?.orderAppliedCoupon),
      payment: getOrderPayment(orderInfo.orderPayment),
      shipping: getOrderShipping(orderInfo.orderShipment),
      products: products.map(item => ({
        ...item,
        targetProperties: getTargetProperties(item.basketItemProperty),
      })).map((item, index) => setProductAnalyticProperties(item, index, isPreOrder ? 'product' : 'cart')),
    });
  };

  const sendAnalyticAddCart = (items) => {
    analyticToggleCart({
      isAdd: true,
      items,
    });
  };

  const sendAnalyticRemoveCart = (items) => {
    analyticToggleCart({
      isAdd: false,
      items,
    });
  };

  const sendAnalyticAddWishlist = (items) => {
    analyticToggleWishlist({
      isAdd: true,
      items,
    });
  };

  const sendAnalyticRemoveWishlist = (items) => {
    analyticToggleWishlist({
      isAdd: false,
      items,
    });
  };

  const sendAnalyticAddPaymentInfo = ({ type, items, coupon, bonusPay, certificatePay }) => {
    analyticAddPaymentInfo({
      type: setPaymentType(Number(type), bonusPay, certificatePay),
      items: items.map((item, index) => setProductAnalyticProperties(item, index, route.value.name)),
      coupon: getOrderCoupon(coupon),
    });
  };

  const sendAnalyticAddShippingInfo = ({ type, items, coupon }) => {
    analyticAddShippingInfo({
      type: setDeliveryType(type),
      items: items.map((item, index) => setProductAnalyticProperties(item, index, route.value.name)),
      coupon: getOrderCoupon(coupon),
    });
  };

  return {
    sendAnalyticSelectProduct,
    sendAnalyticViewCart,
    sendAnalyticAddCart,
    sendAnalyticRemoveCart,
    sendAnalyticAddWishlist,
    sendAnalyticRemoveWishlist,
    sendAnalyticViewItem,
    sendAnalyticAddPaymentInfo,
    sendAnalyticAddShippingInfo,
    sendAnalyticUpdateCart,
    sendAnalyticBeginCheckout,
    sendAnalyticBeginCheckoutPreOrder,
    sendAnalyticPurchase,
  };
};
