<template>
  <div class="authorization-resend-sms-code">
    <loader v-if="loading" />
    <vue-countdown
      v-else-if="timeToResend"
      :time="timeToResend"
      class="authorization-resend-sms-code__countdown"
      @end="endCountdownHandler"
    >
      <template #default="props">
        Запросить новый код можно через
        {{ props.minutes }}
        {{ plural(props.minutes, ['минуту', 'минуты', 'минут']) }},
        {{ props.seconds }}
        {{ plural(props.seconds, ['секунду', 'секунды', 'секунд']) }}
      </template>
    </vue-countdown>
    <button-control
      v-else
      class="authorization-resend-sms-code__resend-code"
      type="more"
      size="small"
      @click="resendSmsCodeHandler"
    >
      {{ buttonText }}
    </button-control>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { ButtonControl, Loader } from '@book24/ui-components';

import plural from '@/utils/plural';
import { analyticAuthFormButtonClick } from '@/utils/analytics/analyticEvents';

export default defineComponent({
  name: 'AuthorizationResendSmsCode',
  components: {
    ButtonControl,
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    timeToResend: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const buttonText = 'Запросить новый код';
    const resendSmsCodeHandler = () => {
      analyticAuthFormButtonClick(buttonText);
      emit('onResendSmsCode');
    };
    const endCountdownHandler = () => emit('onEndCountdown');
    return {
      buttonText,
      plural,
      resendSmsCodeHandler,
      endCountdownHandler,
    };
  },
});
</script>

<style lang="less">
.authorization-resend-sms-code {
  &__countdown {
    .extra-small;

    display: block;
    max-width: 200px;
    color: @gray-dark;
  }

  &__resend-code.b24-btn._more {
    padding: 0;
  }
}
</style>
