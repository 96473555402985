import camelCase from 'camelcase-keys';
import { ERROR_PARSE_JSON } from '@/utils/constants/constErrorsCode';

export default function checkBeforeTransform(data) {
  try {
    return camelCase(JSON.parse(data), {
      deep: true,
    });
  } catch (e) {
    throw new Error(ERROR_PARSE_JSON);
  }
}
