import { ERROR_MESSAGE, ERROR_NOT_FOUND } from '@/utils/constants/globalConst';

/**
 * Дефолтный объект списка ошибок
 * @type {Readonly<{getItemByCode(*): (null|*)}>}
 */
export const defaultErrorsList = Object.freeze({
  notFound: { ...ERROR_NOT_FOUND },
  /**
   * Возвращает объект ошибки по коду ошибки
   * @param {string} code
   * @return {null|*}
   */
  getItemByCode(code) {
    const i = Object.keys(this).find(item => this[item]?.code === code);
    if (!i) return null;
    return this[i];
  },
  /**
   * Проверяет является ли ошибка 404
   * @param code
   * @returns {boolean}
   */
  isNotFound(code) {
    return code === this.notFound.code;
  },
});

/**
 * Возвращает объект ошибки
 * @param {string} errorCode
 * @param {string} errorMessage
 * @param {Object} errorsList
 * @param {Object} payload
 * @return {{code:string, message: string, payload: array}}
 */
const getErrorObject = (errorCode, errorMessage, errorsList = defaultErrorsList, payload) => {
  const error = errorsList.getItemByCode(errorCode);
  return {
    code: errorCode,
    message: error?.message ? error.message : errorMessage,
    payload: payload || [],
  };
};

/**
 * Преобразование к одному виду ошибок
 * @param {Object} data - ответ от бекенда
 * @param {Object} [errorsList={}] - коды ошибок с текстовым описанием
 * @returns {[]}
 */
const formattedError = (data, errorsList = {}) => {
  const modifiedErrorsList = { ...defaultErrorsList, ...errorsList };
  const itemsError = [];
  if (data.errors && Array.isArray(data.errors)) {
    data.errors.forEach((item) => {
      itemsError.push(getErrorObject(item.code, item.title || item.message, modifiedErrorsList, data.data));
    });
  }
  if (!itemsError.length) {
    const code = data.code ? String(data.code) : '0';
    const message = code !== '0' || data.message ? data.message : ERROR_MESSAGE;
    const payload = data.data ? data.data : [];
    itemsError.push({
      code,
      message,
      payload,
    });
  }
  return itemsError;
};

export default formattedError;
