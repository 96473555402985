import get from 'lodash.get';
import getErrorMessage from '@/utils/formsVue/getErrorMessage';

export default function fieldValidation(fieldName, customMessage = '') {
  const field = get(this.v$, fieldName);
  const value = get(this, fieldName);

  if (value) field?.$touch();

  return {
    textError: field?.$error ? getErrorMessage(field, customMessage) : '',
    isValid: !field?.$invalid,
  };
}
