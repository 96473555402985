export default (crumbs = []) => crumbs.sort((a, b) => {
  if (a.position > b.position) {
    return 1;
  }
  if (a.position < b.position) {
    return -1;
  }
  return 0;
}).map(item => ({
  title: item.name,
  link: item.uri,
}));
