/**
 * Чтобы не передавать значения карточки товара
 * и полки через бесконечные пропсы, будем брать
 * их из дата-атрибутов
 * @param {*} target
 */
export const getPropertiesFromAttributes = (target) => {
  if (!target) return {};

  const productShelf = target.closest('[data-b24-shelf-name]');
  const productCard = target.closest('[data-b24-id]') || target;

  const properties = {
    // Добавляем + 1 чтобы в шаблоне везде это не добавлять
    productIndex: Number(productCard.dataset.b24Index) + 1,
    productId: Number(productCard.dataset.b24Id),
    productBaseId: Number(productCard.dataset.b24BaseId),
    productName: productCard.dataset.b24Name,
    productBrand: productCard.dataset.b24Brand,
    productDiscount: Number(productCard.dataset.b24Discount),
    productCategory: productCard.dataset.b24Category,
    productPrice: Number(productCard.dataset.b24Price),
    productAvailable: Boolean(productCard.dataset.b24Available),
    productPreOrder: Boolean(productCard.dataset.b24Preorder),
    itemListName: productCard.dataset.b24ItemListName,
    shelfName: productCard.dataset.b24ItemShelfName,
    shelfIndex: Number(productCard.dataset.b24ItemShelfIndex),
  };

  if (productShelf) {
    Object.assign(properties, {
      shelfName: productShelf.dataset.b24ShelfName,
      shelfIndex: Number(productShelf.dataset.b24ShelfIndex),
    });
  }

  return properties;
};
