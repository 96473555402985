import Vue from 'vue';
import requestIp from 'request-ip';
import { captureException } from '@sentry/browser';
import {
  MAIN_REGIONS,
  LOCAL_CONST_CUSTOMERS,
  LOCAL_CONST_BASKET,
  DISCOUNT_URL_REQUEST,
  PROD_DOMAIN,
} from '@/utils/constants/globalConst';
import getOptionsSetCookie from '@/utils/getOptionsSetCookie';
import { analyticAuthLogout } from '@/utils/analytics/analyticEvents';

export default {
  namespaced: true,
  state: () => ({
    user: {},
    userIp: null,
    isAuthorized: false,
    isUserPartner: false,
    serverLocationCode: null,
    userCountRatings: 0,
    hasUserRecommendations: false,
    isUserSubscribed: false,
    siteSettings: {},
    partnerLinks: {},
    loadingFlags: {},
    isBlackFriday: false,
    promoCode: {},
  }),
  actions: {
    async nuxtServerInit({ dispatch }, {
      res, req, $cookies, query,
    }) {
      dispatch('main/forceRenderNuxt');

      const utmSource = query?.utm_source;
      if (utmSource && typeof utmSource === 'string') {
        $cookies.set(DISCOUNT_URL_REQUEST, `utm_source=${utmSource}`, getOptionsSetCookie(this.$config.domainCookie));
      }

      const currentLocation = $cookies.get('BITRIX_SM_location_code');
      dispatch('setLocation', currentLocation ?? MAIN_REGIONS.msk.code);

      const currentLocationRegionCode = $cookies.get('BITRIX_SM_location_region_code');
      const isMoscowRegion = MAIN_REGIONS.msk.regionFiasList.includes(currentLocationRegionCode);

      dispatch('main/setPhone', isMoscowRegion ? MAIN_REGIONS.msk.code : '');

      const clientIp = requestIp.getClientIp(req);
      if (clientIp) {
        dispatch('setUserIp', clientIp);
      }

      await Promise.allSettled([
        dispatch('getUserSession'),
        dispatch('getSiteSettings'),
      ])
        .then((statuses) => {
          const userSessionStatus = statuses[0];

          if (userSessionStatus.status === 'rejected') {
            const error = userSessionStatus.reason;

            if (error?.request?.res?.headers['set-cookie']) {
              // Костыль для локальной разаротки в сафари
              let setCookie = error.request.res.headers['set-cookie'];
              if (process.env.NODE_ENV !== 'production' && setCookie.length) {
                const [firstItem] = setCookie;
                setCookie = [firstItem.replace('secure; ', '')];
              }
              res.setHeader('Set-Cookie', setCookie);
            }
          }
        });
    },
    async getUserSession({ dispatch }) {
      await this.$httpClient.user.getUserSessions({ isServer: true })
        .then(() => {
          dispatch('setIsAuthorized', true);
        })
        .catch(e => Promise.reject(e));
    },
    async getFullUserSession({ dispatch }) {
      await this.$httpClient.user.getUserSessions({
        isServer: true,
        isGetUser: true,
        isGetUserLoyalty: true,
        isGetUserStats: true,
        isGetUserSubscription: true,
        isGetUserSubscriptionRubric: true,
        isGetUserChildren: true,
      })
        .then((userInfo) => {
          dispatch('setUser', userInfo);
          dispatch('setIsAuthorized', true);

          const countRatings = userInfo?.relationships?.userStats?.countRatings || 0;
          const countAttracted = userInfo?.relationships?.userStats?.countAttractedForProducts || 0;
          const totalCount = countRatings + countAttracted;
          dispatch('setUserCountRatings', totalCount);

          const subscriptionArr = userInfo?.relationships?.subscription || [];
          dispatch('setIsUserSubscribed', !!subscriptionArr.length || false);
        })
        .catch(e => Promise.reject(e));
    },
    async getSiteSettings({ commit }) {
      try {
        const settings = await this.$httpClient.site.getSiteSettings();
        commit('setSiteSettings', settings);
      } catch (error) {
        captureException(error);
      }
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setUserIp({ commit }, ip) {
      commit('setUserIp', ip);
    },
    setIsAuthorized({ commit }, isAuthorized) {
      commit('setIsAuthorized', isAuthorized);
    },
    setIsUserSubscribed({ commit }, isSubscribed) {
      commit('setIsUserSubscribed', isSubscribed);
    },
    async logout(_, payload = { redirectToMain: false, isReload: true }) {
      await this.$httpClient.user.deleteUserSession();
      this.$cookies.set('FORCE_LOGOUT', 'Y', getOptionsSetCookie(this.$config.domainCookie));
      if (process.client) {
        try {
          localStorage.removeItem(LOCAL_CONST_CUSTOMERS);
          localStorage.removeItem(LOCAL_CONST_BASKET);
          this.$cookies.remove('BITRIX_SM_SALE_UID');
          this.$cookies.remove('BITRIX_SM_SALE_UID', { domain: `.${PROD_DOMAIN}` });
          analyticAuthLogout();
        } catch (err) {
          captureException(err);
        }
        if (payload.redirectToMain) {
          document.location = '/';
        } else if (payload.isReload) {
          document.location.reload();
        }
      }
    },
    setHasRecommendationsUser({ commit }, hasRecommendations = false) {
      commit('setHasRecommendationsUser', hasRecommendations);
    },
    setUserCountRatings({ commit }, count = 0) {
      commit('setUserCountRatings', count);
    },
    incrementUserCountRatings({ state, commit }) {
      commit('setUserCountRatings', state.userCountRatings + 1);
    },
    setLocation({ commit }, location) {
      commit('setLocation', location);
    },
  },
  mutations: {
    setUser(state, user) {
      Vue.set(state, 'user', user);
    },
    setPromoCode(state, code) {
      state.promoCode = { ...code };
    },
    setUserIp(state, ip) {
      state.userIp = ip;
    },
    setIsAuthorized(state, isAuthorized) {
      state.isAuthorized = isAuthorized;
    },
    setHasRecommendationsUser(state, hasRecommendations = false) {
      state.hasUserRecommendations = hasRecommendations;
    },
    setUserCountRatings(state, count = 0) {
      state.userCountRatings = count;
    },
    setIsUserSubscribed(state, isSubscribed = false) {
      state.isUserSubscribed = isSubscribed;
    },
    setSiteSettings(state, settings) {
      state.siteSettings = settings;
    },
    setLocation(state, location) {
      state.serverLocationCode = location;
    },
    setIsUserPartner(state, isUserPartner) {
      state.isUserPartner = isUserPartner;
    },
    setPartnerLinks(state, partnerLinks) {
      state.partnerLinks = partnerLinks;
    },
    setLoadingFlags(state, flagObj) {
      Vue.set(state.loadingFlags, flagObj.name, state.loadingFlags[flagObj.name] || []);

      if (flagObj.bool) {
        state.loadingFlags[flagObj.name].push(true);
      } else {
        state.loadingFlags[flagObj.name].pop();
      }
    },
    setIsBlackFriday(state, isBlackFriday) {
      state.isBlackFriday = isBlackFriday;
    },
  },
  getters: {
    getLoading({ loadingFlags }) {
      return stackName => !!(loadingFlags[stackName] || []).length;
    },
  },
};
