<template>
  <div class="product-card-badge-best-price">
    <like-icon class="product-card-badge-best-price__icon" />
    <span class="product-card-badge-best-price__text">
      {{ text }}
    </span>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';

// icons
import LikeIcon from '@/assets/sprite/svg/16/Like_16.svg';

export default {
  name: 'BadgeBestPrice',
  components: { LikeIcon },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const text = computed(() => {
      if (props.isMobile) return 'Лучшая цена';
      return 'Цена';
    });

    return {
      text,
    };
  },
};
</script>

<style lang="less">
.product-card-badge-best-price {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 52px;
    height: 52px;
    display: block;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: 0;
    background-color: @pink;
  }

  &__icon {
    fill: @yellow;
    position: relative;
    z-index: 1;
    width: 16px;
    height: 16px;
  }

  &__text {
    font-size: 10px;
    font-weight: bold;
    line-height: normal;
    color: @white;
    text-align: center;
    z-index: 1;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-top: 3px;
  }
}
</style>
