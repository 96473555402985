const PL_SERVICE = 'pl_service';
const BONUS_ACCOUNT = 'bonus_account';
export const CASH = 'cash';
const NALOZHENNY = 'nalozhenny';
export const BPEREVOD = 'bperevod';
const CLOUD_PAYMENTS = 'cloudpayments';
const CLOUD_PAYMENTS_API = 'cloudpayments_api';
const CERTIFICATES_PAY = 'certificates_pay';
export const BEZNAL = 'beznal';

/**
 * @typedef {Object} PaymentType
 * @property {string} title - название системы оплаты
 * @property {boolean} isPrePay - является ли данный тип предоплатой
 * @property {boolean} isOnlineCard - является ли тип онлайн оплатой
 * @property {string} linkToPayment - ссылка на оплату
 * @property {boolean} isBankTransfer - является ли тип банковским переводом
 * @property {boolean} isCashlessPayment - является ли тип оплатой безналичным расчетом
 */

/**
 * Виды систем оплаты
 * @readonly
 * @type {Object.<string, PaymentType>}
 */
export const PAYMENT_TYPES = Object.freeze({
  28: {
    title: 'Оплата онлайн банковской картой',
    isPrePay: true,
    isOnlineCard: true,
    linkToPayment: '/personal/order/pay/order_id/',
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  31: {
    title: 'Оплата онлайн банковской картой',
    isPrePay: true,
    isOnlineCard: true,
    linkToPayment: '/personal/order/pay/order_id/',
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  10: {
    title: 'Оплата онлайн банковской картой',
    isPrePay: true,
    isOnlineCard: true,
    linkToPayment: '/personal/order/pay/order_id/',
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  17: {
    title: 'Qiwi-кошелёк',
    isPrePay: true,
    isOnlineCard: false,
    linkToPayment: '/personal/payment/?ORDER_ID=order_id',
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  15: {
    title: 'WebMoney',
    isPrePay: true,
    isOnlineCard: false,
    linkToPayment: '/personal/payment/?ORDER_ID=order_id',
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  13: {
    title: 'ЮMoney',
    isPrePay: true,
    isOnlineCard: false,
    linkToPayment: '/personal/payment/?ORDER_ID=order_id',
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  22: {
    title: 'Внутренний счёт',
    isPrePay: true,
    linkToPayment: '',
    isOnlineCard: false,
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  19: {
    title: 'Оплата безналичным расчётом',
    isPrePay: true,
    isOnlineCard: false,
    linkToPayment: '',
    isCashlessPayment: true,
    isBankTransfer: false,
  },
  26: {
    title: 'Оплата сертификатом',
    linkToPayment: '',
    isPrePay: true,
    isOnlineCard: false,
    isBankTransfer: false,
    isCashlessPayment: false,
  },
  18: {
    linkToPayment: '',
    title: 'Банковский перевод',
    isPrePay: true,
    isOnlineCard: false,
    isBankTransfer: true,
    isCashlessPayment: false,
  },
  2: {
    title: 'Наложенный платёж',
    isPrePay: false,
    isOnlineCard: false,
    isBankTransfer: false,
    linkToPayment: '',
    isCashlessPayment: false,
  },
  1: {
    title: 'Оплата при получении',
    isPrePay: false,
    isOnlineCard: false,
    isBankTransfer: false,
    linkToPayment: '',
    isCashlessPayment: false,
  },
});

export const CARD_LIST = [
  CLOUD_PAYMENTS,
  CLOUD_PAYMENTS_API,
];

export const ONLINE_PAY_SYSTEM_LIST = [
  ...CARD_LIST,
];

export const CASH_PAY_SYSTEM_LIST = [
  NALOZHENNY,
  CASH,
];

export const NOT_REAL_MONEY_LIST = [
  PL_SERVICE,
  BONUS_ACCOUNT,
  CERTIFICATES_PAY,
];
