<template>
  <div
    class="author-list"
    :class="{
      _mobile: checkMobile(),
      _adult: isAdult
    }"
  >
    <template v-for="(author, index) in authorList">
      <component
        :is="getComponent(author.link)"
        :key="author.id"
        class="author-list__item"
        v-bind="vBindAttrs(author.link)"
        v-text="author.name"
      />
      <span
        v-if="(index + 1) !== authorList.length"
        :key="`span-${author.id}`"
        class="author-list__separator"
        v-text="', '"
      ></span>
    </template>
  </div>
</template>

<script>
import { checkMobile } from '@/utils/deviceCheck';

export default {
  name: 'AuthorList',
  props: {
    authorList: { type: Array, default: () => ([]) },
    isAdult: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loopLink(link) {
      return this.$route.fullPath.includes(link);
    },
    getComponent(link) {
      if (typeof link === 'string' && !this.loopLink(link)) return 'app-link';
      return 'span';
    },
    vBindAttrs(link) {
      const attrs = {};

      if (typeof link === 'string' && !this.loopLink(link)) {
        attrs.href = link;
      }

      return attrs;
    },
    checkMobile,
  },
};
</script>

<style lang="less">
.author-list {
  .small2(24px);

  &._adult {
    .p;
  }

  color: @gray-dark;

  &._mobile {
    .p();
  }

  &__item {
    transition: @A color;
    text-decoration: none;
    color: @gray-dark;

    &:hover:not(span) {
      color: @link;
    }

    @media @mobile {
      &:hover {
        color: @gray-dark;
      }

      &._tap {
        color: @link;
      }
    }
  }
}
</style>
