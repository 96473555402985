export const NAME_PROP = 'book24-saved-reco';

export const NAME_PROP_DATE = 'book24-saved-reco-date';

export const getRecommendationsSave = () => {
  const saveResult = localStorage.getItem(NAME_PROP);

  if (saveResult) {
    try {
      return saveResult.split(',');
    } catch (e) {
      return [];
    }
  }
  return [];
};

export const saveDate = () => {
  const date = new Date();
  localStorage.setItem(NAME_PROP_DATE, date.toString());
};

export const saveListId = (list = []) => {
  localStorage.setItem(NAME_PROP, list.toString());
};

export const getDateSaveRecommendations = () => localStorage.getItem(NAME_PROP_DATE);
