// @ts-check
import { buildErrorCollection, buildCollection } from '@/utils/formatters/processingOpenApi';
import HttpClient from '@/utils/clients/clientBookMain';

import { ERRORS_GET_WISH_LIST, ERRORS_ADD_TO_WISH_LIST, ERRORS_REMOVE_IN_WISH_LIST } from '@/utils/constants/wishListConstants';
import formattedProduct from '@/utils/formatters/formattedProduct';
import { getTargetProperties } from '@/utils/analytics/getTargetProperties';

export default class WishListService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Возвращает список отложенных товаров
   * @param {Object} options
   * @param {number} [options.page=1]
   * @param {number} [options.perPage=15]
   * @param {boolean} [options.isGetAuthor=false]
   * @param {boolean} [options.isGetSeries=false]
   * @param {boolean} [options.isGetProductText=false]
   * @param {boolean} [options.isGetBadge=false]
   * @param {boolean} [options.isGetPublishingHouse=false]
   * @param {boolean} [options.isGetCategory=false]
   * @param {boolean} [options.isGetProductStats=false]
   * @param {boolean} [options.isGetProductImage=false]
   * @param {boolean} [options.isGetProduct=false]
   * @param {boolean} [options.isGetOrsProduct=true]
   * @param {boolean} [options.isGetTargetProps=true]
   * @return {Promise<unknown>}
   */
  getWishList(options = {}) {
    return new Promise((resolve, reject) => {
      const {
        page = 1,
        perPage = 15,
        isGetAuthor = false,
        isGetSeries = false,
        isGetProductText = false,
        isGetBadge = false,
        isGetPublishingHouse = true,
        isGetCategory = false,
        isGetParentCategory = false,
        isGetProductStats = false,
        isGetProduct = false,
        isGetOrsProduct = true,
        isGetTargetProps = true,
      } = options;

      const include = [];
      if (isGetProduct) {
        include.push('product');
        if (isGetAuthor) include.push('product.author');
        if (isGetSeries) include.push('product.series');
        if (isGetProductText) include.push('product.productText');
        if (isGetBadge) include.push('product.badge');
        if (isGetPublishingHouse) include.push('product.publishingHouse');
        if (isGetCategory) include.push('product.category');
        if (isGetParentCategory) include.push('category.parentCategory');
        if (isGetProductStats) include.push('product.productStats');
        if (isGetOrsProduct) include.push('product.orsProduct');
        if (isGetTargetProps) include.push('wishlistItemParameter');
      }

      const dataRequest = {
        page,
        'per-page': perPage,
      };
      if (include.length) dataRequest.include = include.join(',');

      this.mainHttp.get('/api/v1/wishlist/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          if (isGetProduct) {
            const items = list.map((item) => {
              const product = item.relationships?.product;
              if (product) {
                const element = formattedProduct(product);
                if (isGetProductText) {
                  element.productText = {
                    advertisingBlockHeader:
                      product.relationships.productText.advertisingBlockHeader,
                    advertisingBlockText:
                      product.relationships.productText.advertisingBlockText,
                    detailDescription:
                      product.relationships.productText.detailDescription,
                    additionalDescription:
                      product.relationships.productText.additionalDescription,
                    showAdditionalDescription:
                      product.relationships.productText.showAdditionalDescription,
                  };
                }

                if (isGetProductStats) {
                  element.productStats = {
                    purchased: product.relationships.productStats.purchased,
                    reviewTotal: product.relationships.productStats.reviewTotal,
                  };
                }

                if (isGetTargetProps) {
                  element.targetProperties = getTargetProperties(item.relationships?.wishlistItemParameter);
                }

                return element;
              }
              return product;
            }) || [];
            resolve({
              list: items,
              pagination,
            });
          } else {
            resolve({ list, pagination });
          }
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, ERRORS_GET_WISH_LIST);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Добавление товара в отложенные
   * @param {number} productId
   * @param {Object} [targetProps]
   * @return {Promise<unknown>}
   */
  addToWishList(productId, targetProps) {
    return new Promise((resolve, reject) => {
      if (!productId && typeof productId !== 'number') {
        reject(new Error('[WishListService:addToWishList] некорректно заполнено поле productId'));
      }
      this.mainHttp.post('/api/v1/wishlist/', {
        productId,
        parameters: targetProps,
      })
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, ERRORS_ADD_TO_WISH_LIST);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Удаление товара из отложенных
   * @param {number} productId
   * @return {Promise<unknown>}
   */
  removeInWishListItem(productId) {
    return new Promise((resolve, reject) => {
      if (!productId && typeof productId !== 'number') {
        reject(new Error('[WishListService:removeInWishListItem] некорректно заполнено поле productId'));
      }
      this.mainHttp.delete(`/api/v1/wishlist/${Number(productId)}/`)
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data, ERRORS_REMOVE_IN_WISH_LIST);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
