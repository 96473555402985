export const setPaymentType = (paymentCode, useBonus, useCertificate) => {
  const bonusPayment = 'Бонусами программы лояльности';
  const certificatePayment = 'Оплатить подарочным сертификатом';
  const paymentType = [];

  switch (paymentCode) {
    case 1:
      paymentType.push('При получении');
      break;
    case 26:
      paymentType.push(certificatePayment);
      break;
    case 19:
      paymentType.push('По счёту');
      break;
    case 29:
      paymentType.push(bonusPayment);
      break;
    default:
      paymentType.push('Картой на сайте');
  }

  if (useBonus) paymentType.push(bonusPayment);
  if (useCertificate && !paymentType.includes(certificatePayment)) paymentType.push(certificatePayment);

  if (useBonus || useCertificate) return paymentType;
  return paymentType.join('');
};
