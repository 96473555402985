import { deserialize } from 'deserialize-json-api';

import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import ServiceError from '@/utils/errors/ServiceError';
import { ERROR_SERVER_ERROR } from '@/utils/constants/globalConst';

export default class CustomersService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  createCustomers(options = {}) {
    return new Promise((resolve, reject) => {
      const { visitorId } = options;

      this.mainHttp.post('/api/v1/customers/', { visitorId })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  fixLegacyCustomers(customerId) {
    return new Promise((resolve, reject) => {
      this.mainHttp.post('/api/v1/legacy-customer-fix/', { customerId })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }
}
