import {
  computed,
  getCurrentInstance,
  ref,
} from '@nuxtjs/composition-api';

import { useBasketContext } from '@/compositions/basket/basketStorage';

const useAddToBasket = (productIdInitial) => {
  const { emit } = getCurrentInstance();
  const { addToBasket, productIdList } = useBasketContext();

  const isLoadingData = ref(false);

  const isAddedToCart = computed(() => !!productIdList.value.find(item => item === productIdInitial));

  const add2Basket = async (options = {}) => {
    isLoadingData.value = true;
    const {
      quantity = 1,
      isGift = false,
      isFetchBasket = true,
      target,
    } = options;

    await addToBasket({
      productId: productIdInitial,
      quantity,
      asGift: isGift,
      isFetchBasket,
      target,
    })
      .then(() => {
        emit('addToBasket', {
          productId: productIdInitial,
          quantity,
        });
      })
      .finally(() => isLoadingData.value = false);
  };

  return {
    isLoadingData,
    isAddedToCart,
    add2Basket,
  };
};

export default useAddToBasket;
