<template>
  <app-section :is-container="isContainer">
    <div
      :class="{
        _mobile: isMobile,
        _narrow: isNarrow
      }"
      class="recommendation-cashback-banner"
    >
      <div class="recommendation-cashback-banner__wrapper">
        <div class="recommendation-cashback-banner__inner">
          <div class="recommendation-cashback-banner__desc">
            <span class="recommendation-cashback-banner__desc-start">Знаем, что почитать</span>
            <span class="recommendation-cashback-banner__desc-end">
              Настройте персональные рекомендации: расскажите нам о книгах, которые вам нравятся!
            </span>
          </div>
          <div
            v-if="isMobile"
            class="recommendation-cashback-banner__image"
          >
            <app-picture
              :item="image"
              class-name-img="recommendation-cashback-banner__img"
            />
          </div>
          <div class="recommendation-cashback-banner__button">
            <app-link
              href="/personal/interests/"
              class="b24-btn _block"
              :class="{ '_large': !isMobile }"
            >
              Получить рекомендации
            </app-link>
          </div>
        </div>
        <div
          v-if="!isMobile"
          class="recommendation-cashback-banner__image"
        >
          <app-picture
            :item="image"
            class="banner__picture"
            class-name-img="banner__img"
          />
        </div>
      </div>
    </div>
  </app-section>
</template>

<script>
import { checkMobile } from '@/utils/deviceCheck';

import AppSection from '@/components/AppSection';
import AppPicture from '@/components/AppPicture/AppPictureWithoutLazy.vue';

export default {
  name: 'RecommendationCashbackBanner',
  components: {
    AppPicture,
    AppSection,
  },
  props: {
    isContainer: {
      type: Boolean,
      default: true,
    },
    isNarrow: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const image = {
      x1: '/_ssr/img/recommendations/recommendation.png',
      x2: '/_ssr/img/recommendations/recommendation@2x.png',
      webpX1: '/_ssr/img/recommendations/recommendation.webp',
      webpX2: '/_ssr/img/recommendations/recommendation@2x.webp',
    };

    const isMobile = checkMobile();

    return {
      image,
      isMobile,
    };
  },
};
</script>

<style lang="less">
.recommendation-cashback-banner {
  overflow: hidden;
  padding: @size-x10;
  background: @ultra-light-blue-back;

  &__desc-start {
    .h3-desktop;

    font-weight: 700;
  }

  &__desc-end {
    max-width: 500px;
    display: block;
    margin-top: @size-x2;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }

  &__badge {
    display: inline-block;
    background: @pink;
    color: @white;
    margin-left: @main-size;
    padding: @main-size @size-x3;
  }

  &__button {
    margin-top: @size-x7;
    width: 288px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 60px;

    @media @1366 {
      justify-content: start;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  &__image {
    flex-shrink: 0;
  }

  &._narrow &__wrapper {
    justify-content: start;
  }

  &._mobile {
    .container-m;

    padding: @size-x5 @size-x4;
  }

  &._mobile &__wrapper {
    flex-direction: column;
    text-align: center;
  }

  &._mobile &__desc-start {
    .h3-desktop;

    font-weight: 700;
  }

  &._mobile &__desc-end {
    .p;

    font-weight: 400;
    margin-top: @main-size;
  }

  &._mobile &__badge {
    font-weight: 700;
    margin-top: @size-x2;
  }

  &._mobile &__button {
    width: 100%;
  }

  &._mobile &__inner {
    display: block;
  }

  &._mobile &__image {
    margin: @size-x6 0;
  }

  &._mobile &__img {
    height: 100%;
    max-width: 100%;
    max-height: 110px;
    object-fit: cover;
    object-position: left;
  }
}
</style>
