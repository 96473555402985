<template>
  <base-link
    v-bind="componentBindAttrs()"
    v-on="$listeners"
  >
    <slot></slot>
  </base-link>
</template>
<script>
import Url from 'url-parse';
import { MAIN_URL } from '@/utils/constants/globalConst';

import BaseLink from './BaseLink';

export default {
  name: 'AppLink',
  components: { BaseLink },
  inheritAttrs: false,
  props: {
    href: {
      type: String,
      required: true,
    },
  },
  methods: {
    componentBindAttrs() {
      const isLinkExist = this.isLinkExist();
      return {
        href: isLinkExist.path,
        external: !isLinkExist.flag,
        internal: isLinkExist.flag,
        ...this.$attrs,
      };
    },
    isLinkExist() {
      const urlObject = new Url(this.href);
      const isDomainExist = urlObject.origin === this.$config.appUrl
        || urlObject.origin === MAIN_URL;

      let flag = false;
      if (urlObject.pathname && isDomainExist) {
        const routerMath = this.$router.resolve(urlObject.pathname);
        if (routerMath.resolved?.name) {
          flag = true;
        }
      }
      return {
        flag,
        path: flag ? `${urlObject.pathname}${urlObject.query}${urlObject.hash}` : this.href,
      };
    },
  },
};
</script>
