<template>
  <component :is="errorPage" :error="error" />
</template>

<script>
import {
  defineComponent,
  computed,
} from '@nuxtjs/composition-api';

import Error404 from '@/components/errors/Error404';
import Error500 from '@/components/errors/Error500';

export default defineComponent({
  name: 'NuxtErrorPage',
  layout(context) {
    if (context.app.nuxt?.err?.statusCode === 404) {
      return 'default';
    }
    return 'ErrorLayout';
  },
  props: {
    error: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const statusCode = computed({
      get: () => (props?.error?.statusCode || 500),
      set: () => {},
    });

    const errorPage = computed({
      get: () => {
        if (statusCode.value === 404) {
          return Error404;
        }
        return Error500;
      },
      set: () => {},
    });

    return {
      statusCode,
      errorPage,
    };
  },
});
</script>
