<template>
  <content-placeholders
    class="product-card-mobile"
    :class="{'_without-button': !showButtons}"
    rounded
  >
    <content-placeholders-img class="product-card-mobile__image-block" />
    <div class="product-card-mobile__container">
      <div class="product-card-mobile__content">
        <div class="product-card-mobile__badges"></div>
        <div class="product-card-mobile__price product-card-price" style="margin-top: 8px;">
          <content-placeholders-text
            :lines="1"
            class="product-card-price__current"
            style="width: 65px;"
          />
          <content-placeholders-text
            :lines="1"
            class="product-card-price__old"
            style="width: 65px;"
          />
        </div>
        <content-placeholders-heading
          class="product-card-mobile__title"
          style="margin-top: 12px; width: 100%; display: block;"
        />
        <div v-if="showButtons" class="product-card-mobile__buttons-holder">
          <content-placeholders-img
            class="product-card-mobile__button"
            style="height: 32px; margin-top: 12px;"
          />
        </div>
      </div>
    </div>
  </content-placeholders>
</template>

<script>
export default {
  name: 'ProductCardMobileSkeleton',
  props: {
    showButtons: { type: Boolean, default: true },
  },
};
</script>
