import formattedProduct from '@/utils/formatters/formattedProduct';

import {
  TOP_HUNDRED_TEXT,
  TOP_FIFTY_TEXT,
  TOP_TWENTY_TEXT,
  COMMON_CATEGORY,
  PUBLISHING_CATEGORY,
  BOOKS_OF_THE_YEAR,
  BOOKS_OF_THE_MONTH,
  PUBLISHERS_RATINGS,
  BY_CATEGORIES_RATINGS,
  BY_CATEGORIES_PER_MONTH,
} from '@/utils/constants/ratingConstants';

/**
 * общий элемент рейтинга
 * @typedef {Object} commonRatingElement
 * @property {string} code - код
 * @property {string} id - ид
 * @property {string} name - имя
 * @property {number} position - позиция
 * @property {string} [text] - текст
 * @property {string} type - тип
 */

/**
 * Возвращает отформатированный список рейтингов по общим категориям
 * @param {Array} ratings - список общих рейтингов
 * @return {Array<commonRatingElement> | []}
 */
export const formatCommonRatings = (ratings) => {
  let result = [];
  if (ratings && ratings.length) {
    result = ratings.map((rating) => {
      if (rating.code === BOOKS_OF_THE_YEAR) {
        rating.text = TOP_HUNDRED_TEXT;
      } else if (rating.code === BOOKS_OF_THE_MONTH) {
        rating.text = TOP_FIFTY_TEXT;
      } else {
        rating.text = TOP_TWENTY_TEXT;
      }

      return rating;
    });
  }
  return result;
};

/**
 * изображения для издательства
 * @typedef {Object} publisherImg
 * @property {string} code - код
 * @property {string} img - изображение
 * @property {string} img2X - изображение 2x
 * @property {number} type - тип изображения
 */

/**
 * рейтинг по издательству
 * @typedef {Object} publishingRatingElement
 * @property {string} code - код
 * @property {string} id - ид
 * @property {string} name - имя
 * @property {number} position - позиция
 * @property {publisherImg} publisher - изображения для издательств
 * @property {string} type - тип
 */

/**
 * Возвращает отформатированный список рейтингов издательств
 * @param {Array} ratings - список рейтингов издательств
 * @return {Array<publishingRatingElement> | []}
 */
export const formatPublishingRatings = (ratings) => {
  let result = [];
  if (ratings && ratings.length) {
    result = ratings.map((rating) => {
      const matchedPublisher = PUBLISHERS_RATINGS.find(el => el.code === rating.code);
      if (matchedPublisher) {
        rating.publisher = matchedPublisher;
      }
      return rating;
    });
  }

  return result;
};

/**
 * рейтинг по категории
 * @typedef {Object} categoryRatingElement
 * @property {string} code - код
 * @property {string} id - ид
 * @property {string} name - имя
 * @property {number} position - позиция
 * @property {string} icon - иконка
 * @property {string} type - тип
 */

/**
 * Возвращает отформатированный список рейтингов всех категорий
 * @param {Array} ratings - список рейтингов всех категорий
 * @return {Array<categoryRatingElement> | []}
 */
export const formatCategoriesRatings = (ratings) => {
  let result = [];
  const byCategoriesList = Object.values(BY_CATEGORIES_RATINGS);
  if (ratings && ratings.length) {
    result = ratings.map((rating) => {
      const matchedIcon = byCategoriesList.find(el => el.code === rating.code);
      if (matchedIcon) {
        rating.icon = matchedIcon.icon;
      }
      return rating;
    });
  }

  return result;
};

/**
 * рейтинг
 * @typedef {Object} ratingElement
 * @property {string} id - ид
 * @property {string} name - имя
 * @property {number} position - позиция
 * @property {Array} ratings - рейтинги по категориям
 * @property {string} type - тип
 */

/**
 * Возвращает отформатированный список рейтингов
 * @param {Array} ratings - общий список рейтингов
 * @return {Array<ratingElement> | {}}
 */
export default function formatRatings(ratings) {
  const formattedRatings = {};

  if (!ratings && !ratings.length) {
    return formattedRatings;
  }

  ratings.forEach((category) => {
    if (category.name === COMMON_CATEGORY) {
      formattedRatings.common = { ...category, ratings: formatCommonRatings(category.ratings) };
    }

    if (category.name === PUBLISHING_CATEGORY) {
      formattedRatings.publishers = { ...category, ratings: formatPublishingRatings(category.ratings) };
    }

    if (category.name === BY_CATEGORIES_PER_MONTH) {
      formattedRatings.byCategories = { ...category, ratings: formatCategoriesRatings(category.ratings) };
    }
  });
  return formattedRatings;
}

/**
 * @typedef {ProductInfo} ProductInfoBase
 */

/**
 * @typedef {Object} topRating
 * @property {string} id
 * @property {string} position
 */

/**
 * @typedef {ProductInfoBase} ProductInfoRating
 * @property {topRating} topRating
 */

/**
 * продукт с рейтингом
 * @typedef {ProductInfoRating} productItemElement
 */

/**
 * Возвращает отформатированный список продуктов по рейтингу
 * @param {Array} list - список продуктов с рейтингом
 * @return {Array<productItemElement> | []}
 */
export const formatRatingProducts = (list) => {
  const products = list?.length ? list : [];
  return products.map((el) => {
    let product = {};
    if (el.relationships?.product) {
      product = formattedProduct(el.relationships?.product);
    }

    return {
      ...product,
      topRating: {
        id: el.id,
        position: el.position,
      },
    };
  });
};

/**
 * Форматируем и округляем звёздный рейтинг продукта
 * @param rating
 * @returns {string}
 */
export const formatStarRating = (rating) => {
  if (!rating) return '0';

  if (rating >= 1000000) {
    return `${Math.floor(rating / 1000000)}M`;
  }

  if (rating >= 1000) {
    return `${Math.floor(rating / 1000)}K`;
  }

  return String(rating);
};
