<template>
  <card-price
    class="_normal"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import CardPrice from '@/components/ProductCard/CardPrice';

export default defineComponent({
  name: 'CardPriceNormal',
  components: { CardPrice },
});
</script>

<style lang="less">
.product-card-price {
  &._normal &__current {
    font-weight: normal;
  }
}
</style>
