<template>
  <div class="accordion">
    <accordion-panel
      v-if="isVisiblePanel"
      :collapsed="isLocalCollapsed"
      @toggle="toggleHandler"
    >
      <slot name="accordion"></slot>
    </accordion-panel>
    <slot v-else name="accordion"></slot>
    <div
      ref="collapse"
      class="accordion__wrapper"
    >
      <div
        ref="inner"
        class="accordion__wrapper-inner"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';

import anime from 'animejs';

import AccordionPanel from '@/components/Accordion/AccordionPanel/index';

export default defineComponent({
  name: 'Accordion',
  components: { AccordionPanel },
  props: {
    maxHeight: {
      type: Number,
      default: 300,
    },
    isVisiblePanel: {
      type: Boolean,
      default: true,
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
    isClosable: {
      type: Boolean,
      default: false,
    },
    openedDuration: {
      type: Number,
      default: 500,
    },
    closedDuration: {
      type: Number,
      default: 300,
    },
    isFullStretchHeight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const collapse = ref(null);
    const inner = ref(null);
    const isLocalCollapsed = ref(true);

    const close = (isToggled = false) => {
      isLocalCollapsed.value = true;
      anime({
        targets: collapse.value,
        maxHeight: 0,
        easing: 'easeInOutCubic',
        duration: props.closedDuration,
      });
      emit(isToggled ? 'toggle' : 'close', isLocalCollapsed.value);
    };

    const open = (isToggled = false) => {
      const maxHeight = props.isFullStretchHeight
        ? inner.value?.clientHeight || 0
        : props.maxHeight;
      isLocalCollapsed.value = false;

      anime({
        targets: collapse.value,
        maxHeight,
        easing: 'easeInOutCubic',
        duration: props.openedDuration,
      });
      emit(isToggled ? 'toggle' : 'open', isLocalCollapsed.value);
    };

    const toggleHandler = () => {
      if (isLocalCollapsed.value) {
        open(true);
      } else {
        close(true);
      }
    };

    watch(
      () => props.isClosable,
      (val) => {
        if (val) {
          close();
        }
      },
      { immediate: false },
    );

    watch(
      () => props.isCollapsed,
      (val) => {
        if (val) {
          close();
        } else {
          open();
        }
      },
      { immediate: false },
    );

    return {
      collapse,
      inner,
      isLocalCollapsed,
      close,
      toggleHandler,
    };
  },
});
</script>

<style lang="less">
.accordion {
  width: 100%;

  &__wrapper {
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }
}
</style>
