import {
  comparisonEnum,
  facetsHardCodeType,
  rootCategoryNewName,
} from '@/utils/constants/catalogConstants';

/**
 * Возвращает фильтр в виде строки для API
 * @param {Array} [filter=[]]
 * @param {Object} [options={}]
 * @param {number|string} [options.sectionId]
 * @param {number|string} [options.seriesId]
 * @param {number|string} [options.highlightId]
 * @return {string}
 */
export const formattedFilterString = (filter = [], options = {}) => {
  const { sectionId, seriesId, highlightId } = options;
  const filterCopy = [...filter];
  if (sectionId) filterCopy.push({ name: 'section_id', value: String(sectionId) });
  if (seriesId) filterCopy.push({ name: 'serie_id', value: String(seriesId) });
  if (highlightId) filterCopy.push({ name: 'highlight_ids', value: String(highlightId) });

  let filterString = '';
  filterCopy.forEach((item) => {
    if (Array.isArray(item.value)) {
      filterString += `${item.name}=${item.value.join(',')};`;
    } else if (item.comparison) {
      filterString += `${item.name}${item.comparison}${item.value};`;
    } else {
      filterString += `${item.name}=${item.value};`;
    }
  });

  return filterString;
};

/**
 * Возвращает массив фильтров разбирая query параметры
 * @param {Object} [queryParams={}]
 * @return {[]}
 */
export const formattedFilterFromQuery = (queryParams = {}) => {
  const filter = [];
  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    let resultItem = {};
    if (value === '1' || value === '2') {
      resultItem = {
        value,
        name: key,
      };
    } else if (comparisonEnum.includesSymbolsOrName(key)) {
      const data = comparisonEnum.getNameAndValueWithoutLabel(key);
      // костыль для текущей реализации фильтра по цене
      let val = data.name;
      if (val.includes('price')) val = 'price_discount';

      resultItem = {
        value,
        name: val,
        comparison: data.value,
      };
    } else if (facetsHardCodeType.isSection(key)) {
      const [section] = value.split(',');
      if (section) {
        resultItem = {
          value: section,
          name: key,
        };
      }
    } else {
      resultItem = {
        value: value.split(','),
        name: key,
      };
    }
    if (Object.keys(resultItem).length) filter.push(resultItem);
  });

  return filter;
};

/**
 * Возвращает пресеты фильтра в виде строки
 * @param {Array} [filterPreset=[]]
 * @return {string|null}
 */
export const formattedFilterPreset = (filterPreset = []) => (filterPreset.length ? filterPreset.join(',') : null);

/**
 * Возвращает query параметры для построения url
 * @param {Array} [filter=[]]
 * @return {{}}
 */
export const formattedQueryParamsForUrl = (filter = []) => {
  const resultQuery = {};

  filter.forEach((item) => {
    if (Array.isArray(item.value)) {
      if (!resultQuery[item.name]) resultQuery[item.name] = null;
      resultQuery[item.name] = item.value.join(',');
    } else if (item.comparison) {
      // костыль для текущей реализации фильтра по цене
      let val = item.name;
      if (val.includes('price')) val = 'price';
      val = comparisonEnum.getNameByValue(val, item.comparison);
      resultQuery[val] = item.value;
    } else {
      if (!resultQuery[item.name]) resultQuery[item.name] = null;
      resultQuery[item.name] = item.value;
    }
  });

  return resultQuery;
};

/**
 * @typedef {Object} currentCategory
 * @property {string} name - название категории
 * @property {number} count - кол-во элементов в категории
 * @property {string} code - символьный код категории
 */

/**
 * Возвращает отформатированный список родительских категорий капегорий
 * @param {Array} parentCategory
 * @param {Object<currentCategory>} currentCategory
 * @return {*[]|*}
 */
export const formattedCatalogCategoryListForFacet = (parentCategory, currentCategory) => {
  if (!Array.isArray(parentCategory)) return [];
  // собираем массив категорий
  const list = parentCategory
    .sort((a, b) => {
      if (a.depthLevel > b.depthLevel) {
        return 1;
      }
      if (a.depthLevel < b.depthLevel) {
        return -1;
      }
      return 0;
    })
    .map(item => ({
      name: item.name,
      count: item.countProducts,
      value: item.id,
    }));
  // добавляем текущую категорию
  if (currentCategory.name) {
    list.push({
      name: currentCategory.name,
      count: currentCategory.countProducts,
      value: currentCategory.id,
    });
  }
  // добавляем корневую категорию
  list.unshift({
    name: rootCategoryNewName,
    count: 0,
    value: 0,
  });
  return list;
};
