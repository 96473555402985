// @ts-check
import axios from 'axios';
import HttpClient from '@/utils/clients/clientBookMain';
import {
  buildErrorCollection,
  buildElement,
  buildCollection,
} from '@/utils/formatters/processingOpenApi';

export default class LocationService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
    this.pureClient = axios.create();
  }

  /**
   * Возвращает данные о местоположении пользователя
   * @param {string} strategy - regular — быстрее, accurate — точнее
   * @return {Promise<unknown>}
   */
  getUserLocation(strategy = 'regular') {
    return new Promise((resolve, reject) => this.mainHttp.post('/api/v1/user-location/', { strategy })
      .then(({ data: { data: dataRes } }) => resolve(buildElement(dataRes)))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Устанавливает населенный пункт для пользователя
   * @param {string} locationCode
   * @return {Promise<unknown>}
   */
  setUserLocation(locationCode) {
    return new Promise((resolve, reject) => {
      if (!locationCode || typeof locationCode !== 'string') reject(new Error('Не указан locationCode'));

      this.mainHttp.get('/api/v1/location/', {
        LOCATION: locationCode,
      })
        .then(({ data: { data: dataRes } }) => resolve(dataRes))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Ищет город по переданному запросу
   * @param {string} query - Искомый город
   */
  getCities(query) {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/locations/', {
      query,
    })
      .then(({ data: { data: dataRes } }) => {
        const transformedList = dataRes.data.map(item => ({
          ...item,
          name: item.attributes.fullWithType,
        }));
        resolve(transformedList);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Ищет город по location коду
   * @param {string} code - Код города
   */
  getCitiesByLocationCode(code) {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/locations/', {
      code,
    })
      .then(({ data: { data: dataRes } }) => {
        const transformedList = dataRes.data.map(item => ({
          ...item,
          name: item.attributes.fullWithType,
        }));
        resolve(transformedList);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Ищет города региона
   * @param {string} parentCode - Искомый город
   */
  getCitiesOfRegion(parentCode) {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/locations/', {
      parentCode,
    })
      .then(({ data: { data: dataRes } }) => {
        const transformedList = dataRes.data.map(item => ({
          ...item,
          name: item.attributes.fullWithType,
        }));
        resolve(transformedList);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Возвращает список популярных городов
   */
  getPopularLocations() {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/popular-location/')
      .then(({ data: { data: dataRes } }) => {
        resolve(buildCollection(dataRes));
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * Определяет населенный пункт по координатам
   * @param {string} coordinates
   * @param {string} apiKey
   * @return {Promise<string>}
   */
  yandexGeocoder(coordinates, apiKey) {
    return new Promise((resolve, reject) => this.pureClient.get('https://geocode-maps.yandex.ru/1.x/', {
      params: {
        apikey: apiKey,
        lang: 'ru_RU',
        format: 'json',
        geocode: coordinates,
      },
    })
      .then(({ data: { response } }) => {
        let city = '';
        response
          .GeoObjectCollection
          .featureMember
          .forEach((featureMember) => {
            const metaDataProperty = featureMember.GeoObject.metaDataProperty;
            const components = metaDataProperty.GeocoderMetaData.Address.Components;
            if (!city) {
              const locality = components.find(component => component.kind === 'locality');
              if (locality?.name) {
                city = locality.name;
              }
            }
          });
        resolve(city);
      })
      .catch(e => reject(e)));
  }

  /**
   * Определяет местоположение пользователя
   * @param {string} apiKey
   * @return {Promise<unknown>}
   */
  geoDetect(apiKey) {
    return new Promise((resolve, reject) => {
      let timeoutEnd = false;
      const timeout = setTimeout(() => {
        timeoutEnd = true;
        reject();
      }, 10000);

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          clearTimeout(timeout);
          if (!timeoutEnd) {
            const coords = `${position.coords.longitude},${position.coords.latitude}`;
            const result = await this.yandexGeocoder(coords, apiKey);
            if (result) {
              const data = await this.getCities(result);
              if (data.length > 0) {
                resolve({
                  city: data[0],
                  coordinates: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                });
              } else {
                reject(new Error('Город не найден'));
              }
            } else {
              reject(new Error('Город не найден'));
            }
          }
        },
        (error) => {
          clearTimeout(timeout);
          if (!timeoutEnd) {
            reject(error);
          }
        },
      );
    });
  }
}
