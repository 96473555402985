export const ERROR_MESSAGES = Object.freeze({
  form: {
    code: 'c35bb1f7-6f40-4c38-ae12-fd5e4eb79ea9',
    message: 'Что-то пошло не так, попробуйте повторить попытку позже',
  },
  payloadForClientRequestRequired: {
    code: '',
    message: 'Отсутствует обязательный параметр payload',
  },
});

export const SUBSCRIPTION_FORM = Object.freeze({
  type: 'subscription',
});

export const FEEDBACK_FORM = Object.freeze({
  type: 'feedback',
});

export const CALLBACK_FORM = Object.freeze({
  title: 'Заказать звонок',
  type: 'callback',
  error: ERROR_MESSAGES.form,
});

export const REVIEWS_FORM = Object.freeze({
  type: 'reviews',
});

export const PERSONAL_PROFILE_FORM = Object.freeze({
  main: 'personal_profile_main',
  children: 'personal_profile_children',
});

export const COURIER_FORM = Object.freeze({
  type: 'courier',
});

export const ADDRESS_FORM = Object.freeze({
  type: 'address',
});

export const CANCEL_ORDER_FORM = Object.freeze({
  type: 'cancel_order',
});
