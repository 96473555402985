<script>
import { mergeData } from 'vue-functional-data-merge';

import getLinkTag from './utils/getLinkTag';

const SPAN_TAG = 'span';
const ANCHOR_TAG = 'a';
const FRAMEWORK_LINK = 'nuxt-link';

const ANCHOR_REL_ATTRIBUTE = 'noopener';

export default {
  name: 'BaseLink',
  functional: true,
  props: {
    href: {
      type: String,
      default: '',
    },
    blank: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
  },
  render(h, context) {
    const tag = getLinkTag(context.props, {
      SPAN_TAG,
      ANCHOR_TAG,
      FRAMEWORK_LINK,
    });

    const data = {
      class: 'smartLink',
    };
    switch (tag) {
      case ANCHOR_TAG:
        data.attrs = {
          href: context.props.href,
        };

        if (context.props.blank) {
          data.attrs.target = '_blank';
          data.attrs.rel = ANCHOR_REL_ATTRIBUTE;
        }

        data.on = { ...context.data.nativeOn };
        delete context.data.nativeOn;
        break;

      case FRAMEWORK_LINK:
        data.props = {
          to: context.props.href,
        };
        break;

      default:
        break;
    }

    return h(tag, mergeData(context.data, data), context.slots().default);
  },
};
</script>
