// @ts-check
import { deserialize } from 'deserialize-json-api';
import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';

export default class ContentService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  getContent(type, params) {
    return this.mainHttp.get(`/api/v1/content/lists/${type}/`, params)
      .then(({ data: { data: dataRes } }) => ({
        items: dataRes?.items || [],
        pagination: dataRes?.meta || {},
      }))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * * Возварщает информацию о баннерах
   * @param {Object} options
   * @param {string} options.type - Тип баннера (MAIN_PAGE_SLIDER_BANNERS - основной баннер на главной, MAIN_PAGE_NEAR_SLIDER - баннер справа от слайдера на главной странице, TOP_LOCATION_DELIVERY - сквозной баннер)
   * @param {string} options.url - Url страницы
   * @return {Promise<*[]>}
   */
  getBanners(options) {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/banners/', {
      type: options?.type,
      url: options?.url,
    })
      .then(({ data: dataRes }) => {
        const { data } = deserialize(dataRes);
        resolve(data);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * @typedef {Object} PicturesCdnBook24
   * @property {string|null} type
   * @property {string|null} x1
   * @property {string|null} x2
   * @property {string|null} webpX1
   * @property {string|null} webpX2
   */

  /**
   * @typedef {Object} BannerMainSlider
   * @property {string|number} id
   * @property {string} name
   * @property {string} url
   * @property {string} imageOriginalSrc
   * @property {PicturesCdnBook24} image
   */

  /**
   * Возвращает информацию о баннерах на главной странице
   * @return {Promise<BannerMainSlider[]>}
   */
  getBannersMainSlider() {
    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/banners/main/')
      .then(({ data: dataRes }) => {
        const { data } = deserialize(dataRes);
        if (!data?.length) resolve([]);
        resolve(data.map(item => ({
          ...item,
          image: formattedPicturesCdnBook24(item.images[0].src, {
            sizePicHeight: 320,
            sizePicWidth: 1100,
          }),
          imageOriginalSrc: item.images[0]?.src || null,
        })));
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }
}
