// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildCollection, buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import formattedProduct from '@/utils/formatters/formattedProduct';

export default class HighlightService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  getHighlightProducts(payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        highlightId,
        pageSize = 15,
        pageNumber = 1,
        filterPreset = '',
        filter = '',
        sortPreset = '',
        match = '',
        isGetAuthors = true,
        isGetBadge = true,
        isGetProductText = false,
        isGetPublishingHouse = false,
        isGetCategory = true,
        isGetParentCategory = false,
        isGetOrsProduct = true,
      } = payload;

      if (!highlightId) reject(new Error('[HighlightService] getHighlightProducts: highlightId не должен быть пустым'));
      if (typeof highlightId !== 'string') reject(new Error('[HighlightService] getHighlightProducts: highlightId должен быть строкой'));

      const include = [];
      if (isGetAuthors) include.push('author');
      if (isGetBadge) include.push('badge');
      if (isGetProductText) include.push('productText');
      if (isGetPublishingHouse) include.push('publishingHouse');
      if (isGetCategory) include.push('category');
      if (isGetParentCategory) include.push('category.parentCategory');
      if (isGetOrsProduct) include.push('orsProduct');

      const dataRequest = { 'per-page': pageSize, page: pageNumber };

      if (filterPreset) dataRequest.filterPreset = filterPreset;
      if (filter) dataRequest.filter = filter;
      if (sortPreset) dataRequest.sortPreset = sortPreset;
      if (match) dataRequest.match = match;
      if (include.length) dataRequest.include = include.join(',');

      return this.mainHttp.get(`/api/v1/highlight/${highlightId}/product/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          const items = list.map(item => formattedProduct(item)) || [];
          return resolve({
            items,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
