import Rus from '@/assets/sprite/svg/others/rus.svg';

export const TINKOFF_REDIRECT_URI = '/tinkoff/auth/callback';
export const TINKOFF_INITIAL_LOCATION_KEY = 'B24_TINKOFF_INITIAL_LOCATION';
export const TINKOFF_REDIRECT_URI_KEY = 'B24_TINKOFF_REDIRECT_URI';
export const TINKOFF_SUCCESS_LOGIN = 'B24_TINKOFF_SUCCESS_LOGIN';
export const TINKOFF_ERROR_LOGIN = 'B24_TINKOFF_ERROR_LOGIN';
export const AUTH_PAGE_ROUTE_NAME = 'auth-page';
export const LOGIN = 'login';
export const REGISTRATION = 'registration';
export const PROMO_CODE = 'promoCode';
export const CHECKOUT = 'checkout';
export const SUCCESS = 'success';
export const PHONE_LENGTH = 16;
export const PHONE_RUS_CODE_LENGTH = 1;
export const CODE_LENGTH = 5;
export const CODE_TIMEOUT = 300000;
export const AUTH_ISSUE_ID_KEY = 'B24_AUTH_ISSUE_ID';
export const CUSTOMER_ID_KEY = 'B24_CUSTOMER_ID';

export const ERROR_CAPTCHA_INVALID = '0edad6cc-79e8-4bd6-86fc-61acd875595f';
export const ERROR_CODE_HAS_ALREADY_BEEN_SENT = '791e4e47-8bcd-41d1-828f-f0e43796bb19';
export const ERROR_USER_BLOCKED = '584df9b9-5aff-4604-aa6f-4fd0e575aa74';
export const ERROR_ALREADY_CONFIRM = '1eb57f46-f30f-4dea-adc2-b3b84791127d';
export const ERROR_USER_ALREADY_HAVE = '56d131eb-cd48-4c86-bdc4-155bd30f42ff';
export const ERROR_EMAIL_BLOCKED = 'a66eb37f-3c61-4f60-b18a-ff1f1c0db6d4';

export const ERROR_VERIFY_CODE = [
  '6d180144-d8b4-49f5-b992-4b9acf9c6592',
  '18124a9a-fee4-4590-aa3a-3f05aba6d0c4',
  '34cf79a5-1c24-450d-84cf-689f7ccc0b93',
];

export const USER_BLOCKED_ERROR_TEXT = 'Ваша учётная запись заблокирована. Для восстановления доступа обратитесь в клиентскую службу по номеру 8 (800) 333-65-23.';

export const TINKOFF_AUTH_ERROR_TEXT = 'Авторизация через Tinkoff ID завершилась ошибкой';

export const PHONE_MACK = '+7 000 000-00-00';

export const PHONE_COUNTRIES = [
  {
    value: 'ru',
    label: 'Российская Федерация',
    icon: Rus,
    phoneMask: '00 000-00-00',
    phoneCode: '+7',
    phonePlaceholder: '00 000 000-00-00',
    phoneFirstDigit: '9',
  },
];

