// @ts-check
/// <reference path="./typedefs.js" />
import { deserialize } from 'deserialize-json-api';

import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import formattedProduct from '@/utils/formatters/formattedProduct';
import { buildCollection, buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import { ERROR_SERVER_ERROR } from '@/utils/constants/globalConst';
import { ERRORS_BASKET } from '@/services/v1/BasketService/enumsError';
import { getTargetProperties } from '@/utils/analytics/getTargetProperties';

const formattedBasketRes = dataRes => ({
  id: dataRes.id,
  quantity: dataRes.quantity,
  amount: {
    total: +dataRes.amount.total,
    discounted: +dataRes.amount.discounted,
  },
  discountAmount: {
    percent: +dataRes.discountAmount.percent,
    total: +dataRes.discountAmount.total,
  },
  weight: dataRes.weight,
  basketItem: (dataRes?.cartItem || dataRes?.basketItem || []).map(item => ({
    id: item.id,
    amount: item.amount,
    isGift: item.isGift,
    quantity: item.quantity,
    product: item.product,
    bookId: item.product?.orsProduct?.bookId,
    tbkCode: item.product?.orsProduct?.tbkCode,
    targetProperties: getTargetProperties(item?.basketItemProperty),
  })),
  basketPromoCode: dataRes?.basketPromoCode ?? null,
});

const checkBasketId = (basketId, reject) => {
  if (typeof basketId !== 'string') {
    return reject(new ServiceError(ERRORS_BASKET.basketIdNotString));
  }
  if (!basketId) {
    return reject(new ServiceError(ERRORS_BASKET.basketIdNotExist));
  }
};

const checkPromoCode = (promoCode, reject) => {
  if (typeof promoCode !== 'string') {
    return reject(new ServiceError(ERRORS_BASKET.promoCodeNotString));
  }
  if (!promoCode) {
    return reject(new ServiceError(ERRORS_BASKET.promoCodeNotExist));
  }
};

export default class BasketService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  createBasket(customerId) {
    return new Promise((resolve, reject) => {
      if (typeof customerId !== 'string') {
        return reject(new ServiceError('[BasketService:createBasket] customerId должен быть строкой'));
      }
      if (!customerId) {
        return reject(new ServiceError('[BasketService:createBasket] customerId не должен быть пустым'));
      }

      this.mainHttp.post('/api/v1/baskets/', {
        customerId,
      })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  fetchSmallBasket(basketId, options = {}) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      const { isGetBasketItem = false } = options;

      const include = [];
      if (isGetBasketItem) include.push('basketItemSmall');

      const requestData = {};
      if (include.length) {
        requestData.include = include.join(',');
      }

      this.mainHttp.get(`/api/v1/small-baskets/${basketId}/`, requestData)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  /**
   * @typedef ResBasket
   * @property {number} count - количество товаров в корзине
   * @property {Array<number | string>} productsId - идентификаторы товаров находящиеся в корзине
   */

  /**
   * Возвращает малую корзину
   * @param {string} basketId - идентификатор покупательской корзины пользователя
   * @param {Object} options
   * @param {boolean} [options.isPreOrder=false]
   * @param {boolean} [options.isGetBasketPromoCode=false]
   * @param {boolean} [options.isGetBasketItem=false]
   * @param {boolean} [options.isGetBasketItemProduct=false]
   * @return {Promise<any>}
   */
  fetchBasket(basketId, options = {}) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      const {
        isPreOrder = false,
        isGetBasketPromoCode = false,
        isGetBasketItem = false,
        isGetBasketItemProduct = false,
      } = options;

      const include = [];
      if (isGetBasketPromoCode) include.push('basketPromoCode');
      if (isGetBasketItem) include.push('basketItem');
      if (isGetBasketItemProduct) {
        include.push('basketItem.product');
        include.push('basketItem.product.series');
        include.push('basketItem.product.author');
        include.push('basketItem.product.publishingHouse');
        include.push('basketItem.product.category');
        include.push('basketItem.product.category.parentCategory');
        include.push('basketItem.product.orsProduct');
        include.push('basketItem.basketItemProperty');
      }

      const requestData = { mode: isPreOrder ? 'preOrder' : 'basket' };
      if (include.length) {
        requestData.include = include.join(',');
      }

      this.mainHttp.get(`/api/v1/baskets/${basketId}/`, requestData)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  /**
   * Добавляет товар в корзину
   * @param {Object} payload
   * @param {string} payload.basketId - идентификатор корзины
   * @param {number} payload.productId - идентификатор товара
   * @param {number} [payload.quantity=1] - кол-во экземпляров товара
   * @param {boolean} [payload.asPreOrder=false] - товар как предзаказ
   * @param {boolean} [payload.asGift=false] - товар как подарок
   * @param {TargetProps} [payload.targetProperties] - параметры полки
   * @param {boolean} [payload.isGetBasketItem=false] - нужно ли получать элементы корзины
   * @param {boolean} [payload.isGetBasketItemProduct=false] - нужно ли получать товары элементов корзины
   * @return {Promise<any>}
   */
  addToBasket({
    basketId,
    productId,
    quantity = 1,
    asPreOrder = false,
    asGift = false,
    isGetBasketItem = false,
    isGetBasketItemProduct = false,
    targetProperties,
  }) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);
      const include = [];
      if (isGetBasketItem) include.push('basketItem');
      if (isGetBasketItemProduct) {
        include.push('basketItem.product');
        include.push('basketItem.product.series');
        include.push('basketItem.product.author');
        include.push('basketItem.product.publishingHouse');
        include.push('basketItem.product.category');
        include.push('basketItem.product.category.parentCategory');
        include.push('basketItem.product.orsProduct');
      }

      let url = `/api/v1/baskets/${basketId}/item/`;
      if (include.length) {
        url += `?include=${include.join(',')}`;
      }

      this.mainHttp.post(url, {
        basketId,
        productId,
        quantity,
        asPreOrder,
        asGift,
        parameters: targetProperties,
      })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  updateBasketItem({ basketId, itemId, quantity }) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      this.mainHttp.put(`/api/v1/baskets/${basketId}/item/${itemId}/`, {
        quantity,
      })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  deleteBasketItem({ basketId, itemId }) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      this.mainHttp.delete(`/api/v1/baskets/${basketId}/item/${itemId}/`)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  addToBasketItems({ basketId, items = [] }) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      if (!Array.isArray(items) || !items.length) {
        return resolve();
      }

      this.mainHttp.post(`/api/v1/baskets/${basketId}/items/`, { items })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  deleteBasketItems({ basketId, basketIdItems = [] }) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      this.mainHttp.delete(`/api/v1/baskets/${basketId}/items/`, { ids: basketIdItems })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  syncBasket(basketId, options = {}) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      const {
        isPreOrder = false,
        isAddFirstGift = false,
        isGetBasketPromoCode = false,
        isGetBasketItem = false,
        isGetBasketItemProduct = false,
      } = options;

      const include = [];
      if (isGetBasketPromoCode) include.push('basketPromoCode');
      if (isGetBasketItem) include.push('basketItem');
      if (isGetBasketItemProduct) {
        include.push('basketItem.product');
        include.push('basketItem.product.series');
        include.push('basketItem.product.author');
        include.push('basketItem.product.publishingHouse');
        include.push('basketItem.product.category');
        include.push('basketItem.product.category.parentCategory');
        include.push('basketItem.product.productUserStats');
        include.push('basketItem.product.orsProduct');
        include.push('basketItem.basketItemProperty');
      }

      let url = `/api/v1/baskets/${basketId}/sync/?mode=${isPreOrder ? 'preOrder' : 'basket'}&giftStrategy=${isAddFirstGift ? 'addFirstPotential' : 'none'}`;
      if (include.length) {
        url += `&include=${include.join(',')}`;
      }

      this.mainHttp.post(url)
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  /**
   * Применение промокода к корзине покупателя
   * @param {Object} payload
   * @param {string} payload.basketId
   * @param {string} payload.promoCode
   * @return {Promise<unknown>}
   */
  applyPromoCode({ basketId, promoCode }) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);
      checkPromoCode(promoCode, reject);

      this.mainHttp.post(`/api/v1/baskets/${basketId}/promo-code/?include=basketPromoCode`, { promoCode })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;

            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  /**
   * Удаление промокода из корзины покупателя
   * @param {Object} payload
   * @param {string} payload.basketId
   * @param {string} payload.promoCode
   * @return {Promise<unknown>}
   */
  removePromoCode({ basketId, promoCode }) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);
      checkPromoCode(promoCode, reject);

      this.mainHttp.delete(`/api/v1/baskets/${basketId}/promo-code/`, { promoCode })
        .then(({ data: { data: dataRes } }) => {
          const { data } = deserialize(dataRes);
          resolve(formattedBasketRes(data));
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;

            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }

  /**
   * Получает рекомендации корзины
   * @param {string} basketId
   * @param {Object} payload
   * @param {number} [payload.pageSize=15] - Количество товаров
   * @param {boolean} [payload.isGetAuthors=true] - Нужно ли получить автора товара
   * @param {boolean} [payload.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [payload.isGetCategory=true] - Нужно ли получить категорию товара
   * @param {boolean} [payload.isGetOrsProduct=true] - Нужно ли получить bookId и codeTbk
   * @param {boolean} [payload.isGetPublishingHouse=false] - Нужно ли получить информацию об издательстве
   * @return {Promise<unknown>}
   */
  getRecommendation(basketId, payload = {}) {
    return new Promise((resolve, reject) => {
      checkBasketId(basketId, reject);

      const {
        pageSize = 15,
        isGetAuthors = true,
        isGetBadge = true,
        isGetCategory = true,
        isGetOrsProduct = true,
        isGetPublishingHouse = true,
      } = payload;

      const include = [];
      if (isGetAuthors) include.push('author');
      if (isGetBadge) include.push('badge');
      if (isGetCategory) include.push('category');
      if (isGetOrsProduct) include.push('orsProduct');
      if (isGetPublishingHouse) include.push('publishingHouse');

      const dataRequest = { 'per-page': pageSize, include: include.join(',') };

      this.mainHttp.get(`/api/v1/recommendation-basket/${basketId}/`, dataRequest)
        .then(({ data: dataRes }) => {
          const { list } = buildCollection(dataRes, true);
          const items = list.map(item => formattedProduct(item)) || [];

          return resolve(items);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 500) {
              return reject(new ServiceError(ERROR_SERVER_ERROR));
            }

            const errors = buildErrorCollection(error.response.data, ERRORS_BASKET);
            const [firstError] = errors;

            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(error));
        });
    });
  }
}
