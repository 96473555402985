<script>
import AppSection from '@/components/AppSection/desktop.vue';

export default {
  functional: true,
  render(createElement, context) {
    return createElement(
      AppSection,
      {
        ...context.data,
        class: ['_mobile'],
      },
      context.children,
    );
  },
};
</script>

<style lang="less">
.app-section {
  &._mobile._is-container {
    .container-m;
  }

  &._mobile {
    padding-top: 0;
    padding-bottom: @size-x5;
  }

  &._mobile &__more-link {
    .p;

    line-height: 25px;
    padding-top: @size-x3;
  }

  &._mobile &__title {
    .h2-mobile;

    padding-top: @size-x3;
  }

  &._mobile &__header {
    margin-bottom: @size-x2;
    padding-right: @size-x4;
    padding-left: @size-x4;
  }
}
</style>
