<template>
  <article
    :class="{
      _homepage: isHomepage,
      _unchecked: !isChecked,
      _bundle: isAlsoPurchased
    }"
    class="product-card _small"
    data-b24-product-card
    :data-b24-id="getProductProperty(product, productProperty.id)"
    :data-b24-base-id="getProductProperty(product, productProperty.baseId)"
    :data-b24-price="getProductProperty(product, productProperty.price)"
    :data-b24-name="getProductProperty(product, productProperty.name)"
    :data-b24-brand="getProductProperty(product, productProperty.brand)"
    :data-b24-discount="getProductProperty(product, productProperty.discount)"
    :data-b24-category="getProductProperty(product, productProperty.category)"
    :data-b24-available="getProductProperty(product, productProperty.available)"
    :data-b24-preorder="getProductProperty(product, productProperty.preorder)"
    :data-b24-index="productIndex"
    :data-b24-item-list-name="$route.name"
  >
    <checkbox-control
      v-if="showCheckbox"
      :checked="isChecked"
      :disabled="isDisabled && isChecked"
      class="product-card__checkbox"
      @change="handlerChange"
    />
    <div class="product-card__image-holder">
      <product-card-link
        v-if="!isAlsoPurchased"
        :link="link"
        :title="product.name"
        :handler="handlerClickProduct"
        class="product-card__image-link"
      >
        <app-picture
          :alt="`Купить ${product.name} — Фото №1`"
          :fallback-image="product.fallbackImage"
          :item="product.images"
          :is-adult="!ageIsConfirmed && product.isAdult"
          :show-adult-badge="!ageIsConfirmed && product.isAdult"
          class-img="product-card__image _adult"
          class-picture="product-card__picture"
        />
      </product-card-link>
      <div
        v-else
        @click="openModal"
      >
        <app-picture
          :alt="`Купить ${product.name} — Фото №1`"
          :fallback-image="product.fallbackImage"
          :item="product.images"
          :is-adult="!ageIsConfirmed && product.isAdult"
          :show-adult-badge="!ageIsConfirmed && product.isAdult"
          class-img="product-card__image"
          class-picture="product-card__picture"
        />
      </div>
    </div>
    <div class="product-card__content">
      <div v-if="product.discountPercent > 0" class="product-card__badges">
        <card-badge
          :percent="product.discountPercent"
          class="product-card__badge"
        />
      </div>
      <component
        :is="isNormalFont ? 'CardPriceNormal' : 'CardPrice'"
        v-if="product.isAvailable"
        class="product-card__price"
        :price="product.prices.price"
        :old-price="product.prices.base"
        :discount="product.discountPercent"
        :show-discount="false"
      />
    </div>
  </article>
</template>

<script>
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

import { CheckboxControl } from '@book24/ui-components';

import useProductCard from '@/compositions/catalog/useProductCard';
import { useAdultContext } from '@/compositions/adult/adultStorage';

import CardPrice from '@/components/ProductCard/CardPrice/index.vue';
import CardPriceNormal from '@/components/ProductCard/CardPrice/CardPriceNormal/index.vue';
import AppPicture from '@/components/AppPicture/index.vue';
import CardBadge from '@/components/ProductCardBadge';
import ProductCardLink from '@/components/ProductCard/ProductCardLink/index.vue';

export default defineComponent({
  name: 'ProductCardSmall',
  components: {
    CheckboxControl,
    AppPicture,
    CardPrice,
    CardPriceNormal,
    CardBadge,
    ProductCardLink,
  },
  props: {
    product: { type: Object, required: true },
    isShowShields: { type: Boolean, default: false },
    isNormalFont: { type: Boolean, default: false },
    isHomepage: { type: Boolean, default: false },
    paramsLink: { type: Object, default: () => ({}) },
    showCheckbox: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isAlsoPurchased: { type: Boolean, default: false },
    productIndex: { type: Number, default: 1 },
  },
  setup(props, context) {
    const { ageIsConfirmed } = useAdultContext();
    const productCardComposition = useProductCard(props, context);

    const showBadge = computed({
      get: () => Boolean(props.product?.badge?.percent > 0 || props.product?.badge?.icon),
      set: () => {
      },
    });

    const isChecked = ref(true);

    const handlerChange = (value) => {
      isChecked.value = value;

      const payload = {
        product: props.product,
        value,
      };

      context.emit('change', payload);
    };

    const openModal = () => {
      context.emit('open-modal', props.product);
    };

    return {
      ...productCardComposition,
      showBadge,
      isChecked,
      ageIsConfirmed,
      handlerChange,
      openModal,
    };
  },
});
</script>

<style lang="less">
@import "../../assets/styles/blocks/product-card/index";

.product-card {
  &._small {
    width: 110px;
    height: 190px;
  }

  &._bundle {
    height: auto;
  }

  &._homepage {
    height: 240px;
  }

  &._small &__image-link {
    position: relative;
    height: 150px;
  }

  &._homepage &__image-link {
    height: 200px;
  }

  &__checkbox {
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 2;
    background: @white;
    width: @size-x6;
    border-radius: @size-x1;

    .b24-checkbox-control__control:disabled ~ .b24-checkbox-control__inner .b24-checkbox-control__indicator-icon {
      fill: @gray-dark;
    }
  }

  &._unchecked & {
    &__image,
    &__price {
      opacity: 0.3;
    }

    &__picture._adult {
      .product-card__image {
        opacity: 1;
      }
    }
  }
}
</style>
