export const redirectUrl = (path) => {
  if (path.endsWith('/index.php')) {
    return path.replace('/index.php', '/');
  }

  if (!path.endsWith('/')) {
    return `${path}/`;
  }

  if (path.includes('/page-1/')) {
    return path.replace('/page-1/', '/');
  }

  if (path.endsWith('/?')) {
    return path.slice(0, -1);
  }

  return path;
};

export default function trailingSlashFunc(context) {
  const { route, redirect } = context;
  const path = redirectUrl(route.path);

  if (path !== route.path) {
    if (process.server) {
      return redirect(301, { path, params: route.params, query: route.query });
    }
    // TODO: тут нужно придумать как на клиентской части добавлять символ / в конец пути
    return true;
  }
  return true;
}
