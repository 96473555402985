export const PROP_TYPES = {
  id: 'id',
  baseId: 'baseId',
  price: 'price',
  name: 'name',
  brand: 'brand',
  discount: 'discount',
  category: 'category',
  available: 'available',
  preorder: 'preorder',
  index: 'index',
  itemListName: 'itemListName',
  shelfName: 'shelfName',
  shelfIndex: 'shelfIndex',
};

/**
 * Получаем объект продукта и ключ параметра
 * которое нужно получить, возвращаем необходимый параметр
 * @param product
 * @param type
 * @param fallback
 */
export const getProductProperty = (product, type, fallback) => {
  if (type === PROP_TYPES.id) {
    if (product?.bookId) return product.bookId;
    if (product?.orsProduct?.bookId) return product?.orsProduct?.bookId;
    if (product?.id) return product.id;
    return 0;
  }

  if (type === PROP_TYPES.baseId) {
    return product?.id;
  }

  if (type === PROP_TYPES.price) {
    if (product?.prices) {
      const discountPrice = product?.prices?.price;
      const basePrice = product?.prices?.base;
      return discountPrice || basePrice;
    }

    if (product?.amount) {
      const discountPrice = product?.amount?.discounted;
      const basePrice = product?.amount?.total;
      return discountPrice || basePrice;
    }

    if (product?.price) return product.price;

    return 0;
  }

  if (type === PROP_TYPES.name) {
    return product?.name || '';
  }

  if (type === PROP_TYPES.brand) {
    if (product?.brand) return product.brand;
    return product?.publishingHouse?.name || '';
  }

  if (type === PROP_TYPES.discount) {
    if (product?.prices) {
      const discountPrice = product?.prices?.price;
      const basePrice = product?.prices?.base;
      return basePrice - discountPrice;
    }

    if (product?.amount) {
      const discountPrice = product?.amount?.discounted;
      const basePrice = product?.amount?.total;
      return basePrice - discountPrice;
    }

    if (product?.discount) return product.discount;

    return 0;
  }

  if (type === PROP_TYPES.category) {
    if (product?.category?.name) return product?.category?.name || '';
    if (product?.category) return product.category || '';
  }

  if (type === PROP_TYPES.available) {
    return product?.isAvailable;
  }

  if (type === PROP_TYPES.preorder) {
    return product?.isPreOrder;
  }

  // Здесь вычитаем так как ранее добавляли + 1
  if (type === PROP_TYPES.index) {
    return product?.index - 1 || fallback;
  }

  if (type === PROP_TYPES.itemListName) {
    return product?.itemListName || fallback;
  }

  if (type === PROP_TYPES.shelfName) {
    return product?.productShelf || false;
  }

  if (type === PROP_TYPES.shelfIndex) {
    return product?.indexShelf || false;
  }

  return 'Параметр не найден';
};
