// @ts-check
/// <reference path="../CatalogService/typedefs.js" />
/// <reference path="./typedefs.js" />

import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';

import formattedProduct from '@/utils/formatters/formattedProduct';
import formattedPageBreadcrumbs from '@/utils/formatters/formattedPageBreadcrumbs';
import { buildErrorCollection, buildCollection, buildElement } from '@/utils/formatters/processingOpenApi';

export default class TagService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получение списка товаров
   * @param {TagProductsDataRequest} data
   * @param {string} route - путь к получению списка товаров
   * @param {string} tag - символьный код тега
   * @return {Promise<ProductList>}
   */
  getProducts(data = {}, tag, route) {
    return new Promise((resolve, reject) => {
      const {
        match = '',
        filterPreset = '',
        filter = '',
        sortPreset = '',
        pageSize = 15,
        pageNumber = 1,
        getQuotes = '',
        isGetAuthors = true,
        isGetSeries = false,
        isGetBadge = true,
        isGetPublishingHouse = false,
        isGetCategory = true,
        isGetParentCategory = false,
        isGetOrsProduct = true,
      } = data;

      if (!tag) return reject(new ServiceError('[TagService:getProducts] обязательный параметр tag отсутствует'));
      if (typeof tag !== 'string') return reject(new ServiceError('[TagService:getProducts] параметр tag должен быть строкой'));

      const include = [];
      if (isGetAuthors) include.push('author');
      if (isGetSeries) include.push('series');
      if (isGetBadge) include.push('badge');
      if (getQuotes) include.push(getQuotes);
      if (isGetPublishingHouse) include.push('publishingHouse');
      if (isGetCategory) include.push('category');
      if (isGetParentCategory) include.push('category.parentCategory');
      if (isGetOrsProduct) include.push('orsProduct');

      let dataRequest = { 'per-page': pageSize, page: pageNumber };

      if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
      if (filter) dataRequest = { ...dataRequest, filter };
      if (sortPreset) dataRequest = { ...dataRequest, sortPreset };
      if (match) dataRequest = { ...dataRequest, match };
      if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

      this.mainHttp.get(`/api/v1/${route}/${tag}/product/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          const items = list.map(item => formattedProduct(item)) || [];
          return resolve({
            items,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(e);
        });
    });
  }

  /**
   * Получение списка товаров для теговой страницы
   * @param {TagProductsDataRequest} data
   * @param {string} tag - символьный код тега
   * @return {Promise<ProductList>}
   */
  getProductTagPage(data = {}, tag) {
    const route = 'page-tag';
    return this.getProducts(data, tag, route);
  }

  /**
   * Получение списка товаров по тегам
   * @param {TagProductsDataRequest} data
   * @param {string} tag - символьный код тега
   * @return {Promise<ProductList>}
   */
  getProductByTagPage(data = {}, tag) {
    const route = 'tag';
    return this.getProducts(data, tag, route);
  }

  /**
   * Возвращает информацию о тегах
   * @param {Object} payload
   * @param {string} [payload.tag=''] - Символьный код тега
   * @param {boolean} [payload.isGetBreadcrumbs=''] - получение хлебных крошек
   * @param {boolean} [payload.isGetSeoMeta=''] - получение данных сео
   * @return {Promise<TagPageInfo>}
   */
  getTagPage(payload = {}) {
    const {
      tag = '',
      isGetBreadcrumbs = true,
      isGetSeoMeta = true,
    } = payload;
    let dataRequest = null;
    const include = [];

    if (isGetBreadcrumbs) include.push('breadcrumbs');
    if (isGetSeoMeta) include.push('seoMeta');

    if (include.length) dataRequest = { include: include.join(',') };

    return new Promise((resolve, reject) => {
      if (!tag) return reject(new ServiceError('[TagService:getTagPage] обязательный параметр tag отсутствует'));
      if (typeof tag !== 'string') return reject(new ServiceError('[TagService:getTagPage] параметр tag должен быть строкой'));

      this.mainHttp.get(`/api/v1/page-tag/${tag}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const formattedDataRes = buildElement(dataRes);
          return resolve({
            id: formattedDataRes.id,
            name: formattedDataRes.name,
            count: formattedDataRes.count,
            tag: formattedDataRes.tag,
            breadcrumbs: formattedPageBreadcrumbs(formattedDataRes?.relationships?.breadcrumbs) || [],
            seoMeta: formattedDataRes?.relationships?.seoMeta || undefined,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(e);
        });
    });
  }

  getTageInfo(tag) {
    if (!tag) return Promise.reject(new ServiceError('[TagService:getTageInfo] обязательный параметр tag отсутствует'));
    if (typeof tag !== 'string') return Promise.reject(new ServiceError('[TagService:getTageInfo] параметр tag должен быть строкой'));
    return new Promise((resolve, reject) => {
      this.mainHttp.get(`/api/v1/tag/${tag}/`)
        .then(({ data: { data: dataRes } }) => {
          const formattedDataRes = buildElement(dataRes);
          return resolve(formattedDataRes);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(e);
        });
    });
  }
}
