<template>
  <div
    :class="{ _adult: isAdult }"
    class="product-card-info"
  >
    <app-link
      v-if="product.link && !isAdult"
      :href="product.link"
      class="product-card-info__image-link"
    >
      <app-picture
        :item="product.images"
        class-img="product-card-info__image"
        :alt="product.name"
        :is-adult="checkIsAdult && product.isAdult"
      />
    </app-link>
    <div v-else class="product-card-info__image-wrapper">
      <app-picture
        :item="product.images"
        class-img="product-card-info__image"
        :alt="product.name"
        :is-adult="checkIsAdult && product.isAdult"
      />
    </div>
    <div class="product-card-info__content">
      <app-link v-if="product.link && !isAdult" :href="product.link" class="product-card-info__content-link">
        {{ product.name }}
      </app-link>
      <span v-else class="product-card-info__content-link">
        {{ product.name }}
      </span>
      <download-button
        v-if="isShowDownload && !isAdult"
        :links="product.certificatesList"
        :images="product.imagesLarge"
        type="secondary"
        size="small"
        is-block
        class="product-card-info__certificate"
      >
        {{ downloadButtonText }}
      </download-button>
      <author-list
        :author-list="product.authorList"
        :is-adult="isAdult"
      />
      <span
        v-if="product.isLastBadge"
        class="product-card-info__content-last"
      >
        Последний экземпляр
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';

import {
  DOWNLOAD_CERTIFICATES_TEXT,
  DOWNLOAD_CERTIFICATE_TEXT,
} from '@/utils/constants/certificateConstants';

import useAdult from '@/compositions/adult/useAdult';

import AppPicture from '@/components/AppPicture';
import AuthorList from '@/components/ProductCard/AuthorList';
import DownloadButton from '@/components/DownloadButton';

export default {
  name: 'ProductCardInfo',
  components: {
    AuthorList,
    AppPicture,
    DownloadButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isAdult: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { checkIsAdult } = useAdult();

    const isShowDownload = computed({
      get: () => (props.product.isCertificate && props.isCompleted && props.product.certificatesList?.length),
      set: () => {},
    });

    const downloadButtonText = computed(() => {
      if (props.product.certificatesList?.length === 1) return DOWNLOAD_CERTIFICATE_TEXT;
      return DOWNLOAD_CERTIFICATES_TEXT;
    });

    return {
      isShowDownload,
      downloadButtonText,
      checkIsAdult,
    };
  },
};
</script>

<style lang="less">
.product-card-info {
  display: flex;

  &__image-link,
  &__image-wrapper {
    position: relative;
    margin-right: @size-x4;
  }

  &__image {
    flex-shrink: 0;
    width: 40px;
  }

  &__content-link {
    .small2();
  }

  &__content-last {
    .small2();

    color: @red-error;
  }

  &__certificate {
    margin-top: @size-x2;
  }

  &._adult & {
    &__image {
      width: 60px;
    }

    &__content-link {
      .p;
    }
  }
}
</style>
