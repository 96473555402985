/**
 * Невалидный адрес электронной почты при проверке email на доступность
 * @type {string}
 */
export const NO_VALID_EMAIL_AVAILABILITY = 'e1420a46-7bf6-44cf-8723-7e57e4925236';

export const ERROR_CAPTCHA = Object.freeze({
  notStringCaptchaToken: 'CaptchaToken должен быть строкой',
  emptyCaptchaToken: 'CaptchaToken не должен быть пустым',
  notStringCaptchaAction: 'CaptchaAction должен быть строкой',
  emptyCaptchaAction: 'CaptchaAction не должен быть пустым',
  notStringCaptchaVersion: 'CaptchaVersion должен быть строкой',
  emptyCaptchaVersion: 'CaptchaVersion не должен быть пустым',
});

export const ERROR_CALL_ORDER = Object.freeze({
  notStringPhone: 'Поле Телефон должен быть строкой',
  emptyPhone: 'Поле Телефон не должен быть пустым',
});

export const ERROR_USER_AUTHORIZED = Object.freeze({
  notVisitorId: 'Параметр visitorId отсутствует',
  notAuthIssueId: 'Параметр id заявки отсутствует',
  notVerificationCode: 'Параметр code отсутствует',
  notEmail: 'Поле Email не должно быть пустым',
  notName: 'Поле Имя не должно быть пустым',
  notLastName: 'Поле Фамилия не должно быть пустым',
  notSubscribed: 'Параметр subscribed отсутствует',
});

export const ERROR_USER_UPDATE = Object.freeze({
  notUserId: 'Параметр userId отсутствует',
  notEmail: 'Поле Email не должно быть пустым',
  notName: 'Поле Имя не должно быть пустым',
  notLastName: 'Поле Фамилия не должно быть пустым',
});

export const USER_SUBSCRIPTIONS = Object.freeze({
  subscribedAll: 'Вы подписаны на email-рассылки book24.ru',
  unsubscribedAll: 'Вы отписаны от email-рассылок book24.ru',
});
