/**
 * В старых статьях есть html в которых встроены
 * различные скрипты (тесты), удаляем их из html.
 * @param html
 * @returns {*}
 */
export const removeScripts = (html) => {
  if (html.includes('<script>')) {
    const startScript = html.indexOf('<script>');
    const endScript = html.indexOf('</script>') + 9;
    const script = html.substring(startScript, endScript);
    const htmlWithoutScript = html.replace(script, '');

    return removeScripts(htmlWithoutScript);
  }

  return html;
};

/**
 * В старых статьях есть html в которых встроены
 * различные скрипты (тесты), для того чтобы загрузить
 * скрипты после того как целевые элементы будут встроены в DOM,
 * собираем скрипты в массив и выполняем после.
 * @param html
 * @param scripts
 * @returns {*}
 */
export const getScriptsSrc = (html, scripts = []) => {
  if (html.includes('<script>')) {
    const startScript = html.indexOf('<script>');
    const endScript = html.indexOf('</script>') + 9;
    const startSrc = html.indexOf('https://');
    const endSrc = html.indexOf('.js') + 3;
    const script = html.substring(startScript, endScript);
    const scriptSrc = html.substring(startSrc, endSrc);
    const htmlWithoutScript = html.replace(script, '');

    scripts.push(scriptSrc);
    return getScriptsSrc(htmlWithoutScript, scripts);
  }

  return scripts;
};
