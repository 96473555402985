import { computed, provide, useStore } from '@nuxtjs/composition-api';
import { captureException } from '@sentry/browser';

import useHttpClient from '@/compositions/useHttpClient';

const useUserSession = () => {
  const store = useStore();
  const httpClient = useHttpClient();

  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {
    },
  });

  const user = computed({
    get: () => store.state.user,
    set: () => {},
  });

  const updateUserSession = async () => {
    if (isAuthorized.value) {
      await httpClient.user.getUserSessions({
        isGetUser: true,
        isGetUserLoyalty: true,
        isGetUserStats: true,
        isGetUserSubscription: true,
        isGetUserSubscriptionRubric: true,
        isGetUserChildren: true,
        isGetUserSubscriber: true,
      })
        .then((userInfo) => {
          store.dispatch('setUser', userInfo);

          const countRatings = userInfo?.relationships?.userStats?.countRatings ?? 0;
          const countAttracted = userInfo?.relationships?.userStats?.countAttractedForProducts ?? 0;
          const totalCount = countRatings + countAttracted;
          store.dispatch('setUserCountRatings', totalCount);

          const subscriptionArr = userInfo?.relationships?.subscription ?? [];
          store.dispatch('setIsUserSubscribed', !!subscriptionArr.length ?? false);
        })
        .catch(error => captureException(error));
    }
  };
  provide('updateUserSession', updateUserSession);

  return { user, updateUserSession };
};

export default useUserSession;
