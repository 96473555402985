<template>
  <app-section
    :is-tag-title="isTagTitle"
    :title="title"
    :more-link="moreLink"
    :is-container="isContainer"
    :badge="badge"
    :data-b24-shelf-name="title"
  >
    <product-slider
      :products="mappedProducts"
      :show-buttons="showButtons"
      :shelf-name="title"
      :is-fetch-basket-after-add-to-basket="isFetchBasketAfterAddToBasket"
      :is-loading-fetch-basket="isLoadingFetchBasket"
      :loading-product-id="loadingProductId"
      @addToBasket="handlerClickAddToBasket"
    />
  </app-section>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

import AppSection from '@/components/AppSection';
import ProductSlider from '@/components/ProductSlider';

export default defineComponent({
  name: 'ShelfProducts',
  components: {
    AppSection,
    ProductSlider,
  },
  props: {
    products: { type: Array, default: () => ([]) },
    title: { type: String, default: '' },
    shelfId: { type: String, default: '' },
    moreLink: { type: Object, default: () => ({}) },
    paramsLink: { type: Object, default: () => ({}) },
    showButtons: { type: Boolean, default: true },
    isContainer: { type: Boolean, default: true },
    isTagTitle: { type: String, default: 'h2' },
    isFetchBasketAfterAddToBasket: { type: Boolean, default: true },
    isLoadingFetchBasket: { type: Boolean, default: false },
    badge: { type: String, default: '' },
  },
  setup(props, { emit }) {
    let currentProductId = null;

    const mappedProducts = computed({
      get: () => props.products.map(el => ({
        ...el,
        paramsLink: props.paramsLink,
      })),
      set: () => {},
    });

    const loadingProductId = computed({
      get: () => (props.isLoadingFetchBasket ? currentProductId : null),
      set: () => {},
    });

    const handlerClickAddToBasket = (payload) => {
      currentProductId = payload.productId;
      emit('addToBasket', payload);
    };

    return {
      loadingProductId,
      mappedProducts,
      handlerClickAddToBasket,
    };
  },
});
</script>
