/// <reference path="../../typedefs.js" />

export default class ServiceError extends Error {
  /**
   * @param {string|ErrorObject|Error} args
   */
  constructor(args) {
    let message;
    let code;
    let payload;
    if (args instanceof Error) {
      message = args.message;
      code = '0';
    } else if (typeof args === 'string') {
      message = args;
      code = '0';
    } else if (args.message && args.code) {
      message = args.message;
      code = args.code;

      if (args.payload) payload = args.payload;
    }
    super(message);
    this.name = 'AppServiceError';
    this.code = code;
    this.payload = payload;
  }
}
