import { checkMobile } from '@/utils/deviceCheck';

export default () => {
  const target = document.body;

  const config = {
    childList: true,
  };

  const removeStyle = (ageLimit) => {
    const styles = document.querySelectorAll('style');
    const height = ageLimit ? 60 : 38;
    // eslint-disable-next-line no-restricted-syntax
    for (const style of styles) {
      if (style.innerHTML.indexOf(`html { margin-top: ${height}px !important; }`) === 0) {
        style.remove();
      }
    }
  };

  const handler = (mutationsList) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const mutation of mutationsList) {
      if (mutation.addedNodes.length > 0) {
        if (mutation.addedNodes[0].tagName === 'DIV') {
          const mayBeAdvisor = mutation.addedNodes[0];
          const buttons = mayBeAdvisor.querySelectorAll('div > div > div > div > span[title]');
          const indexCloseButton = buttons.length - 1;
          const ageLimit = indexCloseButton === 3;
          if (buttons.length !== 0) {
            const closeButtonTitle = buttons[indexCloseButton].getAttribute('title').replace('&zwnj;', '');
            const found = closeButtonTitle.includes('ь');
            if (found) {
              mayBeAdvisor.remove();
              removeStyle(ageLimit);

              setTimeout(() => {
                this.disconnect();
              }, 2000);
            }
          }
        }
      }
    }
  };

  if (window.location.pathname.includes('product') && !checkMobile()) {
    const observer = new MutationObserver(handler);
    observer.observe(target, config);
  }
};
