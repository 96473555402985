<template>
  <div
    :class="{ _mobile: isMobile }"
    class="authorization-form"
  >
    <login
      v-if="isLogin"
      :is-mobile="isMobile"
    />
    <registration
      v-if="isRegistration"
      :is-mobile="isMobile"
    />
    <success
      v-if="isSuccess"
      :is-mobile="isMobile"
      @onClickButton="closeAuthorizationModal"
    />
    <promo-code
      v-if="isPromoCode"
      :is-mobile="isMobile"
      @onClickButton="closeAuthorizationModal"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import useAuthorization from '@/compositions/authorization/useAuthorization';

import Login from '@/components/Authorization/Login/index.vue';
import Registration from '@/components/Authorization/Registration/index.vue';
import Success from '@/components/Authorization/Success/index.vue';
import PromoCode from '@/components/PromoCode/index.vue';

export default defineComponent({
  name: 'AuthorizationForm',
  components: {
    PromoCode,
    Login,
    Registration,
    Success,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { ...useAuthorization() };
  },
});
</script>

<style lang="less">
.authorization-form {
  width: 356px;
  box-sizing: content-box;
  padding: @size-x8;

  .b24-input-select-control._large .b24-input-select-control__button {
    padding-left: 0;
  }

  .b24-input-select-control__result-element {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  &._mobile {
    width: auto;
    max-width: 100%;
    padding: @size-x10 @size-x4 @size-x4;
  }

  .b24-btn._more {
    width: auto;
    margin: 0 auto;

    &._left {
      margin: 0;
    }

    &._mb {
      margin-bottom: @size-x8;
    }
  }

  .b24-input-control__placeholder {
    line-height: normal;
  }
}
</style>
