import { MNOGORU_COUPON_LENGTH } from '@/utils/constants/globalConst';
import {
  MAX_LENGTH_REVIEW,
  MIN_LENGTH_REVIEW,
} from '@/utils/constants/productDetailConstants';

export default (field, customMessage = '') => {
  let message = '';

  if (field.emailValidator?.invalid
    || field.email?.$invalid
    || field.checkDomain?.$invalid
    || field.emailValidator?.$invalid
  ) {
    message = 'Введите корректный Email';
  }

  if (field.firstEmailSymbols?.$invalid) {
    message = 'Введите корректный Email';
  }

  if (field.checkCyrr?.$invalid || field.notCyrillic?.$invalid) {
    message = 'В Email не допускаются русские буквы';
  }

  if (field.checkName?.$invalid) {
    message = 'Допускаются только символы A-Z, a-z, А-Я, а-я, -';
  }

  if (field.nameIsNotReader?.$invalid) {
    message = 'Проверьте правильность данных';
  }

  if (field.checkLength?.$invalid) {
    message = 'Слишком мало символов';
  }

  if (field.personName?.$invalid) {
    message = 'Допускаются только символы A-Z, a-z, А-Я, а-я, -, каждая часть имени должна быть длиннее 1 символа';
  }

  if (field.isAllowedSymbolsFullName?.$invalid) {
    message = 'Допускаются только символы A-Z, a-z, А-Я, а-я, -';
  }

  if (field.isMinLengthSymbolsFullName?.$invalid) {
    message = 'Каждая часть ФИО должна быть длиннее 1 символа';
  }

  if (field.checkPhone?.$invalid) {
    message = 'Некорректный номер телефона';
  }

  if (field.checkPhoneWithCode?.$invalid) {
    message = 'Поле обязательно для заполнения';
  }

  if (field.checkMinRegistrationName?.$invalid) {
    message = 'Поле должно содержать не менее 2 символов';
  }

  if (field.checkMaxRegistrationName?.$invalid) {
    message = 'Поле должно содержать не более 50 символов';
  }

  if (field.checkRegistrationName?.$invalid) {
    message = 'Допускаются символы A-Z, a-z, А-Я, а-я, —, –, -';
  }

  if (field.checkOldNames?.$invalid) {
    message = 'Проверьте корректность заполнения данных в личном кабинете';
  }

  if (field.checkFullName?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Необходимо указать фамилию и имя';
  }

  if (field.checkSecondName?.$invalid) {
    message = 'Необходимо указать отчество';
  }

  if (field.required?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Поле обязательно для заполнения';
  }

  if (field.numeric?.$invalid) {
    message = 'Допускаются только цифры';
  }

  if (field.street?.$invalid) {
    message = 'Допускаются только символы A-Z, a-z, А-Я, а-я, 0-9, -';
  }

  if (field.house?.$invalid) {
    message = 'Допускаются только цифры или буквы';
  }

  if (field.minAgeChild?.$invalid) {
    message = 'Укажите корректную дату рождения';
  }

  if (field.minAge?.$invalid) {
    message = 'Мы не продаём товары лицам, не достигшим возраста 18 лет';
  }

  if (field.maxAge?.$invalid) {
    message = 'Укажите корректную дату рождения';
  }

  if (field.correctAgeRange?.$invalid) {
    message = 'Укажите корректную дату рождения';
  }

  if (field.maxLength?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Значение должно содержать от 10 до 20 символов';
  }

  if (field.maxLengthName?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Значение должно содержать до 25 символов';
  }

  if (field.minLength?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Значение должно содержать от 10 до 20 символов';
  }

  if (field.innValidator?.$invalid) {
    message = 'ИНН должен состоять из 10 или 12 цифр';
  }

  if (field.kppValidator?.$invalid) {
    message = 'Введите 9 цифр для ООО, оставьте пустым для ИП';
  }

  if (field.bikValidator?.$invalid) {
    message = 'Поле должно состоять из 9 цифр';
  }

  if (field.account?.$invalid) {
    message = 'Поле должно состоять из 20 цифр';
  }

  if (field.checkIndex?.$invalid) {
    message = customMessage !== '' ? customMessage : `
      Доставка Почтой России осуществляется по индексу.
      Индекс появится автоматически при заполнении адреса доставки.
    `;
  }

  if (field.address?.$invalid) {
    message = 'Поле адрес обязательно для заполнения';
  }

  if (field.checkHouse?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Необходимо ввести номер дома';
  }

  if (field.street?.$invalid) {
    message = 'Необходимо ввести название улицы';
  }

  if (field.number?.$invalid || field.checkBlock?.$invalid || field.checkApartment?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Значение должно быть не более 15 символов';
  }

  if (field.organizationNameLength?.$invalid) {
    message = 'Название организации должно быть не более 100 символов';
  }

  if (field.checkStreet?.$invalid) {
    message = customMessage !== '' ? customMessage : 'Необходимо указать название улицы';
  }

  if (field.mnogoru?.$invalid) {
    message = customMessage !== '' ? customMessage : `Номер карты Много.ру состоит из ${MNOGORU_COUPON_LENGTH} цифр`;
  }

  if (field.minLengthReview?.$invalid || field.maxLengthReview?.$invalid) {
    message = customMessage !== ''
      ? customMessage
      : `Отзыв должен содержать в себе от ${MIN_LENGTH_REVIEW} до ${MAX_LENGTH_REVIEW} букв`;
  }

  if (field.checkCommentCourier?.$invalid) {
    message = 'Значение должно быть не более 250 символов';
  }

  return message;
};
