<template>
  <button
    type="button"
    :aria-label="`${smallTextButton} ${otherTextButton}`"
    class="add-favorites"
    :class="{_active: isActive}"
    :disabled="isLoading"
    @click.prevent="toggleToFavorites"
  >
    <add-bookmark24
      class="add-favorites__icon"
      :width="24"
      :height="24"
    />
    <span v-if="showText" class="add-favorites__text">
      {{ smallTextButton }}
      <b class="add-favorites__other-text">
        {{ otherTextButton }}
      </b>
    </span>
  </button>
</template>

<script>
import { defineComponent, ref, computed, inject } from '@nuxtjs/composition-api';

import { getPropertiesFromAttributes } from '@/utils/analytics/getPropertiesFromAttributes';
import { setTargetProperties } from '@/utils/analytics/setTargetProperties';

import useAuthorizations from '@/compositions/user/useAuthorizations';
import useAuthorization from '@/compositions/authorization/useAuthorization';

// icons
import AddBookmark24 from '@/assets/sprite/svg/24/Add_bookmark_24.svg';

export default defineComponent({
  name: 'AddFavorites',
  components: { AddBookmark24 },
  props: {
    productId: { type: [Number, String], required: true },
    tbkCode: { type: [Number, String], required: true },
    bookId: { type: [Number, String], required: true },
    categoryId: { type: [Number, String], required: true },
    price: { type: [Number, String], required: true },
    oldPrice: { type: [Number, String], required: true },
    showText: { type: Boolean, default: false },
  },
  setup(props) {
    const authorization = useAuthorization();
    const { isAuthorized } = useAuthorizations();
    const wishList = inject('wishList');

    const isLoading = ref(false);

    const isActive = computed({
      get: () => {
        const item = wishList.state.productsId.find(findItem => findItem === props.productId);
        return !!item;
      },
      set: () => {},
    });

    const type = computed({
      get: () => (isActive.value ? 'delete' : 'add'),
      set: () => {},
    });

    const smallTextButton = computed({
      get: () => (isActive.value ? 'Удалить' : 'Отложить'),
      set: () => {},
    });

    const otherTextButton = computed({
      get: () => (isActive.value ? 'из избранного' : 'в избранное'),
      set: () => {},
    });

    const toggleToFavorites = async ({ target }) => {
      if (!isAuthorized.value) {
        authorization.openAuthorizationModal('Add To Favorite Button');
      } else {
        isLoading.value = true;

        const product = getPropertiesFromAttributes(target);
        const targetProperties = setTargetProperties(product);

        switch (type.value) {
          case 'add': {
            await wishList.addToWishList({
              productId: props.productId,
              product,
              targetProperties,
            });
            break;
          }

          case 'delete': {
            await wishList.removeInWishListItem({
              productId: props.productId,
              product,
            });
            break;
          }
        }

        isLoading.value = false;
      }
    };

    return {
      isLoading,
      isActive,
      smallTextButton,
      otherTextButton,
      toggleToFavorites,
    };
  },
});
</script>

<style lang="less">
.add-favorites {
  .button-reset;
  .small;

  display: flex;
  align-items: center;
  justify-content: center;
  color: @link;
  transition: @A color;

  &:hover,
  &._tap {
    color: @link-hover;
  }

  &__icon {
    fill: @gray;
    transition: @A fill;
    flex: 0 0 24px;
  }

  &:hover &__icon,
  &._tap &__icon {
    fill: darken(@gray, 12%);
  }

  &._active &__icon {
    fill: @pink;
  }

  &._active:hover &__icon,
  &._active._tap &__icon {
    fill: darken(@pink, 12%);
  }

  &__icon + &__text {
    white-space: nowrap;
    margin-left: @size-x3;
  }

  &__other-text {
    font-weight: normal;
  }
}
</style>
