import { reactive, toRefs, ref } from '@nuxtjs/composition-api';

import recaptcha from '@/services/recaptcha';

const useRecaptcha = () => {
  const params = reactive({
    recaptchaWidget: null,
    recaptchaAction: 'default',
  });

  const recaptchaContainer = ref(null);

  const renderReCaptcha = async (callback) => {
    params.recaptchaWidget = await recaptcha.renderCaptcha({
      container: recaptchaContainer.value,
      callback,
    });
  };

  const resetReCaptcha = async () => {
    await recaptcha.resetCaptcha(params.recaptchaWidget);
  };

  const getReRecaptchaToken = state => recaptcha.getToken(state || params.recaptchaAction)
    .then(captchaToken => captchaToken)
    .catch(error => error);

  return {
    ...toRefs(params),
    recaptchaContainer,
    renderReCaptcha,
    resetReCaptcha,
    getReRecaptchaToken,
  };
};

export default useRecaptcha;
