export const localConstPrefix = 'book24-';

/**
 * Возвращает сохраненные данные клиента по ключу
 * @param {string} paramName - Имя ключа в котором брать данные
 * @returns {*}
 */
export const getLocalItem = (paramName) => {
  if (navigator.cookieEnabled) {
    return JSON.parse(localStorage.getItem(`${localConstPrefix}${paramName}`)) || {};
  }
  return {};
};

/**
 * Устанавливает параметры пользователя
 * @param {string} paramName - Имя ключа в котором сохранять данные
 * @param {any} obj - Данные для сохранения
 */
export const setLocalItem = (paramName, obj) => {
  if (navigator.cookieEnabled) {
    localStorage.setItem(`${localConstPrefix}${paramName}`, JSON.stringify(obj));
  }
};

/**
 * Удаляет параметр пользователя
 * @param {string} paramName - Имя ключа для удаления данных
 */
export const deleteLocalItem = (paramName) => {
  if (navigator.cookieEnabled) {
    localStorage.removeItem(`${localConstPrefix}${paramName}`);
  }
};
