<template>
  <div class="collapse-panel">
    <div
      ref="collapse"
      class="collapse-panel__panel"
      :class="{ '_hide': isShowBtn && !isOpened && showTransition }"
      :style="`max-height: ${height}px;`"
    >
      <div ref="inner" class="collapse-panel__text">
        <slot></slot>
      </div>
    </div>
    <div v-if="isShowBtn" class="collapse-panel__more">
      <button-control
        type="link-dashed"
        @click="toggleCollapse"
      >
        <template v-if="isOpened">
          {{ hideText }}
        </template>
        <template v-else>
          {{ showText }}
        </template>
      </button-control>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  onUnmounted,
} from '@nuxtjs/composition-api';
import fastdom from 'fastdom';
import fastdomPromised from 'fastdom/extensions/fastdom-promised';
import anime from 'animejs';
import { ButtonControl } from '@book24/ui-components';

import { checkMobile } from '@/utils/deviceCheck';

const fastdomPromise = fastdom.extend(fastdomPromised);
export default defineComponent({
  name: 'AppCollapse',
  components: {
    ButtonControl,
  },
  props: {
    height: {
      type: Number,
      required: true,
    },
    hideText: {
      type: String,
      default: 'Свернуть текст',
    },
    scrollOnePlace: {
      type: Boolean,
      default: true,
    },
    showTransition: {
      type: Boolean,
      default: false,
    },
    offsetTop: {
      type: Number,
      default: 0,
    },
    showText: {
      type: String,
      default: 'Читать полностью',
    },
  },
  setup(props, { root, emit }) {
    const collapse = ref(null);
    const inner = ref(null);
    const state = reactive({
      actualHeight: 0,
      heightWithRestrictions: 0,
      isOpened: false,
      isShowBtn: false,
    });

    const setMeasurements = (isMounting = false) => {
      setTimeout(() => {
        fastdomPromise.measure(() => {
          state.heightWithRestrictions = collapse.value?.clientHeight || 0;
          state.actualHeight = inner.value?.clientHeight || 0;
          if (isMounting) {
            if (state.actualHeight > state.heightWithRestrictions) {
              state.isShowBtn = true;
            }
          }
        });
      }, 0);
    };
    const toggleCollapse = () => {
      if (state.actualHeight > state.heightWithRestrictions) {
        anime({
          targets: collapse.value,
          maxHeight: state.actualHeight,
          easing: 'easeInOutCubic',
          duration: 500,
          complete: () => {
            state.isOpened = true;
            setMeasurements();
            emit('onToggle', state.isOpened);
          },
        });
      } else {
        const list = [
          anime({
            targets: collapse.value,
            maxHeight: props.height,
            easing: 'easeInOutCubic',
            duration: 500,
            update: () => {},
            complete: () => {
              state.isOpened = false;
              setMeasurements();
              emit('onToggle', state.isOpened);
            },
          }),
        ];

        const scrollOptions = {
          duration: 500,
          easing: 'easeInOutCubic',
          offset: props.offsetTop,
        };

        if (checkMobile()) {
          scrollOptions.offset = -96;
        }

        if (props.scrollOnePlace) {
          list.push(root.$scrollTo(collapse.value, scrollOptions));
        }
        Promise.all(list);
      }
    };

    onMounted(() => {
      setMeasurements(true);

      window.addEventListener('resize', () => setMeasurements(true));
    });

    onUnmounted(() => {
      window.removeEventListener('resize', setMeasurements);
    });

    return {
      ...toRefs(state),
      setMeasurements,
      toggleCollapse,
      collapse,
      inner,
    };
  },
});
</script>

<style lang="less">
.collapse-panel {
  &__panel {
    overflow: hidden;
    position: relative;
    transition: @A height;

    &._hide {
      &::after {
        content: '';
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), @white);
      }
    }
  }

  &__more {
    text-align: left;
    margin-top: 20px;

    &._hidden {
      display: none;
    }
  }

  &__img {
    max-width: 100%;
  }
}
</style>
