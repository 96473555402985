export const ERROR_PARSE_JSON = 'Сервер вернул невалидный JSON';

export const ERROR_CODE_VERIFY_RECAPTCHA = '5395533f-ca4e-4f4d-bed4-a44656dcd60a';

export const ERROR_CODE_SAME_PHONE = '55adb4b5-88ca-4a99-85e7-7532c521780a';

export const ERROR_PHONE_INCORRECT = '3ddc6bb0-ecde-4183-af17-1a708bb0a158';

export const ERROR_PHONE_TRY_LATER = 'a0af9b0b-dd2e-401b-9187-8590ee9e0470';

export const ERROR_CALL_ORDER = Object.freeze({
  notStringPhone: 'Телефон должен быть строкой',
  emptyPhone: 'Телефон не должен быть пустым',
  incorrectPhone: 'Номер телефона некорректный',
});

export const ERROR_CODE_EMAIL_YET_SUBSCRIBED = 'd984a965-2e8b-4873-8ac6-5eb24bd0de34';

export const ERROR_UPDATE_USER = '8f23900c-df84-46e1-b287-30c481ccae81';
