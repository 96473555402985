// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';

export default class PeopleAndBooksService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * @typedef {Object} PayloadGetList
   * @param {string} [filterPreset='']
   * @param {string} [sortPreset='']
   * @param {number} [pageSize=15]
   */

  /**
   * Возвращает список статей раздела "Рекомендации знаменитостей"
   * @param {PayloadGetList} payload
   * @return {Promise<unknown>}
   */
  getList(payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        filterPreset = '',
        sortPreset = '',
        pageSize = 15,
      } = payload;

      const dataRequest = {};
      if (filterPreset) dataRequest.FILTER_PRESET = filterPreset;
      if (sortPreset) dataRequest.SORT_PRESET = sortPreset;
      if (pageSize) dataRequest.PAGE_SIZE = pageSize;

      this.mainHttp.get('/api/v1/content/lists/people-and-books/', dataRequest)
        .then(({ data: { data: dataRes } }) => resolve({
          items: dataRes?.items.map(item => ({
            name: item.name,
            link: item.detailPageUrl,
            description: item?.detailTitle,
            previewPictureOriginal: item?.previewPicture?.original?.src,
            books: item.books,
          })) || [],
          pagination: dataRes?.meta || {},
        }))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
