/**
 * Заголовок страницы
 * @type {string}
 */
export const TITLE_PAGE = 'Оплата заказа';

/**
 * @typedef {Object} ErrorType
 * @property {string} empty
 * @property {string} noValid
 */

/**
 * Ошибки заполнения карты
 * @type {Object.<string, ErrorType>}
 */
export const ERROR_TEXT_FIELDS_CARD = {
  cardNumber: {
    empty: 'Введите номер карты',
    noValid: 'В номере карты допущены ошибки',
  },
  expDateMonth: {
    empty: 'Укажите месяц',
    noValid: 'Некорректное значение месяца',
  },
  expDateYear: {
    empty: 'Укажите год',
    noValid: 'Некорректное значение года',
  },
  cvv: {
    empty: 'Введите CVC код',
    noValid: 'Код с обратной стороны карты должен состоять из трёх цифр',
  },
};

/**
 * Идентификатор формы оплаты
 * @type {string}
 */
export const ID_NAME_FORM = 'pay-widget';

export const PAY_SYSTEMS_TYPE = Object.freeze({
  AMERICAN_EXPRESS: {
    match: /^3[47][0-9]{0,}$/, // /^3[47][0-9]{13}$/
    icon: {
      name: 'Ae',
      width: 100,
      height: 28,
    },
  },
  JCB: {
    match: /^(?:2131|1800|35)[0-9]{0,}$/, // /^(?:2131|1800|35\d{3})\d{11}$/
    icon: {
      name: 'Jcb',
      width: 171,
      height: 132,
    },
  },
  MAESTRO: {
    match: /^(5[06789]|6)[0-9]{0,}$/,
    icon: {
      name: 'Maestro',
      width: 30,
      height: 23,
    },
  },
  MASTER_CARD: {
    match: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/, // /^5[1-5][0-9]{14}$/
    icon: {
      name: 'Mastercard',
      width: 30,
      height: 23,
    },
  },
  MIR: {
    match: /^2[2][0-9]{0,}$/,
    icon: {
      name: 'Mir',
      width: 33,
      height: 26,
    },
  },
  VISA: {
    match: /^4[0-9]{0,}$/, // /^4[0-9]{12}(?:[0-9]{3})?$/
    icon: {
      name: 'Visa',
      width: 33,
      height: 26,
    },
  },
  getIcon(bin) {
    if (typeof bin !== 'string') return {};

    const target = bin.replace(/\D/g, '');

    if (target.match(this.AMERICAN_EXPRESS.match)) {
      return this.AMERICAN_EXPRESS.icon;
    }
    if (target.match(this.JCB.match)) {
      return this.JCB.icon;
    }
    if (target.match(this.MAESTRO.match)) {
      return this.MAESTRO.icon;
    }
    if (target.match(this.MASTER_CARD.match)) {
      return this.MASTER_CARD.icon;
    }
    if (target.match(this.MIR.match)) {
      return this.MIR.icon;
    }
    if (target.match(this.VISA.match)) {
      return this.VISA.icon;
    }
    return {};
  },
});

export const ERRORS_MAP = {
  'ecaa33d6-c29b-492a-98f7-b795001117de': 'Заказ уже оплачен',
  '7e80b711-7a6b-4e9c-ae16-62d83c755db2': 'Заказ уже оплачен',
  '03480b7e-eb6a-43c8-8e4d-b9f0efad4695': 'Заказ отменен',
  '5fc70807-cf91-4299-b30c-aca6911bd1b9': 'У вас нет доступа для просмотра этого заказа',
  '752c799d-ac49-4326-b450-be03b3b32ddb': 'Оплата заказа уже осуществляется',
  1002: 'Заказ уже оплачен',
  5001: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5003: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5004: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5005: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5006: 'Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
  5007: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5012: 'Воспользуйтесь другой картой или свяжитесь с банком, выпустившим карту',
  5013: 'Проверьте корректность суммы',
  5014: 'Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
  5015: 'Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
  5019: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5030: 'Повторите попытку позже',
  5031: 'Воспользуйтесь другой картой',
  5033: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5034: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5036: 'Платежи для этой карты запрещены. Попробуйте другую карту',
  5041: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5043: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5051: 'Недостаточно средств на карте',
  5054: 'Проверьте правильность введенных данных карты или воспользуйтесь другой картой',
  5057: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5062: 'Платежи для этой карты запрещены. Попробуйте другую карту',
  5063: 'Воспользуйтесь другой картой',
  5065: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5082: 'Неверно указан код CVV',
  5091: 'Повторите попытку позже или воспользуйтесь другой картой',
  5092: 'Повторите попытку позже или воспользуйтесь другой картой',
  5096: 'Повторите попытку позже',
  5204: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5206: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5207: 'Свяжитесь с вашим банком или воспользуйтесь другой картой',
  5300: 'Воспользуйтесь другой картой',
};

export const ERRORS_PAY_INVOICE = Object.freeze({
  orderPaid: {
    code: 'ecaa33d6-c29b-492a-98f7-b795001117de',
    message: 'Заказ оплачен',
  },
});

export const ERRORS_CREATE_INVOICE = Object.freeze({
  notCreate: {
    code: '7119ffde-8294-481f-8156-5099491bc0a9',
    message: 'Не получилось создать новый счёт',
  },
  orderCancelled: {
    code: '03480b7e-eb6a-43c8-8e4d-b9f0efad4695',
    message: 'Заказ отменен',
  },
  orderPaid: {
    code: '7e80b711-7a6b-4e9c-ae16-62d83c755db2',
    message: 'Заказ оплачен',
  },
});

export const ERRORS_IS_PAID = [
  1002,
  'ecaa33d6-c29b-492a-98f7-b795001117de',
  '5fc70807-cf91-4299-b30c-aca6911bd1b9',
  '03480b7e-eb6a-43c8-8e4d-b9f0efad4695',
];

export const CANCELED_STATUS = 'C';
export const NOT_PAYED_STATUS = 'N';

export const PROCESSED_INVOICE_STATUS = 'processed';
