import { ref, computed, useRouter, useStore, watch, useRoute } from '@nuxtjs/composition-api';

import { authorList, pictures } from '@/utils/formatters/formattedProduct';

import useCommonModal from '@/compositions/useCommonModal';
import useHttpClient from '@/compositions/useHttpClient';
import { useBasketContext } from '@/compositions/basket/basketStorage';
import useUserSession from '@/compositions/layout/useUserSession';
import { useAdultContext } from '~/compositions/adult/adultStorage';

const useAdult = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const basketContext = useBasketContext();
  const adultContext = useAdultContext();
  const userSession = useUserSession();
  const { user } = useHttpClient();
  const { showAdult, hideModalAdult } = useCommonModal();

  const title = 'Вам исполнилось 18 лет?';
  const text = 'Эта книга — только для совершеннолетних. Чтобы посмотреть и купить её, пожалуйста, подтвердите ваш возраст.';
  const prependCartText = 'В вашей корзине есть книги, которые предназначены только для взрослых:';
  const appendCartText = 'Чтобы перейти в корзину, пожалуйста, подтвердите ваш возраст или удалите эти книги из корзины.';

  const isLoading = ref(false);

  // Пользователь авторизован
  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {},
  });

  // Пользователь подтвердил свой возраст
  const isAgeConfirmed = computed(() => store.state.user?.isAdultConfirmed);

  // Пользователь подтвердил свой возраст
  const isUser = computed(() => !!store.state.user?.id);

  const cartListAdultProducts = computed({
    get: () => {
      const list = basketContext.basketItem?.value || [];
      return list
        .map(item => ({
          basketItemId: item.id,
          id: Number(item.product.id),
          name: item.product.name,
          authorList: authorList(item.product.author),
          images: pictures(item.product.picture, { sizePicWidth: 40 }),
          imagesLarge: pictures(item.product.picture),
          isAdult: item.product.isAdult,
        }))
        .filter(item => item.isAdult);
    },
    set: () => {},
  });

  const countAdultProducts = computed(() => cartListAdultProducts.value.length);

  // Подтверждаем возраст
  const sendConfirmAge = async () => {
    try {
      await user.confirmAdult();
    } catch (e) {
      throw new Error(e);
    }
  };

  const saveConfirmAgeCookies = () => {
    adultContext.isAgeConfirmedCookies.value = true;
  };

  // Блюрить ли обложку
  const checkIsAdult = computed({
    get: () => {
      if (!isAuthorized.value) {
        return !adultContext.isAgeConfirmedCookies.value;
      }
      return isUser.value && !isAgeConfirmed.value;
    },
    set: () => {},
  });

  // Блюрить ли обложку в корзине
  const checkIsAdultCart = computed(() => {
    if (!isAuthorized.value) {
      if (!adultContext.isAgeConfirmedCookies.value && cartListAdultProducts.value.length) return true;
    } else {
      return !isAgeConfirmed.value && cartListAdultProducts.value.length;
    }
  });

  // Подтвердить возраст
  const clickConfirmHandler = async () => {
    isLoading.value = true;
    if (!isAuthorized.value) saveConfirmAgeCookies();
    else {
      await sendConfirmAge();
      await userSession.updateUserSession();
      isLoading.value = false;
    }
    hideModalAdult();
  };

  // Вернуться назад
  const back = (firstHit = false) => {
    if (firstHit) return router.push('/');
    hideModalAdult();
    router.back();
  };

  // Удалить продукты
  const clickRemoveProductsHandler = () => {
    cartListAdultProducts.value.forEach(async (item) => {
      await basketContext.deleteBasketItem({
        itemId: item.basketItemId,
        isFetchBasket: true,
      });
    });
    hideModalAdult();
    window.location.reload();
  };

  watch(() => isAgeConfirmed.value,
    (value) => {
      if (value) hideModalAdult();
    });

  watch(() => route.value,
    () => hideModalAdult());

  return {
    title,
    text,
    prependCartText,
    appendCartText,
    isLoading,
    isAuthorized,
    isAgeConfirmed,
    cartListAdultProducts,
    countAdultProducts,
    checkIsAdultCart,
    checkIsAdult,
    showAdult,
    clickConfirmHandler,
    back,
    clickRemoveProductsHandler,
  };
};

export default useAdult;
