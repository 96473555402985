import { setPaymentType } from '@/utils/analytics/setPaymentType';

/**
 *  Получаем список использованных типов оплат
 * @param {*} orderPayment
 */
export const getOrderPayment = (orderPayment) => {
  if (!orderPayment.length) return false;

  const payment = orderPayment
    .map(type => setPaymentType(type.paymentSystemId));

  if (payment.length < 2) return payment.join('');
  return payment;
};
