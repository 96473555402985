import { checkMobile } from '../../utils/deviceCheck';
import getMetaRoute from '../../utils/getMetaRoute';

const BasketPageDesktop = () => import(/* webpackChunkName: "BasketPageDesktop" */ './DesktopPage.vue').then(m => m.default || m);
const BasketPageMobile = () => import(/* webpackChunkName: "BasketPageMobile" */ './MobilePage.vue').then(m => m.default || m);

const isMobile = checkMobile();

export default [
  {
    path: '/personal/cart/',
    component: isMobile ? BasketPageMobile : BasketPageDesktop,
    name: 'basket-page',
    meta: getMetaRoute({
      ddmCategory: 'Cart',
      ddmType: 'cart',
    }),
  },
];
