// @ts-check
/// <reference path="./typedefs.js" />
/// <reference path="../../../typedefs.js" />

import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';
import { ERROR_MESSAGES } from '@/utils/constants/b2bConstants';
import { ERROR_CAPTCHA } from '@/utils/constants/userConstants';

export default class B2BService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Отправка данных формы клиента B2B
   * @param {FormRequest} payload
   * @return {Promise<boolean|ErrorObject|Error>}
   */
  sendClientRequest(payload) {
    return new Promise((resolve, reject) => {
      if (!payload && Object.keys(payload)) return reject(ERROR_MESSAGES.payloadForClientRequestRequired.message);
      if (!payload.brsign.payload.token) return reject(ERROR_CAPTCHA.emptyCaptchaToken);
      if (typeof payload.brsign.payload.token !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaToken);
      if (!payload.brsign.payload.action) return reject(ERROR_CAPTCHA.emptyCaptchaAction);
      if (typeof payload.brsign.payload.action !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaAction);
      if (!payload.brsign.payload.version) return reject(ERROR_CAPTCHA.emptyCaptchaVersion);
      if (typeof payload.brsign.payload.version !== 'string') return reject(ERROR_CAPTCHA.notStringCaptchaVersion);

      this.mainHttp.post('/api/v1/form/b2b/', payload, { isFormData: true })
        .then(({ data: { data: success } }) => resolve(success))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
