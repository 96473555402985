// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildElement, buildErrorCollection } from '@/utils/formatters/processingOpenApi';

export default class BrandService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  getBrandInfo({ brandId, isGetSeo, isGetDescription }) {
    const dataRequest = {};
    const include = [];
    if (isGetSeo) include.push('seoMeta');
    if (isGetDescription) include.push('brandDescription');
    if (include.length) dataRequest.include = include.join(',');

    return this.mainHttp.get(`/api/v1/brand-page/${brandId}/`, dataRequest)
      .then(({ data: { data: dataRes } }) => buildElement(dataRes))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }
}
