<template>
  <content-placeholders
    class="product-card _small"
    rounded
  >
    <content-placeholders-img class="product-card__image-holder" />
    <div class="product-card__content">
      <div class="product-card__price product-card-price">
        <content-placeholders-text
          :lines="1"
          class="product-card-price__current"
          style="width: 35px;"
        />
        <content-placeholders-text
          :lines="1"
          class="product-card-price__old"
          style="width: 35px;"
        />
      </div>
    </div>
  </content-placeholders>
</template>

<script>
export default {
  name: 'ProductCardSmallSkeleton',
};
</script>
