<template>
  <app-section
    :link-more="linkMore"
    :is-container="isContainer"
    :title="title"
    :badge="badge"
    :data-b24-shelf-name="title"
  >
    <div class="shelf-products" :class="{ _container: isContainer }">
      <product-scroller
        class="shelf-products__scroller"
        :is-small="isSmall"
        :is-line-price="false"
        :params-link="paramsLink"
        :products="products"
      />
    </div>
  </app-section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import ProductScroller from '@/components/ProductScroller/index.vue';
import AppSection from '@/components/_mobile/AppSection/index.vue';

export default defineComponent({
  name: 'ShelfProductsMobile',
  components: {
    ProductScroller,
    AppSection,
  },
  props: {
    products: { type: Array, default: () => ([]) },
    paramsLink: { type: Object, default: () => ({}) },
    title: { type: String, default: '' },
    isContainer: { type: Boolean, default: true },
    isSmall: { type: Boolean, default: true },
    linkMore: { type: String, default: '' },
    showLoadMore: { type: Boolean, default: false },
    badge: { type: String, default: '' },
  },
});
</script>

<style lang="less">
.shelf-products {
  &._container {
    .container-m();
  }

  &__title {
    .h2-mobile();

    margin: 0 0 @size-x4;
  }
}
</style>
