<template>
  <a
    :href="catalogUrl"
    :title="title"
    :target="blank ? '_blank' : '_self'"
    @click="clickHandler"
  >
    <slot></slot>
  </a>
</template>
<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardLink',
  props: {
    link: { type: String, required: true },
    title: { type: String, required: false, default: '' },
    blank: { type: Boolean, default: false },
    handler: { type: Function, required: false, default: () => {} },
    useHandler: { type: Boolean, default: true },
  },
  setup(props) {
    const catalogUrl = computed(() => `${props.link}`);

    const clickHandler = (event) => {
      if (props.useHandler && typeof props.handler === 'function') {
        event.preventDefault();
        event.stopPropagation();

        props.handler(event);
      }
    };

    return {
      catalogUrl,
      clickHandler,
    };
  },
});
</script>
