import { inject, provide, computed, useStore } from '@nuxtjs/composition-api';
import { useLocalStorage } from '@vueuse/core';

const ADULT_CONTEXT = Symbol('b24-adult');

export const useAdultProvider = () => {
  const store = useStore();

  // Пользователь авторизован
  const isAuthorized = computed({
    get: () => store.state.isAuthorized,
    set: () => {},
  });

  // Пользователь подтвердил свой возраст
  const isAgeConfirmed = computed(() => store.state.user?.isAdultConfirmed);

  // Пользователь подтвердил свой возраст
  const isUser = computed(() => !!store.state.user?.id);

  // Пользователь подтвердил свой возраст локально
  const isAgeConfirmedCookies = useLocalStorage('AGE_CONFIRMED', false);

  const ageIsConfirmed = computed({
    get: () => {
      if (!isAuthorized.value) return isAgeConfirmedCookies.value;
      return isUser.value && isAgeConfirmed.value;
    },
    set: () => {},
  });

  provide(ADULT_CONTEXT, {
    ageIsConfirmed,
    isAgeConfirmedCookies,
  });
};

export const useAdultContext = () => {
  const context = inject(ADULT_CONTEXT);

  if (!context) {
    throw new Error('useAdultContext необходимо использовать с useAdultProvider');
  }

  return context;
};
