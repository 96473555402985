import { getCurrentInstance } from '@nuxtjs/composition-api';

import { getLocalItem, setLocalItem } from '@/utils/paramsLocalStorage';
import { DEFAULT_ERROR_TEXT, MONTH_IN_MS } from '@/utils/constants/globalConst';
import { checkMobile } from '@/utils/deviceCheck';

import AdultForm from '@/components/Adult/AdultForm';
import AdultCart from '@/components/Adult/AdultCart';
import AuthorizationForm from '@/components/Authorization/Form/index.vue';

const useCommonModal = () => {
  const vm = getCurrentInstance();
  const isMobile = checkMobile();
  const modal = vm.root.proxy.$vfm;

  /**
   * Динамический выбор компонента модального окна для моб и десктоп
   */
  const getModalComponent = (isDrawer = false) => {
    if (isMobile) {
      return () => (isDrawer
        ? import('@/components/AppModalDrawer/index.vue')
        : import('@/components/AppModal/index.vue'));
    }
    return () => import('@/components/AppModal/AppModalDesktop/index.vue');
  };

  /**
   * Открыть модальное окно авторизации
   */
  const showAuth = () => {
    modal.show({
      component: getModalComponent(),
      bind: {
        nameModal: 'AuthorizationModal',
        contentClass: '_click-outside',
        classModal: '_no-padding',
        zIndex: 100503,
        isAbsoluteHeader: true,
      },
      slots: {
        default: {
          component: AuthorizationForm,
          bind: {
            isMobile,
          },
        },
      },
    });
  };

  /**
   * Открыть модальное окно подтверждения возраста
   */
  const showAdult = (isCart = false) => {
    modal.show({
      component: getModalComponent(true),
      bind: {
        nameModal: 'AdultModal',
        isUnclosed: true,
        isBlurred: true,
      },
      slots: {
        default: {
          component: isCart ? AdultCart : AdultForm,
          bind: {
            isMobile,
          },
        },
      },
    });
  };

  /**
   * Закрыть модальное окно авторизации
   */
  const hideModalAuth = () => {
    modal.hide('AuthorizationModal');
  };

  /**
   * Закрыть модальное окно подтверждения возраста
   */
  const hideModalAdult = () => {
    modal.hide('AdultModal');
  };

  /**
   * Закрыть модальное окно установки Android приложения
   */
  const hideModalAndroidApp = () => {
    modal.hide('AndroidAppModal');
  };

  /**
   * От установки PWA ранее уже отказались
   * @returns {*|number|number}
   */
  const cancelledInstall = () => {
    const igA2hsDismiss = getLocalItem('ig_a2hs_dismiss');
    const currentDate = new Date();
    const diffTime = igA2hsDismiss ? ((currentDate - new Date(igA2hsDismiss)) / MONTH_IN_MS) : 0;
    return igA2hsDismiss && diffTime;
  };

  /**
   * Модальное окно установки Android в этом месяце уже показывали
   * @returns {*|number|number}
   */
  const cancelledInstallAndroidApp = () => {
    const androidAppDismiss = getLocalItem('android_app_dismiss');
    const currentDate = new Date();
    const diffTime = androidAppDismiss ? ((currentDate - new Date(androidAppDismiss)) / MONTH_IN_MS) : 0;
    return androidAppDismiss && diffTime;
  };

  /**
   * Открыть модальное окно с просьбой установить PWA
   */
  const showInstallModal = () => {
    if (cancelledInstall()) return;
    modal.show({
      component: () => import('@/components/AppModal/SmallMobileModal/index.vue'),
      bind: {
        nameModal: 'InstallPrompt',
      },
      slots: {
        default: {
          component: () => import('@/components/Install/InstallModal.vue'),
          bind: {
            isMobile: true,
          },
          on: {
            hideModal: hideModalAuth,
          },
        },
      },
    });
  };

  /**
   * Открыть модальное окно с просьбой
   * установить Android приложение
   */
  const showAndroidAppModal = () => {
    if (cancelledInstallAndroidApp()) return;
    modal.show({
      component: () => import('@/components/AppModalDrawer'),
      bind: {
        nameModal: 'AndroidAppModal',
        class: '_android-app-modal',
      },
      on: {
        closed: () => {
          const date = new Date();
          const timestamp = date.getTime();
          setLocalItem('android_app_dismiss', timestamp);
        },
      },
      slots: {
        default: {
          component: () => import('@/components/Install/AndroidAppModal'),
        },
      },
    });
  };

  /**
   * Закрыть модальное окно с просьбой установить PWA
   */
  const hideModalInstall = () => {
    modal.hide('InstallPrompt');
  };

  /**
   * Открыть модальное окно с базовой ошибкой
   * обработки запроса
   * @param err
   */
  const openError = (err) => {
    const nameModal = `error-${err.code}`;
    modal.show({
      component: () => import('@/components/AppModal/AppModalDesktop/index.vue'),
      bind: {
        nameModal,
      },
      slots: {
        default: {
          component: () => import('@/components/ModalConfirm/index.vue'),
          bind: {
            description: DEFAULT_ERROR_TEXT,
            showAccept: false,
            textCancel: 'Ок',
          },
          on: {
            cancel: () => {
              modal.hide(nameModal);
            },
          },
        },
      },
    });
  };

  /**
   * Динамический выбор компонента списка ПВЗ
   */
  const getPvzListComponent = () => {
    if (isMobile) {
      return () => import('@/modules/checkout/components/_mobile/ModalPvzList');
    }
    return () => import('@/modules/checkout/components/_desktop/MapListPvz');
  };

  /**
   * Открыть модальное окно выбора ПВЗ
   */
  const showPvzModal = () => {
    modal.show({
      component: getModalComponent(),
      bind: {
        nameModal: 'choice-pvz',
        isTransparentHeader: !isMobile,
        isVisibleHeader: !isMobile,
        classModal: !isMobile ? '_map-pvz-list js-map-modal' : '',
        contentClass: !isMobile ? '_map-pvz-list' : '',
      },
      slots: {
        default: {
          component: getPvzListComponent(),
        },
      },
    });
  };

  return {
    modal,
    isMobile,
    showAuth,
    showAdult,
    openError,
    hideModalAuth,
    hideModalAdult,
    hideModalAndroidApp,
    showInstallModal,
    hideModalInstall,
    getModalComponent,
    showPvzModal,
    showAndroidAppModal,
  };
};

export default useCommonModal;
