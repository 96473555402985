// @ts-check
/// <reference path="./typedefs.js" />
/// <reference path="../../../typedefs.js" />

import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';

import { buildErrorCollection, buildCollection, buildElement } from '@/utils/formatters/processingOpenApi';
import { formatRatingProducts } from '@/utils/formatters/formattedRating';

export default class RatingService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получение разделов для рейтинга
   * @param {Object} data
   * @param {boolean} data.isGetRatingList=true - нужно ли получить список по рейтингам
   * @return {Promise<Ratings|Error|ErrorObject>} - рейтинги
   */
  getRatings(data = {}) {
    return new Promise((resolve, reject) => {
      const {
        isGetRatingList = true,
      } = data;

      const include = [];
      let dataRequest = {};
      if (isGetRatingList) include.push('rating');
      if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

      this.mainHttp.get('/api/v1/rating/list/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes, true);
          const formattedList = list.map(el => ({
            id: el.id,
            name: el.name,
            position: el.position,
            ratings: el?.relationships.rating,
          }));
          return resolve(formattedList);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(e);
        });
    });
  }

  /**
   * Получение информации и списка продуктов по разделу рейтинга
   * @param {string} code - код категории
   * @return {Promise<RatingPageInfo|Error|ErrorObject>} - рейтинги
   */
  getRatingDetails(code) {
    return new Promise((resolve, reject) => {
      if (!code) reject(new ServiceError('[RatingService:getRatingsProducts] code обязателен для заполнения'));
      if (typeof code !== 'string') reject(new ServiceError('[RatingService:getRatingsProducts] code обязан быть строкой'));

      const dataRequest = {
        code,
        include: 'seoMeta',
      };

      return this.mainHttp.get('/api/v1/rating/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);
          return resolve({
            code: resFormatted.code,
            id: resFormatted.id,
            name: resFormatted.name,
            position: resFormatted.position,
            seoMeta: resFormatted.relationships?.seoMeta || null,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }

  /**
   * Получение информации и списка продуктов по разделу рейтинга
   * @param {Object} payload
   * @param {string} payload.id - ид категории
   * @param {number} [payload.pageNumber=1] - Номер страницы в пагинации
   * @param {number} [payload.pageSize=30] - Количество товаров на странице
   * @param {string} [payload.filterPreset=''] - Заготовленный пресет сортировки
   * @param {string} [payload.filter=''] - Фильтр
   * @param {boolean} [payload.isGetAuthors=true] - Нужно ли получить список авторов товара
   * @param {boolean} [payload.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [payload.isGetCategory=true] - Нужно ли получить категорию товара
   * @param {boolean} [payload.isOrsProduct=true] - Нужно ли получить bookId и codeTbk
   * @param {boolean} [payload.isGetPublishingHouse=true] - Нужно ли получить издателя товара
   * @param {boolean} [payload.isGetParentCategory=false] - Нужно ли получить родительские категории товара
   * @return {Promise<ProductList|Error|ErrorObject>}
   */
  getRatingsProducts(payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        id = '',
        pageNumber = 1,
        pageSize = 30,
        filterPreset = '',
        filter = '',
        isGetAuthors = true,
        isGetBadge = true,
        isGetCategory = true,
        isGetParentCategory = true,
        isGetPublishingHouse = true,
        isOrsProduct = true,
      } = payload;

      if (!id) return reject(new ServiceError('[RatingService:getRatingsProducts] id категории обязателен для заполнения'));
      if (typeof id !== 'string') return reject(new ServiceError('[RatingService:getRatingsProducts] id категории быть строкой'));

      const include = [];
      if (isGetAuthors) include.push('product.author');
      if (isGetBadge) include.push('product.badge');
      if (isGetCategory) include.push('product.category');
      if (isGetParentCategory) include.push('product.category.parentCategory');
      if (isGetPublishingHouse) include.push('product.publishingHouse');
      if (isOrsProduct) include.push('product.orsProduct');
      let dataRequest = { 'per-page': pageSize, page: pageNumber };

      if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
      if (filter) dataRequest = { ...dataRequest, filter };
      if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };
      return this.mainHttp.get(`/api/v1/rating/${id}/product/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          const formattedProducts = formatRatingProducts(list);
          return resolve({
            items: formattedProducts,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
