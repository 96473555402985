import { MNOGORU_COUPON_LENGTH } from '@/utils/constants/globalConst';
import { PHONE_RUS_CODE_LENGTH } from '@/utils/constants/authorizationConstants';
import { MAX_LENGTH_REVIEW, MIN_LENGTH_REVIEW } from '@/utils/constants/productDetailConstants';
import { onlyLettersAndNumbers } from '@/utils/onlyLettersAndNumbers';

import { getDifferenceBetweenDateTypes } from '@/utils/dateTime';

const isEmpty = value => (typeof value === 'undefined' || value === null || value === '');

function street(value) {
  if (isEmpty(value)) {
    return true;
  }
  return !/[^(а-яА-ЯЁёa-zA-Z0-9\-\s)]/.test(value);
}

function house(value) {
  if (isEmpty(value)) {
    return true;
  }
  return /^[а-яА-ЯЁёa-zA-Z0-9]+$/.test(value);
}

function minAge(date) {
  if (isEmpty(date)) {
    return true;
  }
  const dateNow = new Date();
  const yearsDiff = getDifferenceBetweenDateTypes(dateNow, date, 'years');
  return yearsDiff >= 18;
}

function minAgeChild(date) {
  if (isEmpty(date)) {
    return true;
  }
  const dateNow = new Date();
  return date <= dateNow;
}

function maxAge(date) {
  if (isEmpty(date)) {
    return true;
  }
  const dateNow = new Date();
  const yearsDiff = getDifferenceBetweenDateTypes(dateNow, date, 'years');
  return yearsDiff <= 100;
}

function correctAgeRange(date) {
  if (isEmpty(date)) {
    return true;
  }
  const dateNow = new Date();
  const yearsDiff = getDifferenceBetweenDateTypes(dateNow, date, 'years');
  const daysDiff = getDifferenceBetweenDateTypes(dateNow, date, 'days');
  return yearsDiff <= 100 && daysDiff >= 0;
}

function notCyrillic(value) {
  if (isEmpty(value)) {
    return true;
  }
  return !/[а-яА-ЯЁё]/.test(value);
}

function personName(value) {
  if (isEmpty(value)) {
    return true;
  }
  // https://bit.ly/2YkfW2I ссылка на regexper.com где можно посмотреть визуально работу выражения
  return /^([а-яА-ЯЁёa-zA-Z]+-?[а-яА-ЯЁёa-zA-Z]+)\s([а-яА-ЯЁёa-zA-Z]+-?[а-яА-ЯЁёa-zA-Z]+)\s?([а-яА-ЯЁёa-zA-Z]+)?$/.test(value);
}

function isAllowedSymbolsFullName(value) {
  if (isEmpty(value)) {
    return true;
  }
  const namesList = value.split(' ')
    .filter(word => word)
    .filter(item => !/^([а-яёa-z\s-]+)$/i.test(item));

  return !namesList.length;
}

function isMinLengthSymbolsFullName(value) {
  if (isEmpty(value) || !isAllowedSymbolsFullName(value)) {
    return true;
  }
  const namesList = value.split(' ')
    .filter(word => word)
    .filter(item => item.length < 2);

  return !namesList.length;
}

function checkFullName(value) {
  if (isEmpty(value)) {
    return false;
  }
  return value.split(' ').filter(word => word).length >= 2;
}

function phone(value) {
  if (isEmpty(value)) {
    return true;
  }
  return !/[^(0-9+\-\s())]/.test(value);
}

function phoneMinLength(value) {
  if (isEmpty(value)) {
    return true;
  }
  const length = value.length;
  return length > 10;
}

function minLength(value) {
  if (isEmpty(value)) {
    return true;
  }
  const length = onlyLettersAndNumbers(value).length;
  return length >= 10;
}

function maxLength(value) {
  if (isEmpty(value)) {
    return true;
  }
  const length = onlyLettersAndNumbers(value).length;
  return length <= 20;
}

function maxLengthName(value) {
  if (isEmpty(value)) {
    return true;
  }
  const length = value.length;
  return length < 26;
}

function minLengthReview(value) {
  if (isEmpty(value)) {
    return true;
  }
  const length = onlyLettersAndNumbers(value).length;
  return length >= MIN_LENGTH_REVIEW;
}

function maxLengthReview(value) {
  if (isEmpty(value)) {
    return true;
  }
  const length = onlyLettersAndNumbers(value).length;
  return length <= MAX_LENGTH_REVIEW;
}

function inn(value) {
  if (isEmpty(value)) {
    return true;
  }

  const length = value.length;
  return length === 10 || length === 12;
}

function kpp(value) {
  if (isEmpty(value)) {
    return true;
  }
  return (value.length === 1 && value === '0') || value.length === 9;
}

function bik(value) {
  if (isEmpty(value)) {
    return true;
  }
  return value.length === 9;
}

function account(value) {
  if (isEmpty(value)) {
    return true;
  }
  return value.length === 20;
}

function fio(value) {
  if (isEmpty(value)) {
    return true;
  }

  const words = value.split(' ');
  const filledWords = words.filter(word => !!word);

  return filledWords.length === 3;
}

const checkMinRegistrationName = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return value.length >= 2;
};

const checkMaxRegistrationName = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return value.length <= 50;
};

const checkRegistrationName = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return /^[а-яА-Яa-zA-Z]+([-—–\s][а-яА-Яa-zA-Z]+)*$/.test(value);
};

function checkSecondName(value) {
  if (isEmpty(value) || !isMinLengthSymbolsFullName(value)) {
    return true;
  }
  const list = value.split(' ').filter(word => word);
  return !(list.length >= 2 && list.length < 3);
}

function number(value) {
  if (isEmpty(value)) {
    return true;
  }
  return value.length <= 15;
}

function firstEmailSymbols(value) {
  if (isEmpty(value)) {
    return true;
  }
  return /^[a-zA-Z0-9]/.test(value);
}

function organizationNameLength(value) {
  if (isEmpty(value)) {
    return true;
  }
  return value.length <= 100;
}

const checkName = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return !/[^а-яА-ЯЁёa-zA-Z\-\s]/.test(value);
};

const nameIsNotReader = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  const readerRegex = /читатель/i;
  return !readerRegex.test(value);
};

const checkLength = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return value.length > 1;
};

const checkPhoneWithCode = (value) => {
  const numbersValue = value.replace(/\D/g, '');
  return numbersValue.length > PHONE_RUS_CODE_LENGTH;
};

const checkPhone = (value) => {
  const numbersValue = value.replace(/\D/g, '');
  return numbersValue.length === 11 || (numbersValue.length < 11 && numbersValue === '8');
};

const checkPhoneForRu = (value) => {
  const numbersValue = value.replace(/\D/g, '');
  const hasNineNumber = Number(numbersValue[1]) === 9;
  return numbersValue.length === 11 && hasNineNumber;
};

const mnogoru = value => (
  (value.length === MNOGORU_COUPON_LENGTH) || !value
) && this.customMnogoRuError === '';

export {
  street,
  house,
  minAge,
  maxAge,
  minAgeChild,
  notCyrillic,
  personName,
  phone,
  phoneMinLength,
  inn,
  kpp,
  bik,
  account,
  fio,
  checkFullName,
  number,
  organizationNameLength,
  firstEmailSymbols,
  checkName,
  checkLength,
  checkPhone,
  checkPhoneForRu,
  minLength,
  maxLength,
  maxLengthName,
  minLengthReview,
  maxLengthReview,
  mnogoru,
  correctAgeRange,
  isAllowedSymbolsFullName,
  isMinLengthSymbolsFullName,
  checkSecondName,
  checkPhoneWithCode,
  checkMinRegistrationName,
  checkMaxRegistrationName,
  checkRegistrationName,
  nameIsNotReader,
};
