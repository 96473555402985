/**
 * Приводим список с баннерами к формату аналитики
 * @param items
 */
export const getArrayBanners = (items) => {
  if (!items) return [];

  const resultItems = [];

  items.forEach((item) => {
    const params = {
      creative_name: item.name,
      creative_slot: item.slot,
      index: item.index ? Number(item.index) + 1 : 1,
      promotion_id: Number(item.id),
    };

    if (item.link) params.promotion_name = item.link;

    resultItems.push(params);
  });

  return resultItems;
};
