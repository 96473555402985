const POST_USUAL_PREPAYMENT = 'post_usual_prepayment';
const POST_USUAL_CASH_ON_DELIVERY = 'post_usual_cash_on_delivery';
const POST_ONLINE_COURIER = 'post_online_courier';
const BOXBERRY_COURIER = 'boxberry_courier';
export const BOXBERRY_PVZ = 'boxberry_pvz';
export const DHL_COURIER = 'dhl_courier';
export const PICKPOINT_PVZ = 'pickpoint_pvz';
export const LOGSIS_COURIER = 'logsis_courier';
const MANUAL_FOR_EMPLOYEES_CHEKHOV = 'manual_for_employees_chekhov';
const MANUAL_FOR_EMPLOYEES_IMPERIYA = 'manual_for_employees_imperiya';
const MANUAL_FOR_EMPLOYEES_VIDNOE = 'manual_for_employees_vidnoe';
const MANUAL_FOR_EMPLOYEES_ZORGE = 'manual_for_employees_zorge';
const MANUAL_FOR_EMPLOYEES_CHG = 'manual_for_employees_chg';
const MANUAL_MSK_COURIER_INSIDE_MSK = 'manual_msk_courier_inside_msk';
const MANUAL_MSK_COURIER_OUTSIDE_MSK = 'manual_msk_courier_outside_msk';
export const MANUAL_PARTNER_PROGRAMS = 'manual_partner_programs';
export const MANUAL_PVZ_FOR_CLIENTS = 'manual_pvz_for_clients';
export const MANUAL_PVZ_NIZHNIY_NOVGOROD = 'manual_pvz_nizhniy_novgorod';
const SDEK_ECONOM_SD = 'sdek_econom_sd';
const SDEK_ECONOM_SS = 'sdek_econom_ss';
const SDEK_POSYLKA_SD = 'sdek_posylka_sd';
const SDEK_POSYLKA_SS = 'sdek_posylka_ss';
const SDEK_SPEC_FROM_KRASNODAR_COURIER = 'sdek_spec_from_krasnodar_courier';
const SDEK_SPEC_FROM_KRASNODAR_PVZ = 'sdek_spec_from_krasnodar_pvz';
const SDEK_SPEC_FROM_ROSTOV_COURIER = 'sdek_spec_from_rostov_courier';
const SDEK_SPEC_FROM_ROSTOV_PVZ = 'sdek_spec_from_rostov_pvz';
const SDEK_SPEC_FROM_SPB_COURIER = 'sdek_spec_from_spb_courier';
const SDEK_SPEC_FROM_SPB_PVZ = 'sdek_spec_from_spb_pvz';
const SDEK_SPEC_FROM_TUMEN_COURIER = 'sdek_spec_from_tumen_courier';
const SDEK_SPEC_FROM_TUMEN_PVZ = 'sdek_spec_from_tumen_pvz';
const TOPDELIVERY_COURIER = 'topdelivery_courier';
const TOPDELIVERY_PVZ = 'topdelivery_pvz';
const ZABBERI_PVZ = 'zabberi_pvz';
const B2CPL_COURIER = 'b2cpl_courier';
const PONY_COURIER = 'pony_courier';
const SPSR_COURIER_ZEBRA = 'spsr_courier_zebra';
const X5_POSTAMAT = 'x5_postamat';
export const GOODS_RU = 'goods_ru';
export const RUDOSTAVKA_COURIER = 'rudostavka_courier';
export const BERU_RU = 'beru_ru';
const MANUAL_1953 = 'manual_1953';
const MANUAL_1954 = 'manual_1954';
const SBERLOGISTICS_PVZ = 'sberlogistics_pvz';
const SBERLOGISTICS_COURIER = 'sberlogistics_courier';

/**
 * @typedef {Object} DeliveryType
 * @property {string} name - название доставки
   * @property {string} trackingUrl - url где можно отследить трек-номер
 */

/**
 * Виды систем доставок
 * @readonly
 * @type {Object.<string, DeliveryType>}
 */

export const deliveryTypes = Object.freeze({
  [LOGSIS_COURIER]: {
    name: 'Логсис',
    trackingUrl: 'https://logsis.ru/traking/',
  },
  [TOPDELIVERY_COURIER]: {
    name: 'TopDelivery',
    trackingUrl: 'https://www.topdelivery.ru/tracking/',
  },
  [TOPDELIVERY_PVZ]: {
    name: 'TopDelivery',
    trackingUrl: 'https://www.topdelivery.ru/tracking/',
  },
  [SDEK_POSYLKA_SS]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_POSYLKA_SD]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_ECONOM_SD]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_ECONOM_SS]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_KRASNODAR_COURIER]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_KRASNODAR_PVZ]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_ROSTOV_COURIER]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_ROSTOV_PVZ]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_SPB_COURIER]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_SPB_PVZ]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_TUMEN_COURIER]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [SDEK_SPEC_FROM_TUMEN_PVZ]: {
    name: 'СДЭК',
    trackingUrl: 'https://www.cdek.ru/track.html',
  },
  [BOXBERRY_COURIER]: {
    name: 'Boxberry',
    trackingUrl: 'http://boxberry.ru/tracking/',
  },
  [BOXBERRY_PVZ]: {
    name: 'Boxberry',
    trackingUrl: 'http://boxberry.ru/tracking/',
  },
  [ZABBERI_PVZ]: {
    name: 'Связной/Евросеть',
    trackingUrl: 'https://new.zabberi.ru/',
  },
  [DHL_COURIER]: {
    name: 'DHL',
    trackingUrl: 'https://www.dhl.ru/ru/express/tracking.html',
  },
  [POST_USUAL_PREPAYMENT]: {
    name: 'Почта России',
    trackingUrl: 'https://www.pochta.ru/tracking',
  },
  [POST_USUAL_CASH_ON_DELIVERY]: {
    name: 'Почта России',
    trackingUrl: 'https://www.pochta.ru/tracking',
  },
  [POST_ONLINE_COURIER]: {
    name: 'Почта России',
    trackingUrl: 'https://www.pochta.ru/tracking',
  },
  [X5_POSTAMAT]: {
    name: 'Постаматы 5 Post',
    trackingUrl: 'https://fivepost.ru/tracking',
  },
  [RUDOSTAVKA_COURIER]: {
    name: 'РУ Доставка',
    trackingUrl: 'https://ru-dostavka.ru/ecommerce/track_the_shipment/',
  },
  [SBERLOGISTICS_COURIER]: {
    name: 'Сберлогистика',
    trackingUrl: 'https://sberlogistics.ru/tracking',
  },
  [SBERLOGISTICS_PVZ]: {
    name: 'Сберлогистика',
    trackingUrl: 'https://sberlogistics.ru/tracking',
  },
});

export const DELIVERIES_FOR_EMPLOYEES_LIST = [
  MANUAL_FOR_EMPLOYEES_CHEKHOV,
  MANUAL_FOR_EMPLOYEES_IMPERIYA,
  MANUAL_FOR_EMPLOYEES_VIDNOE,
  MANUAL_FOR_EMPLOYEES_ZORGE,
  MANUAL_FOR_EMPLOYEES_CHG,
];

export const COURIER_DELIVERY_LIST = [
  MANUAL_MSK_COURIER_INSIDE_MSK,
  MANUAL_MSK_COURIER_OUTSIDE_MSK,
  DHL_COURIER,
  B2CPL_COURIER,
  SDEK_POSYLKA_SD,
  PONY_COURIER,
  SPSR_COURIER_ZEBRA,
  LOGSIS_COURIER,
  TOPDELIVERY_COURIER,
  RUDOSTAVKA_COURIER,
  BOXBERRY_COURIER,
  SDEK_ECONOM_SD,
  SDEK_SPEC_FROM_KRASNODAR_COURIER,
  SDEK_SPEC_FROM_ROSTOV_COURIER,
  SDEK_SPEC_FROM_SPB_COURIER,
  SDEK_SPEC_FROM_TUMEN_COURIER,
  POST_ONLINE_COURIER,
  SBERLOGISTICS_COURIER,
];

export const POST_DELIVERY_LIST = [
  POST_USUAL_CASH_ON_DELIVERY,
  POST_USUAL_PREPAYMENT,
  POST_ONLINE_COURIER,
];

export const STR_CONTAINS_DELIVERY_PVZ = [
  '_pvz',
  '_ss',
  '_employees',
  '_partner',
  'postamat',
  MANUAL_1953,
  MANUAL_1954,
];

export const PRE_PAYMENT_PVZ = [
  MANUAL_PVZ_FOR_CLIENTS,
  MANUAL_1953,
  MANUAL_1954,
  X5_POSTAMAT,
  BOXBERRY_PVZ,
  SBERLOGISTICS_PVZ,
];
