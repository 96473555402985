import getMetaRoute from '../../utils/getMetaRoute';
import { TITLE_PAGE_ORDER } from '../../utils/constants/globalConst';

const CheckoutPage = () => import(/* webpackChunkName: "CheckoutPageDesktop" */ './DesktopPage.vue').then(m => m.default || m);

export default [
  {
    path: '/personal/order/make/',
    component: CheckoutPage,
    name: 'checkout-page',
    meta: getMetaRoute({
      ddmCategory: 'Checkout',
      ddmType: 'checkout',
      title: TITLE_PAGE_ORDER,
    }),
  },
];
