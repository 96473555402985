/// <reference path="../../typedefs.js" />

/**
 * изображения для издателя
 * @typedef {Object} publisherImg
 * @property {string} code - код
 * @property {string} img - путь до изображения
 * @property {string} img2X - путь до изображения 2x
 * @property {string} type - тип изображения
 */

/**
 * Список издательств
 * @readonly
 * @const
 * @type {Array<publisherImg>} PUBLISHERS_RATINGS
 */

export const PUBLISHERS_RATINGS = [
  {
    code: 'eksmo',
    img: '/_ssr/img/logo-publisher/001_eksmo.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'ast',
    img: '/_ssr/img/logo-publisher/002_ast.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'origami',
    img: '/_ssr/img/logo-publisher/003_origami.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'fanzon',
    img: '/_ssr/img/logo-publisher/004_fanzon.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'grandmaster',
    img: '/_ssr/img/logo-publisher/005_grandmaster.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'corpus',
    img: '/_ssr/img/logo-publisher/006_corpus.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'drofa',
    img: '/_ssr/img/logo-publisher/007_drofa.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'bombora',
    img: '/_ssr/img/logo-publisher/008_bombora_v2.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'komilfo',
    img: '/_ssr/img/logo-publisher/009_komilfo.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'mif',
    img: '/_ssr/img/logo-publisher/010_mif.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'kanceksmo',
    img: '/_ssr/img/logo-publisher/011_kanceksmo.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'breadsalt',
    img: '/_ssr/img/logo-publisher/012_breadsalt.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'detstvo',
    img: '/_ssr/img/logo-publisher/013_detstvo.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'inspiria',
    img: '/_ssr/img/logo-publisher/014_inspiria.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'lb',
    img: '/_ssr/img/logo-publisher/015_lb.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'inspiria-new',
    img: '/_ssr/img/logo-publisher/016_inspiria-new.png',
    img2X: '',
    type: 'png',
  },
  {
    code: 'mesharykova',
    img: '/_ssr/img/logo-publisher/017_mesharykova.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'azbuka',
    img: '/_ssr/img/logo-publisher/018_azbuka.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'alpina',
    img: '/_ssr/img/logo-publisher/019_alpina.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'rosmen',
    img: '/_ssr/img/logo-publisher/020_rosmen.svg',
    img2X: '',
    type: 'svg',
  },
  {
    code: 'astrel-spb',
    img: '/_ssr/img/logo-publisher/ast/astrel-spb.png',
    img2X: '/_ssr/img/logo-publisher/ast/astrel-spb@2x.png',
    type: 'png',
  },
  {
    code: 'avanta',
    img: '/_ssr/img/logo-publisher/ast/avanta.png',
    img2X: '/_ssr/img/logo-publisher/ast/avanta@2x.png',
    type: 'png',
  },
  {
    code: 'janr',
    img: '/_ssr/img/logo-publisher/ast/janr.png',
    img2X: '/_ssr/img/logo-publisher/ast/janr@2x.png',
    type: 'png',
  },
  {
    code: 'kladez',
    img: '/_ssr/img/logo-publisher/ast/kladez.png',
    img2X: '/_ssr/img/logo-publisher/ast/kladez@2x.png',
    type: 'png',
  },
  {
    code: 'leningrad',
    img: '/_ssr/img/logo-publisher/ast/leningrad.png',
    img2X: '/_ssr/img/logo-publisher/ast/leningrad@2x.png',
    type: 'png',
  },
  {
    code: 'lingua',
    img: '/_ssr/img/logo-publisher/ast/lingua.png',
    img2X: '/_ssr/img/logo-publisher/ast/lingua@2x.png',
    type: 'png',
  },
  {
    code: 'mainstream',
    img: '/_ssr/img/logo-publisher/ast/mainstream.png',
    img2X: '/_ssr/img/logo-publisher/ast/mainstream@2x.png',
    type: 'png',
  },
  {
    code: 'malish',
    img: '/_ssr/img/logo-publisher/ast/malish.png',
    img2X: '/_ssr/img/logo-publisher/ast/malish@2x.png',
    type: 'png',
  },
  {
    code: 'neoclassic',
    img: '/_ssr/img/logo-publisher/ast/neoclassic.png',
    img2X: '/_ssr/img/logo-publisher/ast/neoclassic@2x.png',
    type: 'png',
  },
  {
    code: 'obrazov',
    img: '/_ssr/img/logo-publisher/ast/obrazov.png',
    img2X: '/_ssr/img/logo-publisher/ast/obrazov@2x.png',
    type: 'png',
  },
  {
    code: 'ogiz',
    img: '/_ssr/img/logo-publisher/ast/ogiz.png',
    img2X: '/_ssr/img/logo-publisher/ast/ogiz@2x.png',
    type: 'png',
  },
  {
    code: 'prime',
    img: '/_ssr/img/logo-publisher/ast/prime.png',
    img2X: '/_ssr/img/logo-publisher/ast/prime@2x.png',
    type: 'png',
  },
  {
    code: 'resh',
    img: '/_ssr/img/logo-publisher/ast/resh.png',
    img2X: '/_ssr/img/logo-publisher/ast/resh@2x.png',
    type: 'png',
  },
  {
    code: 'shubina-clear',
    img: '/_ssr/img/logo-publisher/ast/shubina-clear.png',
    img2X: '/_ssr/img/logo-publisher/ast/shubina-clear@2x.png',
    type: 'png',
  },
  {
    code: 'vremena',
    img: '/_ssr/img/logo-publisher/ast/vremena.png',
    img2X: '/_ssr/img/logo-publisher/ast/vremena@2x.png',
    type: 'png',
  },
  {
    code: 'willie-wimkie',
    img: '/_ssr/img/logo-publisher/ast/willie-wimkie.png',
    img2X: '/_ssr/img/logo-publisher/ast/willie-wimkie@2x.png',
    type: 'png',
  },
  {
    code: 'willie-wimkie-clear',
    img: '/_ssr/img/logo-publisher/ast/willie-wimkie-clear.png',
    img2X: '',
    type: 'png',
  },
];

/**
 * иконки для категорий рейтинга
 * @typedef {Object} categoryImg
 * @property {string} code - код
 * @property {string} icon - имя иконки
 */

/**
 * Список иконок для категорий
 * @readonly
 * @const
 * @enum {categoryImg} BY_CATEGORIES_RATINGS
 */

export const BY_CATEGORIES_RATINGS = Object.freeze({
  children: {
    code: 'children-books',
    icon: 'Books_for_children_24',
  },
  teenager: {
    code: 'teen-books',
    icon: 'Books_for_teenager_24',
  },
  scienceFiction: {
    code: 'science-fiction',
    icon: 'Science_fiction',
  },
  detectives: {
    code: 'detectives',
    icon: 'Detectives_32',
  },
  action: {
    code: 'action',
    icon: 'action',
  },
  business: {
    code: 'business',
    icon: 'Business_24',
  },
  personalityPsychology: {
    code: 'personality-psychology',
    icon: 'Psychology_24',
  },
  popularPsychology: {
    code: 'popular-psychology',
    icon: 'Popular_psychology',
  },
  selfEducation: {
    code: 'self-education',
    icon: 'Self_education_24',
  },
  memoirs: {
    code: 'memoirs',
    icon: 'Imaginative_literature_24',
  },
  esoteric: {
    code: 'esoteric',
    icon: 'Esoteric',
  },
  publicism: {
    code: 'publicism',
    icon: 'Journalism_24',
  },
  medicine: {
    code: 'medicine',
    icon: 'Non_fiction_24',
  },
  handicraft: {
    code: 'handicraft',
    icon: 'Creation_24',
  },
  cooking: {
    code: 'cooking',
    icon: 'Cooking_32',
  },
  comics: {
    code: 'comics',
    icon: 'Manga_24',
  },
  fantasy: {
    code: 'fantasy',
    icon: 'Fantasy_32',
  },
  prose: {
    code: 'prose',
    icon: 'Prose',
  },
});

/**
 * ранжирование рейтинга для общей категории
 * @typedef {string} ratingRangeCommonCategory
 */

/**
 * @type {ratingRangeCommonCategory}
 */
export const TOP_HUNDRED_TEXT = 'топ 200';

/**
 *
 * @type {ratingRangeCommonCategory}
 */
export const TOP_FIFTY_TEXT = 'топ 50';

/**
 *
 * @type {ratingRangeCommonCategory}
 */
export const TOP_TWENTY_TEXT = 'топ 20';

/**
 * наименование рейтинга категории
 * @typedef {string} ratingCategoryName
 */

/**
 * @type {ratingCategoryName}
 */
export const COMMON_CATEGORY = 'Общая категория';

/**
 * @type {ratingCategoryName}
 */
export const PUBLISHING_CATEGORY = 'Лучшие книги издательств';

/**
 * @type {ratingCategoryName}
 */
export const BY_CATEGORIES_PER_MONTH = 'Топ книг месяца по категориям';

/**
 * период для общей категории рейтинга
 * @type {string}
 */
export const BOOKS_OF_THE_YEAR = 'year';

/**
 * период для общей категории рейтинга
 * @type {string}
 */
export const BOOKS_OF_THE_MONTH = 'month';

/**
 * тип страницы для детальной страницы рейтинга
 * @typedef {Object} ratingPageType
 * @property {string} id - ид
 * @property {string} page - тип страницы
 */

/**
 * список страниц для детальной страницы рейтинга для фасета фильтров
 * @const
 * @enum
 * @type {Object<string, ratingPageType>} RATING_PAGE_TYPES
 */
export const RATING_PAGE_TYPES = Object.freeze({
  ratingCommon: {
    id: 'c54d69ed-c19c-4033-9b00-310fa3bedcde',
    page: 'rating_common',
  },
  ratingPublishers: {
    id: '466d38b7-3d35-46b9-891e-dfcf3dbdef93',
    page: 'rating_publishers',
  },
  ratingByCategory: {
    id: '18365967-788e-4cbc-a632-97a842f79684',
    page: 'rating_by_category',
  },
});

/**
 * ошибки рейтинга
 * @type {ErrorObject} ERROR_RATING_NOT_FOUND
 */
export const ERROR_RATING_NOT_FOUND = Object.freeze({
  code: 'd9130c52-0ba7-4400-9516-c09254a65d31',
  message: 'Рейтинг не найден',
});

export const ENABLED_RATINGS = [
  RATING_PAGE_TYPES.ratingCommon.id,
  RATING_PAGE_TYPES.ratingByCategory.id,
];

export const DISABLED_RATINGS = [
  'month',
  'week',
  'novelty',
  'pre-orders',
];
