// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import {
  buildCollection,
  buildErrorCollection,
} from '@/utils/formatters/processingOpenApi';
import ServiceError from '@/utils/errors/ServiceError';
import { ERROR_NOT_FOUND, ERROR_SERVER_ERROR } from '@/utils/constants/globalConst';

export default class SiteService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  getSiteMap() {
    return this.mainHttp.get('/api/v1/sitemap/')
      .then(({ data: { data: dataRes } }) => Promise.resolve(buildCollection(dataRes)))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(new ServiceError(firstError));
        }
        return Promise.reject(new ServiceError(e));
      });
  }

  /**
   * Получение настроек сайта
   * @return {*}
   */
  getSiteSettings() {
    return this.mainHttp
      .get('/api/v1/site-settings/')
      .then(({ data: { data } }) => data)
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            return Promise.reject(new ServiceError(ERROR_NOT_FOUND));
          }
          if (e.response.status === 500) {
            return Promise.reject(new ServiceError(ERROR_SERVER_ERROR));
          }
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return Promise.reject(new ServiceError(firstError));
        }
        return Promise.reject(new ServiceError(e));
      });
  }
}
