import { reactive, toRefs } from '@nuxtjs/composition-api';
import useHttpClient from '@/compositions/useHttpClient';

const useProductsRecommendation = () => {
  const httpClient = useHttpClient();

  const localState = reactive({
    productsRecommendation: [],
    isProductsRecommendationLoading: false,
    isEmptyProducts: false,
  });

  /**
   * Получаем список рекомендуемых товаров
   * @param {Object} [payload={}]
   * @param {number} [payload.limit=15]
   * @param {number} [payload.pageNumber=1]
   * @return {Promise<void>}
   */
  const getProductsRecommendation = async (payload = {}) => {
    const { limit = 15, pageSize = 35 } = payload;
    localState.isProductsRecommendationLoading = true;
    await httpClient.recommendation.getRecommendationProduct({
      pageSize,
      ...payload,
    })
      .then((res) => {
        localState.isEmptyProducts = !res.items.length;
        const itemsWithoutAdult = res.items.filter(item => !item.isAdult);
        localState.productsRecommendation = limit
          ? itemsWithoutAdult.slice(0, limit)
          : itemsWithoutAdult;
      })
      .catch(() => {
        localState.productsRecommendation = [];
        localState.isEmptyProducts = true;
      })
      .finally(() => {
        localState.isProductsRecommendationLoading = false;
      });
  };

  return {
    ...toRefs(localState),
    getProductsRecommendation,
  };
};

export default useProductsRecommendation;
