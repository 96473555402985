<template>
  <div
    :class="{ _mobile: isMobile }"
    class="authorization-success"
  >
    <img src="/_ssr/img/authorization/authorization-success.svg" />
    <h3 class="authorization-success__title">
      Спасибо за регистрацию!
    </h3>
    <p class="authorization-success__desc">
      Бонусная карта появится в вашем профиле в течение дня.
    </p>
    <promo-code-info class="authorization-success__promo-code" />
    <button-control
      :is-block="true"
      class="authorization-success__button"
      @click="clickButtonHandler"
    >
      Перейти к покупкам
    </button-control>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { ButtonControl } from '@book24/ui-components';

import PromoCodeInfo from '@/components/PromoCode/PromoCodeInfo/PromoCodeInfo.vue';

export default defineComponent({
  name: 'AuthorizationSuccess',
  components: { PromoCodeInfo, ButtonControl },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const clickButtonHandler = () => emit('onClickButton');
    return { clickButtonHandler };
  },
});
</script>

<style lang="less">
.authorization-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    .h3-desktop;

    margin-top: 0;
    margin-bottom: @size-x4;
  }

  &__desc {
    .p2;

    max-width: 80%;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__button {
    margin-top: @size-x10;
  }

  &__promo-code {
    width: 100%;
    margin-top: @size-x4;
  }
}
</style>
