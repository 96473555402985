<template>
  <picture>
    <source
      v-if="webpSrcset.length"
      type="image/webp"
      :srcset="webpSrcset"
    />
    <source
      v-if="item.x2"
      :type="`image/${getExt(item.x2)}`"
      :srcset="`${item.x2} 2x`"
    />
    <img
      :class="classNameImg"
      :src="item.x1"
      :alt="alt"
      :title="title"
    />
  </picture>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PictureForLazyEmbla',
  props: {
    item: {
      type: Object,
      required: true,
    },
    classNameImg: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { item } = toRefs(props);

    const webpSrcset = computed({
      get: () => {
        let src = '';
        if (item.value.webpX1) {
          src += item.value.webpX1;
        }
        if (item.value.webpX2) {
          src += `, ${item.value.webpX2} 2x`;
        }

        return src;
      },
      set: () => {},
    });

    const getExt = pic => pic.split('.').pop();

    return {
      webpSrcset,
      getExt,
    };
  },
});
</script>
