import { defaultErrorsList } from '@/utils/formatters/formattedError';

export const ERRORS_GET_SALE_DETAIL = {
  ...defaultErrorsList,
  saleNotFound: {
    code: '7c98bdf5-47a6-43c9-a341-fec81e621c8a',
    message: 'Страница акции не найдена',
  },
  isSaleNotFound(code) {
    return code === this.saleNotFound.code;
  },
};

export const SALE_FORM_SUBSCRIBE = Object.freeze({
  type: 'saleSubscribe',
  error: 'Что-то пошло не так, попробуйте повторить попытку позже',
});
