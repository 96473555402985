import getMetaRoute from '../../utils/getMetaRoute';

const BookPage = () => import(/* webpackChunkName: "BookPage" */ './BookQRPage.vue').then(m => m.default || m);

export default [
  {
    path: '/bookqr/',
    component: BookPage,
    props: route => ({
      bookId: Number(route.query.bookId),
      externalPrice: route.query.price ? Number(route.query.price) : undefined,
      shopId: route.query.PT_ID ? Number(route.query.PT_ID) : undefined,
    }),
    name: 'bookqr-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
      title: '',
    }),
  },
  {
    path: '/book/',
    redirect: '/bookqr/',
    name: 'book-page',
    meta: getMetaRoute({
      ddmCategory: 'Content',
      ddmType: 'content',
      title: '',
    }),
  },
];
