<template>
  <app-collapse
    class="_typography"
    :height="0"
    :offset-top="0"
    show-text="Развернуть"
    hide-text="Свернуть"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </app-collapse>
</template>
<script>
import AppCollapse from '@/components/AppCollapse/index';

export default {
  name: 'TypographyCollapse',
  components: { AppCollapse },
  inheritAttrs: false,
};
</script>

<style lang="less">
.collapse-panel {
  &._typography {
    margin-bottom: @size-x4;
  }

  &._typography &__text {
    padding-bottom: @size-x4;
  }

  &._typography &__more {
    margin-top: 0;
  }
}
</style>
