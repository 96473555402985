/**
 * Нормализаует ответ по партнёрским ссылкам
  * @param {Object} data
 */
export default function formattedInterests(data) {
  const { data: { data: { data: { attributes } } } } = data;
  const links = attributes;

  return links;
}
