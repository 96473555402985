/// <reference path="../../typedefs.js" />

import { nbspCharacter } from '@/utils/constants/globalConst';

/**
 * @typedef {Object} PartnerType
 * @property {string} title
 * @property {string} icon
 */

/**
 * @readonly
 * @const
 * @type {Object} B2BPartners
 * @property {string} title
 * @property {Array.<PartnerType>} list - список партнеров
 */

export const B2B_PARTNERS = Object.freeze({
  title: 'Книжные новинки, бестселлеры и учебная литература оптом',
  list: [
    { title: 'Для юридических лиц', icon: 'legalPartner' },
    { title: 'Для индивидуальных предпринимателей', icon: 'entrepreneurParnter' },
    { title: 'Для родительских комитетов, преподавателей', icon: 'committePartner' },
  ],
});

/**
 * @readonly
 * @type {Object} ERROR_MESSAGES
 * @property {ErrorObject} form - ошибка для формы
 * @property {ErrorObject} payloadForClientRequestRequired - ошибка если сервис не получил payload
 * @const
 */
export const ERROR_MESSAGES = Object.freeze({
  form: {
    code: 'c35bb1f7-6f40-4c38-ae12-fd5e4eb79ea9',
    message: 'Что-то пошло не так, попробуйте повторить попытку позже',
  },
  payloadForClientRequestRequired: {
    code: '',
    message: 'Отсутствует обязательный параметр payload',
  },
});

/**
 * @readonly
 * @type {Array.<string, []>} B2B_BENEFITS
 * @const
 */
export const B2B_BENEFITS = [
  'Доставляем во все регионы России',
  '5 лет стабильной работы',
  '60 000+ товаров',
  '30+ издательств России',
];

/**
 * @typedef {Object} B2BProfitItem
 * @property {string} title
 * @property {string} text
 */

/**
 * @readonly
 * @const
 * @type {Object} B2B_PROFITS
 * @property {string} title
 * @property {Array.<B2BProfitItem>} list - список предложений
 */
export const B2B_PROFITS = Object.freeze({
  title: 'Почему выбирают нас?',
  list: [
    {
      title: 'Выгодные цены',
      text: 'В нашем магазине действует гибкая система скидок и бонусов напрямую от издательств, входящих в группу компаний «Эксмо-АСТ», которая позволяет нашим партнёрам получать лучшие ценовые предложения на рынке. Наши клиенты могут рассчитывать на персональные предложения в зависимости от объема заказа.',
    },
    {
      title: 'Широкий ассортимент',
      text: `В каталоге представлено более 60${nbspCharacter}000 товаров. Book24.ru одним из первых получает книжные новинки ведущих российских издательств и предлагает их покупателям по лучшей цене. На книги, которые только планируются к выходу, доступен предзаказ.`,
    },
    {
      title: '100% качество',
      text: 'Мы гарантируем качество доставляемых книг. Книги отправляются вам прямо со склада издательства. Все заказы тщательно упаковываются для исключения их повреждения во время транспортировки.',
    },
    {
      title: 'Экономия времени и денег',
      text: 'Книги для ваших сотрудников или партнёров, или учебная литература на весь класс в одном месте с доставкой в офис или сразу в школу. Доставка по всей России, а также почти по всему миру.',
    },
    {
      title: 'Профессиональные консультации',
      text: 'Ваш личный менеджер поможет подобрать товар и способ доставки, оформит необходимые документы и будет отслеживать выполнение Вашего заказа.',
    },
  ],
});

/**
 * @typedef {Object} B2BWorkItem
 * @property {string} title
 * @property {string} link
 */

/**
 * @readonly
 * @const
 * @type {Object} B2B_WORK
 * @property {string} title
 * @property {Array.<string, []>} list - пункты как мы работаем
 * @property {Array.<B2BWorkItem>} filesList - список файлов для скачивания
 */
export const B2B_WORK = Object.freeze({
  title: 'Как мы работаем',
  list: [
    'Вы оставляете заявку',
    'Менеджер согласовывает условия заказа',
    'Оплата',
    'Доставка',
    'Получение вами заказа',
  ],
  filesList: [
    {
      title: 'Скачать шаблон договор-счёта',
      link: 'https://ndf.book24.ru/docs/b2b/%D0%A1%D1%87%D0%B5%D1%82-%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80.pdf',
    },
    {
      title: 'Скачать шаблон счёта',
      link: 'https://ndf.book24.ru/docs/b2b/%D0%A1%D1%87%D0%B5%D1%82.pdf',
    },
  ],
});

/**
 * @typedef {Object} B2BReviewItem
 * @property {string} name
 * @property {string} membership
 * @property {string} text
 */

/**
 * @readonly
 * @const
 * @type {Object} B2B_REVIEWS
 * @property {string} title
 * @property {Array.<B2BReviewItem>} list - список отзывов
 */
export const B2B_REVIEWS = Object.freeze({
  title: 'Отзывы наших клиентов',
  list: [
    {
      name: 'Ирина Мельникова',
      membership: 'Член родительского комитета',
      text: 'Заказываем второй год на класс рабочие тетради по всем предметам, проверочные, альбомы, летом хорошие акции для началки, дарили раскраски, наклейки, дети рады. Разные варианты доставки и оплаты. По срокам не подводили. Хороший интернет-магазин, рекомендую.',
    },
    {
      name: 'Елена',
      membership: 'ИП Радужная З.Й.',
      text: 'Не так давно открыла для себя Book24. Нужны были подарки к мероприятию к определенной дате, менеджер помогла со всеми вопросами, заказ получился на 70 кг. Доставили прямо в офис. В целом ассортименте много новинок от издательств Эксмо и АСТ, индивидуальный подход. Буду и дальше сотрудничать.',
    },
  ],
});

/**
 * @typedef {Object} B2BQuestionItem
 * @property {string} label - текст для селектора
 * @property {string} value - значение для селектора
 */

/**
 * @const
 * @readonly
 * @typedef {Array.<B2BQuestionItem>} B2B_FORM_QUESTIONS_LIST - список вопросов для формы
 */
export const B2B_FORM_QUESTIONS_LIST = [
  {
    label: 'Запрос договора',
    value: 'contract request',
  },
  {
    label: 'Запрос счёта',
    value: 'account request',
  },
  {
    label: 'Вопрос по товару',
    value: 'product question',
  },
  {
    label: 'Вопрос по скидке',
    value: 'sale question',
  },
  {
    label: 'Вопрос по оплате',
    value: 'payment question',
  },
  {
    label: 'Вопрос по доставке',
    value: 'delivery question',
  },
  {
    label: 'Вопрос по возврату',
    value: 'return question',
  },
  {
    label: 'Другое',
    value: 'another question',
  },
];

/**
 * @typedef {Object} B2BForm
 * @property {string} title - заголовок который приложен к апи к конкретной форме
 * @property {string} type - тип конкретной формы для апи
 * @property {string} success
 * @property {ErrorObject} error - ошибка формы
 * @property {Array<B2BQuestionItem>|undefined} [questions] - вопросы для формы
 */

/**
 * @readonly
 * @const
 * @type {B2BForm} B2B_FORM_REQUEST
 */
export const B2B_FORM_REQUEST = Object.freeze({
  title: 'Заявка на расчёт',
  type: 'calculationRequest',
  success: 'Ваша заявка успешно отправлена, менеджер свяжется с вами в ближайшее время.',
  error: ERROR_MESSAGES.form,
});

/**
 * @readonly
 * @const
 * @type {B2BForm} B2B_FORM_CALLBACK
 */
export const B2B_FORM_CALLBACK = Object.freeze({
  title: 'Запрос звонка менеджера',
  type: 'callRequest',
  success: 'Ваша заявка успешно отправлена',
  error: ERROR_MESSAGES.form,
});

/**
 * @readonly
 * @const
 * @type {B2BForm} B2B_FORM_QUESTIONS
 */
export const B2B_FORM_QUESTIONS = Object.freeze({
  title: 'Задать вопрос',
  type: 'askQuestion',
  questions: B2B_FORM_QUESTIONS_LIST,
  success: 'Ваша заявка успешно отправлена',
  error: ERROR_MESSAGES.form,
});

/**
 * @readonly
 * @const
 * @type {B2BForm} B2B_PRICE_LIST_FORM
 */
export const B2B_PRICE_LIST_FORM = Object.freeze({
  title: 'Получить прайс',
  type: 'getPrice',
  success: 'Ваша заявка успешно отправлена',
  error: ERROR_MESSAGES.form,
});

/**
 * @readonly
 * @const
 * @type {Array.<string, []>} B2B_INSTRUCTION_PRICE_LIST
 */
export const B2B_INSTRUCTION_PRICE_LIST = [
  'Для оформления заказа необходимо зарегистрироваться на сайте интернет-магазина.',
  'Добавить интересующие Вас товары в корзину в нужном количестве.',
  'При оформлении заказа выбрать тип плательщика (физическое или юридическое лицо).',
  'После оформления заказа менеджер свяжется с Вами для подтверждения скидки и выбора способа доставки.',
];

/**
 * @typedef {Array.<string, []>} B2BSalesList
 */

/**
 * @readonly
 * @const
 * @type {Object} B2B_SALES_CALCULATIONS - скидки
 * @property {B2BSalesList} byVolume - по объему сделок
 * @property {B2BSalesList} byPrice - по цене
 */

export const B2B_SALES_CALCULATIONS = Object.freeze({
  byVolume: [
    `10${nbspCharacter}000 - 25${nbspCharacter}000${nbspCharacter}р.`,
    `25${nbspCharacter}000${nbspCharacter}р - 50${nbspCharacter}000${nbspCharacter}р.`,
    `50${nbspCharacter}000${nbspCharacter}р - 100${nbspCharacter}000${nbspCharacter}р.`,
    `100${nbspCharacter}000${nbspCharacter}р - 300${nbspCharacter}000${nbspCharacter}р.`,
    `от 300${nbspCharacter}000${nbspCharacter}р.`,
  ],
  byPrice: ['20%', '22%', '25%', '30%', 'Условия обсуждаются индивидуально'],
});

