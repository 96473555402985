<template>
  <div class="products-scroller">
    <div
      v-for="(product, index) in products"
      :key="product.id"
      class="products-scroller__item"
    >
      <component
        :is="isSmall ? 'product-card-small' : 'product-card-mobile-vertical'"
        :product="product"
        :params-link="paramsLink"
        :is-line-price="isLinePrice"
        :product-index="index"
        is-normal-font
        show-buttons
        with-label
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import ProductCardSmall from '@/components/ProductCardSmall/index.vue';
import ProductCardMobileVertical from '@/components/ProductCard/ProductCardMobile/index.vue';

export default defineComponent({
  name: 'ProductScroller',
  components: {
    ProductCardSmall,
    ProductCardMobileVertical,
  },
  props: {
    products: { type: Array, default: () => ([]) },
    paramsLink: { type: Object, default: () => ({}) },
    isSmall: { type: Boolean, default: true },
    isLinePrice: { type: Boolean, default: false },
  },
});
</script>

<style lang="less">
.products-scroller {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  align-items: stretch;
  padding-bottom: @size-x2;

  &__item {
    flex-shrink: 0;

    &:not(:first-child) {
      margin-left: @size-x3;
    }
  }
}
</style>
