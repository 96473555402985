// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import {
  buildCollection,
  buildElement,
  buildErrorCollection,
} from '@/utils/formatters/processingOpenApi';
import formattedPageBreadcrumbs from '@/utils/formatters/formattedPageBreadcrumbs';
import { ERROR_NOT_FOUND } from '@/utils/constants/globalConst';
import { convertISOFromDateFormat } from '@/utils/dateTime';
import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';
import formattedProduct from '@/utils/formatters/formattedProduct';

export default class SelectionService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * @typedef {Object} OptionsGetSelectionPage
   * @property {boolean} [isGetBreadcrumbs=false] - нужно ли получать хлебные крошки
   * @property {boolean} [isGetSeoMeta=false] - нужно ли получать SEO данные
   * @property {boolean} [isGetPageConstructor=false] - нужно ли получать данные о конструкторе
   * @property {boolean} [isGetPageConstructorBlock=false] - нужно ли получать данные о блоках конструктора
   */

  /**
   * Возвращает информацию о странице подборки
   * @param {number|string} selectionId
   * @param {OptionsGetSelectionPage} options
   * @return {Promise<unknown>}
   */
  getSelectionPage(selectionId, options = {}) {
    return new Promise((resolve, reject) => {
      if (!selectionId) reject(new ServiceError('[SelectionService:getSelectionPage] selectionId обязателен для заполнения'));

      const {
        isGetBreadcrumbs = false,
        isGetSeoMeta = false,
        isGetPageConstructor = false,
        isGetPageConstructorBlock = false,
      } = options;

      const include = [];
      if (isGetBreadcrumbs) include.push('breadcrumbs');
      if (isGetSeoMeta) include.push('seoMeta');
      if (isGetPageConstructor) include.push('pageConstructor');
      if (isGetPageConstructorBlock) include.push('pageConstructor.pageConstructorBlock');

      const dataRequest = {};
      if (include.length) dataRequest.include = include.join(',');

      this.mainHttp.get(`/api/v1/page-selection/${selectionId}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const formattedElement = buildElement(dataRes);
          return resolve({
            detailPage: {
              title: formattedElement?.relationships?.seoMeta.h1 || null,
              previewPicture: formattedElement.previewPicture,
              detailPicture: formattedElement.detailPicture,
              name: formattedElement.name,
              id: formattedElement.id,
              code: formattedElement.code,
              previewText: formattedElement.previewText,
              detailText: formattedElement.detailText,
              dateActiveFrom: formattedElement.dateActiveFrom
                ? convertISOFromDateFormat(formattedElement.dateActiveFrom, 'dd.MM.yyyy')
                : null,
              dateActiveTo: formattedElement.dateActiveTo
                ? convertISOFromDateFormat(formattedElement.dateActiveTo, 'dd.MM.yyyy')
                : null,
              ...formattedElement.properties,
              pageConstructorBlock: formattedElement.properties.isPageConstructor
                ? formattedElement.relationships.pageConstructor.relationships.pageConstructorBlock
                : null,
            },
            attrsPage: {
              crumbs: formattedPageBreadcrumbs(formattedElement?.relationships?.breadcrumbs || []),
              title: formattedElement?.relationships?.seoMeta.title || null,
              description: formattedElement?.relationships?.seoMeta.description || null,
            },
          });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Возвращает товары подборки
   * @param {Object} data
   * @param {number|string} [data.selectionId] - ID подборки
   * @param {string} [data.match=''] - Фильтр match для сфинкса
   * @param {string} [data.filterPreset=''] - Заготовленный пресет сортировки
   * @param {string} [data.filter=''] - Фильтр
   * @param {string} [data.sortPreset=''] - Заготовленный пресет сортировки
   * @param {number} [data.pageSize=15] - Количество товаров на странице
   * @param {number} [data.pageNumber=1] - Номер страницы в пагинации
   * @param {boolean} [data.isGetAuthors=true] - Нужно ли получить автора товара
   * @param {boolean} [data.isGetSeries=false] - Нужно ли получить серии товара
   * @param {boolean} [data.isGetBadge=true] - Нужно ли получить бейджи товара
   * @param {boolean} [data.isGetProductText=false] - Нужно ли получить описание товара
   * @param {string} [data.getQuotes=''] - Получить цитаты товаров, строка вида quote:limit(2|0)
   * @param {boolean} [data.isGetPublishingHouse=false] - Нужно ли получить издателя товара
   * @param {boolean} [data.isGetCategory=true] - Нужно ли получить категорию товара
   * @param {boolean} [data.isGetParentCategory=false] - Нужно ли получить родительские категории товара
   * @param {boolean} [data.isGetProductStats=false] - Нужно ли получать статистику по товару
   * @return {Promise<unknown>}
   */

  getSelectionProductsList(data = {}) {
    const {
      selectionId,
      filterPreset = '',
      filter = '',
      sortPreset = '',
      pageSize = 20,
      pageNumber = 1,
      isGetAuthors = true,
      isGetSeries = false,
      isGetBadge = true,
      isGetProductText = false,
      isGetCategory = true,
      isGetProductStats = false,
    } = data;

    const include = [];
    if (isGetAuthors) include.push('author');
    if (isGetSeries) include.push('series');
    if (isGetBadge) include.push('badge');
    if (isGetProductText) include.push('productText');
    if (isGetCategory) include.push('category');
    if (isGetProductStats) include.push('productStats');

    let dataRequest = { 'per-page': pageSize, page: pageNumber, id: selectionId };

    if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
    if (filter) dataRequest = { ...dataRequest, filter };
    if (sortPreset) dataRequest = { ...dataRequest, sortPreset };
    if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

    return new Promise((resolve, reject) => this.mainHttp.get(`/api/v1/page-selection/${selectionId}/product/`, dataRequest)
      .then(({ data: { data: dataRes } }) => {
        const { list, pagination } = buildCollection(dataRes, true);
        const items = list.map((item) => {
          const element = formattedProduct(item);
          if (isGetProductText) {
            element.productText = {
              advertisingBlockHeader: item.relationships.productText.advertisingBlockHeader,
              advertisingBlockText: item.relationships.productText.advertisingBlockText,
              detailDescription: item.relationships.productText.detailDescription,
              additionalDescription: item.relationships.productText.additionalDescription,
              showAdditionalDescription: item.relationships.productText.showAdditionalDescription,
            };
          }

          if (isGetProductStats) {
            element.productStats = {
              purchased: item.relationships.productStats.purchased,
              reviewTotal: item.relationships.productStats.reviewTotal,
            };
          }

          if (isGetSeries && Array.isArray(item.series)) {
            element.series.forEach((seriesItem) => {
              if (+seriesItem.numBooks <= 1) {
                seriesItem.link = null;
              }
            });
          }

          return element;
        }) || [];
        return resolve({
          items,
          pagination,
        });
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }

  /**
   * @typedef {Object} SelectionItem
   * @property {string} title - название элемента
   * @property {string} link - ссылка на элемент
   * @property {string} imageOriginal - ссылка на оригинал картинки элемента
   * @property {Object} image - объект ресайза картинки элемента
   */

  /**
   * @typedef {Object} PayloadGetSelectionsList
   * @property {number} [pageSize=10] - кол-во элементов на странице
   * @property {number} [pageNumber=1] - номер страницы
   * @property {string} [filterPreset=''] - пресет фильтра
   * @property {string} [sortPreset=''] - пресет сортировки
   * @property {string} [filter=''] - фильтр
   */

  /**
   * @typedef {Object} OptionsGetSelectionsList
   * @property {number} [sizePicWidth=365] - размер ширины ресайза картинки
   * @property {number} [sizePicHeight=180] - размер высоты ресайза картинки
   */

  /**
   * Возвращает список подборок
   * @param {PayloadGetSelectionsList} payload
   * @param {OptionsGetSelectionsList} options
   * @returns {Promise<{pagination, items: SelectionItem[]}>}
   */
  getSelectionsList(payload = {}, options = {}) {
    const {
      pageSize = 10,
      pageNumber = 1,
      filterPreset = '',
      sortPreset = '',
      filter = '',
    } = payload;

    const {
      sizePicWidth = 365,
      sizePicHeight = 180,
    } = options;

    const dataRequest = {
      PAGE: pageNumber,
      PAGE_SIZE: pageSize,
    };

    if (filterPreset) dataRequest.FILTER_PRESET = filterPreset;
    if (sortPreset) dataRequest.SORT_PRESET = sortPreset;
    if (filter) dataRequest.FILTER = filter;

    return this.mainHttp.get('/api/v1/content/lists/selections/', dataRequest)
      .then(({ data: { data: dataRes } }) => ({
        items: dataRes?.items.map(item => ({
          title: item.name,
          link: item?.detailPageUrl || null,
          image: formattedPicturesCdnBook24(item.previewPicture?.original?.src, {
            sizePicWidth,
            sizePicHeight,
          }),
          imageOriginal: item.previewPicture?.original?.src,
        })) || [],
        pagination: dataRes?.meta || {},
      }))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * @typedef {Object} PayloadGetConstructorProducts
   * @property {string} [getQuotes=''] - Получить цитаты товаров, строка вида quote:limit(2|0)
   * @property {boolean} [isGetAuthors=true] - Нужно ли получить автора товара
   * @property {boolean} [isGetSeries=false] - Нужно ли получить серии товара
   * @property {boolean} [isGetBadge=true] - Нужно ли получить бейджи товара
   * @property {boolean} [isGetProductText=false] - Нужно ли получить описание товара
   * @property {boolean} [isGetPublishingHouse=false] - Нужно ли получить издателя товара
   * @property {boolean} [isGetCategory=true] - Нужно ли получить категорию товара
   * @property {boolean} [isGetParentCategory=false] - Нужно ли получить родительские категории товара
   * @property {boolean} [isGetProductStats=false] - Нужно ли получать статистику по товару
   * @property {boolean} [isGetOrsProduct=true] - Нужно ли получать данные товара из ORS
   */

  /**
   * Возвращает товары блока подборки-конструктора
   * @param {number|string} constructorId - id конструктора
   * @param {number|string} blockId - id блока
   * @param {PayloadGetConstructorProducts} payload
   * @returns {Promise<unknown>}
   */
  getConstructorProducts(constructorId, blockId, payload = {}) {
    return new Promise((resolve, reject) => {
      if (!constructorId) reject(new ServiceError('[SelectionService:getConstructorProducts] constructorId обязателен для заполнения'));
      if (!blockId) reject(new ServiceError('[SelectionService:getConstructorProducts] blockId обязателен для заполнения'));

      const {
        getQuotes = '',
        isGetAuthors = true,
        isGetSeries = false,
        isGetBadge = true,
        isGetProductText = false,
        isGetPublishingHouse = false,
        isGetCategory = true,
        isGetParentCategory = false,
        isGetProductStats = false,
        isGetOrsProduct = true,
      } = payload;

      const include = [];
      if (isGetAuthors) include.push('author');
      if (isGetSeries) include.push('series');
      if (isGetBadge) include.push('badge');
      if (isGetProductText) include.push('productText');
      if (getQuotes) include.push(getQuotes);
      if (isGetPublishingHouse) include.push('publishingHouse');
      if (isGetCategory) include.push('category');
      if (isGetParentCategory) include.push('category.parentCategory');
      if (isGetProductStats) include.push('productStats');
      if (isGetOrsProduct) include.push('orsProduct');

      const dataRequest = {};
      if (include.length) dataRequest.include = include.join(',');

      this.mainHttp.get(`/api/v1/page-constructor/${constructorId}/block/${blockId}/product/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          const items = list.map((item) => {
            const element = formattedProduct(item);
            if (isGetProductText) {
              element.productText = {
                advertisingBlockHeader: item.relationships.productText.advertisingBlockHeader,
                advertisingBlockText: item.relationships.productText.advertisingBlockText,
                detailDescription: item.relationships.productText.detailDescription,
                additionalDescription: item.relationships.productText.additionalDescription,
                showAdditionalDescription: item.relationships.productText.showAdditionalDescription,
              };
            }

            if (isGetProductStats) {
              element.productStats = {
                purchased: item.relationships.productStats.purchased,
                reviewTotal: item.relationships.productStats.reviewTotal,
              };
            }

            if (isGetSeries && Array.isArray(item.series)) {
              element.series.forEach((seriesItem) => {
                if (+seriesItem.numBooks <= 1) {
                  seriesItem.link = null;
                }
              });
            }

            return element;
          }) || [];
          return resolve({
            items,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * @typedef {Object} PayloadGetConstructorBlocks
   * @property {Boolean} [isGetPageConstructorBlock=false] - Получить блоки
   */

  /**
   * Возвращает товары блока подборки-конструктора
   * @param {number|string} constructorId - id конструктора
   * @param {PayloadGetConstructorBlocks} payload
   * @returns {Promise<unknown>}
   */
  getConstructorBlocks(constructorId, payload = {}) {
    return new Promise((resolve, reject) => {
      if (!constructorId) reject(new ServiceError('[SelectionService:getConstructorProducts] constructorId обязателен для заполнения'));

      const {
        isGetPageConstructorBlock = false,
      } = payload;

      const include = [];
      if (isGetPageConstructorBlock) include.push('pageConstructorBlock');

      const dataRequest = {};
      if (include.length) dataRequest.include = include.join(',');

      this.mainHttp.get(`/api/v1/page-constructor/${constructorId}/`, dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const data = buildElement(dataRes);
          return resolve(data);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
