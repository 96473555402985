<template>
  <span v-if="price" class="app-price">
    {{ priceItem }}
    <meta v-if="hasMicrodata" itemprop="price" :content="price" />
    <meta v-if="hasMicrodata" itemprop="priceCurrency" content="RUB" />
  </span>
</template>

<script>
import { priceFormatted } from '@/utils/formatters/formattedPrice';

export default {
  props: {
    price: { type: [Number, String], default: null },
    hasMicrodata: { type: Boolean, default: true },
    isSymbolRub: { type: Boolean, default: false },
    isSetCurrency: { type: Boolean, default: true },
    isSetFreeText: { type: Boolean, default: false },
    beforeText: { type: String, default: '' },
  },
  computed: {
    priceItem() {
      return priceFormatted(this.price, {
        setCurrency: this.isSetCurrency,
        setFreeText: this.isSetFreeText,
        beforeText: this.beforeText,
        isSymbolRub: this.isSymbolRub,
      });
    },
  },
};
</script>

<style lang="less">
.app-price {
  white-space: nowrap;
}
</style>
