<template>
  <div ref="emblaDots" class="bullets" :class="{ '_mobile': isMobile }">
    <button
      v-for="(snap, indexDot) in lengthDots"
      ref="sliderDot"
      :key="indexDot"
      class="bullets__item"
      :class="{ 'is-selected': selectedItemIndex === indexDot }"
      @click="handlerClick(indexDot)"
    ></button>
  </div>
</template>

<script>
export default {
  name: 'EmblaDotButtons',
  props: {
    selectedItemIndex: { type: Number, default: 0 },
    lengthDots: { type: Number, default: 1 },
    isMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      firstSmallDot: null,
      lastSmallDot: null,
      firstTinyDot: null,
      lastTinyDot: null,
      isChanged: false,
    };
  },
  mounted() {
    if (this.isMobile) {
      this.initDots();
    }
  },
  methods: {
    handlerClick(index) {
      this.$emit('click', index);
      if (this.isMobile) {
        if (index > 4) {
          this.isChanged = true;

          this.$refs.sliderDot.forEach((item) => {
            item.classList.remove('_small', '_tiny');
          });
        }

        if (index > 3) {
          this.$refs.emblaDots.style.transform = `translateX(${-(index - 4) * 20}px)`;

          if (index + 1 < this.$refs.sliderDot.length) {
            this.lastSmallDot = this.$refs.sliderDot[index + 1];
            this.lastSmallDot.classList.add('_small');
          }

          if (index + 2 < this.$refs.sliderDot.length) {
            this.lastTinyDot = this.$refs.sliderDot[index + 2];
            this.lastTinyDot.classList.add('_tiny');
          }
        }

        if (index > 4 && this.isChanged) {
          this.firstSmallDot = this.$refs.sliderDot[index - 3];
          this.firstSmallDot.classList.add('_small');
          this.firstTinyDot = this.$refs.sliderDot[index - 4];
          this.firstTinyDot.classList.add('_tiny');
        }

        if (index < 5 && this.isChanged) {
          this.firstSmallDot.classList.remove('_small');
          this.firstTinyDot.classList.remove('_tiny');
        }
      }
    },
    initDots() {
      if (this.$refs.sliderDot?.length) {
        if (this.lengthDots > 7) {
          this.$refs.emblaDots.classList.add('_left');
          this.lastSmallDot = this.$refs.sliderDot[5];
          this.lastTinyDot = this.$refs.sliderDot[6];
          this.lastSmallDot.classList.add('_small');
          this.lastTinyDot.classList.add('_tiny');
        }
      }
    },
  },
};
</script>

<style lang="less">
.bullets {
  &__item {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: @white;
    opacity: 0.5;
    transition: @A opacity;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

    &.is-selected {
      opacity: 1;
    }
  }

  &._mobile {
    transition: @A transform;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .bullets__item {
      .button-reset;

      display: inline-block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      opacity: 0.5;
      min-width: @size-x2;
      min-height: @size-x2;
      margin-right: @size-x3;
      background-color: @gray-light;
      transform: none;
      transition: @A transform;

      &:last-child {
        margin-right: 0;
      }

      &.is-selected {
        background-color: @pink;
        opacity: 1;
      }

      &._small {
        transform: scale(0.5);
      }

      &._tiny {
        transform: scale(0.25);
      }
    }
  }

  &._left {
    justify-content: left;
  }
}
</style>
