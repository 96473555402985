<template>
  <div class="not-found" :class="{'_mobile': isMobile}">
    <div class="not-found__body">
      <h1 class="not-found__title">
        Запрашиваемая страница не найдена
      </h1>
      <div class="not-found__pic">
        <svg-icon
          class="not-found__img"
          name="404-robot"
          :width="isMobile ? 540 : 637"
          :height="isMobile ? 285 : 337"
        />
      </div>
    </div>
    <recommendation-cashback-banner
      v-if="!productsRecommendation.length && isAuthorized"
      :is-container="false"
    />
    <div class="not-found__shelf">
      <client-only>
        <component
          :is="shelfSkeletonComponent"
          v-if="isProductsRecommendationLoading"
          :show-buttons="false"
        />
        <component
          v-bind="shelfRecomendedProps"
          :is="shelfComponent"
          v-else-if="!isProductsRecommendationLoading && productsRecommendation.length"
        />
        <template #placeholder>
          <component
            :is="shelfSkeletonComponent"
            :show-buttons="false"
          />
        </template>
      </client-only>
      <client-only>
        <component
          :is="shelfSkeletonComponent"
          v-if="isProductsNoveltyLoading"
          :show-buttons="false"
        />
        <component
          v-bind="shelfNoveltyProps"
          :is="shelfComponent"
          v-else-if="!isProductsNoveltyLoading && productsNovelty.length"
        />
        <template #placeholder>
          <component
            :is="shelfSkeletonComponent"
            :show-buttons="false"
          />
        </template>
      </client-only>
      <client-only>
        <component
          :is="shelfSkeletonComponent"
          v-if="isProductsBestsellerLoading"
          :show-buttons="false"
        />
        <component
          v-bind="shelfBestsellerProps"
          :is="shelfComponent"
          v-else-if="!isProductsBestsellerLoading && productsBestseller.length"
        />
        <template #placeholder>
          <component
            :is="shelfSkeletonComponent"
            :show-buttons="false"
          />
        </template>
      </client-only>
    </div>
  </div>
</template>

<script>
import {
  onMounted,
  useStore,
  computed,
} from '@nuxtjs/composition-api';

import useProductsNovelty from '@/compositions/shelfs/useProductsNovelty';
import useProductsBestseller from '@/compositions/shelfs/useProductsBestseller';
import useProductsRecommendation from '@/compositions/shelfs/useProductsRecommendation';

import ShelfProductsMobile from '@/components/_mobile/ShelfProductsMobile';
import ShelfProductsMobileSkeleton from '@/components/_mobile/ShelfProductsMobile/skeleton';
import ShelfProducts from '@/components/ShelfProducts';
import ShelfProductsSkeleton from '@/components/ShelfProducts/skeleton';
import RecommendationCashbackBanner from '@/components/Recommendations/RecommendationCashbackBanner';

import { checkMobile } from '@/utils/deviceCheck';

export default {
  name: 'Error404',
  components: {
    RecommendationCashbackBanner,
  },
  props: {
    error: { type: Object, default: () => ({}) },
  },
  setup() {
    const productsNovelty = useProductsNovelty();
    const productsBestseller = useProductsBestseller();
    const productsRecommendation = useProductsRecommendation();
    const { state } = useStore();

    const isMobile = checkMobile();

    if (state.isAuthorized) {
      productsRecommendation.isProductsRecommendationLoading.value = true;
    }

    const getProductsRecommendation = async () => {
      if (state.isAuthorized) {
        await productsRecommendation.getProductsRecommendation();
      }
    };

    const shelfComponent = computed({
      get: () => {
        if (isMobile) {
          return ShelfProductsMobile;
        }
        return ShelfProducts;
      },
      set: () => {},
    });

    const shelfSkeletonComponent = computed({
      get: () => {
        if (isMobile) {
          return ShelfProductsMobileSkeleton;
        }
        return ShelfProductsSkeleton;
      },
      set: () => {},
    });

    const shelfRecomendedProps = computed({
      get: () => {
        const attrs = {
          title: 'Рекомендуем почитать',
          products: productsRecommendation.productsRecommendation.value,
        };

        if (isMobile) {
          attrs.isContainer = false;
          attrs.linkMore = '/personal/recommendation/';
        } else {
          attrs.moreLink = { title: 'Все рекомендации', link: '/personal/recommendation/' };
          attrs.showButtons = false;
        }

        return attrs;
      },
      set: () => {},
    });

    const shelfNoveltyProps = computed({
      get: () => {
        const attrs = {
          title: 'Новинки',
          products: productsNovelty.productsNovelty.value,
        };

        if (isMobile) {
          attrs.isContainer = false;
        } else {
          attrs.moreLink = { title: 'Все новинки', link: '/novie-knigi/' };
          attrs.showButtons = false;
        }

        return attrs;
      },
      set: () => {},
    });

    const shelfBestsellerProps = computed({
      get: () => {
        const attrs = {
          title: 'Бестселлеры',
          products: productsBestseller.productsBestseller.value,
        };

        if (isMobile) {
          attrs.isContainer = false;
        } else {
          attrs.moreLink = { title: 'Все бестселлеры', link: '/knigi-bestsellery/' };
          attrs.showButtons = false;
        }

        return attrs;
      },
      set: () => {},
    });

    onMounted(async () => {
      await Promise.allSettled([
        getProductsRecommendation(),
        productsNovelty.getProductsNovelty(),
        productsBestseller.getProductsBestseller(),
      ]);
    });

    return {
      isMobile,
      productsNovelty: productsNovelty.productsNovelty,
      isProductsNoveltyLoading: productsNovelty.isProductsNoveltyLoading,
      productsBestseller: productsBestseller.productsBestseller,
      isProductsBestsellerLoading: productsBestseller.isProductsBestsellerLoading,
      isProductsRecommendationLoading: productsRecommendation.isProductsRecommendationLoading,
      productsRecommendation: productsRecommendation.productsRecommendation,
      shelfComponent,
      shelfSkeletonComponent,
      shelfRecomendedProps,
      shelfNoveltyProps,
      shelfBestsellerProps,
      isAuthorized: state.isAuthorized,
    };
  },
  head: {
    title: 'Запрашиваемая страница не найдена',
    meta: [
      { hid: 'title', name: 'title', content: 'Запрашиваемая страница не найдена' },
      { hid: 'og:title', name: 'og:title', content: 'Запрашиваемая страница не найдена' },
      { hid: 'og:description', name: 'og:description', content: null },
      { hid: 'description', name: 'description', content: null },
    ],
  },
};
</script>

<style lang="less">
.not-found {
  .container-d;

  &__body {
    padding-top: 50px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  &__body:last-child {
    margin-bottom: 50px;
  }

  &__title {
    font-size: 30px;
    font-weight: normal;
    line-height: 40px;
    color: @black;
    margin: 0;
  }

  &__pic {
    margin-top: 80px;
    padding-bottom: 20px;
  }

  &__img {
    display: inline-block;
    vertical-align: middle;
  }

  &__shelf {
    max-width: 1600px;
    min-width: 1024px;
    margin: 0 auto;
  }

  &._mobile {
    .container-m;

    padding: 50px 0;
  }

  &._mobile &__body {
    max-width: none;
    margin: 0;
    padding: 0 @size-x4;
  }

  &._mobile &__img {
    width: 100%;
    height: auto;
  }

  &._mobile &__shelf {
    max-width: 100%;
    min-width: 320px;
    padding-left: @size-x4;

    .shelf-products {
      text-align: left;
      margin: @size-x6 0;
    }
  }
}
</style>
