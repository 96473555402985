import { CURRENCY } from '@/utils/constants/globalConst';
import formatNum from '@/utils/formatNum';

/**
 * Возвращает форматированную цену
 * @param {string|number} value
 * @param {Object} [options={}]
 * @param {string} [options.beforeText=''] - Текст перед ценой (без пробела)
 * @param {boolean} [options.setCurrency=true] - Добавить ли наименование валюты
 * @param {boolean} [options.setFreeText=true] - Выводить ли слово "Бесплатно" вместо стоимости 0р
 * @param {boolean} [options.isSymbolRub=false] - Выводить ли символ рубля вместо валюты
 * @return {string | number}
 */
const priceFormatted = (value, options = {}) => {
  const {
    beforeText = '',
    setCurrency = true,
    setFreeText = true,
    isSymbolRub = false,
  } = options;
  if ((typeof value === 'string' || typeof value === 'number') && !Number.isNaN(Number(value))) {
    let text = formatNum(value);

    if (setCurrency) {
      const currency = isSymbolRub ? '₽' : CURRENCY;
      text = `${text} ${currency}`;
    }
    if (beforeText.length) {
      text = `${beforeText} ${text}`;
    }
    if (setFreeText && value === 0) {
      text = 'Бесплатно';
    }
    return text;
  }
  return value;
};

export { priceFormatted };
