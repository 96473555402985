<template>
  <badge-best-price
    :is-mobile="true"
    class="_mobile"
  />
</template>
<script>
import BadgeBestPrice from '@/components/ProductCard/BadgeBestPrice/index';

export default {
  components: { BadgeBestPrice },
};
</script>

<style lang="less">
.product-card-badge-best-price {
  &._mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: @pink;
    flex-direction: row;
    border-radius: @size-x1;
    padding: @size-x1 @size-x2;
    z-index: 2;

    &::before {
      display: none;
    }
  }

  &._mobile &__icon {
    z-index: 0;
    width: @size-x3;
    height: @size-x3;
  }

  &._mobile &__text {
    z-index: 0;
    display: inline-block;
    padding-left: @size-x1;
    margin-top: 0;
  }
}
</style>
