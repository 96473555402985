<template>
  <div class="authorization-sms-code">
    <div class="authorization-sms-code__inner">
      <input-control
        v-model="code"
        inputmode="numeric"
        type="number"
        autocomplete="one-time-code"
        class="authorization-sms-code__code"
        @input="inputCodeHandler"
      />
      <resend-sms-code
        :loading="loading"
        :time-to-resend="timeToResend"
        class="authorization-sms-code__resend"
        @onResendSmsCode="resendSmsCodeHandler"
        @onEndCountdown="endCountdownHandler"
      />
    </div>
    <p
      v-if="codeError"
      class="authorization-sms-code__error _error"
    >
      {{ codeError }}
    </p>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';

import { InputControl } from '@book24/ui-components';

import ResendSmsCode from '@/components/Authorization/ResendSmsCode/index.vue';

export default defineComponent({
  name: 'AuthorizationSmsCode',
  components: {
    InputControl,
    ResendSmsCode,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    codeError: {
      type: String,
      default: '',
    },
    timeToResend: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const code = ref('');
    const inputCodeHandler = () => emit('onInputCode', code.value);
    const resendSmsCodeHandler = () => emit('onResendSmsCode');
    const endCountdownHandler = () => emit('onEndCountdown');
    return {
      code,
      inputCodeHandler,
      resendSmsCodeHandler,
      endCountdownHandler,
    };
  },
});
</script>

<style lang="less">
.authorization-sms-code {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__code {
    width: 100px;

    @media @320 {
      width: 115px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__resend {
    margin-left: @size-x4;
  }

  &__error {
    .small;

    margin-top: 2px;
    margin-bottom: @size-x5;
    color: @red-error;
  }
}
</style>
