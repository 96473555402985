/**
 * Передаем параметры того откуда был добавлен
 * товар в корзину и возвращаем их в нужном формате
 * @param params
 */
export const getTargetProperties = (params) => {
  if (!params) return;

  const result = {};

  params.forEach((param) => {
    result[param.code] = param.value;
  });

  return result;
};
