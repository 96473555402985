import { STATUSES_BY_TYPE } from '@/utils/constants/orderStatuses';

/**
 * На основании названия роута (route.name, слева)
 * возвращаем нужное для аналитики значение
 * @param routeName
 * @param orderStatus
 */
export const getItemListName = (routeName, orderStatus) => {
  const names = {
    main: 'dashboard', // Главная страница
    catalog: 'catalog', // Каталог
    profile: 'personal-my-profile', // Профиль
    'search-query': 'search_products', // Поиск
    'basket-page': 'cart', // Корзина
    'personal-my-library': 'bookmark', // Закладки
    'personal-my-library-page': 'bookmark', // Закладки
    'catalog-category': 'catalog', // Категория
    'product-page': 'product', // Страница продукта
    'selection-page': 'compilation', // Подборка
    'sales-detail': 'stock_info', // Детальная страница акции
    'rating-page-category': 'rating-page', // Страница рейтинга
    'rating-page-categories': 'rating-page', // Страница рейтинга с пагинацией
    'presents-page-category': 'presents-page', // Страница подарков
    'presents-pages-category': 'presents-page', // Страница подарков с пагинацией
  };

  if (routeName === null) return 'not_found'; // Страница 404

  if (routeName?.includes('-pages') && !routeName.includes('-pages-')) {
    const route = routeName.replace('-pages', '');
    return names[route] || route;
  }

  if (orderStatus) {
    if (STATUSES_BY_TYPE.canceled.includes(orderStatus)) return 'canceled_order';
    if (STATUSES_BY_TYPE.completed.includes(orderStatus)) return 'completed_order';
    return 'current_order';
  }

  return names[routeName] || routeName;
};
