import { reactive, toRefs } from '@nuxtjs/composition-api';

const useNotifications = () => {
  const params = reactive({
    notify: {
      caption: '',
      type: '',
    },
  });

  const showNotify = (type, caption) => {
    params.notify.type = type;
    params.notify.caption = caption;
  };

  const hideNotify = () => {
    params.notify.type = '';
    params.notify.caption = '';
  };

  return {
    ...toRefs(params),
    showNotify,
    hideNotify,
  };
};

export default useNotifications;
