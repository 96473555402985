// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection, buildElement } from '@/utils/formatters/processingOpenApi';
import formattedProduct from '@/utils/formatters/formattedProduct';

export default class SearchService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  getProducts(data = {}) {
    return new Promise((resolve, reject) => {
      const {
        pageSize = 20,
        pageNumber = 1,
        filter,
        filterPreset,
        sortPreset,
        match,
        isGetAuthors = false,
        isGetProductAttraction = false,
        isGetBadge = false,
        isGetCategory = true,
        isGetParentCategory = false,
        isGetPublishingHouse = false,
        isGetOrsProduct = true,
      } = data;

      const include = [];
      if (isGetAuthors) include.push('product.author');
      if (isGetProductAttraction) include.push('product.customerProductAttraction');
      if (isGetBadge) include.push('product.badge');
      if (isGetCategory) include.push('product.category');
      if (isGetParentCategory) include.push('category.parentCategory');
      if (isGetPublishingHouse) include.push('product.publishingHouse');
      if (isGetOrsProduct) include.push('product.orsProduct');

      let dataRequest = {
        match,
        'per-page': pageSize,
        page: pageNumber,
      };

      if (filter) dataRequest = { ...dataRequest, filter };
      if (filterPreset) dataRequest = { ...dataRequest, filterPreset };
      if (sortPreset) dataRequest = { ...dataRequest, sortPreset };
      if (include.length) dataRequest = { ...dataRequest, include: include.join(',') };

      return this.mainHttp.get('/api/v1/internal/search/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const resFormatted = buildElement(dataRes);

          let list = [];
          if (resFormatted.relationships?.product) {
            list = resFormatted.relationships.product.map((item) => {
              let formattedItem = formattedProduct(item);
              const customerProductAttraction = item.relationships?.customerProductAttraction;
              if (customerProductAttraction) {
                formattedItem = {
                  ...formattedItem,
                  isHidden: customerProductAttraction.type === 'hide',
                  rating: customerProductAttraction.rate || null,
                };
              }
              return formattedItem;
            }) || [];
            delete resFormatted.relationships.product;
          }

          const res = {
            ...resFormatted,
            list,
          };

          return resolve(res);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
