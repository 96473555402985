<template>
  <div class="product-card-labels">
    <span
      v-for="(label, index) in labels"
      :key="index"
      class="product-card-labels__item"
      :class="{
        _best: label.isBestseller,
        _new: label.isNovelty,
        _recommended: label.isRecommendation
      }"
    >
      {{ label.title }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CardLabels',
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style lang="less">
.product-card-labels {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: @size-x2;
  z-index: 2;

  &__item {
    display: block;
    border-radius: @main-size;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: @white;
    padding: 2px @size-x3;

    &._best {
      background-color: @orange;
    }

    &._new {
      background-color: @green;
    }

    &._recommended {
      background-color: @link-hover;
    }
  }

  &._mobile &__item {
    padding: 2px @size-x2;
    font-size: 10px;
  }
}
</style>
