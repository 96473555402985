/**
 * Определяет какой тег использовать
 * @param {Boolean} href - адрес для перехода
 * @param {Boolean} blank - открывать ссылку в новой вкладке
 * @param {Boolean} external - принудительно открывать как внешнюю ссылку
 * @param {Boolean} internal - принудительно открывать как внутреннюю ссылку
 * @param {Boolean} SPAN_TAG - тег span
 * @param {Boolean} ANCHOR_TAG - тег a
 * @param {Boolean} FRAMEWORK_LINK - ссылка внутри роутера
 * @return {String} - найденный тег
 */
const getLinkTag = (
  {
    href, blank, external, internal,
  },
  { SPAN_TAG = 'span', ANCHOR_TAG = 'a', FRAMEWORK_LINK = 'router-link' },
) => {
  if (!href) {
    return SPAN_TAG;
  }

  if (external && internal) {
    // eslint-disable-next-line
    console.warn(
      'пропсы "external" и "internal" конфликтуют друг с другом, приоретет отдается "external"',
    );
    return ANCHOR_TAG;
  }

  if (blank || external) {
    return ANCHOR_TAG;
  }

  if (internal || /^\/(?!\/).*$/.test(href)) {
    return FRAMEWORK_LINK;
  }

  return ANCHOR_TAG;
};

export default getLinkTag;
