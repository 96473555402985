import { setDeliveryType } from '@/utils/analytics/setDeliveryType';

/**
 * Получаем тип и стоимость доставки заказа
 * @param {*} orderShipping
 */
export const getOrderShipping = (orderShipping) => {
  if (!orderShipping) return false;

  const price = parseFloat(orderShipping.amount) || 0;
  const type = setDeliveryType(orderShipping.code);

  return { price, type };
};
