/**
 * @typedef TypesDelivery
 * @property {string} code - Код вида доставки
 * @property {string} name - Название вида доставки
 */

/**
 * Список основных типов доставки
 * @readonly
 * @enum {TypesDelivery}
 * @const
 */
export const TYPES_DELIVERY = Object.freeze({
  point: {
    code: 'point',
    name: 'Пункты выдачи',
  },
  post: {
    code: 'post',
    name: 'Почта России',
  },
  courier: {
    code: 'courier',
    name: 'Курьерская доставка',
  },
  courierPost: {
    code: 'post_online_courier',
    name: 'Курьерская доставка Почтой России',
  },
});

/**
 * Код курьерской доствкаи DHL
 * @type {string}
 */
export const EXPRESS_COURIER_DELIVERY_CODE = 'dhl_courier';

/**
 * @typedef {Object} PaySystemItem - Платежная система
 * @property {number|string} id
 * @property {string} code
 * @property {string} name
 * @property {string} description
 */

/**
 * Типы платежных систем
 * @type {Object<string, PaySystemItem>}
 */
export const PAY_SYSTEMS_TYPE = Object.freeze({
  cloudpayments: {
    code: 'cloudpayments',
    id: 28,
    name: 'Оплата онлайн банковской картой',
    description: 'Visa, MasterCard, МИР',
  },
  cloudpayments_api: {
    code: 'cloudpayments_api',
    id: 31,
    name: 'Оплата онлайн банковской картой',
    description: 'Visa, MasterCard, МИР',
  },
  cash: {
    code: 'cash',
    id: 1,
    name: 'Оплата заказа при получении',
    description: 'Оплата производится наличными или банковской картой в момент получения заказа (оплата картой возможна при наличии терминала, в постаматах оплата только картой)',
  },
  nalozhenny: {
    code: 'nalozhenny',
    id: 2,
    name: 'Наложенный платёж',
  },
  beznal: {
    code: 'beznal',
    id: 19,
    name: 'Оплата безналичным расчётом',
  },
  certificate: {
    code: 'certificate',
    id: 26,
    name: 'Оплата сертификатом',
  },
});

/**
 * @typedef PersonType
 * @property {number} code
 * @property {string} label
 * @property {string} name
 */

/**
 * Типы платежных аккаунтов
 * @type {Object<string, PersonType>}
 */
export const PERSON_TYPES = {
  legal: {
    code: 2,
    label: 'юридическое лицо',
    name: 'legal',
  },
  individual: {
    code: 1,
    label: 'физическое лицо',
    name: 'individual',
  },
};

/**
 * @type {string}
 */
export const DHL_TAX_TEXT = 'До подтверждения заказа уточните информацию о дополнительных налогах, сборах и пошлинах в таможенном департаменте страны доставки, местном офисе DHL или на сайте dhl.ru';

/**
 * @type {string}
 */
export const PVZ_NOT_RUS_TEXT = 'При передаче заказа в транспортную компанию на доставку мы вышлем на ваш email ссылку и трек-номер для отслеживания отправления. Пожалуйста, внимательно заполните ваши контактные данные и адрес доставки.';

export const MANUAL_DELIVERY_COURIER_SUBSTR = 'manual_';

export const ERROR_TEXT_WHEN_FETCH_PVZ = Object.freeze({
  detectCoords: 'Ошибка определения координат',
  cityChangedWhenDetectCoords: 'Город определения координат не соответствует выбранному городу',
  emptyNearPvz: 'Ближайшие пункты выдачи не найдены',
  emptyCheapestPvz: 'Дешевые пункты выдачи не найдены',
  emptyPvz: 'Пункты выдачи не найдены',
});
