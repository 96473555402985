import getMetaRoute from '../../utils/getMetaRoute';

const SuccessPayPageDesktop = () => import(/* webpackChunkName: "SuccessPayPageDesktop" */ './DesktopPage.vue').then(m => m.default || m);

export default [
  {
    path: '/personal/order/make/:orderId(\\d+)/success/',
    component: SuccessPayPageDesktop,
    name: 'success-pay-page',
    props: route => ({
      orderId: Number(route.params.orderId),
    }),
    meta: getMetaRoute({
      requiredAuth: true,
      ddmCategory: 'Confirmation',
      ddmType: 'confirmation',
    }),
  },
];
