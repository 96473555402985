import { helpers } from '@vuelidate/validators';
import { MAX_LENGTH_COMMENT_COURIER } from '@/utils/constants/globalConst';

const isEmpty = value => (typeof value === 'undefined' || value === null || value === '');

const checkHouse = (value, vm) => {
  if (typeof value !== 'string' || value === '') {
    return false;
  }
  if (!vm.userAddress.value.house
    && typeof vm.userAddress.value.block === 'string'
    && !!vm.userAddress.value.block
  ) {
    return true;
  }
  return typeof vm.userAddress.value.house === 'string' && !!vm.userAddress.value.house;
};

const checkStreet = (value, vm) => typeof vm.userAddress.value.street === 'string'
  && !!vm.userAddress.value.street;

const checkBlock = (value) => {
  if (typeof value !== 'string' || value === '') {
    return true;
  }
  return value.length <= 15;
};

const checkApartment = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return value.length <= 15;
};

const checkIndex = value => helpers.req(value);

const dhlValueMask = (e) => {
  if (/[^(а-яА-ЯЁёa-zA-Z0-9\-_,.\\/\s)]/.test(e.key)) {
    e.preventDefault();
  }
};

const checkCommentCourier = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return value.length <= MAX_LENGTH_COMMENT_COURIER;
};

export {
  checkHouse,
  checkStreet,
  checkBlock,
  checkApartment,
  checkIndex,
  dhlValueMask,
  checkCommentCourier,
};
