import getMetaRoute from '../../utils/getMetaRoute';
import { TITLE_PAGE } from './enums';

const PayPage = () => import(/* webpackChunkName: "PayPage" */ './PayPage.vue').then(m => m.default || m);
const ErrorPayPage = () => import(/* webpackChunkName: "ErrorPayPage" */ './ErrorPayPage.vue').then(m => m.default || m);
const SuccessPayPage = () => import(/* webpackChunkName: "SuccessPayPage" */ './SuccessPayPage.vue').then(m => m.default || m);

export default [
  {
    path: '/personal/order/pay/:orderId(\\d+)/',
    component: PayPage,
    props: route => ({
      orderId: Number(route.params.orderId),
    }),
    name: 'pay-cart-page',
    meta: getMetaRoute({
      requiredAuth: true,
      title: TITLE_PAGE,
    }),
  },
  {
    path: '/service/invoice/:orderInvoiceId/pay/',
    component: PayPage,
    props: route => ({
      orderInvoiceId: route.params.orderInvoiceId,
    }),
    name: 'pay-invoice-page',
    meta: getMetaRoute({
      title: TITLE_PAGE,
    }),
  },
  {
    path: '/personal/order/pay/:orderId(\\d+)/fail/',
    component: ErrorPayPage,
    props: route => ({
      orderId: Number(route.params.orderId),
      errorCode: route.query.errorCode,
    }),
    name: 'pay-cart-error-page',
    meta: getMetaRoute({
      title: TITLE_PAGE,
    }),
  },
  {
    path: '/service/invoice/:orderInvoiceId/pay/fail/',
    component: ErrorPayPage,
    props: route => ({
      orderInvoiceId: route.params.orderInvoiceId,
      errorCode: route.query.errorCode,
    }),
    name: 'pay-invoice-error-page',
    meta: getMetaRoute({
      title: TITLE_PAGE,
    }),
  },
  {
    path: '/service/invoice/:orderInvoiceId/pay/success/',
    component: SuccessPayPage,
    props: route => ({
      orderInvoiceId: route.params.orderInvoiceId,
      errorCode: route.query.errorCode,
    }),
    name: 'pay-invoice-success-page',
    meta: getMetaRoute({
      title: TITLE_PAGE,
    }),
  },
];
