// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import formattedPageBreadcrumbs from '@/utils/formatters/formattedPageBreadcrumbs';
import {
  buildCollection,
  buildElement,
  buildErrorCollection,
} from '@/utils/formatters/processingOpenApi';
import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';
import { errorsGetAuthorInfo } from '@/utils/constants/authorConstants';

export default class AuthorService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получение информации об авторе
   * @param authorId
   * @return {*}
   */
  getAuthorInfo(authorId = '134836') {
    return this.mainHttp
      .get(`/api/v1/internal/author/${authorId}/?include=breadcrumbs,authorText,authorVideo`)
      .then(({ data: { data } }) => {
        const resFormatted = buildElement(data);
        return {
          crumbs: formattedPageBreadcrumbs(resFormatted.relationships?.breadcrumbs || []),
          authorInfo: {
            id: resFormatted.id,
            fullName: resFormatted.fullName,
            code: resFormatted.code,
            imageUrl: resFormatted.imageUrl,
            isForeignAgent: resFormatted.isForeignAgent,
            detailDescription: resFormatted.relationships?.authorText?.detailDescription || '',
            videos: resFormatted.relationships?.authorVideo || [],
          },
        };
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data, errorsGetAuthorInfo);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Получаем цитаты конкретной книги
   * @param productId
   * @param {Object} options
   * @param {number} [options.perPage=2]
   * @param {number} [options.page=1]
   * @return {Promise<{list: *[], pagination?}>}
   */
  getQuotesByProductId(productId, options = {}) {
    return this.mainHttp
      .get(`/api/v1/products/${productId}/quote/`, {
        'per-page': options?.perPage || 2,
        page: options?.page || 1,
      })
      .then(({ data }) => buildCollection(data.data, true))
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);

          const [firstError] = errors;
          return Promise.reject(firstError);
        }
        return Promise.reject(e);
      });
  }

  /**
   * Получаем список авторов
   * @param {Object} [payload={}] - данные для запроса
   * @param {number} [payload.pageNumber=1] - номер страницы
   * @param {number} [payload.pageSize=15] - кол-во элементов на странице, максимум 100
   * @param {number|string} [payload.sectionId] - идентификатор раздела каталога
   * @param {string} [payload.letter] - первая буква в имени автора
   * @param {Array} [payload.id=[]] - идентификаторы авторов
   * @param {boolean} [payload.popular=false] - популярные авторы
   * @param {boolean} [payload.attractive=false] - любимые авторы
   * @param {boolean} [payload.isGetAuthorText=false] - получать ли описание авторов
   * @param {boolean} [payload.isGetFavorite=false] - нужно ли получать uuid любимого автора
   * @param {Object} [options={}] - дополнительные параметры
   * @param {number} [options.sizePicWidth=134] - ширина картинки автора
   * @param {number} [options.sizePicHeight=134] - высота картинки автора
   * @return {Promise<unknown>}
   */
  getAuthorsList(payload = {}, options = {}) {
    return new Promise((resolve, reject) => {
      const {
        pageNumber = 1,
        pageSize = 15,
        sectionId,
        letter,
        id = [],
        popular = false,
        attractive = false,
        isGetAuthorText = false,
        isGetFavorite = false,
      } = payload;

      const include = [];

      const {
        sizePicWidth = 134,
        sizePicHeight = 134,
      } = options;

      const dataRequest = {
        'per-page': pageSize,
      };

      dataRequest.page = Number.isNaN(pageNumber) && !Number.isInteger(pageNumber) ? 1 : pageNumber;
      if (id.length) dataRequest.id = id;
      if (popular) dataRequest.popular = popular;
      if (sectionId) dataRequest.sectionId = sectionId;
      if (letter) dataRequest.letter = letter.toUpperCase();
      if (attractive) dataRequest.attractive = true;
      if (isGetAuthorText) include.push('authorText');
      if (isGetFavorite) include.push('customerReferenceAttraction');

      if (include.length) {
        dataRequest.include = include.join(',');
      }

      return this.mainHttp.get('/api/v1/internal/author/', dataRequest)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          return resolve({
            list: list.map(item => ({
              name: item.fullName,
              letter: item.letter,
              link: `/author/${item.code}-${item.id}/`,
              code: item.code,
              id: item.id,
              image: formattedPicturesCdnBook24(item.imageUrl, {
                sizePicHeight,
                sizePicWidth,
                fallbackImages: {
                  type: 'fallback',
                  x1: '/_ssr/img/not-image/Not_image_author_d.jpg',
                  x2: '/_ssr/img/not-image/Not_image_author_d@2x.jpg',
                  webpX1: null,
                  webpX2: null,
                },
              }),
              description: item.relationships?.authorText || null,
              booksCount: item.booksCount || null,
              checked: !!item.relationships?.customerReferenceAttraction?.id,
              uuid: item.relationships?.customerReferenceAttraction?.id,
            })),
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(firstError);
          }
          return reject(e);
        });
    });
  }
}
