<template>
  <section
    class="app-section"
    :class="classObject"
  >
    <div v-if="title" class="app-section__header">
      <component
        :is="isTagTitle"
        class="app-section__title"
        :class="{_centered: isCentered}"
      >
        {{ title }}
        <span
          v-if="badge"
          class="app-section__badge"
        >
          <span class="app-section__badge-text">
            {{ badge }}
          </span>
        </span>
      </component>
      <app-link v-if="hasMoreLink" class="app-section__more-link" :href="moreLink.link">
        {{ moreLink.title }}
        <svg-icon
          class="app-section__icon"
          name="16/Arrow_right_16"
          width="16"
          height="16"
        />
      </app-link>
    </div>
    <div class="app-section__content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppSection',
  props: {
    title: { type: String, default: '' },
    moreLink: { type: Object, default: () => ({}) },
    isCentered: { type: Boolean, default: false },
    isFullWidth: { type: Boolean, default: false },
    isContainer: { type: Boolean, default: true },
    isNoYPadding: { type: Boolean, default: false },
    isBottomOffset: { type: Boolean, default: false },
    isTagTitle: { type: String, default: 'h2' },
    badge: { type: String, default: '' },
  },
  computed: {
    hasMoreLink() {
      return this.moreLink && Object.keys(this.moreLink).length;
    },
    classObject() {
      return {
        '_full-width': this.isFullWidth,
        '_is-container': this.isContainer,
        '_no-offset': this.isBottomOffset,
        '_no-y-padding': this.isNoYPadding,
      };
    },
  },
};
</script>

<style lang="less">
.app-section {
  &._is-container {
    .container-d;
  }

  padding-top: @size-x10;
  padding-bottom: @size-x10;

  &._full-width {
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding: @size-x10 0;
  }

  &._full-width._is-container &__content {
    .container-d;
  }

  &._no-y-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__header {
    display: flex;
    margin-bottom: @size-x8;
    align-items: flex-end;
  }

  &__title {
    .h3-desktop;

    margin: 0;
    color: @black;
    font-weight: 800;

    &._centered {
      margin: 0 auto;
      text-align: center;
    }
  }

  &__badge {
    .p;

    display: inline-block;
    border-radius: @main-size;
    background-color: @pink;
    color: @white;
    font-weight: bold;
    margin-left: @size-x4;
    padding: @main-size @size-x3;
  }

  &__badge-text {
    position: relative;
    top: -1px;
  }

  &__icon {
    vertical-align: middle;
    margin-left: @main-size;
    fill: currentColor;
    transition: @A fill, @A transform;
  }

  &__more-link {
    .small;

    display: block;
    margin-left: auto;
    font-weight: 600;
    vertical-align: center;
    text-decoration: none;

    &:hover .app-section__icon {
      transform: translate(-@main-size);
    }
  }

  &__content {
    position: relative;
  }

  &._no-offset._mobile {
    padding-bottom: 0;
  }
}
</style>
