<template>
  <div class="embla" :class="{ '_mobile': isMobile }">
    <div ref="container" class="embla embla__viewport">
      <div class="embla__container">
        <slot></slot>
      </div>
    </div>
    <client-only>
      <embla-arrow-buttons
        v-if="showArrows && embla"
        :is-disabled="!prevBtnEnabled"
        @click="handlerClickArrowButton"
      />
      <embla-arrow-buttons
        v-if="showArrows && embla"
        :is-disabled="!nextBtnEnabled"
        :is-next="true"
        @click="handlerClickArrowButton"
      />
      <div class="embla__dots">
        <embla-dot-buttons
          v-if="showDots && embla"
          ref="dots"
          :length-dots="getLengthDots"
          :selected-item-index="selectedScrollSnap"
          :is-mobile="isMobile"
          @click="handlerClickDotButton"
        />
      </div>
    </client-only>
  </div>
</template>

<script>
import EmblaCarousel from 'embla-carousel';
import EmblaAutoplay from 'embla-carousel-autoplay';
import ClassNames from 'embla-carousel-class-names';

import EmblaDotButtons from '@/components/EmblaCarousel/EmblaDotButtons';
import EmblaArrowButtons from '@/components/EmblaCarousel/EmblaArrowButtons';

export default {
  name: 'EmblaCarousel',
  components: { EmblaDotButtons, EmblaArrowButtons },
  props: {
    options: { type: Object, default: () => ({}) },
    showDots: { type: Boolean, default: false },
    showArrows: { type: Boolean, default: true },
    isAutoPlay: { type: Boolean, default: false },
    autoPlayDelay: { type: Number, default: 5000 },
    isMobile: { type: Boolean, default: false },
    isStopOnMouseEnterAutoPlay: { type: Boolean, default: false },
  },
  data() {
    return {
      timer: 0,
      embla: null,
      scrollSnaps: [],
      selectedScrollSnap: null,
      prevBtnEnabled: false,
      nextBtnEnabled: true,
      slidesInView: [],
      localOptions: {
        align: 'start',
        inViewThreshold: 1,
        autoResize: true,
      },
      autoplay: null,
    };
  },
  computed: {
    allOptions() {
      return { ...this.localOptions, ...this.options };
    },
    getLengthDots() {
      const length = Math.ceil(this.scrollSnaps.length / this.slidesInView.length);
      return length || 1;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initPlugins();
      this.onInitEmbla();
      this.reInitSlidesToScroll();
      this.onSelect();
    });
  },
  destroyed() {
    if (this.embla && this.embla.destroy) {
      this.embla.destroy();
    }
  },
  methods: {
    onInitEmbla() {
      const plugins = [ClassNames()];

      if (this.autoplay) {
        plugins.push(this.autoplay);
      }

      this.embla = EmblaCarousel(this.$refs.container, this.allOptions, plugins);
      this.embla.on('select', this.onSelect);
      this.embla.on('scroll', this.onSettle);
      this.scrollSnaps = this.embla.scrollSnapList();
    },
    onSettle() {
      if (this.$refs?.dots) {
        this.$refs.dots.handlerClick(this.selectedScrollSnap);
      }
    },
    onSelect() {
      this.selectedScrollSnap = this.embla.selectedScrollSnap();
      this.prevBtnEnabled = this.embla.canScrollPrev();
      this.nextBtnEnabled = this.embla.canScrollNext();
    },
    reInitSlidesToScroll() {
      this.slidesInView = this.embla.slidesInView();
      const slidesToScroll = this.slidesInView.length;
      if (slidesToScroll > 0) {
        this.embla.reInit({ slidesToScroll });
      }
    },
    handlerClickArrowButton(type) {
      if (this.autoplay) {
        this.autoplay.stop();
      }
      switch (type) {
        case 'next': {
          this.embla.scrollNext();
          break;
        }
        default: {
          this.embla.scrollPrev();
          break;
        }
      }
    },
    handlerClickDotButton(index) {
      this.embla.scrollTo(index);
    },
    initPlugins() {
      if (this.isAutoPlay) {
        this.autoplay = EmblaAutoplay({
          delay: this.autoPlayDelay,
          stopOnMouseEnter: this.isStopOnMouseEnterAutoPlay,
        });
      }
    },
  },
};
</script>

<style lang="less">
.embla {
  position: relative;

  &__viewport {
    overflow: hidden;

    &.is-draggable {
      cursor: grab;
    }

    &.is-dragging {
      cursor: grabbing;
    }
  }

  &__container {
    display: flex;
    will-change: transform;
  }

  &__dots {
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    bottom: 16px;
  }

  &._mobile {
    margin-bottom: @size-x3;

    .embla__dots {
      position: static;
      width: 128px;
      overflow: hidden;
      margin: 12px auto 0;
      transform: none;
    }
  }

  &._open-modal &__container {
    will-change: unset;
    transform: none !important;
  }
}
</style>
