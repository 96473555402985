<template>
  <content-placeholders
    class="product-card"
    :class="{'_without-button': !showButtons}"
    rounded
  >
    <content-placeholders-img class="product-card__image-holder" />
    <div class="product-card__content">
      <div class="product-card__price product-card-price" style="margin-top: 8px;">
        <content-placeholders-text
          :lines="1"
          class="product-card-price__current"
          style="width: 65px;"
        />
        <content-placeholders-text
          :lines="1"
          class="product-card-price__old"
          style="width: 65px;"
        />
      </div>
      <content-placeholders-heading class="product-card__name" style="margin-top: 12px;" />
      <div v-if="showButtons" class="product-card__buttons-holder">
        <content-placeholders-img class="product-card__button" style="height: 32px;" />
      </div>
    </div>
  </content-placeholders>
</template>

<script>
export default {
  name: 'ProductCardSkeleton',
  props: {
    showButtons: { type: Boolean, default: true },
  },
};
</script>
