import { getCurrentInstance } from '@nuxtjs/composition-api';

const useToast = () => {
  const vm = getCurrentInstance();
  const toast = vm.root.proxy.$toast;

  return {
    toast,
  };
};

export default useToast;
