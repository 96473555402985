/**
 * Ошибка 403 при попытке запросить чужой заказ
 * @type {Readonly<{code: string, message: string}>}
 */
export const ACCESS_DENIED = Object.freeze({
  code: '5fc70807-cf91-4299-b30c-aca6911bd1b9',
  message: 'Заказ не принадлежит пользователю',
});

/**
 * Ошибка 422 при попытке использовать чужой сертификат
 * @type {Readonly<{code: string, message: string}>}
 */
export const CERTIFICATE_DENIED = Object.freeze({
  code: 'a3c0731f-2972-446a-a571-f4c4983d2fda',
  message: 'Сертификат принадлежит другому пользователю',
});

/**
 * Текст ошибки при неудачном редактировании заказа
 * @type {string}
 */

export const ERROR_EDIT = 'С текущим составом заказа и примененными скидками заказ не может быть сохранен';

/**
 * Редактирование для заказа с оплатой наличными
 * @type {string}
 */
export const EDIT_NOTICE_FOR_CASH = 'Вы можете внести изменения в заказ в течение 30 минут';

/**
 * Редактирование для заказа с предоплатой
 * @type {string}
 */
export const EDIT_NOTICE_FOR_PREPAY = 'Вы можете внести изменения в заказ, пока он не оплачен';

/**
 * Предупреждение при редактировании заказа
 * @type {string}
 */
export const EDIT_WARN = 'Обратите внимание, при изменении состава заказа применённые акции будут пересчитаны.';
