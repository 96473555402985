<template>
  <picture v-if="!isErrorLoad && item" :class="classPicture">
    <card-adult
      v-if="isAdult"
      :show-badge="showAdultBadge"
    />
    <source
      v-if="webpSrcset.length"
      type="image/webp"
      v-bind="vBindSourceWebp"
    />
    <source
      v-if="srcset.length"
      :type="`image/${getExt(item.x2)}`"
      v-bind="vBindSource"
    />
    <img
      :class="[classImg, { [lazyLoadClassName]: isLazyLoad }]"
      v-bind="vBindImg"
      :alt="alt"
      :title="title"
      @error="onError"
      @load="onLoad"
    />
  </picture>
  <img
    v-else-if="item && isErrorLoad"
    :class="classImg"
    :data-srcset="`${fallbackImage.x1}, ${fallbackImage.x2} 2x`"
    :src="fallbackImage.x1"
    :alt="alt"
    :title="title"
  />
</template>

<script>
import { productImage } from '@/utils/constants/catalogConstants';
import CardAdult from '@/components/ProductCard/CardAdult';

export default {
  name: 'AppPicture',
  components: { CardAdult },
  props: {
    fallbackImage: {
      type: Object,
      default: () => (productImage),
    },
    item: {
      type: Object,
      required: true,
    },
    classImg: {
      type: String,
      default: '',
    },
    classPicture: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    isLazyLoad: {
      type: Boolean,
      default: true,
    },
    lazyLoadClassName: {
      type: String,
      default: 'lazyload',
    },
    notUseFallback: {
      type: Boolean,
      default: false,
    },
    isAdult: {
      type: Boolean,
      default: false,
    },
    showAdultBadge: {
      type: Boolean,
      default: false,
    },
    isFullSize: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isErrorLoad: false,
    isItemChanged: false,
  }),
  computed: {
    mainSrc() {
      return this.isLazyLoad ? this.fallbackImage.x1 : this.item.x1;
    },
    mainDataSrc() {
      return this.isLazyLoad ? this.item.x1 : null;
    },
    srcset() {
      let srcset = '';
      if (this.item.x2) {
        srcset += `${this.isLazyLoad ? this.fallbackImage.x2 : this.item.x2} 2x`;
      }

      return srcset;
    },
    dataSrcset() {
      let srcset = null;
      if (this.item.x2 && this.isLazyLoad) {
        srcset = `${this.item.x2} 2x`;
      }

      return srcset;
    },
    webpSrcset() {
      let srcset = '';
      if (this.isFullSize && this.item.webpX2) {
        srcset += this.isLazyLoad ? this.fallbackImage.webpX2 : this.item.webpX2;
        return srcset;
      }

      if (this.item.webpX1) {
        srcset += this.isLazyLoad ? this.fallbackImage.webpX1 : this.item.webpX1;
      }
      if (this.item.webpX2) {
        srcset += `, ${this.isLazyLoad ? this.fallbackImage.webpX2 : this.item.webpX2} 2x`;
      }

      return srcset;
    },
    dataWebpSrcset() {
      let srcset = '';
      if (this.isFullSize && this.item.webpX2 && this.isLazyLoad) {
        srcset += this.item.webpX2;
        return srcset;
      }

      if (this.item.webpX1 && this.isLazyLoad) {
        srcset += this.item.webpX1;
      }
      if (this.item.webpX2 && this.isLazyLoad) {
        srcset += `, ${this.item.webpX2} 2x`;
      }

      return srcset;
    },
    vBindImg() {
      const attrs = {
        src: this.mainSrc,
      };
      if (this.mainDataSrc) {
        attrs['data-src'] = this.mainDataSrc;
      }
      return attrs;
    },
    vBindSource() {
      const attrs = {
        srcset: this.srcset,
      };
      if (this.dataSrcset) {
        attrs['data-srcset'] = this.dataSrcset;
      }
      return attrs;
    },
    vBindSourceWebp() {
      const attrs = {};
      if (this.webpSrcset) {
        attrs.srcset = this.webpSrcset;
      }
      if (this.dataWebpSrcset) {
        attrs['data-srcset'] = this.dataWebpSrcset;
      }
      return attrs;
    },
  },
  watch: {
    item() {
      if (!this.isItemChanged) this.isItemChanged = true;
    },
  },
  methods: {
    onLoad(data) {
      if (this.isLazyLoad) {
        data.target.classList.add('_loaded');
        this.$emit('pictureLoaded');
      }
    },
    getExt(pic) {
      return pic.split('.').pop();
    },
    onError() {
      if (!this.notUseFallback) {
        this.isErrorLoad = true;
      }
    },
  },
};
</script>

