<template>
  <button-control
    :size="size"
    :type="type"
    :is-block="isBlock"
    class="download-button"
    @click="clickHandler"
  >
    <download width="24" height="24" class="download-button__icon" />
    <div class="download-button__label">
      <slot></slot>
    </div>
  </button-control>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { ButtonControl } from '@book24/ui-components';

import useCommonModal from '@/compositions/useCommonModal';

import CertificatesList from '@/components/Certificates/CertificatesList';

// icons
import Download from '@/assets/sprite/svg/24/Download_24.svg';

export default defineComponent({
  name: 'DownloadButton',
  components: {
    ButtonControl,
    Download,
  },
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    type: {
      type: String,
      default: 'default',
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Object,
      default: () => {},
    },
    number: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const { isMobile, modal, getModalComponent } = useCommonModal();

    const clickHandler = () => {
      if (props.links.length === 1) {
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.setAttribute('target', '_blank');
        downloadLink.setAttribute('href', props.links[0]);
        downloadLink.setAttribute('download', `Сертификат №${props.number}`);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }

      modal.show({
        component: getModalComponent(),
        bind: {
          nameModal: 'CertificatesModal',
          contentClass: '_click-outside',
        },
        slots: {
          default: {
            component: CertificatesList,
            bind: {
              certificates: props.links,
              images: props.images,
              isMobile,
            },
          },
        },
      });
    };

    return { clickHandler };
  },
});
</script>

<style lang="less">
.download-button {
  &__icon {
    fill: @gray;
    transition: @A fill;
  }

  &__label {
    margin: 0 @size-x2;
    white-space: nowrap;
  }

  &:hover &__icon {
    fill: @white;
  }

  &._tap &__icon {
    fill: @white;
  }
}
</style>
