/**
 * Возвращаем итоговую стоимость корзины.
 * Если нет цены со скидкой, возвращаем базовую
 * @param price
 * @param withShipmentPrice
 */
export const getBasketTotalPrice = (price, withShipmentPrice = false) => {
  if (!price) return 0;

  const parsePrice = parseFloat(price?.discounted
    ? price.discounted
    : price.total);

  if (withShipmentPrice) {
    return parsePrice + parseFloat(price.delivery);
  }

  return parsePrice;
};
