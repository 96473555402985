// @ts-check
import HttpClient from '@/utils/clients/clientBookMain';
import ServiceError from '@/utils/errors/ServiceError';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';

export default class FilesService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  addFile(file) {
    return new Promise((resolve, reject) => {
      this.mainHttp.upload('/api/v1/file/', file)
        .then(({ data: { data: dataRes } }) => resolve(dataRes))
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);

            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
