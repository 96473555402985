// @ts-check
import { deserialize } from 'deserialize-json-api';
import HttpClient from '@/utils/clients/clientBookMain';
import { buildErrorCollection } from '@/utils/formatters/processingOpenApi';

export default class PolicyService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  getPolicy(isGetSeo = true) {
    const dataRequest = {};
    const include = [];
    if (isGetSeo) include.push('seoMeta');
    if (include.length) dataRequest.include = include.join(',');

    return new Promise((resolve, reject) => this.mainHttp.get('/api/v1/policy/', dataRequest)
      .then(({ data: dataRes }) => {
        // TODO: После выкатки бэкенда удалить это
        if (dataRes.data.data) {
          const { data } = deserialize(dataRes.data);
          resolve(data);
        }

        const { data } = deserialize(dataRes);
        resolve(data);
      })
      .catch((e) => {
        if (e.response) {
          const errors = buildErrorCollection(e.response.data);
          // знаем точно что ожидаем одну ошибку
          const [firstError] = errors;
          return reject(firstError);
        }
        return reject(e);
      }));
  }
}
