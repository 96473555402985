<template>
  <span class="checkbox-policy-text">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'CheckboxPolicyText',
};
</script>

<style lang="less">
.checkbox-policy-text {
  .small;

  &._long-text {
    line-height: 20px;
  }
}
</style>
