<template>
  <div
    :class="{ _mobile: isMobile }"
    class="adult-cart"
  >
    <div class="adult-cart__wrapper">
      <h3 class="adult-cart__title">
        {{ title }}
      </h3>
      <p class="adult-cart__text">
        {{ prependCartText }}
      </p>
      <div class="adult-cart__products">
        <product-card-info
          v-for="product of cartListAdultProducts"
          :key="product.id"
          :product="product"
          :is-adult="!isMobile"
        />
      </div>
      <p class="adult-cart__text">
        {{ appendCartText }}
      </p>
      <div class="adult-cart__buttons">
        <button-control
          size="large"
          class="adult-cart__confirm-button"
          :loading="isLoading"
          :disabled="isLoading"
          @click="clickConfirmHandler"
        >
          Да, мне есть 18 лет
        </button-control>
        <button-control
          :disabled="isLoading"
          type="secondary"
          class="adult-cart__back-button"
          @click="clickRemoveProductsHandler"
        >
          Удалить {{ countAdultProducts }} {{ label }} и продолжить
        </button-control>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { ButtonControl } from '@book24/ui-components';

import useAdult from '@/compositions/adult/useAdult';

import ProductCardInfo from '@/components/PersonalOrders/ProductCardInfo';
import plural from '@/utils/plural';

export default defineComponent({
  name: 'AdultCart',
  components: {
    ButtonControl,
    ProductCardInfo,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const adult = useAdult();

    const label = plural(adult.countAdultProducts.value, ['книгу', 'книги', 'книг']);

    return {
      ...adult,
      label,
    };
  },
});
</script>

<style lang="less">
.adult-cart {
  width: 356px;
  box-sizing: content-box;
  padding: @size-x4;

  &__products {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    .h-reset;
    .h3-desktop;

    margin-bottom: @size-x3;
  }

  &__text {
    .p(28px);

    margin-bottom: @size-x5;
  }

  &__confirm-button {
    margin-bottom: @size-x4;
    width: 100%;
  }

  &__back-button {
    width: 100%;
  }

  &._mobile {
    width: auto;
    padding: 0;
  }

  &._mobile & {
    &__title {
      .h4-desktop;
    }

    &__text {
      .small;
    }
  }

  &._full {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: @white;
  }

  &._full & {
    &__wrapper {
      width: 500px;
      margin: 65px auto;
    }

    &__title {
      .h2-desktop;

      margin-top: @size-x9;
    }

    &__text {
      margin-bottom: @size-x8;
    }

    &__buttons {
      display: flex;
      gap: @size-x3;
    }

    &__confirm-button {
      margin-bottom: 0;
    }

    &__back-button {
      margin-top: 0;
    }
  }

  .b24-btn + .b24-btn {
    margin-left: 0;
  }
}
</style>
