<template>
  <div
    :class="{ _mobile: isMobile }"
    class="authorization-cashback-tooltip"
  >
    Дополнительный кэшбэк в размере 5% суммируется с кэшбэком по статусу участника в программе лояльности
    и начисляется за каждый заказ в интернет-магазине book24.ru.<br />
    Не начисляется:<br />
    <ul>
      <li>за первый заказ в интернет-магазине book24.ru;</li>
      <li>за покупку рекомендованных товаров;</li>
      <li>участникам программы лояльности со статусом «Бриллиант».</li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AuthorizationCashbackTooltip',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="less">
.authorization-cashback-tooltip {
  .small;

  padding: @size-x2 @size-x3;

  ul {
    margin-top: 0;
  }

  &._mobile {
    padding: @size-x2 0;
  }
}
</style>
