<template>
  <div class="product-card-badge">
    <svg-icon
      v-if="icon"
      class="product-card-badge__icon"
      :name="`16/${icon}`"
      width="16"
      height="16"
    />
    <span v-else class="product-card-badge__text">
      - {{ percent }}%
    </span>
  </div>
</template>

<script>
export default {
  name: 'ProductCardBadge',
  props: {
    icon: { type: String, default: '' },
    percent: { type: [String, Number], default: '' },
  },
};
</script>

<style lang="less">
.product-card-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  color: @white;
  background-color: @pink;
  min-height: 24px;
  padding: 0 @size-x2;
  border-radius: @main-size;

  &__icon {
    fill: currentColor;
    position: relative;
    z-index: 1;
    width: 16px;
    height: 16px;
  }

  &__text {
    .small;

    font-weight: bold;
    color: currentColor;
    text-align: center;
    z-index: 1;
  }

  &._badge-color-f6522e {
    background-color: #f6522e;
  }

  &._badge-color-ff7a2f {
    background-color: #ff7a2f;
    color: @black;
  }

  &._badge-color-f9c900 {
    background-color: #f9c900;
    color: @black;
  }

  &._badge-color-ce93d8 {
    background-color: #ce93d8;
    color: @black;
  }

  &._badge-color-a5d6a7 {
    background-color: #a5d6a7;
    color: @black;
  }

  &._badge-color-80deea {
    background-color: #80deea;
    color: @black;
  }

  &._badge-color-90caf9 {
    background-color: #90caf9;
    color: @black;
  }

  &._badge-color-ffc400 {
    background-color: #ffc400;
    color: @black;
  }

  &._badge-color-fb8c00 {
    background-color: #fb8c00;
    color: @black;
  }

  &._badge-color-fe6562 {
    background-color: @pink;
    color: @black;
  }

  &._badge-color-e20338 {
    background-color: #e20338;
  }

  &._biblionight {
    width: 52px;
    height: 50px;
    margin: -25px -5px 0 auto;
    padding: 0;
    background-color: transparent;
    .icon('/_ssr/img/backgrounds/badges/biblionight_bg.svg?inline', 52px, 50px);
  }

  &._biblionight &__icon {
    width: 27px;
    height: 27px;
  }
}
</style>
