// @ts-check
/// <reference path="./typedefs.js" />
import { deserialize } from 'deserialize-json-api';
import HttpClient from '@/utils/clients/clientBookMain';
import { ERROR_NOT_FOUND } from '@/utils/constants/globalConst';
import { ACCESS_DENIED } from '@/utils/constants/personalOrderConstants';

import ServiceError from '@/utils/errors/ServiceError';
import { buildCollection, buildErrorCollection } from '@/utils/formatters/processingOpenApi';

export default class OrderService {
  constructor(config) {
    this.mainHttp = new HttpClient(config);
  }

  /**
   * Получает список заказов пользователя
   * @param { Object } params
   * @param {( 'cancelled' | 'done' | 'current' )} params.just - тип заказов
   * @param { number } [params.perPage = 5] - количество заказов на странице
   * @param { number } [params.pageNumber = 1] - текущая страница
   * @param { boolean } [params.isGetOrderLocation]
   * @param { boolean } [params.isGetCart] - добавлять ли корзину
   * @param { boolean } [params.isGetCartItem]
   * @param { boolean } [params.isGetCartProduct]
   * @param { boolean } [params.isGetCartProductSeries]
   * @param { boolean } [params.isGetCartProductAuthor]
   * @param { boolean } [params.isGetOrderAppliedCoupon] - примененные купоны
   * @param { boolean } [params.isGetOrderAvailableAction] - доступные действия с заказом
   * @param { boolean } [params.isGetOrderMark]
   * @param { boolean } [params.isGetOrderShipment] - информация о доставке
   * @param { boolean } [params.isGetOrderPayment] - информация об оплате
   * @param { boolean } [params.isGetOrderPropertyValue]
   * @return { Promise<OrderList> }
   */
  getUserOrders(params) {
    return new Promise((resolve, reject) => {
      let payload = {
        just: params.just,
      };

      const include = [];

      if (params.pageNumber) {
        payload.page = params.pageNumber;
      }

      if (params.pageNumber) {
        payload['per-page'] = params.perPage;
      }

      if (params.isGetOrderLocation) include.push('orderLocation');
      if (params.isGetCart) include.push('basket');
      if (params.isGetCartItem) include.push('basket.basketItem');
      if (params.isGetCartProduct) {
        include.push('basket.basketItem.product');
        include.push('cart.cartItem.product');
      }
      if (params.isGetCartProductSeries) include.push('basket.basketItem.product.series');
      if (params.isGetCartProductAuthor) include.push('basket.basketItem.product.author');
      if (params.isGetOrderAppliedCoupon) include.push('orderAppliedCoupon');
      if (params.isGetOrderAvailableAction) include.push('orderAvailableAction');
      if (params.isGetOrderMark) include.push('orderMark');
      if (params.isGetOrderShipment) include.push('orderShipment');
      if (params.isGetOrderPayment) include.push('orderPayment');
      if (params.isGetOrderPropertyValue) include.push('orderPropertyValue');

      if (include.length) payload = { ...payload, include: include.join(',') };

      this.mainHttp.get('/api/v1/order/', payload)
        .then(({ data: { data: dataRes } }) => {
          const { list, pagination } = buildCollection(dataRes, true);
          return resolve({
            items: list,
            pagination,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Получает заказ пользователя по id
   * @param { number } id - номер заказа
   * @param { Object } params
   * @param { boolean } [params.isGetOrderLocation]
   * @param { boolean } [params.isGetCart] - добавлять ли корзину
   * @param { boolean } [params.isGetCartItem]
   * @param { boolean } [params.isGetCartProduct]
   * @param { boolean } [params.isGetCartProductSeries]
   * @param { boolean } [params.isGetCartProductAuthor]
   * @param { boolean } [params.isGetCartProductCategory]
   * @param { boolean } [params.isGetCartProductPublishingHouse]
   * @param { boolean } [params.isGetOrderAppliedCoupon] - примененные купоны
   * @param { boolean } [params.isGetOrderAvailableAction] - доступные действия с заказом
   * @param { boolean } [params.isGetOrderMark]
   * @param { boolean } [params.isGetOrderShipment] - информация о доставке
   * @param { boolean } [params.isGetOrderPayment] - информация об оплате
   * @param { boolean } [params.isGetOrderPropertyValue]
   * @param { boolean } [params.isGetCustomerAttraction] - пользовательский рейтинг для товара
   * @param { boolean } [params.isGetCartBonus]
   * @param { boolean } [params.isGetOrderStats]
   * @param { boolean } [params.isGetPdfCertificate] - получать ссылки на PDF сертификаты
   * @param { boolean } [params.isGetBasketItemProduct] - получать ли параметры того откуда добавили
   * @return { Promise<OrderItem> }
   */
  getOrderItem(id, params = {}) {
    return new Promise((resolve, reject) => {
      let payload = {};

      const include = [];

      if (params.isGetOrderLocation) include.push('orderLocation');
      if (params.isGetCart) include.push('basket');
      if (params.isGetCartItem) include.push('basket.basketItem');
      if (params.isGetCartProduct) {
        include.push('cart.cartItem.product');
        include.push('basket.basketItem.product');
        include.push('basket.basketItem.product.orsProduct');
      }
      if (params.isGetCartProductSeries) include.push('basket.basketItem.product.series');
      if (params.isGetCartProductAuthor) include.push('basket.basketItem.product.author');
      if (params.isGetCartProductPublishingHouse) include.push('basket.basketItem.product.publishingHouse');
      if (params.isGetCartProductCategory) {
        include.push('basket.basketItem.product.category');
        include.push('basket.basketItem.product.category.parentCategory');
      }
      if (params.isGetOrderAppliedCoupon) include.push('orderAppliedCoupon');
      if (params.isGetOrderAvailableAction) include.push('orderAvailableAction');
      if (params.isGetOrderMark) include.push('orderMark');
      if (params.isGetOrderShipment) include.push('orderShipment');
      if (params.isGetOrderPayment) include.push('orderPayment');
      if (params.isGetOrderPropertyValue) include.push('orderPropertyValue');
      if (params.isGetCustomerAttraction) include.push('basket.basketItem.product.customerProductAttraction');
      if (params.isGetCartBonus) {
        include.push('basket.basketItem.basketItemBonusPart');
        include.push('basket.basketItem.basketItemBonusPart');
      }
      if (params.isGetOrderStats) include.push('orderStats');
      if (params.isGetPdfCertificate) include.push('orderPdfCertificate');
      if (params.isGetBasketItemProduct) include.push('basket.basketItem.basketItemProperty');

      if (include.length) payload = { ...payload, include: include.join(',') };

      this.mainHttp.get(`/api/v1/order/${id}/`, payload)
        .then(({ data: { data: dataRes } }) => {
          const item = deserialize(dataRes);
          return resolve(item.data);
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;

            if (e.response.status === 404) {
              return reject(new ServiceError(ERROR_NOT_FOUND));
            }
            if (e.response.status === 403) {
              return reject(new ServiceError(ACCESS_DENIED));
            }

            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Статистика по заказам текущего пользователя
   * @return {Promise<OrderStats>}
   */
  getOrderStats() {
    return new Promise((resolve, reject) => {
      this.mainHttp.get('/api/v1/customer-order-stats/')
        .then(({ data: { data: dataRes } }) => {
          const { list } = buildCollection(dataRes, false);
          return resolve({
            items: list,
          });
        })
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }

  /**
   * Отменяет заказ по переданному id
   * @param { Object} payload
   * @param { number } payload.orderId
   * @param { string } payload.reason
   * @return {Promise<void>}
   */
  cancelOrder(payload) {
    return new Promise((resolve, reject) => {
      this.mainHttp.put(`/api/v1/sale/order/${payload.orderId}/cancel/`, {
        reason: payload.reason,
      },
      {
        isFormData: true,
      })
        .then(() => resolve())
        .catch((e) => {
          if (e.response) {
            const errors = buildErrorCollection(e.response.data);
            const [firstError] = errors;
            return reject(new ServiceError(firstError));
          }
          return reject(new ServiceError(e));
        });
    });
  }
}
