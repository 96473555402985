<template>
  <embla-carousel
    class="shelf-products"
    :class="{ '_open-modal': openedModal }"
    :options="options"
    itemscope
    itemtype="https://schema.org/OfferCatalog"
  >
    <embla-slide
      v-for="(product, index) in products"
      :key="product.id"
      class="shelf-products__item"
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/Offer"
    >
      <product-card
        :params-link="product.paramsLink"
        :product="product"
        :with-badge="false"
        :with-label="false"
        :show-buttons="showButtons"
        :product-index="index"
        :is-fetch-basket-after-add-to-basket="isFetchBasketAfterAddToBasket"
        :is-loading-fetch-basket="isProductLoadingState(product.id)"
        @showFormSubscribeOnAvailableProduct="openProductModal"
        @addToBasket="handlerClickAddToBasket"
      />
    </embla-slide>
  </embla-carousel>
</template>

<script>
import EmblaCarousel from '@/components/EmblaCarousel';
import EmblaSlide from '@/components/EmblaSlideProduct';
import ProductCard from '@/components/ProductCard';

export default {
  components: {
    EmblaCarousel,
    EmblaSlide,
    ProductCard,
  },
  props: {
    products: { type: Array, default: () => ([]) },
    showButtons: { type: Boolean, default: true },
    isFetchBasketAfterAddToBasket: { type: Boolean, default: true },
    isLoadingFetchBasket: { type: Boolean, default: false },
    loadingProductId: { type: Number, default: null },
  },
  data() {
    return {
      openedModal: false,
      options: {
        containScroll: 'keepSnaps',
        loop: true,
      },
    };
  },
  methods: {
    openProductModal(isOpenProductModal) {
      this.openedModal = isOpenProductModal;
    },
    handlerClickAddToBasket(payload) {
      this.$emit('addToBasket', payload);
    },
    isProductLoadingState(productId) {
      if (this.loadingProductId && this.loadingProductId === productId) {
        return this.isLoadingFetchBasket;
      }

      return false;
    },
  },
};
</script>

<style lang="less">
.shelf-products {
  margin-right: @size-x6;

  & .embla__viewport {
    padding-right: @size-x6;
    margin-right: -@size-x6;
  }

  & .embla__container {
    margin-left: -@size-x6;
  }

  & .slider-arrow._prev {
    transform: translate(-50%, -50%);
  }

  & .slider-arrow._next {
    transform: translate(100%, -50%);
  }

  &__item {
    width: calc(237px + @size-x6);
    padding-left: @size-x6;
  }
}
</style>
