import { getItemListName } from '@/utils/analytics/getItemListName';

/**
 * Форматируем параметры того где отображается
 * товар (полка, индекс, страница) чтобы передать их
 * при запросе на добавление в корзину или отложенные
 * @param productIndex
 * @param routeName
 * @param shelfIndex
 * @param shelfName
 */
export const setTargetProperties = ({
  productIndex = 1,
  itemListName = false,
  shelfIndex = false,
  shelfName = false,
}) => {
  const params = {
    index: productIndex,
    itemListName: getItemListName(itemListName),
  };

  if (shelfIndex) params.indexShelf = shelfIndex;
  if (shelfName) params.productShelf = shelfName;

  return params;
};
