import { computed, reactive, toRefs, onMounted } from '@nuxtjs/composition-api';

import { getDifferenceBetweenDateTypes, parseDateISO } from '@/utils/dateTime';
import { getLatestVerificationIssue } from '@/utils/getLatestVerificationIssue';
import { CODE_LENGTH } from '@/utils/constants/authorizationConstants';

export const useSms = () => {
  const localState = reactive({
    code: '',
    codeError: '',
    codeLoading: false,
    timeToResend: 0,
  });

  const codeIsComplete = computed(() => (localState.code.length === CODE_LENGTH));

  const getTimeout = to => getDifferenceBetweenDateTypes(parseDateISO(to), Date.now(), 'milliseconds');

  const getSmsCodeTimeout = (transitions) => {
    if (!transitions) return 0;
    const latestVerificationIssue = getLatestVerificationIssue(transitions);
    if (!latestVerificationIssue) return 0;
    return getTimeout(latestVerificationIssue.tokenExpiredAt);
  };

  const setTimer = (data) => {
    const isDataNumeric = typeof data === 'number';
    const timeLeft = isDataNumeric ? data : getSmsCodeTimeout(data);
    if (timeLeft <= 0) return;
    localState.timeToResend = timeLeft;
  };

  onMounted(() => {
    localState.code = '';
    localState.codeError = '';
  });

  return {
    ...toRefs(localState),
    codeIsComplete,
    setTimer,
  };
};
