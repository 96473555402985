<template>
  <div
    :class="{ _mobile: isMobile }"
    class="authorization-registration"
  >
    <h3 class="authorization-registration__title">
      Регистрация
    </h3>

    <p
      v-if="!isAutocomplete || isAutocompleteError"
      class="authorization-registration__desc"
    >
      Осталось совсем немного: пожалуйста, заполните эту короткую анкету.
    </p>

    <div class="authorization-registration__change-phone">
      <button-control
        type="more"
        size="small"
        tabindex="1"
        class="authorization-registration__back-button _left"
        @click="registrationByAnotherPhone"
      >
        <arrow-back class="authorization-registration__arrow-back" />
        {{ buttonTextAnotherPhone }}
      </button-control>
    </div>

    <union-loyalty
      v-if="isAutocomplete"
      :is-autocomplete="true"
      class="authorization-registration__union-loyalty"
    />

    <form
      id="authorization-registration"
      @submit.prevent="submitHandler"
    >
      <form-item
        v-bind="fieldValidation('firstName')"
        :is-gray-label="true"
        label="Имя"
        class="authorization-registration__input"
      >
        <input-control
          v-model.trim="firstName"
          name="firstName"
          autocomplete="off"
          tabindex="2"
        />
      </form-item>

      <form-item
        v-bind="fieldValidation('lastName')"
        :is-gray-label="true"
        label="Фамилия"
        class="authorization-registration__input"
      >
        <input-control
          v-model.trim="lastName"
          name="lastName"
          autocomplete="off"
          tabindex="3"
        />
      </form-item>

      <form-item
        v-bind="fieldValidation('email')"
        :is-gray-label="true"
        :class="{ '_error': emailAlreadyTaken }"
        label="Электронная почта"
        class="authorization-registration__input"
      >
        <input-control
          v-model.trim="email"
          :loading="domainChecking"
          tabindex="4"
          name="email"
          inputmode="email"
          autocomplete="on"
        />
        <template v-if="emailAlreadyTaken" #componentBefore>
          Этот адрес уже привязан к другой учетной записи.
          Пожалуйста, напишите нам
          <app-link href="/contacts/" blank>
            в обратную связь
          </app-link> для актуализации данных или укажите другой адрес.
        </template>
      </form-item>

      <form-item class="authorization-registration__checkbox-data">
        <checkbox-control
          v-model="subscribed"
          :model-value="true"
          tabindex="5"
        >
          <checkbox-policy-text class="_long-text">
            Соглашаюсь получать письма о скидках и новинках
            <div class="authorization-registration__hint">
              Дарим 50 бонусов за подписку и дополнительный кэшбэк
              <div class="authorization-registration__hint-inline">
                <v-popover
                  v-if="!isMobile"
                  class="authorization-registration__tooltip"
                  offset="8"
                  container=".modal-content"
                >
                  <button
                    type="button"
                    class="authorization-registration__info-btn"
                  >
                    <info-icon
                      class="authorization-registration__info-icon"
                      :width="16"
                      :heigh="16"
                    />
                  </button>

                  <template slot="popover">
                    <div class="authorization-registration__popover">
                      <cashback-tooltip />
                    </div>
                  </template>
                </v-popover>
                <button
                  v-else
                  type="button"
                  class="authorization-registration__info-btn"
                  @click="showInfoHandler"
                >
                  <info-icon
                    class="authorization-registration__info-icon"
                    :width="16"
                    :heigh="16"
                  />
                </button>
              </div>
            </div>
          </checkbox-policy-text>
        </checkbox-control>
      </form-item>

      <form-item
        :text-error="errorTextPolicy"
        class="authorization-registration__input _policy"
      >
        <checkbox-control
          v-model="isAgreeWithRules"
          :model-value="true"
          tabindex="6"
        >
          <checkbox-policy-text class="authorization-registration__rules _long-text">
            Я подтверждаю достижение возраста 18 лет, принимаю условия
            <app-link class="form-controls__link" href="/pravila/" blank>
              Пользовательского соглашения
            </app-link>
            и <app-link
              class="form-controls__link"
              href="/terms/cashback/"
              blank
            >
              Правил программы лояльности
            </app-link>
          </checkbox-policy-text>
        </checkbox-control>
      </form-item>

      <notification
        v-if="isAlert && isAutocomplete"
        type="warning"
        class="authorization-registration__update-alert"
      >
        Мы обновим анкетные данные в ваших
        профилях на сайтах-партнерах, чтобы
        бонусная карта продолжила работать.
      </notification>

      <union-loyalty
        v-if="isAutocompleteError"
        class="authorization-registration__union-loyalty"
      />

      <button-control
        :is-block="true"
        :loading="isLoading"
        :disabled="buttonDisabled || domainChecking"
        :is-added-disabled-class-name="buttonDisabled || domainChecking"
        size="large"
        native-type="submit"
        tabindex="7"
      >
        {{ buttonText }}
      </button-control>
    </form>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import {
  FormItem,
  InputControl,
  ButtonControl,
  CheckboxControl,
  Notification,
} from '@book24/ui-components';

import useRegistration from '@/compositions/authorization/useRegistration';

import CheckboxPolicyText from '@/components/CheckboxPolicyText/index.vue';
import CashbackTooltip from '@/components/Authorization/CashbackTooltip/index.vue';
import UnionLoyalty from '@/components/Authorization/UnionLoyalty/index.vue';

// icons
import InfoIcon from '@/assets/sprite/svg/16/Info_16.svg';
import ArrowBack from '@/assets/sprite/svg/16/Arrow_back_16.svg';

export default defineComponent({
  name: 'AuthorizationRegistration',
  components: {
    Notification,
    FormItem,
    InputControl,
    ButtonControl,
    CheckboxControl,
    InfoIcon,
    ArrowBack,
    CheckboxPolicyText,
    CashbackTooltip,
    UnionLoyalty,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    return { ...useRegistration(props, { emit }) };
  },
});
</script>

<style lang="less">
.authorization-registration {
  &__title {
    margin-top: 0;
  }

  &__desc {
    .p2;

    margin: 0;
  }

  &__union-loyalty {
    margin-bottom: @size-x5;
  }

  &__update-alert {
    margin-bottom: @size-x4;
  }

  &__rules._long-text {
    line-height: @size-x6;
  }

  &__checkbox-data.b24-form-item {
    margin-bottom: @size-x3;
  }

  &__hint {
    .extra-small;

    color: @gray-dark;
  }

  &__hint-inline {
    display: inline-block;
  }

  &__info-btn {
    .button-reset;
  }

  &__info-icon {
    fill: @gray;
    width: @size-x4;
    height: @size-x4;
    flex: 0 0 @size-x4;
    margin-bottom: -2px;
    cursor: pointer;
  }

  &__tooltip {
    display: inline-block;
  }

  &__popover {
    padding: @size-x2 @size-x3;
    max-width: 360px;

    .tooltip-arrow {
      box-shadow: none !important;
    }
  }

  &__change-phone {
    margin: @size-x5 0;
  }

  &__back-button.b24-btn._small {
    .p;

    justify-content: flex-start;
    padding: 0;
    border: 0;
  }

  &__arrow-back {
    width: 12px;
    height: 12px;
    fill: currentColor;
    margin-right: @size-x2;
  }

  &__input.b24-form-item {
    margin-bottom: @size-x5;
    padding: 0 !important;

    &._policy .b24-form-item__desc._before {
      margin-left: 30px;
    }
  }

  &__input .b24-form-item__desc._before {
    position: relative;
  }

  &__input._error._success .b24-form-item__desc._before {
    color: @red-error;
  }

  &._mobile & {
    &__desc {
      .small;
    }

    &__back-button.b24-btn._small {
      .small;
    }
  }
}
</style>
