<template>
  <div
    class="product-card-price"
    :class="{
      _vertical: !isLine,
      _mobile: isMobile
    }"
  >
    <div
      class="product-card-price__current"
      :class="{ _narrow: !showOldPrice }"
    >
      <app-price
        :price="price"
        :has-microdata="false"
        :is-symbol-rub="true"
      />
    </div>
    <div v-if="showOldPrice" class="product-card-price__old">
      <app-price
        :price="oldPrice"
        :has-microdata="false"
        :is-symbol-rub="true"
        class="product-card-price__old-value"
      />
      <span v-if="showDiscount && discount" class="product-card-price__discount">
        - {{ discount }}%
      </span>
    </div>
  </div>
</template>

<script>
import AppPrice from '@/components/AppPrice';
import { checkMobile } from '@/utils/deviceCheck';

export default {
  name: 'CardPrice',
  components: {
    AppPrice,
  },
  props: {
    price: { type: [Number, String], required: true },
    oldPrice: { type: [Number, String], default: null },
    discount: { type: [Number, String], default: null },
    showDiscount: { type: Boolean, default: true },
    isLine: { type: Boolean, default: true },
  },
  computed: {
    showOldPrice() {
      return +this.oldPrice && +this.oldPrice !== +this.price;
    },
    isMobile() {
      return checkMobile();
    },
  },
};
</script>

<style lang="less">
.product-card-price {
  .p;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-self: baseline;

  &._mobile {
    margin: 0;
  }

  &__current {
    font-weight: bold;
    margin-right: @size-x2;

    @media @mobile {
      &._narrow {
        margin-right: 0;
      }
    }
  }

  &__old {
    display: flex;
  }

  &__old-value {
    .small;

    text-decoration: line-through;
    color: @gray-dark;
  }

  &__discount {
    .small;

    border-radius: @main-size;
    background-color: @pink;
    color: @white;
    font-weight: bold;
    padding: 0 @size-x2;
  }

  &__old-value + &__discount {
    margin-left: @size-x2;
  }

  &._vertical {
    flex-direction: column;
  }

  &._vertical &__old {
    margin-left: 0;
    align-items: center;
  }

  &._vertical &__discount {
    font-size: 12px;
    line-height: 16px;
    padding: 0 @main-size;
  }
}
</style>
